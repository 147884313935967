/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { notificationShowAction } from '../../store/slices/utils';
import {
  selectAcceptFightData,
  selectIsActiveFight,
  selectNoPawnsError,
  selectPlayers,
  selectSearchFight,
} from '../../store/slices/arena';
import { handleCancelFindingFight, handleFindFight, handleMakeFightRequest } from '../../utils/arenaSocket';
import Header from '../Marketplace/Components/Header';
import MobileLayout from '../MobileLayout';
import MobileTitle from './MobileTitle';
import uniqueId from '../../utils/uniqueId';
import { selectEvm } from '../../store/slices/auth';
import loader from '../../static/img/loader.gif';
import InfoButton from './MatchmakingButton';
import styles from './Arena.module.scss';
import matchmakingModal from './MatchmackingModal';
import MatchmakingModal from './MatchmackingModal';

const ArenaHub = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [activeFightOption, setActiveFightOption] = useState('OneVsOne');
  const [isClick, setIsClick] = useState(false);

  const address = useSelector(selectEvm);
  const isActiveFight = useSelector(selectIsActiveFight);
  const { players } = useSelector(selectPlayers);
  const { noPawnsError } = useSelector(selectNoPawnsError);
  const isSearch = useSelector(selectSearchFight);
  const isAcceptFightModal = useSelector(selectAcceptFightData);

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const onResize = (e) => {
      setWidth(e.target.innerWidth);
    };
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    if (noPawnsError) {
      dispatch(notificationShowAction(false, 'User has not pawns'));
    }
  }, [noPawnsError]);

  useEffect(() => {
    if (isActiveFight) {
      navigate('/battle');
    }
  }, [isActiveFight]);

  const renderContent = () => (
    <div className={styles.bodyRight}>
      <div className={styles.bodyRightContainer}>
        <div className={styles.usersWindow}>
          <div className={styles.usersWindowTitle}>
            <span>
              Online players:
              {` ${players.length}`}
            </span>
          </div>
          <div className={styles.usersWindowContainer}>
            {
              players.map((player) => (
                <div key={uniqueId('player')}>
                  <div className={styles.usersWindowContainer__user}>
                    <span className={styles.usersWindowContainer__user__login}>{player.discordName || 'BOT'}</span>
                    <span
                      role="presentation"
                      className={styles.usersWindowContainer__user__match}
                      onClick={() => handleMakeFightRequest(player.address, activeFightOption)}
                    />
                  </div>
                  <div className={styles.usersWindowContainer__separator} />
                </div>
              ))
            }
          </div>
        </div>
        <div className={styles.usersWindowScroll}>
          <div className={styles.usersWindowScroll__marker} />
        </div>
      </div>
      <div className={styles.pick}>
        <button
          type="button"
          className={`
            ${styles.one_vs_one}
            ${activeFightOption === 'OneVsOne' ? styles.one_active : ''}
          `}
          onClick={() => setActiveFightOption('OneVsOne')}
        >
          one
        </button>
        <button
          type="button"
          className={`
            ${styles.two_vs_two}
            ${activeFightOption === 'TwoVsTwo' ? styles.two_active : ''}
          `}
          onClick={() => setActiveFightOption('TwoVsTwo')}
        >
          two
        </button>
        <button
          type="button"
          className={`
            ${styles.three_vs_three}
            ${activeFightOption === 'ThreeVsThree' ? styles.three_active : ''}
          `}
          onClick={() => setActiveFightOption('ThreeVsThree')}
        >
          three
        </button>
      </div>
      <div
        className={styles.wrapper_button_match}
        role="presentation"
        onMouseUp={() => setIsClick(false)}
        onMouseDown={() => setIsClick(true)}
        onClick={() => isSearch ? handleCancelFindingFight() : handleFindFight(activeFightOption)}
      >
        <InfoButton
          text={isSearch ? 'Cancel' : 'Matchmaking' }
          isClick={isClick}
        />
        {isSearch ? (
          <div className={styles.loader}>
            <img src={loader} alt="loading" />
          </div>
        ) : null}
      </div>
    </div>
  );

  return (
    width > 880 ? (
      <>
        <Header address={address} />
        {isAcceptFightModal ? <MatchmakingModal/> : null}
        <div className={styles.mainWindow}>
          <Link to="/" className="closeWindow"><button type="button">&#10006;</button></Link>
          <div className={styles.container}>
            <div className={styles.header}>
              <div className={styles.separator} />
              <div className={styles.titleBox}>
                <span>Arena hub</span>
              </div>
              <div className={styles.separator} />
            </div>
            <div className={styles.body}>
              <div className={styles.bodyLeft} />
              {renderContent()}
              <div className={styles.separator} />
            </div>
          </div>
        </div>
      </>
    ) : (
      <MobileLayout flag>
        <div className={styles.container}>
          <MobileTitle>Arena Hub</MobileTitle>
          <div className={styles.body}>
            <div className={styles.bodyLeft} />
            {renderContent()}
          </div>
          <div className={`${styles.separator} ${styles.reverse} ${styles.margin}`} />
        </div>
      </MobileLayout>
    )
  );
};

export default ArenaHub;
