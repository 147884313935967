import React from 'react';
import styles from '../Arena.module.scss';

const MobileTitle = ({ children }) => (
  <div className={styles.header}>
    <div className={styles.separator} />
    <div className={styles.titleBox}>
      <span>{children}</span>
    </div>
    <div className={`${styles.separator} ${styles.reverse}`} />
  </div>
);

export default MobileTitle;
