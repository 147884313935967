import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleAcceptFoundFight, handleRejectFoundFight } from '../../../utils/arenaSocket';
import { acceptFightModal, selectAcceptFightData, selectIsOpponentAccepted } from '../../../store/slices/arena';
import buttonAgree from '../../../static/img/header/balancebar/dropdown/Accept button.png';
import buttonDesAgree from '../../../static/img/header/balancebar/dropdown/Decline button.png';
import AgreeHover from '../../../static/img/header/balancebar/dropdown/Accept button hover.png';
import buttonAgreeActive from '../../../static/img/header/balancebar/dropdown/Accept button hover pressed.png';
import DisagreeHover from '../../../static/img/header/balancebar/dropdown/Decline button hover.png';
import buttonDesAgreeActive from '../../../static/img/header/balancebar/dropdown/Decline button pressed.png';
import loader from '../../../static/img/loader.gif';
import styles from './Matchmacking.module.scss';

const MatchmakingModal = () => {
  const dispatch = useDispatch();

  const [agree, setAgree] = useState('');
  const [disagree, setDisagree] = useState('');

  const isAcceptFightModal = useSelector(selectAcceptFightData);
  const isOpponentAccepted = useSelector(selectIsOpponentAccepted);

  const agreeStatus = {
    hover: AgreeHover,
    pressed: buttonAgreeActive,
  };

  const disagreeStatus = {
    hover: DisagreeHover,
    pressed: buttonDesAgreeActive,
  };

  return (
    <div
      className={styles.modalWrapper}
      role="presentation"
      onClick={() => {
        handleRejectFoundFight();
        dispatch(acceptFightModal(null));
      }}
    >
      <div
        className={styles.modal}
        role="presentation"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <p className={styles.modal_text}>
          <span>
            {`${isAcceptFightModal.discordName || '?'} has challenged you to duel`}
          </span>
        </p>
        {isOpponentAccepted ? (
          <div className={styles.loader}>
            <img src={loader} alt="loading" />
          </div>
        ) : (
          <div className={styles.button_wrapper}>
            <button
              type="button"
              onMouseEnter={() => setAgree('hover')}
              onMouseDown={() => setAgree('pressed')}
              onMouseUp={() => setAgree('hover')}
              onMouseLeave={() => setAgree('')}
              onClick={() => handleAcceptFoundFight()}
            >
              <img src={agree ? agreeStatus[agree] : buttonAgree} alt="ok" />
            </button>
            <button
              type="button"
              onMouseEnter={() => setDisagree('hover')}
              onMouseDown={() => setDisagree('pressed')}
              onMouseUp={() => setDisagree('hover')}
              onMouseLeave={() => setDisagree('')}
              onClick={() => handleRejectFoundFight()}
            >
              <img src={disagree ? disagreeStatus[disagree] : buttonDesAgree} alt="no" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MatchmakingModal;
