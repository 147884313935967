import React from 'react';
import { useSelector } from 'react-redux';
import styles from './CastingBar.module.scss';
import uniqueId from '../../../../utils/uniqueId';
import { selectSpellDescriptionByPawn } from '../../../../store/slices/arena';

const CastingBar = ({
  userData, opponentData, currentPlayerStatusBarPositions, opponentPlayerStatusBarPositions, transformScale,
}) => {
  const selectSpells = useSelector(selectSpellDescriptionByPawn);

  const handleAllSpells = () => {
    let allSpellsObj = {};
    Object.keys(selectSpells).forEach((id) => {
      allSpellsObj = { ...allSpellsObj, ...selectSpells[id] };
    });
    return allSpellsObj;
  };

  const spells = handleAllSpells();

  return (
    <div className={styles.wrapper}>
      <div className={styles.separator} />
      <div className={styles.body} style={{ height: `${32 * transformScale}px` }}>
        {userData && userData.length
          ? (
            userData.map((pawn, index) => {
              if (pawn.length && currentPlayerStatusBarPositions?.length) {
                return (
                  <div
                    className={styles.pawn}
                    style={{
                      left: `${currentPlayerStatusBarPositions[index].x}px`,
                      transform: `scale(${transformScale}) translate(-50%, 0)`,
                    }}
                    key={uniqueId('pawnCasting')}
                  >
                    {pawn.map((elem) => (
                      <img
                        key={uniqueId('castingSpell')}
                        src={spells[elem].image}
                        alt=""
                        width="32px"
                        height="32px"
                      />
                    ))}
                  </div>
                );
              }
              return null;
            })
          )
          : null}
        <div className={styles.casting} style={{ transform: `scale(${transformScale})` }}>
          Casting
        </div>
        {opponentData && opponentData.length
          ? (
            opponentData.map((pawn, index) => {
              if (pawn.length && opponentPlayerStatusBarPositions?.length) {
                return (
                  <div
                    className={styles.pawn}
                    style={{
                      left: `${opponentPlayerStatusBarPositions[index].x}px`,
                      transform: `scale(${transformScale}) translate(-50%, 0)`,
                    }}
                    key={uniqueId('pawnCasting')}
                  >
                    {pawn.map((elem) => (
                      <img
                        key={uniqueId('castingSpell')}
                        src={spells[elem].image}
                        alt=""
                        width="32px"
                        height="32px"
                      />
                    ))}
                  </div>
                );
              }
              return null;
            })
          )
          : null}
      </div>
      <div className={styles.separator} />
    </div>
  );
};

export default CastingBar;
