import React, { useEffect, useState } from 'react';
import styles from './TurnControl.module.scss';
// import checkImg from '../../../../static/img/battleWindow/Check button.png';
// import checkHover from '../../../../static/img/battleWindow/Check button hover.png';
// import checkPressed from '../../../../static/img/battleWindow/Check button pressed.png';
import passImg from '../../../../static/img/battleWindow/Pass button.png';
import passHover from '../../../../static/img/battleWindow/Pass button hover.png';
import passPressed from '../../../../static/img/battleWindow/Pass button pressed.png';
import backspace from '../../../../static/img/battleWindow/Backspace.png';
// import space from '../../../../static/img/battleWindow/space.png';
import { handlePassTurn } from '../../../../utils/arenaSocket';

const TurnControl = () => {
  // const [check, setCheck] = useState('');
  const [pass, setPass] = useState('');

  // const checkStatus = {
  //   hover: checkHover,
  //   pressed: checkPressed,
  // };

  const passStatus = {
    hover: passHover,
    pressed: passPressed,
  };

  const downHandler = (event) => {
    // if (event.code === 'Space') {
    //   setCheck('pressed');
    // }
    if (event.key === 'Backspace') {
      setPass('pressed');
      handlePassTurn();
    }
  };

  const upHandler = (event) => {
    // if (event.code === 'Space') {
    //   setCheck('');
    // }
    if (event.code === 'Backspace') {
      setPass('');
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', downHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [window]);

  useEffect(() => {
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keyup', upHandler);
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.button_wrapper}>
        <div className={styles.button_content}>
          <button
            type="button"
            className={styles.button}
            onMouseEnter={() => setPass('hover')}
            onMouseDown={() => setPass('pressed')}
            onMouseUp={() => setPass('hover')}
            onMouseLeave={() => setPass('')}
            onClick={() => {
              handlePassTurn();
            }}
            onKeyUp={(e) => upHandler(e)}
            onKeyDown={(e) => downHandler(e)}
          >
            <img src={pass ? passStatus[pass] : passImg} alt="ok" />
          </button>
          <div className={styles.hot_key_backspace}>
            <img src={backspace} alt="" />
          </div>
        </div>
        {/* <div className={styles.button_content}> */}
        {/*   <button */}
        {/*     type="button" */}
        {/*     className={styles.button1} */}
        {/*     onMouseEnter={() => setCheck('hover')} */}
        {/*     onMouseDown={() => setCheck('pressed')} */}
        {/*     onMouseUp={() => setCheck('hover')} */}
        {/*     onMouseLeave={() => setCheck('')} */}
        {/*     onKeyUp={(e) => upHandler(e)} */}
        {/*     onKeyDown={(e) => downHandler(e)} */}
        {/*   > */}
        {/*     <img src={check ? checkStatus[check] : checkImg} alt="ok" className={styles.passButton} /> */}
        {/*   </button> */}
        {/*   <div className={styles.hot_key_space}> */}
        {/*     <img src={space} alt="" /> */}
        {/*   </div> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default TurnControl;
