import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAddressEnemy, selectFightId,
  selectFightResults, setInviteFrom, setNavigate,
} from '../../../../../../store/slices/arena';
import openButton from '../../../../../../static/img/header/balancebar/dropdown/button-dropdown.png';
import buttonAgree from '../../../../../../static/img/header/balancebar/dropdown/Accept button.png';
import buttonDesAgree from '../../../../../../static/img/header/balancebar/dropdown/Decline button.png';
import {
  handleAcceptFightRequest, handleCancelFindingFight,
  handleLeaveTheFight,
  handleRejectFightRequest,
} from '../../../../../../utils/arenaSocket';
import uniqueId from '../../../../../../utils/uniqueId';
import { agreeStatus, disagreeStatus, dropdownStatus } from '../../../../../../utils/chooseData';
import styles from './FightNotification.module.scss';

const FightNotification = ({
  isNotification,
  setIsNotificationIndicator,
  setIsNotification,
  opponentAddress,
}) => {
  const dispatch = useDispatch();

  const [dropdownOpen, setDropdownOpen] = useState('');
  const [agree, setAgree] = useState('');
  const [disagree, setDisagree] = useState('');
  const opponent = useSelector(selectAddressEnemy);
  const fightId = useSelector(selectFightId);
  const duels = useSelector(selectFightResults);

  const dataOpponent = {
    name: opponent ? `${(opponent).slice(0, 5)}...${(opponent)
      .slice((opponent).length - 4, (opponent).length).toUpperCase()}` : '',
  };

  const handleButtonEvent = (accept) => {
    setIsNotificationIndicator(false);
    setIsNotification(false);
    if (accept) {
      handleAcceptFightRequest(opponentAddress);
      dispatch(setInviteFrom(null));
      // todo: maybe only when message receive??
      dispatch(setNavigate('/battle'));
      handleCancelFindingFight();
    }
    if (!accept) {
      setTimeout(() => {
        handleRejectFightRequest(opponentAddress);
        dispatch(setInviteFrom(null));
      }, 500);
    }
  };

  const content = useMemo(() => {
    if (fightId) {
      return (
        <div className={styles.text_content}>
          <div className={styles.text_wrapper}>
            <span>Do you want to leave from the fight ?</span>
          </div>
          <div className={styles.button_wrapper}>
            <button
              type="button"
              onMouseEnter={() => setAgree('hover')}
              onMouseDown={() => setAgree('pressed')}
              onMouseUp={() => setAgree('hover')}
              onMouseLeave={() => setAgree('')}
              onClick={() => {
                handleLeaveTheFight();
                setIsNotification(false);
              }}
            >
              <img src={agree ? agreeStatus[agree] : buttonAgree} alt="ok" />
            </button>
            <button
              type="button"
              onMouseEnter={() => setDisagree('hover')}
              onMouseDown={() => setDisagree('pressed')}
              onMouseUp={() => setDisagree('hover')}
              onMouseLeave={() => setDisagree('')}
              onClick={() => setIsNotification(false)}
            >
              <img src={disagree ? disagreeStatus[disagree] : buttonDesAgree} alt="no" />
            </button>
          </div>
        </div>
      );
    }
    if (opponentAddress) {
      return (
        <div className={styles.text_content}>
          <div className={styles.text_wrapper}>
            <span className={styles.text_red}>{dataOpponent.name}</span>
            <span>has challenged you to duel</span>
          </div>
          <div className={styles.button_wrapper}>
            <button
              type="button"
              onMouseEnter={() => setAgree('hover')}
              onMouseDown={() => setAgree('pressed')}
              onMouseUp={() => setAgree('hover')}
              onMouseLeave={() => setAgree('')}
              onClick={() => handleButtonEvent(true)}
            >
              <img src={agree ? agreeStatus[agree] : buttonAgree} alt="ok" />
            </button>
            <button
              type="button"
              onMouseEnter={() => setDisagree('hover')}
              onMouseDown={() => setDisagree('pressed')}
              onMouseUp={() => setDisagree('hover')}
              onMouseLeave={() => setDisagree('')}
              onClick={() => handleButtonEvent(false)}
            >
              <img src={disagree ? disagreeStatus[disagree] : buttonDesAgree} alt="no" />
            </button>
          </div>
        </div>
      );
    }
    return null;
  }, [opponentAddress, fightId, disagree, agree, isNotification]);

  return (
    <div className={`${styles.wrapper} ${isNotification ? styles.open : ''} `}>
      <div className={styles.top_header} />
      <div className={styles.bottom}>
        <div className={styles.bottom_left} />
        <div className={styles.bottom_centre}>
          <div
            className={styles.bottom_img}
            role="presentation"
            onMouseEnter={() => setDropdownOpen('hover')}
            onMouseDown={() => setDropdownOpen('pressed')}
            onMouseUp={() => setDropdownOpen('hover')}
            onMouseLeave={() => setDropdownOpen('')}
            onClick={() => setIsNotification(false)}
          >
            <img src={dropdownOpen ? dropdownStatus[dropdownOpen] : openButton} alt="btn" />
          </div>
          <div className={styles.bottom_line} />
        </div>
        <div className={styles.bottom_right} />
      </div>
      <div className={styles.main_content}>
        <div className={styles.duel_wrapper}>
          {duels ? duels.map((duel) => (
            <div key={uniqueId('fightNotification')}>
              <span
                className={`${styles.text} ${duel === 'won' ? styles.won : ''} ${duel === 'draw' ? styles.draw : ''}`}
              >
                {`Duel ${duel}`}
              </span>
              <div className={styles.separator} />
            </div>
          )) : null}
        </div>
        {content}
      </div>
      <div className={styles.left} />
      <div className={styles.right} />
    </div>
  );
};

export default FightNotification;
