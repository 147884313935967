import React, {
  useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Inventory from '../Presale/Inventory';
import HeadContent from '../HeadContent';
import Pagination from '../Presale/Pagination';
import ButtonsListBuy from '../Portraits/ButtonsListBuy';
import {
  boughtSuccessfully, getNewPawn,
  getPawnsBuy, getPawnsSell, listedSuccessfully, selectMarketplace, unListedSuccessfully,
} from '../../../../store/slices/requests';
import {
  clearPawn, getPawn, getPawnSuccess, selectPawn, selectPawnLoading,
} from '../../../../store/slices/pawnSelect';
import Item from './Item/Item';
import Filters from './Filters';
import { notificationShowAction } from '../../../../store/slices/utils';
import {
  amIWhitelisted, onNewOffering, onOfferingCancelled, onOfferingClosed,
} from '../../../../utils/requests';
import {
  buyItemSuccess,
  clearUnListId,
  listItemSuccess, selectIsUnListSuccess,
  unlistItemSuccess,
} from '../../../../store/slices/tradeItem';
import { selectEvm } from '../../../../store/slices/auth';
import { defaultPawnValue } from '../../../../constants/stats';
import { dividerCommaFormat } from '../../../../utils/format';
import styles from './Pawns.module.scss';
import { mainNetId } from '../../../../utils/chooseData';

const Pawns = () => {
  const dispatch = useDispatch();
  const [type, setType] = useState('buy');
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState('Filter');
  const [sort, setSort] = useState('Sort by');
  const [tier, setTier] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  const [search, setSearch] = useState('');
  const [canMint, setCanMint] = useState(false);
  const [mintInfoReceived, setMintInfoReceived] = useState(false);
  const firstUpdate = useRef(true);
  const location = useLocation();
  const navigate = useNavigate();

  const {
    register, watch, setValue, reset,
  } = useForm();

  const filterType = {
    Filter: '',
    Mage: 'Mage',
    Warrior: 'Warrior',
    Archer: 'Archer',
  };

  const tierType = {
    Common: 'Common',
    Uncommon: 'Uncommon',
    Rare: 'Rare',
    Epic: 'Epic',
    Legendary: 'Legendary',
  };

  const sortType = {
    'Sort by': false,
    Price: true,
  };

  const {
    marketplace, marketplaceItems, loading, inventory, inventoryItems,
  } = useSelector(selectMarketplace);
  const unListSuccess = useSelector(selectIsUnListSuccess);
  const pawn = useSelector(selectPawn);
  const itemLoading = useSelector(selectPawnLoading);
  const address = useSelector(selectEvm);

  const onPageInc = () => {
    if (marketplaceItems) {
      setPage(page + 1);
    }
  };

  const onPageDec = () => {
    if (page - 1 > 0) {
      setPage(page - 1);
    }
  };

  const onInventoryPageInc = () => {
    if (inventoryItems) {
      setPage(page + 1);
    }
  };

  const onInventoryPageDec = () => {
    if (page - 1 > 0) {
      setPage(page - 1);
    }
  };

  const generateRequest = () => {
    const obj = {
      limit: type === 'buy'
        ? canMint || window?.ethereum?.networkVersion !== mainNetId
          ? 11
          : 12
        : 12,
      address,
      offset: (page - 1) * 12,
    };
    if (search) {
      obj.name = search;
    }
    if (filterType[filter]) {
      obj.class = filterType[filter];
    }
    if (tierType[tier]) {
      obj.tier = tierType[tier];
    }
    if (sortType[sort]) {
      obj.sort_by = 'Price';
      if (sortDirection === 'asc') {
        obj.order_by = 'Asc';
      } else {
        obj.order_by = 'Desc';
      }
    } else {
      obj.sort_by = 'Default';
      setSortDirection('');
    }
    return obj;
  };

  useEffect(() => {
    amIWhitelisted().then((result) => {
      setCanMint(result);
      setMintInfoReceived(true);
    });
  }, []);

  useEffect(() => {
    if (mintInfoReceived) {
      if (type === 'buy') {
        dispatch(getPawnsBuy(generateRequest()));
        reset({
          price: '',
        });
      } else {
        dispatch(getPawnsSell(generateRequest()));
        reset({
          price: '',
        });
      }
    }
  }, [page, type, filter, sort, sortDirection, tier, mintInfoReceived]);

  useEffect(() => {
    if (pawn && pawn.price && !location.pathname.includes('transfer')) {
      setValue('price', dividerCommaFormat(+pawn.price));
    }
    if (pawn && location.pathname.includes('transfer')) {
      setValue('price', '');
    }
  }, [pawn]);

  useEffect(() => {
    setPage(1);
  }, [filter, sort]);

  useEffect(() => {
    if (unListSuccess) {
      dispatch(notificationShowAction(true, 'Item unlisted Successfully'));
      dispatch(clearUnListId());
      dispatch(clearPawn());
    }
  }, [unListSuccess]);

  useEffect(() => {
    onNewOffering((offeringId, nftContract, offerer, tokenId) => {
      if (offerer === address) {
        dispatch(listedSuccessfully(tokenId.toString()));
        dispatch(listItemSuccess());
        dispatch(clearPawn());
        dispatch(notificationShowAction(true, 'Item Listed Successfully'));
      } else {
        dispatch(getNewPawn(tokenId.toString()));
      }
    });

    onOfferingCancelled((offeringId, nftContract, tokenId) => {
      dispatch(unListedSuccessfully(tokenId.toString()));
      dispatch(unlistItemSuccess(offeringId.toString()));
    });

    onOfferingClosed((offeringId, buyer, offerer, tokenId) => {
      if (offerer === address) {
        dispatch(boughtSuccessfully(tokenId.toString()));
        dispatch(buyItemSuccess());
        dispatch(clearPawn());
        dispatch(notificationShowAction(true, 'Item Bought Successfully'));
      }
    });
  }, []);

  useEffect(() => {
    if (location.pathname === '/marketplace/pawns') {
      navigate('/marketplace/pawns/buy');
    }
    if (location.pathname.split('/').some((item) => item === 'buy')) {
      setType('buy');
    } else if ((location.pathname.split('/').some((item) => item === 'sell'))) {
      setType('sell');
    } else if ((location.pathname.split('/').some((item) => item === 'transfer'))) {
      setType('transfer');
    } else {
      navigate('/marketplace/pawns/buy');
    }
    const id = location.pathname.match(/\d+/)?.[0];

    if (id) {
      dispatch(getPawn(id));
    }
    if (location.pathname.slice(-4) === 'mint') {
      dispatch(getPawnSuccess(defaultPawnValue));
    }
    return () => dispatch(clearPawn());
  }, [location]);

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    const timeOutId = setTimeout(() => {
      if (type === 'buy') {
        dispatch(getPawnsBuy(generateRequest()));
        reset({
          price: '',
        });
      } else {
        dispatch(getPawnsSell(generateRequest()));
        reset({
          price: '',
        });
      }
    }, 1000);
    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timeOutId);
  }, [search]);

  return (
    <div className={styles.pawns_wrapper}>
      <div className={styles.pawns_wrapper_filters}>
        <Filters
          sort={sort}
          text={['Sort by', 'Price']}
          setSort={setSort}
          filter={filter}
          tier={tier}
          setTier={setTier}
          setFilter={setFilter}
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
          search={search}
          setSearch={setSearch}
        />
      </div>
      <HeadContent
        setType={setType}
        setPage={setPage}
        type={type}
        setFilter={setFilter}
        setSort={setSort}
      />
      <div className={styles.content_decor} />
      <Inventory
        text={type === 'buy' ? 'Market' : 'Inventory'}
        data={type === 'buy' ? marketplace : inventory}
        page={page}
        reset={reset}
        setPage={setPage}
        loading={loading}
        type={type}
        canMint={canMint}
      />
      <Item
        item={pawn && Object.keys(pawn).length !== 0
          ? canMint || window?.ethereum?.networkVersion !== mainNetId
            ? { image: 'Mint', ...pawn }
            : pawn
          : ''}
        itemLoading={itemLoading}
      />
      <Pagination
        page={page}
        onPageInc={type === 'buy' ? onPageInc : onInventoryPageInc}
        onPageDec={type === 'buy' ? onPageDec : onInventoryPageDec}
      />
      <ButtonsListBuy
        text={type === 'buy' ? pawn?.nftStatus === 'Mint' ? 'Mint' : 'Buy' : type === 'transfer' ? 'Transfer' : 'List'}
        pawn={pawn}
        register={register}
        itemPrice={watch('price')}
        reset={reset}
        setValue={setValue}
        generateRequest={generateRequest}
      />
    </div>
  );
};

export default Pawns;
