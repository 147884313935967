import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import victory from '../../static/img/endofbattle/Victory.png';
import defeat from '../../static/img/endofbattle/defeat.png';
import VLobby from '../../static/img/endofbattle/VBack to lobby.png';
import VLobbyHover from '../../static/img/endofbattle/VBack to lobby hover.png';
import VLobbyPressed from '../../static/img/endofbattle/VBack to lobby pressed.png';
import DLobby from '../../static/img/endofbattle/DBack to lobby.png';
import DLobbyHover from '../../static/img/endofbattle/DBack to lobby hover.png';
import DLobbyPressed from '../../static/img/endofbattle/DBack to lobby pressed.png';
import styles from './EndOfBattle.module.scss';
import {
  clearFightAll, selectExp, selectFightInfo, setNavigate,
} from '../../store/slices/arena';
import Experience from '../Experience';

const EndOfBattle = () => {
  const [won, setWon] = useState('');
  const [lost, setLost] = useState('');
  const [onExp, setOnExp] = useState(false);
  const result = useSelector(selectFightInfo);
  const exp = useSelector(selectExp);
  const dispatch = useDispatch();

  const handleLobby = () => {
    dispatch(clearFightAll());
    dispatch(setNavigate('/arena-hub'));
  };

  const VictoryButton = {
    hover: VLobbyHover,
    pressed: VLobbyPressed,
  };

  const DefeatButton = {
    hover: DLobbyHover,
    pressed: DLobbyPressed,
  };

  return (
    <>
      {onExp && exp && (
        <div className={styles.exp}>
          <div className={styles.pawns}>
            {exp.pawns.map((pawn) => (
              <Experience
                key={pawn.nftId}
                pawnClass={pawn.class}
                name={pawn.name}
                expGain={pawn.gainedExperience}
                pawnLevel={pawn.level}
                startExp={pawn.experience}
                newLevel={pawn.newLevel}
              />
            ))}
          </div>
          {result === 'won'
            ? (
              <button
                type="button"
                onClick={() => handleLobby()}
                style={{
                  background: 'black',
                  border: 'none',
                  marginTop: '60px',
                  cursor: 'pointer',
                }}
              >
                <img src={won ? VictoryButton[won] : VLobby} alt="lobby" />
              </button>
            )
            : (
              <button
                type="button"
                onClick={() => handleLobby()}
                style={{
                  background: 'black',
                  border: 'none',
                  marginTop: '60px',
                  cursor: 'pointer',
                }}
              >
                <img src={lost ? DefeatButton[lost] : DLobby} alt="lobby" />
              </button>
            )}
        </div>
      )}
      <div className={styles.wrapper}>
        {result === 'won' ? (
          <>
            <img src={victory} alt="win" />
            <button
              type="button"
              onClick={() => setOnExp(true)}
              onMouseEnter={() => setWon('hover')}
              onMouseDown={() => setWon('pressed')}
              onMouseUp={() => setWon('hover')}
              onMouseLeave={() => setWon('')}
            >
              <img src={won ? VictoryButton[won] : VLobby} alt="lobby" />
            </button>
          </>
        ) : (
          <>
            <img src={defeat} alt="lost" />
            <button
              type="button"
              onClick={() => setOnExp(true)}
              onMouseEnter={() => setLost('hover')}
              onMouseDown={() => setLost('pressed')}
              onMouseUp={() => setLost('hover')}
              onMouseLeave={() => setLost('')}
            >
              <img src={lost ? DefeatButton[lost] : DLobby} alt="lobby" />
            </button>
          </>
        )}
      </div>
    </>
  );
};
export default EndOfBattle;
