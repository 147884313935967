import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectOpponentAddress } from '../../../store/slices/arena';
import styles from './EnemyInfo.module.scss';
import diffImg from '../../../static/img/arena/difficulty.png';
import uniqueId from '../../../utils/uniqueId';
import { archerData } from '../../../constants/archer';
import { mageData } from '../../../constants/mage';
import { warriorData } from '../../../constants/warrior';

const EnemyInfo = ({ data }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const opponent = useSelector(selectOpponentAddress);

  useEffect(() => {
    const onResize = (e) => {
      setWidth(e.target.innerWidth);
    };
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const dataOpponent = {
    name: opponent ? `${(opponent).slice(0, 5)}...${(opponent)
      .slice((opponent).length - 4, (opponent).length).toUpperCase()}` : 'Enemy',
  };

  const chooseData = {
    Archer: archerData,
    Mage: mageData,
    Warrior: warriorData,
  };

  const userData = chooseData[data.class];

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_content}>
        <div className={styles.name}>
          <span>{dataOpponent.name}</span>
          <span>Creature</span>
        </div>
        <div className={styles.level}>
          <span>Level</span>
          <span>24</span>
        </div>
        <div className={styles.difficulty}>
          <span>Difficulty</span>
          <div className={styles.skull}>
            {Array.from(Array(4).keys()).map(() => (
              <img src={diffImg} alt="" key={uniqueId('enemyInfo')} />
            ))}
          </div>
        </div>
      </div>
      {width < 880 && (
        <div className={styles.character_img}>
          <img src={userData.logo} alt="" />
        </div>
      )}
    </div>
  );
};

export default EnemyInfo;
