import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  handleSendMessageToTheArena,
  handleSendMessageToTheFight,
} from '../../utils/arenaSocket';
import {
  selectArenaChat,
  selectFightChat,
  selectIsNewMessage,
  selectOpenChat,
  selectOpenChatInput,
  setNewMessage,
  setOpenChat,
  setOpenChatInput,
} from '../../store/slices/chat';
import { handleSendGlobalMessage } from '../../utils/socialHubSocket';
import ChatImg from '../../static/img/Chat/chat.png';
import ChatPressed from '../../static/img/Chat/chat-pressed.png';
import ChatHover from '../../static/img/Chat/chat-hover.png';
import inputImg from '../../static/img/Chat/input.png';
import inputImgPressed from '../../static/img/Chat/input-pressed.png';
import GlobalImg from '../../static/img/Chat/General active.png';
import GlobalClose from '../../static/img/Chat/General innactive.png';
import Battle from '../../static/img/Chat/Battle active.png';
import BattleClose from '../../static/img/Chat/Battle innactive.png';
import notification from '../../static/img/header/balancebar/notification.png';
import uniqueId from '../../utils/uniqueId';
import styles from './Chat.module.scss';

const Chat = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [chatEvent, setChatEvent] = useState('');
  const [press, setPress] = useState('');
  const [active, setActive] = useState('global');
  const [text, setText] = useState('');
  const inputRef = useRef(null);
  const textRef = useRef(null);
  const arenaChat = useSelector(selectArenaChat);
  const battleChat = useSelector(selectFightChat);
  const chat = active === 'global' ? arenaChat : battleChat;

  const isOpen = useSelector(selectOpenChat);
  const isInputOpen = useSelector(selectOpenChatInput);
  const newMessage = useSelector(selectIsNewMessage);

  useEffect(() => {
    textRef.current.scrollTo(0, textRef.current.scrollHeight);
  }, [chat]);

  useEffect(() => {
    let timer;
    if (isOpen) {
      dispatch(setNewMessage(false));
      timer = setTimeout(() => inputRef.current.focus(), 300);
    }
    return () => clearTimeout(timer);
  }, [isOpen]);

  useEffect(() => {
    let timer;
    if (isInputOpen) {
      timer = setTimeout(() => inputRef.current.focus(), 300);
    }
    return () => clearTimeout(timer);
  }, [isInputOpen]);

  const downHandler = (event) => {
    if (event && event.code === 'Enter' && isInputOpen && text) {
      event.preventDefault();
      if (active === 'global') {
        handleSendGlobalMessage(text);
      }
      if (active === 'battle') {
        handleSendMessageToTheFight(event.target.value);
      }
      setPress('pressed');
      setText('');
    }
  };

  const upHandler = (event) => {
    if (event) {
      event.preventDefault();
      if (event.code !== 'Enter' || (event.code === 'Enter' && !isOpen && !isInputOpen)) {
        return;
      }
      if (event.code === 'Enter') {
        if (!isOpen && isInputOpen) {
          dispatch(setOpenChatInput(false));
          inputRef.current.blur();
        }
        setPress('');
      }
    }
  };

  const clickHandle = (event) => {
    if (event?.target?.alt === 'chat') {
      event.preventDefault();
      event.stopPropagation();
      if (!isOpen && !isInputOpen) {
        dispatch(setOpenChat(true));
        dispatch(setOpenChatInput(true));
      }
      if (!isOpen && isInputOpen) {
        dispatch(setOpenChat(true));
      }
      if (isOpen && isInputOpen) {
        dispatch(setOpenChat(false));
        dispatch(setOpenChatInput(false));
        setText('');
      }
    }
  };

  useEffect(() => {
    window.addEventListener('click', clickHandle);

    return () => {
      window.removeEventListener('click', clickHandle);
    };
  }, [window, isOpen, isInputOpen]);

  useEffect(() => {
    window.addEventListener('keydown', downHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [window, active, isOpen, text, isInputOpen]);

  useEffect(() => {
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keyup', upHandler);
    };
  }, [window, isOpen, isInputOpen]);

  const chatButton = {
    hover: ChatHover,
    pressed: ChatPressed,
  };

  return (
    <>
      {isOpen || isInputOpen
        ? (
          <div
            className={styles.closeWrapper}
            onClick={(e) => {
              e.preventDefault();
              dispatch(setOpenChat(false));
              dispatch(setOpenChatInput(false));
            }}
            role="presentation"
          />
        ) : null}
      <div className={`${styles.wrapper} ${isOpen ? styles.active : ''} ${isInputOpen ? styles.z_index : ''}`}>
        <div className={styles.button_wrapper}>
          {!isOpen && newMessage
            ? <img src={notification} alt="notice" className={styles.img_notification} />
            : null}
          <button
            type="button"
            tabIndex="-1"
            className={styles.chat_button}
            onMouseEnter={() => setChatEvent('hover')}
            onMouseDown={() => setChatEvent('pressed')}
            onMouseUp={() => setChatEvent('hover')}
            onMouseLeave={() => setChatEvent('')}
            onBlur={(e) => e.target.blur()}
            onClick={() => clickHandle()}
          >
            <img
              src={chatEvent ? chatButton[chatEvent] : ChatImg}
              alt="chat"
            />
          </button>
        </div>
        <div className={styles.chat_container}>
          <div className={styles.header}>
            <button
              type="button"
              tabIndex="-1"
              className={styles.chat_button}
              onClick={() => setActive('global')}
            >
              <img src={active === 'global' ? GlobalImg : GlobalClose} alt="global" />
            </button>
            <button
              type="button"
              tabIndex="-1"
              className={styles.chat_button}
              onClick={() => setActive('battle')}
            >
              <img src={active === 'battle' ? Battle : BattleClose} alt="battle" />
            </button>
          </div>
          <div className={styles.left} />
          <div className={styles.right} />
          <div
            className={styles.content}
            ref={textRef}
          >
            {chat && chat.length
              ? (chat.map((infoMessage) => (
                <div
                  className={styles.wrapper_message}
                  key={uniqueId(active === 'global' ? 'chatArena' : 'chatBattle')}
                >
                  {infoMessage?.fightRequest ? (
                    <div className={styles.wrapper_chat_invite}>
                      <span className={styles.usersWindowContainer__user__match} />
                      <span>{infoMessage.message}</span>
                    </div>
                  ) : (
                    <span
                      className={styles.message_date}
                      style={{ color: infoMessage.address === 'BOT' ? '#000000' : infoMessage.color }}
                    >
                      [
                      {infoMessage.date}
                      ]
                      {infoMessage?.discordName
                        ? infoMessage.discordName
                        : infoMessage.address === 'BOT'
                          ? 'bot'
                          : `${(infoMessage.address).slice(0, 5)}...${(infoMessage.address)
                            .slice((infoMessage.address).length - 4, (infoMessage.address).length)
                            .toUpperCase()}`}
                      :
                      {' '}
                      {infoMessage.message}
                    </span>
                  )}
                </div>
              ))) : 'No messages'}
          </div>
          <div className={styles.bot} />
        </div>
      </div>
      <div className={styles.wrapper_input}>
        <div className={styles.input_header} />
        <div className={styles.input_left} />
        <div className={styles.input_right} />
        <div className={styles.input_bottom} />
        <div className={styles.input}>
          <input
            className={styles.input_text}
            tabIndex="-1"
            placeholder="Type here..."
            onChange={(e) => {
              setText(e.target.value);
              e.preventDefault();
            }}
            onBlur={() => inputRef.current.blur()}
            ref={inputRef}
            value={text}
            disabled={!isInputOpen}
          />
          <button
            type="button"
            tabIndex="-1"
            onMouseDown={() => setPress('pressed')}
            onMouseUp={() => setPress('')}
            onMouseLeave={() => setPress('')}
            onClick={() => {
              if (active === 'global') {
                if (pathname.includes('social')) {
                  handleSendGlobalMessage(text);
                } else {
                  handleSendMessageToTheArena(text);
                }
              } else {
                handleSendMessageToTheFight(text);
              }
              setText('');
              inputRef.current.focus();
            }}
            onKeyDown={() => downHandler()}
            onKeyUp={() => upHandler()}
            className={styles.input_button}
          >
            <img src={press ? inputImgPressed : inputImg} alt="input" />
          </button>
        </div>
      </div>
    </>
  );
};

export default Chat;
