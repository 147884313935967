import React from 'react';
import styles from '../../../../components/Marketplace/Components/Pawns/Cards/Cards.module.scss';

const Card = ({
  data, setSelectedPawn, selectedPawn, handlePawns, check, vs,
}) => (
  <div
    role="presentation"
    className={`${styles.card} ${styles[data.tier]}`}
    onClick={() => {
      setSelectedPawn(data.nftId);
      handlePawns(data, vs);
    }}
  >
    <div className={styles.card_top_level}>{data.level}</div>
    <div className={styles.card_top} />
    <div className={styles.card_top_right} />
    <div className={styles.card_left} />
    <div className={styles.card_content}>
      <img src={`https://i.bimium.link/${data.image}`} alt="person" />
      {selectedPawn === data.nftId || check
        ? <div className={styles.select} />
        : null}
      {data?.upgradePoints
        ? <div className={styles.updatePoints} />
        : null}
    </div>
    <div className={styles.card_right} />
    <div className={styles.card_bottom_left} />
    <div className={styles.card_bottom} />
    <div className={styles.card_bottom_right} />
  </div>
);

export default Card;
