import React, { useState } from 'react';
import buttonAgree from '../../../../static/img/header/balancebar/dropdown/Accept button.png';
import { agreeStatus } from '../../../../utils/chooseData';
import styles from '../../Equipment.module.scss';

const AgreedButton = ({ click }) => {
  const [agree, setAgree] = useState('');
  return (
    <button
      type="button"
      className={styles.button}
      onMouseEnter={() => setAgree('hover')}
      onMouseDown={() => setAgree('pressed')}
      onMouseUp={() => setAgree('hover')}
      onMouseLeave={() => setAgree('')}
      onClick={() => {
        click();
        setAgree('');
      }}
    >
      <img src={agree ? agreeStatus[agree] : buttonAgree} alt="ok" width="62px" height="52px" />
    </button>
  );
};

export default AgreedButton;
