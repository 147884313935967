import React from 'react';
import styles from '../Portrait/Portrait.module.scss';

const NameBlock = ({ address, user }) => (
  <div className={styles.portrait}>
    <div className={styles.portrait_top_left} />
    <div className={styles.portrait_top} />
    <div className={styles.portrait_top_right} />
    <div className={styles.portrait_left} />
    <div className={styles.portrait_content}>
      {address
        ? (user ? user.length > 15 ? `${user.slice(0, 15).trim()}...` : user : `${address.slice(0, 5)}...${address
          .slice(address.length - 4, address.length)
          .toUpperCase()}`)
        : null }
    </div>
    <div className={styles.portrait_right} />
    <div className={styles.portrait_bottom_left} />
    <div className={styles.portrait_bottom} />
    <div className={styles.portrait_bottom_right} />
  </div>
);

export default NameBlock;
