import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfoButton from '../../../Presale/InfoButton';
import uniqueId from '../../../../../../utils/uniqueId';
import { selectEquipmentType } from '../../../../../../store/slices/preset';
import {
  clearConsumables,
  clearEquipment,
  clearSpellsData, lastEquipmentData,
  lastItemsData, lastSpellsData,
} from '../../../../../../store/slices/constructor';
import styles from './DropdownFilter.module.scss';

const DropdownFilter = ({
  text, setSort, setFilter, setIsOpenSort, setIsOpenFilter,
  sortDirection, setSortDirection, filter, setTier, tier, sort,
}) => {
  const dispatch = useDispatch();
  const equipmentType = useSelector(selectEquipmentType);

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_top}>
        <div className={styles.top_corner_left} />
        <div className={styles.top} />
        <div className={styles.top_corner_right} />
      </div>
      {text.map((item, index) => (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <div key={uniqueId('text_dropDOwn')}>
          {
            item !== filter || item === 'Filter'
              ? (
                <>
                  <div className={styles.dropwdown_wrapper_item} key={[index]}>
                    <div className={styles.dropdown_left} />
                    <div
                      role="presentation"
                      className={styles.dropdown_content}
                      onClick={() => {
                        if (equipmentType === 'Items') {
                          dispatch(clearConsumables());
                          dispatch(lastItemsData(false));
                        }
                        if (equipmentType === 'Spells') {
                          dispatch(clearSpellsData());
                          dispatch(lastSpellsData(false));
                        }
                        if (setFilter) {
                          setFilter(item);
                          if (equipmentType === 'Equipment') {
                            dispatch(clearEquipment());
                            dispatch(lastEquipmentData(false));
                          }
                          setIsOpenFilter(false);
                        } else {
                          if (item === 'Sort by') {
                            setSortDirection('');
                          }
                          setSort(item);
                          setIsOpenSort(false);
                        }
                      }}
                    >
                      <InfoButton text={item} sort={sort} />
                    </div>
                    <div className={styles.dropdown_right} />
                  </div>
                  { !index && (
                    <div className={styles.wrapper_decor}>
                      <div className={styles.decor_left} />
                      <div className={styles.decor_content} />
                      <div className={styles.decor_right} />
                    </div>
                  )}
                </>
              )
              : null
          }
        </div>
      ))}
      {equipmentType === 'Equipment' || equipmentType === 'Items' || equipmentType === 'Spells' ? null : (
        <div className={styles.sort_content}>
          {text.includes('Filter') ? (
            <>
              <div className={styles.dropdown_left} />
              <div className={styles.flag_wrapper}>
                <div
                  role="presentation"
                  className={`${styles.flag_common} ${tier === 'Common' ? styles.active : ''}`}
                  onClick={() => {
                    if (tier === 'Common') {
                      setTier('');
                    } else {
                      setTier('Common');
                    }
                  }}
                />
                <div
                  role="presentation"
                  className={`${styles.flag_uncommon} ${tier === 'Uncommon' ? styles.active : ''}`}
                  onClick={() => {
                    if (tier === 'Uncommon') {
                      setTier('');
                    } else {
                      setTier('Uncommon');
                    }
                  }}
                />
                <div
                  role="presentation"
                  className={`${styles.flag_rare} ${tier === 'Rare' ? styles.active : ''}`}
                  onClick={() => {
                    if (tier === 'Rare') {
                      setTier('');
                    } else {
                      setTier('Rare');
                    }
                  }}
                />
                <div
                  role="presentation"
                  className={`${styles.flag_epic} ${tier === 'Epic' ? styles.active : ''}`}
                  onClick={() => {
                    if (tier === 'Epic') {
                      setTier('');
                    } else {
                      setTier('Epic');
                    }
                  }}
                />
                <div
                  role="presentation"
                  className={`${styles.flag_legendary} ${tier === 'Legendary' ? styles.active : ''}`}
                  onClick={() => {
                    if (tier === 'Legendary') {
                      setTier('');
                    } else {
                      setTier('Legendary');
                    }
                  }}
                />
              </div>
              <div className={styles.dropdown_right} />
            </>
          ) : (
            <div className={styles.sort}>
              <div className={styles.decor_sort} />
              <div className={styles.wrapper_bottom}>
                <div className={styles.dropdown_left} />
                <div className={styles.flag_wrapper}>
                  <div
                    role="presentation"
                    className={`${styles.up} ${sortDirection === 'asc' ? styles.active : ''}`}
                    onClick={() => {
                      if (sort === 'Price' || sort === 'Level') {
                        setSortDirection('asc');
                      }
                    }}
                  />
                  <div
                    role="presentation"
                    className={`${styles.down} ${sortDirection === 'desc' ? styles.active : ''}`}
                    onClick={() => {
                      if (sort === 'Price' || sort === 'Level') {
                        setSortDirection('desc');
                      }
                    }}
                    disabled={false}
                  />
                </div>
                <div className={styles.dropdown_right} />
              </div>
            </div>
          )}
        </div>
      )}
      <div className={styles.wrapper_bottom}>
        <div className={styles.bottom_corner_left} />
        <div className={styles.bottom} />
        <div className={styles.bottom_corner_right} />
      </div>
    </div>
  );
};

export default DropdownFilter;
