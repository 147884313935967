import React from 'react';
import { useSelector } from 'react-redux';
import styles from './CharacterBar.module.scss';
import uniqueId from '../../../../utils/uniqueId';
import { selectPlayersInfo, selectSpellDescriptionByPawn } from '../../../../store/slices/arena';

const chooseBg = ({
  Warrior: 'warrior_left',
  WarriorR: 'warrior_right',
  Mage: 'mage_left',
  MageR: 'mage_right',
  Archer: 'archer_left',
  ArcherR: 'archer_right',
});

const chooseActiveBg = ({
  Warrior: 'warrior_active',
  WarriorR: 'warrior_active_right',
  Mage: 'mage_active',
  MageR: 'mage_active_right',
  Archer: 'archer_active',
  ArcherR: 'archer_active_right',
});

const CharacterBar = ({
  hp, maxHp, actionPoints, maxActionPoints, shield, type, debuffs, id,
}) => {
  const playersInfo = useSelector(selectPlayersInfo);
  const spells = useSelector(selectSpellDescriptionByPawn);

  const hpToPx = () => (maxHp >= hp ? Math.floor((102 * hp) / maxHp) : 102);
  const apToPx = () => Math.floor((86 * actionPoints) / maxActionPoints);
  const shieldToPx = () => {
    if (shield >= maxHp) {
      return 102;
    }
    return Math.floor((102 * shield) / maxHp);
  };

  return (
    <div
      className={styles.wrapper}
    >
      {playersInfo.whoseTurn.id === id
        ? <div className={styles[chooseActiveBg[type]]} />
        : null}
      <div className={`${styles.bg} ${styles[chooseBg[type]]}`}>
        <div className={styles.mirror}>
          <div className={styles.hp} style={{ width: `${hpToPx()}px` }} />
          {shield
            ? <div className={styles.shield_bar} style={{ width: `${shieldToPx()}px` }} />
            : null}
        </div>
        <div className={styles.mirror}>
          <div className={styles.ap} style={{ width: `${apToPx()}px` }} />
        </div>
        {debuffs && debuffs.length
          ? (
            <div className={styles.mirror}>
              <div className={styles.defuff}>
                {debuffs.map((elem) => (
                  <div key={uniqueId('debuff')} className={`${styles.spell_img} ${styles.mirror}`}>
                    <img src={spells[id][elem.spellCode].image} alt="" width="16px" height="16px" />
                    {elem.type === 'magic_shield' && shield
                      ? (
                        <div className={styles.shield}>
                          <div
                            className={`
                            ${shield.toString().length === 2 ? styles.counter_two : ''}
                            ${shield.toString().length >= 3 ? styles.counter_tree : ''}
                            ${styles.shield_counter}`}
                          >
                            {shield}
                          </div>
                        </div>
                      )
                      : null}
                  </div>
                ))}
              </div>
            </div>
          )
          : null}
      </div>
    </div>
  );
};

export default CharacterBar;
