import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Header from '../../components/Marketplace/Components/Header';
import Filters from '../Pick/Filters';
import Inventory from '../Equipment/Inventory';
import MobileLayout from '../../components/MobileLayout';
import Separator from '../../components/LayoutWindow/Separator';
import Transmute from './Transmute';
import { selectEvm } from '../../store/slices/auth';
import { resetInventoryForEquipment, selectPawnsForEquipment } from '../../store/slices/requests';
// import { selectMarketplace } from "../../store/slices/requests";
import { clearPawn } from '../../store/slices/pawnSelect';
import { clearDataEquipment } from '../../store/slices/constructor';
import styles from './Crafting.module.scss';

const Crafting = () => {
  const dispatch = useDispatch();
  const [selectedPawn, setSelectedPawn] = useState();
  const [width, setWidth] = useState(window.innerWidth);
  const [filter, setFilter] = useState('Filter');
  const [filterByTier, setFilterByTier] = useState('Tier');
  const [filterBySlot, setFilterBySlot] = useState('Slot');
  const [filterByClass, setFilterByClass] = useState('Class');
  const [filterByTierForItems, setFilterByTierForItems] = useState('Tier');
  const [filterByApplication, setFilterByApplication] = useState('Application');
  const [tier, setTier] = useState('');
  const [search, setSearch] = useState('');

  const address = useSelector(selectEvm);

  const pawnInventory = useSelector(selectPawnsForEquipment);

  // const {
  //   loading, count, inventoryMobileEquipment,
  // } = useSelector(selectMarketplace);

  useEffect(() => {
    const onResize = (e) => {
      setWidth(e.target.innerWidth);
    };
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => () => {
    dispatch(clearPawn());
    dispatch(clearDataEquipment());
    dispatch(resetInventoryForEquipment());
  }, []);

  return (
    width > 880 ? (
      <>
        <Header address={address} />
        <div className={styles.wrapper}>
          <Link to="/" className="closeWindow"><button type="button">&#10006;</button></Link>
          <div className={styles.container}>
            <div className={styles.header}>
              <div className={styles.separator} />
              <div className={styles.titleBox}>
                <div className={styles.button_group}>
                  <Filters
                    start
                    filterByClass={filterByClass}
                    filterByTier={filterByTier}
                    filterBySlot={filterBySlot}
                    filterByApplication={filterByApplication}
                    filterByTierForItems={filterByTierForItems}
                    setFilterByClass={setFilterByClass}
                    setFilterByTier={setFilterByTier}
                    setFilterBySlot={setFilterBySlot}
                    setFilterByApplication={setFilterByApplication}
                    setFilterByTierForItems={setFilterByTierForItems}
                    filter={filter}
                    tier={tier}
                    setTier={setTier}
                    setFilter={setFilter}
                    search={search}
                    setSearch={setSearch}
                  />
                </div>
                <span>Alchemist</span>
              </div>
              <div className={styles.separator} />
            </div>
            <div className={styles.content}>
              <div className={styles.set_inventory}>
                <Inventory
                  data={pawnInventory}
                  selectedPawn={selectedPawn}
                  setSelectedPawn={setSelectedPawn}
                  filter={filter}
                  search={search}
                  tier={tier}
                  filterByClass={filterByClass}
                  filterByTier={filterByTier}
                  filterBySlot={filterBySlot}
                  filterByApplication={filterByApplication}
                />
                <div className={styles.crafting_background}>
                  <Transmute />
                </div>
              </div>
              <div className={styles.book_background} />
            </div>
          </div>
        </div>
      </>
    ) : (
      <MobileLayout>
        <div className={styles.header}>
          <div className={styles.separator} />
          <div className={styles.titleBox}>
            <div className={styles.button_group}>
              <Filters
                start
                filterByClass={filterByClass}
                filterByTier={filterByTier}
                filterBySlot={filterBySlot}
                setFilterByClass={setFilterByClass}
                setFilterByTier={setFilterByTier}
                setFilterBySlot={setFilterBySlot}
                filter={filter}
                tier={tier}
                setTier={setTier}
                setFilter={setFilter}
                search={search}
                setSearch={setSearch}
              />
            </div>
            <span>Crafting</span>
          </div>
          <div className={`${styles.separator} ${styles.rotate}`} />
        </div>
        <div className={styles.dec}>
          <Separator />
        </div>
        <div className={styles.content}>
          <div className={styles.set_inventory}>
            <div className={styles.crafting_background}>
              <Transmute />
            </div>
            <Inventory
              data={pawnInventory}
              selectedPawn={selectedPawn}
              setSelectedPawn={setSelectedPawn}
              filter={filter}
              search={search}
              tier={tier}
              filterByClass={filterByClass}
              filterByTier={filterByTier}
              filterBySlot={filterBySlot}
              filterByApplication={filterByApplication}
            />
            {/* <div className={styles.book_background} /> */}
          </div>
        </div>
      </MobileLayout>
    )
  );
};

export default Crafting;
