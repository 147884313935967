import Phaser from 'phaser';
import { pawns } from './pawns';
import Pawn from './pawn';
import { getPawnConfigByKey } from './const';
import { SkillBase } from './skills/skillBase';

class SkillScene extends Phaser.Scene {
  constructor(config) {
    super(config);
    this.setReady = config.setReady;
    this.queue = new Promise((resolve) => {
      resolve(true);
    });
  }

  preload() {
    this.load.image('background', 'assets/Battle background.png');
    this.load.image('selectArrow', 'assets/battle/Select arrow.png');
    this.load.image('heroShadow', 'assets/hero-shadow.png');
    pawns.forEach((p) => {
      p.class.preload(this, p);
    });
  }

  create() {
    this.bgImage = this.add.image(700, 307, 'background');
    this.bgImageFade = this.add.rectangle(700, 307, 1400, 614, 0x000000, 0);

    this.selectArrow = this.add.image(50, 50, 'selectArrow').setVisible(false);
    this.selectArrowOpponent = this.add.image(50, 50, 'selectArrow').setVisible(false);

    this.currentPlayerGroup = new Phaser.GameObjects.Group(this, [], { name: 'currentPlayerGroup' });
    this.opponentPlayerGroup = new Phaser.GameObjects.Group(this, [], { name: 'opponentPlayerGroup' });
    const sc = this;
    setTimeout(
      () => {
        if (this.currentPlayerGroup.children.getArray().length === 0) {
          this.currentPlayerGroup.add(
            new Pawn({
              scene: sc,
              x: SkillScene.getPosition(this.game, 0, true, 1)[0],
              y: SkillScene.getPosition(this.game, 0, true, 1)[1],
              idPawn: 1,
              isCurrentPlayer: true,
              pawnConfig: pawns[1],
              debug: true,
            }),
          );
        }
        this.opponentPlayerGroup.add(
          new Pawn({
            scene: sc,
            x: SkillScene.getPosition(this.game, 0, false, 1)[0],
            y: SkillScene.getPosition(this.game, 0, false, 1)[1],
            idPawn: 2,
            isCurrentPlayer: false,
            pawnConfig: pawns[0],
            debug: true,
          }),
        );
        console.log(this.currentPlayerGroup);
        this.setReady(true);
      },
      1000,
    );
  }

  createPawn(pawnKey, idPawn, isCurrentPlayer = true, set = null) {
    const groupPawns = isCurrentPlayer ? this.currentPlayerGroup : this.opponentPlayerGroup;
    const pawn = groupPawns.children.getArray().find((i) => i.idPawn === idPawn);
    // console.log(groupPawns.children.getArray(), pawnKey, pawnId, isCurrentPlayer, this);
    if (pawn) {
      pawn.destroy(true, true);
      this.children.remove(pawn);
    }
    groupPawns.add(
      new Pawn({
        scene: this,
        x: SkillScene.getPosition(this.game, 0, isCurrentPlayer, 1)[0],
        y: SkillScene.getPosition(this.game, 0, isCurrentPlayer, 1)[1],
        idPawn,
        isCurrentPlayer,
        pawnConfig: getPawnConfigByKey(pawns, pawnKey),
        debug: true,
        set,
      }),
    );
  }

  createSpell(spellConfig) {
    const currentPawn = this.currentPlayerGroup.children.getArray().find((i) => i.pawnId === 1);
    const opponentPawn = this.opponentPlayerGroup.children.getArray().find((i) => i.pawnId === 2);
    console.log(
      'SPELL CONFIG',
      spellConfig,
      this.currentPlayerGroup.children.getArray(),
      this.opponentPlayerGroup.children.getArray(),
    );
    const actions = spellConfig.actions.map(
      (action) => {
        if (action.type.toLowerCase() === 'pawnanimation') {
          return {
            ...action,
            sceneTarget: currentPawn,
          };
        }
        if (action.type.toLowerCase() === 'opponentanimation') {
          return {
            ...action,
            sceneTarget: opponentPawn,
          };
        }
        return action;
      },
    );
    const skillPrecast = new SkillBase(
      this,
      currentPawn,
      opponentPawn,
      actions.filter((a) => a.phase === 'Precast'),
      spellConfig,
    );
    console.log('ACTIONSSS!', actions.filter((a) => a.phase !== 'Precast'), actions);
    const skillCast = new SkillBase(
      this,
      currentPawn,
      opponentPawn,
      actions.filter((a) => a.phase !== 'Precast'),
      spellConfig,
    );
    (new Promise(
      (resolve) => {
        resolve(true);
      },
    ))
      .then(() => skillPrecast.run())
      .then(() => new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 4000);
      }))
      .then(() => skillPrecast.run('end'))
      .then(() => new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 1000);
      }))
      .then(() => skillCast.run())
      .then(() => new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 4000);
      }))
      .then(() => skillCast.run('end'));
  }

  static getPosition(game, position, isCurrentPlayer, pawnsCount) {
    const gameWidth = game.scale.width;
    const correctPosition = isCurrentPlayer ? (pawnsCount - 1) - position : position;
    if (pawnsCount === 3) {
      switch (correctPosition) {
        case 0:
          return [isCurrentPlayer ? 150 : gameWidth - 570, 410];
        case 1:
          return [isCurrentPlayer ? 360 : gameWidth - 360, 370];
        case 2:
          return [isCurrentPlayer ? 570 : gameWidth - 150, 400];
        default:
          return [0, 0];
      }
    }
    if (pawnsCount === 2) {
      switch (correctPosition) {
        case 0:
          return [isCurrentPlayer ? 255 : gameWidth - 465, isCurrentPlayer ? 370 : 410];
        case 1:
          return [isCurrentPlayer ? 465 : gameWidth - 255, isCurrentPlayer ? 410 : 370];
        // case 2:
        //   return [isCurrentPlayer ? 570 : gameWidth - 150, 450];
        default:
          return [0, 0];
      }
    }

    return [isCurrentPlayer ? 300 : 1100, 410];
  }
}

export default SkillScene;
