import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import styles from '../SkillUtils.module.scss';
import loader from '../../../static/img/loader.gif';
import {
  getSetDataAction, onGetSetDataSuccess, selectActiveSet,
  selectConstructorLoading, selectSets,
  selectSkins, selectSpriteLink, setDeleteAction,
  setPostAction,
  setsGetAction, setUpdateAction, skinsGetAction,
} from '../../../store/slices/skillConstructor';
import {
  getAllItems,
  selectAllItems,
  selectLoadingAllItems,
} from '../../../store/slices/constructor';

const SetsForm = ({
  scene,
  sceneReady,
}) => {
  const dispatch = useDispatch();

  const loading = useSelector(selectConstructorLoading);
  const equipmentLoading = useSelector(selectLoadingAllItems);
  const setsList = useSelector(selectSets);
  const skinsList = useSelector(selectSkins);
  const equipmentList = useSelector(selectAllItems);
  const spriteLink = useSelector(selectSpriteLink);
  const existedSet = useSelector(selectActiveSet);
  const itemsClasses = ['helmet', 'chest', 'boots', 'weapon', 'shield'];
  const {
    register, setValue, handleSubmit, watch, reset,
  } = useForm();
  const selectedSet = watch('selectedSet');

  const onSubmit = (data) => {
    const set = [data.helmet, data.chest, data.boots, data.weapon, data.shield].filter((elem) => elem);
    const result = watch('selectedSet');
    if (result && result === 'new') {
      dispatch(setPostAction(
        {
          name: data.name,
          items: set,
          skinId: data.nameList,
        },
        reset,
      ));
    } else {
      dispatch(setUpdateAction(
        data?.selectedSet,
        {
          name: data.name,
          items: set,
          skinId: data.nameList,
        },
      ));
    }
  };

  useEffect(() => {
    if (existedSet && equipmentList) {
      Object.keys(existedSet).forEach((option) => setValue(option, existedSet[option]));
      setValue('name', existedSet.name);
      setValue('class', existedSet.class);
      itemsClasses.forEach((i) => {
        setValue(i, null);
      });
      existedSet.items.forEach((i) => {
        setValue(i.type.toLowerCase(), i.id);
      });
      setValue('nameList', existedSet.skin.id);
    }
  }, [existedSet, equipmentList, watch('class')]);

  const handleSelectedItem = (item) => {
    if (equipmentList) {
      return (
        equipmentList
          .filter((elem) => elem.class === watch('class'))
          .filter((elem) => elem.type === item)
      );
    }
    return [];
  };

  useEffect(() => {
    if (spriteLink) {
      setValue('skin', `https://i.bimium.link/${spriteLink}`);
    }
  }, [spriteLink]);

  useEffect(() => {
    dispatch(setsGetAction());
    dispatch(skinsGetAction());
    dispatch(getAllItems({ limit: 300, offset: 0 }));
  }, []);

  useEffect(() => {
    if (selectedSet && selectedSet !== 'new') {
      dispatch(getSetDataAction(selectedSet));
    }
    if (selectedSet === 'new') {
      setValue('name', null);
      setValue('nameList', null);
      itemsClasses.forEach((elem) => setValue(elem, null));
      dispatch(onGetSetDataSuccess(null));
    }
  }, [selectedSet]);

  useEffect(() => {
    const skinId = watch('nameList');
    const skin = skinsList.find((i) => i.id === skinId);
    if (sceneReady && skin?.class) {
      scene.createPawn(skin.class, 1, true, { id: skinId, skin });
    }
  }, [watch('nameList'), sceneReady]);

  useEffect(() => () => {
    dispatch(onGetSetDataSuccess(null));
  }, []);

  return (
    <form
      className={styles.form}
      onSubmit={handleSubmit(onSubmit)}
    >
      {loading || equipmentLoading
        ? (
          <div className={styles.loader_wrap}>
            <img src={loader} alt="" />
          </div>
        )
        : null}
      <div className={styles.scroll}>
        <div className={styles.form_row}>
          <select
            {...register('selectedSet')}
            defaultValue="New"
          >
            <option value="" hidden>Select</option>
            <option value="new">New</option>
            {setsList.map((elem) => (
              <option value={elem.id} key={elem.id}>{elem.name}</option>
            ))}
          </select>
        </div>
        <div className={styles.form_row}>
          <div>Name</div>
          <input
            type="text"
            {...register('name', { required: true })}
          />
        </div>
        <div className={styles.form_row}>
          <div>Class</div>
          <select
            {...register('class')}
            defaultValue="Mage"
          >
            <option value="" hidden>Select</option>
            <option value="Archer">Archer</option>
            <option value="Mage">Mage</option>
            <option value="Warrior">Warrior</option>
          </select>
        </div>
        <div className={styles.form_row}>
          <div>Helmet</div>
          <select
            {...register('helmet')}
          >
            <option value="">Select</option>
            {handleSelectedItem('Helmet').length
              ? handleSelectedItem('Helmet')
                .map((elem) => (
                  <option value={elem.id} key={elem.id}>{elem.name}</option>
                ))
              : <option disabled>No data</option>}
          </select>
        </div>
        <div className={styles.form_row}>
          <div>Chest</div>
          <select
            {...register('chest')}
          >
            <option value="" hidden>Select</option>
            {handleSelectedItem('Chest').length
              ? handleSelectedItem('Chest')
                .map((elem) => (
                  <option value={elem.id} key={elem.id}>{elem.name}</option>
                ))
              : <option disabled>No data</option>}
          </select>
        </div>
        <div className={styles.form_row}>
          <div>Boots</div>
          <select
            {...register('boots')}
          >
            <option value="">Select</option>
            {handleSelectedItem('Boots').length
              ? handleSelectedItem('Boots')
                .map((elem) => (
                  <option value={elem.id} key={elem.id}>{elem.name}</option>
                ))
              : <option disabled>No data</option>}
          </select>
        </div>
        <div className={styles.form_row}>
          <div>Weapon</div>
          <select
            {...register('weapon')}
          >
            <option value="">Select</option>
            {handleSelectedItem('Weapon').length
              ? handleSelectedItem('Weapon')
                .map((elem) => (
                  <option value={elem.id} key={elem.id}>{elem.name}</option>
                ))
              : <option disabled>No data</option>}
          </select>
        </div>
        <div className={styles.form_row}>
          <div>Off-hand</div>
          <select
            {...register('shield')}
          >
            <option value="">Select</option>
            {handleSelectedItem('Shield').length
              ? handleSelectedItem('Shield')
                .map((elem) => (
                  <option value={elem.id} key={elem.id}>{elem.name}</option>
                ))
              : <option disabled>No data</option>}
          </select>
        </div>
        <div className={styles.form_row}>
          <div>Skin</div>
          <select
            {...register('nameList')}
          >
            <option value="" hidden>Select</option>
            <optgroup label="Archer">
              {skinsList
                .filter((elem) => elem.class === 'Archer')
                .map((elem) => (
                  <option
                    value={elem.id}
                    key={elem.id}
                  >
                    {elem.name}
                  </option>
                ))}
            </optgroup>
            <optgroup label="Mage">
              {skinsList
                .filter((elem) => elem.class === 'Mage')
                .map((elem) => (
                  <option
                    value={elem.id}
                    key={elem.id}
                  >
                    {elem.name}
                  </option>
                ))}
            </optgroup>
            <optgroup label="Warrior">
              {skinsList
                .filter((elem) => elem.class === 'Warrior')
                .map((elem) => (
                  <option
                    value={elem.id}
                    key={elem.id}
                  >
                    {elem.name}
                  </option>
                ))}
            </optgroup>
          </select>
        </div>
        <div className={styles.buttons_block}>
          <button type="submit">Save</button>
          {watch('selectedSet') && watch('selectedSet') !== 'new'
            ? (
              <button
                type="button"
                onClick={() => dispatch(setDeleteAction(watch('selectedSet'), reset))}
              >
                Delete Set
              </button>
            )
            : null}
        </div>
      </div>
    </form>
  );
};

export default SetsForm;
