import React, { useState } from 'react';
import styles from './Filters.module.scss';
import InfoButton from '../../Presale/InfoButton';
import Search from '../../Presale/Search';
import DropdownFilter from './DropdownFilter';

const Filters = ({
  sort, setSort, filter, setFilter, sortDirection, setSortDirection, setTier, tier,
  search, setSearch, text,
}) => {
  const [isOpenSort, setIsOpenSort] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  return (
    <div className={styles.header_wrapper_left}>
      <div className={styles.wrapper_filter} onMouseLeave={() => setIsOpenFilter(false)}>
        <div
          role="presentation"
          onClick={() => setIsOpenFilter(!isOpenFilter)}
        >
          <InfoButton text={filter} />
        </div>
        {isOpenFilter
        && (
          <DropdownFilter
            text={['Filter', 'Mage', 'Warrior', 'Archer']}
            filter={filter}
            tier={tier}
            setFilter={setFilter}
            setTier={setTier}
            setIsOpenFilter={setIsOpenFilter}
          />
        )}
      </div>
      <div className={styles.wrapper_sort} onMouseLeave={() => setIsOpenSort(false)}>
        <div
          role="presentation"
          onClick={() => setIsOpenSort(!isOpenSort)}
        >
          <InfoButton text={sort} />
        </div>
        {isOpenSort
        && (
          <DropdownFilter
            text={text}
            setSort={setSort}
            sort={sort}
            setIsOpenSort={setIsOpenSort}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
          />
        )}
      </div>
      <Search search={search} setSearch={setSearch} />
    </div>
  );
};

export default Filters;
