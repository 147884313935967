import React from 'react';
import {
  BrowserRouter, Navigate, Route, Routes,
} from 'react-router-dom';
import Marketplace from '../components/Marketplace';
import Pawns from '../components/Marketplace/Components/Pawns';
import Portraits from '../components/Marketplace/Components/Portraits';
import Presale from '../components/Marketplace/Components/Presale';
import Arena from '../components/Arena';
import LayoutWindow from '../components/LayoutWindow';
import BattleWindow from '../pages/test/BattleWindow';
import Equipment from '../pages/Equipment';
import Quests from '../pages/Quests';
import QuestPage from '../pages/QuestPage';
import Auth from '../components/Auth';
import SkillUtils from '../pages/SkillUtils';
import Constructor from '../pages/Constructor';
import Crafting from '../pages/Crafting';

export const Router = () => {
  const some = true;
  return (
    <BrowserRouter>
      <Auth>
        <Routes>
          <Route path="/arena-hub" element={some ? <Arena /> : <Navigate to="/battle" replace />} />
          <Route path="/skill-utils" element={<SkillUtils />} />
          <Route path="/inventory-utils" element={<Constructor />} />
          <Route
            path="/arena"
            element={(
              <LayoutWindow>
                <Arena />
              </LayoutWindow>
            )}
          />
          <Route
            path="/battle"
            element={(
              <BattleWindow />
            )}
          />
          <Route
            path="/equipment"
            element={(
              <Equipment />
            )}
          />
          <Route
            path="/quests"
            element={(
              <Quests />
            )}
          />
          <Route
            path="/crafting"
            element={(
              <Crafting />
            )}
          />
          <Route
            path="/quests/:slot"
            element={(
              <QuestPage />
            )}
          />
          <Route
            path="/marketplace/pawns/*"
            element={(
              <Marketplace>
                <Pawns />
              </Marketplace>
            )}
          />
          <Route
            path="/marketplace/portraits/*"
            element={(
              <Marketplace>
                <Portraits />
              </Marketplace>
            )}
          />
          <Route
            path="/marketplace/presale"
            element={(
              <Marketplace>
                <Presale />
              </Marketplace>
            )}
          />
          <Route
            path="/tavern"
          />
          <Route
            path="/"
          />
          <Route
            path="*"
            element={<Navigate to="/" />}
          />
        </Routes>
      </Auth>
    </BrowserRouter>
  );
};
