import React, {
  useMemo, useRef, useLayoutEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { selectEvm } from '../../../../store/slices/auth';
import {
  getPage,
  getPawnsSellForEquipment, lastPawnsData,
  selectLoading, selectPage,
  selectPawnsForEquipment,
  selectWasLastPawns,
} from '../../../../store/slices/requests';
import loader from '../../../../static/img/loader.gif';
import { filterType, tierType, typeFunc } from '../../../../utils/chooseData';
import uniqueId from '../../../../utils/uniqueId';
import Card from '../../../Pick/Inventory/Card';
import { selectActivePreset, selectGuilds } from '../../../../store/slices/preset';
import styles from '../Inventory.module.scss';

const PawnInventory = ({
  setSelectedPawn, selectedPawn, handlePawns, filter, search, tier,
}) => {
  const dispatch = useDispatch();
  const firstUpdate = useRef(true);
  const firstUpdateFilter = useRef(true);

  const address = useSelector(selectEvm);
  const loading = useSelector(selectLoading);
  const wasLastPawn = useSelector(selectWasLastPawns);
  const page = useSelector(selectPage);
  const pawns = useSelector(selectPawnsForEquipment);
  const activePreset = useSelector(selectActivePreset);
  const guild = useSelector(selectGuilds);

  const generateRequest = () => {
    const obj = {
      limit: 12,
      address,
      sort_by: 'Default',
      offset: (page - 1) * 12,
    };
    if (search) {
      obj.name = search;
    }
    if (filterType[filter]) {
      obj.class = filterType[filter];
    }
    if (tierType[tier]) {
      obj.tier = tierType[tier];
    }
    return obj;
  };

  const fetchPawns = (params, offset) => {
    dispatch(getPawnsSellForEquipment(params, offset));
    dispatch(getPage(page + 1));
  };

  const checkHandle = (id) => (guild[typeFunc(activePreset)]
    ? guild[typeFunc(activePreset)].map((elem) => elem.pawn.nftId).includes(id)
    : false);

  const handleHeight = (value) => {
    if (window.innerWidth > 1921) {
      if (value > 1400) {
        return 2.65;
      }
      if (value > 1300) {
        return 2.68;
      }
      if (value > 1200) {
        return 2.72;
      }
      if (value > 1100) {
        return 2.8;
      }
      return 2.85;
    }
    return 2.76;
  };

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    const timeOutId = setTimeout(() => {
      dispatch(getPawnsSellForEquipment({
        limit: 12,
        address,
        sort_by: 'Default',
        offset: 0,
        name: search,
      }));
    }, 1000);
    dispatch(lastPawnsData(false));
    dispatch(getPage(2));
    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timeOutId);
  }, [search]);

  useLayoutEffect(() => {
    if (firstUpdateFilter.current) {
      firstUpdateFilter.current = false;
      return;
    }
    dispatch(getPawnsSellForEquipment({
      limit: 12,
      address,
      sort_by: 'Default',
      offset: 0,
      class: filterType[filter] || '',
      tier: tierType[tier] || '',
    }));
    dispatch(lastPawnsData(false));
    dispatch(getPage(2));
  }, [filter, tier]);

  return useMemo(() => {
    if (loading) {
      return (
        <div className={styles.loader}>
          <img src={loader} alt="loading..." />
        </div>
      );
    }
    if (pawns.length) {
      return (
        <div id="scrollableDiv">
          <InfiniteScroll
            dataLength={pawns?.length}
            next={() => fetchPawns(generateRequest(), true)}
            hasMore={!wasLastPawn}
            scrollableTarget="scrollableDiv"
            loader={(
              <div className={styles.loader}>
                <img src={loader} alt="loading..." />
              </div>
            )}
            className={styles.wrapper_card}
            height={window.innerHeight / handleHeight(window.innerHeight)}
          >
            {pawns?.map((elem) => (
              <div key={uniqueId('pawns_showing')}>
                <Card
                  check={checkHandle(elem.nftId)}
                  data={elem}
                  setSelectedPawn={setSelectedPawn}
                  selectedPawn={selectedPawn}
                  handlePawns={handlePawns}
                  vs={activePreset}
                />
              </div>
            ))}
          </InfiniteScroll>
        </div>
      );
    }
    return (
      <div className={styles.no_data}>
        You do not have any pawns
      </div>
    );
  }, [
    loading,
    pawns,
    selectedPawn,
    page, handlePawns]);
};

export default PawnInventory;
