import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styles from '../LayoutWindow.module.scss';
import EnemyInfo from '../../../pages/Arena/EnemyInfo/EnemyInfo';
import StatusBar from '../../../pages/Arena/StatusBar/StatusBar';
import BattlePlace from '../../../pages/Arena/BattlePlace';
import Separator from '../Separator';
import TurnControl from '../../../pages/Arena/TurnControl/TurnControl';
import loader from '../../../static/img/loader.gif';
// import { selectPlayersInfo } from '../../../store/slices/arena';
import { selectIsActiveFight, selectPlayersInfo } from '../../../store/slices/arena';
import FightHistory from '../../../pages/Arena/FightHistory';

const ArenaWrapper = ({ address }) => {
  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);
  const { player, opponent, whoseTurn } = useSelector(selectPlayersInfo);
  const activeFight = useSelector(selectIsActiveFight);

  useEffect(() => {
    const onResize = (e) => {
      setWidth(e.target.innerWidth);
    };
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    if (!activeFight) {
      navigate('/arena-hub');
    }
  }, [activeFight]);

  return (
    <div className={styles.container}>
      {Object.keys(player).length && Object.keys(opponent).length
        ? (
          <>
            <div className={styles.arena_header}>
              <EnemyInfo data={opponent} />
              <StatusBar
                data={opponent}
                isMyTurn={whoseTurn !== address}
              />
            </div>
            {width < 880 && <Separator />}
            <div className={styles.battle_area}>
              <BattlePlace
                address={address}
                player={player}
                opponent={opponent}
              />
              {width >= 880 && <FightHistory />}
            </div>
            <Separator reverse />
            <div className={styles.arena_footer}>
              <StatusBar
                who="user"
                data={player}
                isMyTurn={whoseTurn === address}
              />
              <TurnControl />
            </div>
            {width < 880 && (
              <>
                <Separator />
                <FightHistory />
                <Separator reverse />
              </>
            )}
          </>
        )
        : (
          <>
            <div className={styles.loader_wrap} />
            <img src={loader} alt="loader" className={styles.loader} />
          </>
        )}
    </div>
  );
};

export default ArenaWrapper;
