const formulaVars = [
  'MD',
  'WD',
  'Speed',
  'Magicka',
  'Focus',
  'Discipline',
  'Normal',
  'Quick',
  'Primary',
  'Secondary',
  'Armor',
  'Willpower',
  'Dodge',
  'Crit',
];

export default formulaVars;
