import Phaser from 'phaser';
import { Parser } from 'expr-eval';
import Action from './action';
import SpritePreviewScene from '../SpritePreviewScene';

class ActionSpriteAnimation extends Action {
  constructor(skill, config) {
    super(config);
    this.skill = skill;
    this.timeline = config.timeline;
    // this.target = config.sceneTarget.objects.pawn;
    console.log('CREATE SPRITE ANIMATION ACTION', config, skill);
    skill.scene.load.spritesheet(
      config.key || config.sprite.key,
      config.path || config.sprite.path,
      { frameWidth: config.width || config.sprite.width, frameHeight: config.height || config.sprite.height },
    );
    skill.scene.load.once(Phaser.Loader.Events.COMPLETE, () => {
      console.log('LOAD COMPLETE!!!');
      if (skill.pawn.objects[config.key || config.sprite.key]) {
        console.log('SPRITE EXISTS!!!', skill.pawn.objects[config.key || config.sprite.key]);
        skill.pawn.objects[config.key || config.sprite.key].destroy();
        delete skill.pawn.objects[config.key || config.sprite.key];
        // return;
      }
      // texture loaded so use instead of the placeholder
      skill.pawn.objects[config.key || config.sprite.key] = (
        new Phaser.GameObjects.Sprite(
          skill.scene,
          skill.pawn.objects.pawn.x,
          skill.pawn.objects.pawn.y,
          config.key || config.sprite.key,
        )
      )
        .setDisplayOrigin(
          ...(this.skill.pawn.isCurrentPlayer
            ? SpritePreviewScene.getXYOrigin(config.originCurrent || config.sprite.originCurrent)
            : SpritePreviewScene.getXYOrigin(config.originOpponent || config.sprite.originOpponent)),
        )
        .setVisible(false)
        .setDepth(20);

      skill.pawn.objects[config.key || config.sprite.key].flipX = !this.skill.pawn.isCurrentPlayer;

      if (config.depth) {
        skill.pawn.objects[config.key || config.sprite.key].setDepth(config.depth);
      }
      this.target = skill.pawn.objects[config.key || config.sprite.key];
      skill.scene.add.existing(skill.pawn.objects[config.key || config.sprite.key]);
      const animationConfig = (config.animations || config.sprite.animations)
        .find((a) => (a.key || config.sprite.key) === config.animation);
      skill.createAnimations({ key: config.key || config.sprite.key, animations: [animationConfig] });

      const propXY = {};
      if (this.config.positionFrom && this.config.positionTo) {
        const parser = new Parser();
        const posFromArr = this.config.positionFrom.split(',');
        const posToArr = this.config.positionTo.split(',');
        const currentX = this.skill.pawn.objects.pawn.x;
        const currentY = this.skill.pawn.objects.pawn.y;
        const opponentX = this.skill.opponentPawn.objects.pawn.x;
        const opponentY = this.skill.opponentPawn.objects.pawn.y;
        propXY.x = {
          value: {
            from: parser.parse(posFromArr[0].replace('current', currentX).replace('opponent', opponentX)).evaluate(),
            to: parser.parse(posToArr[0].replace('current', currentX).replace('opponent', opponentX)).evaluate(),
          },
        };
        propXY.y = {
          value: {
            from: parser.parse(posFromArr[1].replace('current', currentY).replace('opponent', opponentY)).evaluate(),
            to: parser.parse(posToArr[1].replace('current', currentY).replace('opponent', opponentY)).evaluate(),
          },
        };
      }

      this.timeline.add({
        at: this.delay,
        tween: {
          targets: this.target,
          duration: this.duration,
          flipX: !this.skill.pawn.isCurrentPlayer,
          props: {
            ...propXY,
            visible: 1,
            // x: { value: { from: this.target.x, to: this.target.x } },
            // y: { value: { from: this.target.y, to: this.target.y } },
          },
          // persist: false,
          persist: this.config.group === 'Persistent',
          // completeDelay: this.duration,
          onComplete: () => {
            console.log('!!!!!!!!!!!!!!!!!! oncompleteevent');
            if (this.config.group !== 'Persistent') {
              this.target.setVisible(false);
            }
          },
        },
        run: () => {
          // this.target.setVisible(true);
          this.target.play({
            // eslint-disable-next-line max-len
            key: animationConfig.key,
            // duration: this.duration,
          });
        },
      });
    });
    skill.scene.load.start();
  }
}

export default ActionSpriteAnimation;
