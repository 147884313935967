import React from 'react';
import styles from '../../SkillUtils.module.scss';

const Animations = ({
  register, watch, index, scene, onDelete, refreshScene,
}) => {
  const animationKey = watch(`animations.${index}.key`);
  const playAnimation = () => {
    refreshScene();
    scene.playAnimation(animationKey);
  };
  return (
    <div className={styles.form_row} style={{ paddingLeft: '10px' }}>
      <div>
        <span>choose animation from sprite</span>
      </div>
      <div style={{ margin: '5px 0 5px 0' }}>
        <div className={styles.animation_block}>
          <div>
            key
            <input
              type="text"
              placeholder="anim-mage-hit"
              onFocus={playAnimation}
              {...register(`animations.${index}.key`)}
            />
          </div>
          <div>
            start
            <input
              type="text"
              placeholder="0"
              onFocus={playAnimation}
              {...register(`animations.${index}.start`)}
            />
          </div>
          <div>
            end
            <input
              type="text"
              placeholder="6"
              onFocus={playAnimation}
              {...register(`animations.${index}.end`)}
            />
          </div>
          <div>
            frameRate
            <input
              type="text"
              placeholder="10"
              onFocus={playAnimation}
              {...register(`animations.${index}.frameRate`)}
            />
          </div>
          <div>
            repeat
            <input
              type="text"
              placeholder="2"
              onFocus={playAnimation}
              {...register(`animations.${index}.repeat`)}
            />
          </div>
          <button
            type="button"
            onClick={() => playAnimation()}
          >
            Play
          </button>
          <button
            type="button"
            onClick={() => onDelete()}
          >
            Delete animation
          </button>
        </div>
      </div>
    </div>
  );
};

export default Animations;
