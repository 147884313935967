import React, { useState } from 'react';
import styles from './Pagination.module.scss';

const Pagination = ({ page, onPageInc, onPageDec }) => {
  const [isClickLeft, setIsClickLeft] = useState(false);
  const [isClickRight, setIsClickRight] = useState(false);
  return (
    <div className={styles.pagination}>
      <div
        className={`${styles.left_arrow} ${isClickLeft && styles.active}`}
        role="presentation"
        onMouseDown={() => {
          setIsClickLeft(true);
          if (page > 1) {
            onPageDec();
          }
        }}
        onMouseUp={() => setIsClickLeft(false)}
        onMouseLeave={() => setIsClickLeft(false)}
      />
      <div className={styles.page}>{page}</div>
      <div
        className={`${styles.right_arrow} ${isClickRight && styles.active}`}
        role="presentation"
        onMouseDown={() => {
          setIsClickRight(true);
          onPageInc();
        }}
        onMouseUp={() => setIsClickRight(false)}
        onMouseLeave={() => setIsClickRight(false)}
      />
    </div>
  );
};

export default Pagination;
