import { HubConnectionBuilder } from '@microsoft/signalr';
import { store } from '../store';
import { userDisconnect } from '../store/slices/taverna';
import { setArenaChat, setLastMessage, setNewMessage } from '../store/slices/chat';
import { API_URL } from '../constants/constants';

let socialHubConnection = null;

export const socialHubSocket = (address, accessToken = localStorage.getItem('token')) => {
  if (socialHubConnection) {
    socialHubConnection.stop();
  }

  const url = `${API_URL}socialhub`;

  socialHubConnection = new HubConnectionBuilder()
    .withUrl(url, {
      withCredentials: true,
      accessTokenFactory: () => accessToken,
    })
    .build();

  socialHubConnection.start().catch((err) => {
    console.error(err.toString());
  });

  socialHubConnection.on('GlobalMessageReceived', (response) => {
    console.log('GlobalMessageReceived', response);
    store.dispatch(setArenaChat({
      ...response,
      date: [new Date().getHours(), new Date().getMinutes()].map((x) => (x < 10 ? `0${x}` : x)).join(':'),
    }));
    if (address?.toLowerCase() !== response?.address?.toLowerCase()) {
      store.dispatch(setNewMessage(true));
    }
    store.dispatch(setLastMessage(response));
  });

  // socialHubConnection.on('UserStatesUpdated', (response) => {
  //   console.log('UserStatesUpdated', response);
  //   store.dispatch(usersState(response));
  // });

  socialHubConnection.on('UserConnected', (response) => {
    console.log('UserConnected', response);
  });

  socialHubConnection.on('UserDisconnected', (response) => {
    console.log('UserDisconnected', response);
    store.dispatch(userDisconnect(response));
  });

  return socialHubConnection;
};

export const catchError = (err) => {
  console.error(err.toString());
};

export const handleUpdateUserPosition = (x, y, action = null, arg = null) => {
  socialHubConnection
    .invoke(
      'UpdateUserPosition',
      {
        x, y, action, arguments: arg,
      },
    )
    .catch(catchError);
};

export const handleSendGlobalMessage = (message) => {
  socialHubConnection
    .invoke('SendGlobalMessage', { message })
    .catch(catchError);
};
