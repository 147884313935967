// eslint-disable-next-line max-classes-per-file
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Phaser from 'phaser';
import { selectFightState, selectSpellDescriptionByPawn, selectTempResult } from '../../store/slices/arena';
import BattleScene from '../../utils/battle/battleScene';
import uniqueId from '../../utils/uniqueId';
import CharacterBar from './BattleWindow/CharacterBar';
import CastingBar from './BattleWindow/CastingBar';

let game = null;

const config = {
  type: Phaser.AUTO,
  parent: 'battle-container',
  transparent: true,
  backgroundColor: 'rgba(0,0,0,0)',
  'render.transparent': true,
  scale: {
    // mode: Phaser.Scale.ScaleModes.RESIZE,
    mode: Phaser.Scale.FIT,
    width: 1400,
    height: 614,
  },
};

const Test = ({
  onOpponentPawnSelect, currentPlayerPawns, opponentPlayerPawns, onPawnSelect, selectedPawn,
  currentPlayerSetsByPawn, opponentPlayerSetsByPawn,
}) => {
  // const [game, setGame] = useState();
  const [pawnsCreated, setPawnsCreated] = useState();
  const [gameReady, setGameReady] = useState(false);
  const [pawnsReady, setPawnsReady] = useState(false);
  const [currentPlayerStatusBarPositions, setCurrentPlayerStatusBarPositions] = useState();
  const [opponentPlayerStatusBarPositions, setOpponentPlayerStatusBarPositions] = useState();
  const [transformScale, setTransformScale] = useState(0);

  const [tempSolution, setTempSolution] = useState([]);
  // const [currentWidth, setCurrentHeight] = useState();
  // const fightPawns = useSelector(selectFightPawns);
  // const fightActions = useSelector(selectFightActions);
  const fightState = useSelector(selectFightState);
  const tempResult = useSelector(selectTempResult);
  const spellDescriptionByPawn = useSelector(selectSpellDescriptionByPawn);
  // const battleClick = () => {
  //   game.scene.getScene('battle-scene').battleZoom();
  // };

  // useEffect(() => {
  //   if (game && spellDescriptionByPawn) {
  //     game.scene.getScene('battle-scene')?.setSpells(spellDescriptionByPawn);
  //   }
  // }, [spellDescriptionByPawn]);

  useEffect(() => {
    if (fightState && JSON.stringify(fightState) !== JSON.stringify(tempSolution)) {
      setTempSolution(fightState);
    }
  }, [fightState]);

  useEffect(() => {
    if (pawnsReady) {
      game.scene.getScene('battle-scene').processState(tempSolution, tempResult);
    }
  }, [tempSolution, tempResult]);

  useEffect(() => {
    game = new Phaser.Game(config);
    game.events.on(
      Phaser.Core.Events.READY,
      () => {
        const scene = new BattleScene({
          key: 'battle-scene',
          setReady: setGameReady,
          setPawnsReady,
          onOpponentPawnSelect,
          setCurrentPlayerStatusBarPositions,
          setOpponentPlayerStatusBarPositions,
          setTransformScale,
          onPawnSelect,
          spells: spellDescriptionByPawn,
        });
        game.scene.add('battle-scene', scene, true);
      },
    );
    // setTimeout(
    //   () => {
    //     game.scene.getScene('battle-scene').createPawns(
    //       [{ class: 'Mage', id: 1 }, { class: 'Warrior', id: 2 }, { class: 'Archer', id: 3 }],
    //       [{ class: 'Mage', id: 4 }, { class: 'Warrior', id: 5 }, { class: 'Archer', id: 6 }],
    //       // [{ class: 'Warrior', id: 2 }],
    //       // [{ class: 'Mage', id: 4 }],
    //     );
    //   },
    //   1000,
    // );
    // game.scene.add('battle-scene', battleScene);
    // setGame();
  }, []);

  useEffect(() => {
    if (
      !pawnsCreated && fightState.fightPawns[0].length && fightState.fightPawns[0].length && gameReady
      && currentPlayerSetsByPawn && opponentPlayerSetsByPawn
    ) {
      setPawnsCreated(true);
      game.scene.getScene('battle-scene')
        .createPawns(
          fightState.fightPawns[0],
          fightState.fightPawns[1],
          currentPlayerSetsByPawn,
          opponentPlayerSetsByPawn,
        );
    }
  }, [fightState, gameReady, currentPlayerSetsByPawn, opponentPlayerSetsByPawn]);

  useEffect(() => {
    if (spellDescriptionByPawn && gameReady) {
      game.scene.getScene('battle-scene').setSpells(spellDescriptionByPawn);
    }
  }, [spellDescriptionByPawn, gameReady]);

  useEffect(() => {
    if (selectedPawn && pawnsReady) {
      game.scene.getScene('battle-scene').onCurrentPlayerPawnSelect(selectedPawn.id);
    }
  }, [selectedPawn, pawnsReady]);

  return (
    <>
      <div id="battle-container" />
      {(currentPlayerPawns || opponentPlayerPawns) && transformScale ? (
        <div style={{ position: 'absolute', top: '25px', width: '100%' }}>
          <CastingBar
            userData={currentPlayerPawns.map((spell) => spell.castEndOpponentSpells)}
            opponentData={opponentPlayerPawns.map((spell) => spell.castEndOpponentSpells)}
            currentPlayerStatusBarPositions={currentPlayerStatusBarPositions}
            opponentPlayerStatusBarPositions={opponentPlayerStatusBarPositions}
            transformScale={transformScale}
          />
        </div>
      ) : null }
      {currentPlayerPawns && currentPlayerStatusBarPositions?.length && currentPlayerPawns.map((elem, index) => (
        <div
          style={{
            position: 'absolute',
            top: `${currentPlayerStatusBarPositions?.[index]?.y}px`,
            left: `${currentPlayerStatusBarPositions[index].x - (84 * transformScale)}px`,
            transform: `scale(${transformScale})`,
          }}
          key={uniqueId('pawn')}
        >
          <CharacterBar
            type={elem.class}
            actionPoints={elem.actionPoints}
            maxActionPoints={elem.maxActionPoints}
            id={elem.id}
            hp={elem.health}
            maxHp={elem.maxHealth}
            debuffs={[...elem.buffs]
              .reduce((acc, cur) => (acc.findIndex((i) => i.type === cur.type) === -1 ? [...acc, cur] : acc), [])}
            active={selectedPawn?.id === elem.id}
            shield={elem.shields?.length ? elem.shields.reduce((acc, cur) => acc + cur.armor, 0) : 0}
          />
        </div>
      ))}
      {opponentPlayerPawns && opponentPlayerStatusBarPositions?.length && opponentPlayerPawns.map((elem, index) => (
        <div
          style={{
            position: 'absolute',
            top: `${opponentPlayerStatusBarPositions?.[index]?.y}px`,
            left: `${opponentPlayerStatusBarPositions[index].x - (84 * transformScale)}px`,
            transform: `scale(${transformScale})`,
          }}
          key={uniqueId('pawn')}
        >
          <CharacterBar
            type={`${elem.class}R`}
            actionPoints={elem.actionPoints}
            maxActionPoints={elem.maxActionPoints}
            id={elem.id}
            hp={elem.health}
            maxHp={elem.maxHealth}
            debuffs={[...elem.buffs]
              .reduce((acc, cur) => (acc.findIndex((i) => i.type === cur.type) === -1 ? [...acc, cur] : acc), [])}
            active={selectedPawn?.id === elem.id}
            shield={elem.shields?.length ? elem.shields.reduce((acc, cur) => acc + cur.armor, 0) : 0}
            pawn={elem}
          />
        </div>
      ))}
    </>
  );
};

export default Test;
