import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BigNumber, ethers } from 'ethers';
import archer from '../../../../static/img/presale/arch1.png';
import warrior from '../../../../static/img/presale/warr1.png';
import minus from '../../../../static/img/presale/minus.png';
import plus from '../../../../static/img/presale/plus.png';
import styles from './Presale.module.scss';
import InfoButton from './InfoButton';
import {
  getAllocationRemaining,
  getBatchRemaining,
  getPresaleBatchInfo,
  getTotalLeft,
  onPresaleBuy, selectBatchAdditionalData,
  selectBatchInfo, selectPresaleLoading,
} from '../../../../store/slices/presale';
import { BATCH_ID } from '../../../../constants/constants';
import loader from '../../../../static/img/loader.gif';

const Presale = () => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);

  const batchInfo = useSelector(selectBatchInfo);
  const { totalLeft, batchRemaining, allocationRemaining } = useSelector(selectBatchAdditionalData);
  const isLoading = useSelector(selectPresaleLoading);

  const onSubmit = () => {
    if (amount && batchInfo?.price) {
      dispatch(onPresaleBuy(BATCH_ID, amount, ethers.utils.parseUnits(batchInfo.price.toString(), 'wei')));
    }
  };

  useEffect(() => {
    dispatch(getPresaleBatchInfo(BATCH_ID));
    dispatch(getTotalLeft(BATCH_ID));
    dispatch(getBatchRemaining(BATCH_ID));
    dispatch(getAllocationRemaining(BATCH_ID));
  }, []);

  return (
    <div className={styles.wrapper}>
      {
        batchInfo
          ? (
            <div className={styles.content}>
              <img src={archer} alt="Archer" />
              <div className={styles.inventory}>
                <div className={styles.corner_inventory_top_left} />
                <div className={styles.border_inventory_top} />
                <div className={styles.corner_inventory_top_right} />
                <div className={styles.border_inventory_left} />
                <div className={styles.inventory_content}>
                  <div className={styles.wprapper_text}>
                    <h1>Pre-sale 1</h1>
                    <div className={styles.mobile_content_decor} />
                    <p>
                      Welcome to the first pre-sale for Sanctum! If you&apos;re
                      whitelisted, you&apos;ll have 24 hours stress-free from the start
                      of the sale to pick up your allocation. This can be done in
                      whatever size increments you wish, but you will not be able to
                      exceed your total allocation size.
                    </p>
                    <p>
                      After the 24 hours has elapsed, all remaining pawns will be sold
                      FCFS to the public (no whitelist required.)
                    </p>
                    <p>
                      Each mint will recruit a pawn of a random class, with random
                      traits, and stats (traits & stats depending on rarity level.) For
                      more information on any of this, head over to the discord.
                    </p>
                    <div className={styles.mobile_content_decor} />
                    <div className={styles.info}>
                      <div className={styles.info_col}>
                        <span className={styles.bacth_total}>
                          {`Total left: ${totalLeft}`}
                        </span>
                        <span className={styles.bacth_info}>
                          {`Your whitelisted allocation remaining: ${batchRemaining}`}
                        </span>
                        <span className={styles.bacth_pawns}>
                          {`Your batch pawns remaining: ${allocationRemaining}`}
                        </span>
                      </div>
                      <div className={styles.info_col}>
                        <span className={styles.bacth_start}>
                          {`Batch start: ${batchInfo.start.slice(0, 10)}`}
                        </span>
                        <span className={styles.bacth_end}>
                          {`Batch end: ${batchInfo.end.slice(0, 10)}`}
                        </span>
                        <span className={styles.bacth_size}>
                          {`Batch size: ${batchInfo.size}`}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.border_inventory_right} />
                <div className={styles.corner_inventory_bottom_left} />
                <div className={styles.border_inventory_bottom} />
                <div className={styles.corner_inventory_bottom_right} />
              </div>
              <img src={warrior} alt="Warrior" />
            </div>
          )
          : null
      }
      <div className={styles.pricecontent}>
        <div className={styles.buttons}>
          {
            isLoading
              ? (
                <div className={styles.loading}>
                  <img src={loader} alt="loading" />
                </div>
              )
              : (
                <div onClick={() => onSubmit()} role="presentation">
                  <InfoButton
                    text="Buy"
                  />
                </div>
              )
          }
          <div className={styles.button_pagination}>
            <div
              role="presentation"
              className={styles.button_count}
              onClick={() => {
                if (amount && amount > 0) {
                  setAmount(amount - 1);
                } else {
                  setAmount(0);
                }
              }}
            >
              <img src={minus} alt="minus" />
            </div>
            <div className={styles.button_info}>
              <div className={styles.input_left} />
              <div className={styles.input_content}>
                <input
                  type="number"
                  placeholder=""
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
              <div className={styles.input_right} />
            </div>
            <div
              role="presentation"
              className={styles.button_count}
              onClick={() => {
                if (amount) {
                  setAmount(amount + 1);
                } else {
                  setAmount(1);
                }
              }}
            >
              <img src={plus} alt="plus" />
            </div>
          </div>
        </div>
        <div className={styles.line}>
          <div className={styles.line_top} />
          <div className={styles.line_centre} />
          <div className={styles.line_bottom} />
        </div>
        {
          batchInfo
            ? (
              <div className={styles.priceinfo}>
                <p>
                  Price per unit:&nbsp;
                  {ethers.utils.formatEther(batchInfo.price.toString()).toString()}
                </p>
                <p>
                  Total:&nbsp;
                  {ethers.utils.formatEther(BigNumber.from(batchInfo.price).mul(amount))}
                </p>
              </div>
            )
            : null
        }
      </div>
    </div>
  );
};

export default Presale;
