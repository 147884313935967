import React, { useState, useEffect } from 'react';
import styles from '../QuestPage.module.scss';

const CountDown = ({
  duration, setIsTimeout, isTimeout,
}) => {
  const [finish, setFinish] = useState(duration);
  const [timerId, setTimerID] = useState(0);

  useEffect(() => {
    if (duration < 0) {
      setIsTimeout(true);
      return;
    }
    if (!finish) {
      setIsTimeout(true);
    }
  }, [finish]);

  useEffect(() => {
    if (isTimeout) clearInterval(timerId);
  }, [isTimeout]);

  useEffect(() => {
    const timerID = setInterval(() => {
      setFinish(finish - 1);
    }, 1000);
    setTimerID(timerID);
    return () => clearInterval(timerID);
  }, [finish]);

  return (
    <div className={styles.time_over}>
      {`Time left: ${(Math.floor((finish / 60) % 60))
        .toString().padStart(2, '0')}:${(Math.floor(finish % 60))
        .toString().padStart(2, '0')}`}
    </div>
  );
};

export default CountDown;
