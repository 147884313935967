import React, {
  useEffect, useLayoutEffect, useMemo, useRef,
} from 'react';
import { useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactTooltip from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { selectEvm } from '../../../../store/slices/auth';
import uniqueId from '../../../../utils/uniqueId';
import {
  addPawnEquipment,
  modalShowing,
  removePawnEquipment,
  selectActivePreset,
} from '../../../../store/slices/preset';
import { setItemForCrafting } from '../../../../store/slices/crafting';
import Tooltip from '../../components/Tooltip';
import {
  filterEquipmentCard,
  getEquipmentData,
  getPageForEquipment,
  lastEquipmentData,
  selectEquipment,
  selectLoadingEquipment,
  selectPageForEquipment,
  selectWasLastEquipment,
} from '../../../../store/slices/constructor';
import loader from '../../../../static/img/loader.gif';
import { filterClass, filterSlot, filterTier } from '../../../../utils/chooseData';
import styles from '../Inventory.module.scss';

const PawnEquipments = ({
  pawnGuildEquipment, pawn, selectedPawn, filterByClass, filterByTier, filterBySlot,
}) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const firstUpdate = useRef(true);

  const address = useSelector(selectEvm);
  const loading = useSelector(selectLoadingEquipment);
  const wasLastEquipment = useSelector(selectWasLastEquipment);
  const page = useSelector(selectPageForEquipment);
  const equipmentCards = useSelector(selectEquipment);
  const activePreset = useSelector(selectActivePreset);

  const generateRequest = () => ({
    limit: 48,
    address,
    offset: (page - 1) * 48,
    class: filterClass[filterByClass],
    tier: filterTier[filterByTier],
    type: filterSlot[filterBySlot],
  });

  const fetchEquipments = (params, offset, wasLast) => {
    if (wasLast) return;
    dispatch(getEquipmentData(params, offset));
    dispatch(getPageForEquipment(page + 1));
  };

  const handleType = (type, classes) => {
    if (type === 'Shield') {
      if (classes === 'Archer') {
        return 'Quiver';
      }
      if (classes === 'Mage') {
        return 'Trinket';
      }
    }
    return type;
  };

  const handleHeight = (value) => {
    if (window.innerWidth > 1921) {
      if (value > 1400) {
        return 2.35;
      }
      if (value > 1300) {
        return 2.68;
      }
      if (value > 1200) {
        return 2.72;
      }
      if (value > 1100) {
        return 2.8;
      }
      return 2.85;
    }
    return pathname.includes('crafting') ? 2.6 : 2.76;
  };

  useLayoutEffect(() => {
    if (wasLastEquipment) return;
    if (firstUpdate.current) {
      dispatch(getEquipmentData(generateRequest()));
      dispatch(getPageForEquipment(2));
      firstUpdate.current = false;
      return;
    }
    dispatch(getEquipmentData({
      limit: 48,
      address,
      offset: 0,
      class: filterClass[filterByClass],
      tier: filterTier[filterByTier],
      type: filterSlot[filterBySlot],
    }));
    dispatch(lastEquipmentData(false));
    dispatch(getPageForEquipment(2));
  }, [filterByClass, filterByTier, filterBySlot, wasLastEquipment]);

  useEffect(() => () => dispatch(getPageForEquipment(1)), []);

  return useMemo(() => {
    if (loading && !equipmentCards?.length) {
      return (
        <div className={styles.loader}>
          <img src={loader} alt="loading..." />
        </div>
      );
    }
    if (equipmentCards?.length) {
      return (
        <div className={styles.wrapper_content}>
          <InfiniteScroll
            dataLength={equipmentCards?.length}
            next={() => fetchEquipments(generateRequest(), true, wasLastEquipment)}
            hasMore={!wasLastEquipment}
            scrollableTarget="scrollableDiv"
            loader={(
              <div className={styles.loader}>
                <img src={loader} alt="loading..." />
              </div>
            )}
            className={styles.wrapper_spells}
            height={window.innerHeight / handleHeight(window.innerHeight)}
          >
            {/* <div className={styles.wrapper_spells}> */}
            {equipmentCards.map((elem) => (
              <div key={uniqueId('pawns_equipments')}>
                <div
                  className={`${styles.img_wrap} ${elem?.usedByPawn && !pathname.includes('crafting')
                    ? styles.gray_img : ''} `}
                  role="presentation"
                  onClick={() => {
                    if (pathname.includes('crafting')) {
                      dispatch(setItemForCrafting(elem));
                      dispatch(filterEquipmentCard(elem.id));
                    } else {
                      if ((elem?.usedByPawn === selectedPawn
                              && pawnGuildEquipment[elem.type])
                          || (Object.keys(pawn).length && !elem?.usedByPawn && pawnGuildEquipment[elem.type])) {
                        dispatch(removePawnEquipment(
                          {
                            pawnId: selectedPawn,
                            itemId: pawnGuildEquipment[elem.type].id,
                            vs: activePreset,
                          },
                          {
                            [elem.type]: '',
                          },
                          pawnGuildEquipment[elem.type]?.stats || null,
                          { ...elem },
                        ));
                      }
                      if (elem?.usedByPawn && elem?.usedByPawn !== selectedPawn) {
                        dispatch(modalShowing({
                          modal: true,
                          action: {
                            pawnId: elem.usedByPawn,
                            itemId: elem.id,
                          },
                        }));
                      }
                      if (Object.keys(pawn).length && !elem?.usedByPawn && !pawnGuildEquipment[elem.type]) {
                        dispatch(addPawnEquipment({
                          pawnId: selectedPawn,
                          body: {
                            guildEquipmentItemId: elem.id,
                          },
                          vs: activePreset,
                        }, {
                          ...elem,
                        }));
                      }
                    }
                  }}
                >
                  <img
                    src={`https://i.bimium.link/${elem.image}`}
                    alt="equipment"
                    width="52px"
                    height="52px"
                    data-tip
                    data-for={`${elem.id}equipment`}
                  />
                </div>
                <ReactTooltip
                  id={`${elem.id}equipment`}
                  place="top"
                  backgroundColor="none"
                  arrowColor="transparent"
                  delayShow={100}
                >
                  <Tooltip
                    name={elem.name}
                    stats={{
                      status: elem?.usedByPawn ? 'Used' : 'Not used',
                      class: elem?.class,
                      level: elem?.level,
                      type: handleType(elem?.type, elem?.class),
                      tier: elem?.tier,
                      ...elem?.stats,
                      health: elem?.additionalStats?.health?.value
                        ? `${elem?.additionalStats?.health?.value}${elem?.additionalStats?.health?.type === 'Percent'
                          ? '%'
                          : ''}`
                        : undefined,
                      magicka: elem?.additionalStats?.magicka?.value
                        ? `${elem?.additionalStats?.magicka?.value}${elem?.additionalStats?.magicka?.type === 'Percent'
                          ? '%'
                          : ''}`
                        : undefined,
                      willpower: elem?.additionalStats?.willpower?.value
                        ? `${elem?.additionalStats?.willpower?.value}${elem?.additionalStats?.willpower?.type
                        === 'Percent'
                          ? '%'
                          : ''}`
                        : undefined,
                      armor: elem?.additionalStats?.armor?.value
                        ? `${elem?.additionalStats?.armor?.value}${elem?.additionalStats?.armor?.type === 'Percent'
                          ? '%'
                          : ''}`
                        : undefined,
                      speed: elem?.additionalStats?.speed?.value
                        ? `${elem?.additionalStats?.speed?.value}${elem?.additionalStats?.speed?.type === 'Percent'
                          ? '%'
                          : ''}`
                        : undefined,
                      magic_damage: elem?.additionalStats?.magicDamage?.value
                        ? `${elem?.additionalStats?.magicDamage?.value}${elem?.additionalStats?.magicDamage?.type
                        === 'Percent'
                          ? '%'
                          : ''}`
                        : undefined,
                      weapon_damage: elem?.additionalStats?.weaponDamage?.value
                        ? `${elem?.additionalStats?.weaponDamage?.value}${elem?.additionalStats?.weaponDamage?.type
                        === 'Percent'
                          ? '%'
                          : ''}`
                        : undefined,
                      critical_strike: elem?.additionalStats?.criticalStrike?.value
                        ? `${elem?.additionalStats?.criticalStrike?.value}${elem?.additionalStats?.criticalStrike?.type
                        === 'Percent'
                          ? '%'
                          : ''}`
                        : undefined,
                      dodge: elem?.additionalStats?.dodge?.value
                        ? `${elem?.additionalStats?.dodge?.value}${elem?.additionalStats?.dodge?.type === 'Percent'
                          ? '%'
                          : ''}`
                        : undefined,
                    }}
                  />
                </ReactTooltip>
              </div>
            ))}
            {/* </div> */}
          </InfiniteScroll>
        </div>
      );
    }
    return (
      <div className={styles.no_data}>
        You do not have any equipment
      </div>
    );
  }, [equipmentCards, loading, page]);
};

export default PawnEquipments;
