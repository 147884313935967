import { createSlice } from '@reduxjs/toolkit';
import {
  cancelListing, claimNft, listItem, transferPawn,
} from '../../../utils/requests';
import { clearPawn } from '../pawnSelect';
import { notificationShowAction } from '../utils';

export const tradeItem = createSlice({
  name: 'tradeItem',
  initialState: {
    loading: false,
    transferLoading: false,
    buyLoading: false,
    listLoading: false,
    unListLoading: false,
    unListId: null,
    unListSuccess: false,
  },
  reducers: {
    onTransferRequested: (state) => ({
      ...state,
      transferLoading: true,
    }),
    onTransferFailure: (state, { payload }) => ({
      ...state,
      transferLoading: false,
      error: payload,
    }),
    onTransferSuccess: (state) => ({
      ...state,
      transferLoading: false,
      error: false,
    }),
    buyItemRequested: (state) => ({
      ...state,
      buyLoading: true,
    }),
    buyItemFailure: (state, { payload }) => ({
      ...state,
      buyLoading: false,
      error: payload,
    }),
    buyItemSuccess: (state) => ({
      ...state,
      buyLoading: false,
    }),
    listItemRequested: (state) => ({
      ...state,
      listLoading: true,
    }),
    listItemFailure: (state, { payload }) => ({
      ...state,
      listLoading: false,
      error: payload,
    }),
    listItemSuccess: (state) => ({
      ...state,
      listLoading: false,
    }),
    unlistItemRequested: (state, { payload }) => ({
      ...state,
      unListLoading: true,
      unListId: payload,
    }),
    unlistItemFailure: (state, { payload }) => ({
      ...state,
      unListLoading: false,
      error: payload,
      unListId: null,
    }),
    // eslint-disable-next-line consistent-return
    unlistItemSuccess: (state, { payload }) => {
      if (state.unListId?.toString() === payload) {
        return ({
          ...state,
          unListLoading: false,
          unListId: null,
          unListSuccess: true,
        });
      }
    },
    clearUnListId: (state) => ({
      ...state,
      unListId: null,
      unListSuccess: false,
    }),
  },
});

// ---- actions ----
export const {
  buyItemSuccess, buyItemRequested, buyItemFailure,
  listItemSuccess, listItemRequested, listItemFailure,
  unlistItemSuccess, unlistItemRequested, unlistItemFailure, clearUnListId,
  onTransferRequested, onTransferFailure, onTransferSuccess,
} = tradeItem.actions;

// ---- request actions ----
export const buyItem = (nftId) => async (dispatch) => {
  try {
    dispatch(buyItemRequested());
    await claimNft(nftId);
  } catch (e) {
    dispatch(buyItemFailure('Error during transaction'));
    dispatch(clearPawn());
    dispatch(notificationShowAction(false, 'Error during transaction'));
  }
};

export const listInventoryItem = (nftId, priceInEth) => async (dispatch) => {
  try {
    dispatch(listItemRequested());
    await listItem(nftId, priceInEth);
  } catch (e) {
    dispatch(listItemFailure('Error during transaction'));
    dispatch(clearPawn());
    dispatch(notificationShowAction(false, 'Error during transaction'));
  }
};

export const unlistInventoryItem = (nftId) => async (dispatch) => {
  try {
    dispatch(unlistItemRequested(nftId));
    await cancelListing(nftId);
  } catch (e) {
    dispatch(unlistItemFailure('Error during transaction'));
    dispatch(clearPawn());
    dispatch(notificationShowAction(false, 'Error during transaction'));
  }
};

export const onTransfer = (to, id) => async (dispatch) => {
  try {
    dispatch(onTransferRequested());
    await transferPawn(to, id);
    dispatch(notificationShowAction(true, 'Transferred successfully'));
    dispatch(onTransferSuccess());
  } catch (e) {
    const err = e.message;
    dispatch(onTransferFailure(err));
    if (err.includes('user rejected')) {
      dispatch(notificationShowAction(false, 'User denied transaction'));
    } else {
      dispatch(notificationShowAction(false, 'Transferred failure'));
    }
  }
};

// ---- selectors ----
export const selectPawnLoading = (state) => (state.tradeItem.loading);
export const selectItemList = (state) => ({
  buyLoading: state.tradeItem.buyLoading,
  listLoading: state.tradeItem.listLoading,
  unListLoading: state.tradeItem.unListLoading,
  isTransferLoading: state.tradeItem.transferLoading,
});

export const selectUnListId = (state) => state.tradeItem.unListId;
export const selectIsUnListSuccess = (state) => state.tradeItem.unListSuccess;
