import { createSlice } from '@reduxjs/toolkit';
import {
  deletePawnGuildConsumable,
  deletePawnGuildEquipment, deletePawnSpell,
  getGuilds,
  getGuildsEquipmentForPawn,
  postPawnGuildConsumable,
  postPawnGuildEquipment, putPawnSpell,
  putPreset,
} from '../../../utils/requests';
import { notificationShowAction } from '../utils';
import { emptyConsumablesArray, emptyItemsObject, emptySpellsObject } from '../../../utils/skill/pawns';
import { usingEquipment, usingItem } from '../constructor';
// eslint-disable-next-line import/no-cycle
import { removeSkills, updateStats } from '../pawnSelect';

export const preset = createSlice({
  name: 'preset',
  initialState: {
    presetLoaded: false,
    presetLoading: false,
    equipmentLoading: false,
    isModalShowing: false,
    pawnId: null,
    upgradePoints: 0,
    activePreset: 1,
    pawnEquipment: {
      items: emptyItemsObject,
      consumables: emptyConsumablesArray,
      spells: emptySpellsObject,
    },
    error: null,
    guilds: {},
    action: null,
    type: null,
    equipmentType: 'Pawns',
    set: null,
  },
  reducers: {
    pawnSpellRequest: (state) => ({
      ...state,
      equipmentLoading: true,
    }),
    pawnEquipmentRequest: (state) => ({
      ...state,
      equipmentLoading: true,
    }),
    pawnItemRequest: (state) => ({
      ...state,
      equipmentLoading: true,
    }),
    presetRequested: (state) => ({
      ...state,
      presetLoading: true,
      presetLoaded: false,
    }),
    presetFailure: (state, { payload }) => ({
      ...state,
      presetLoading: false,
      error: payload,
    }),
    pawnEquipmentFailure: (state, { payload }) => ({
      ...state,
      equipmentLoading: false,
      error: payload,
    }),
    pawnItemFailure: (state, { payload }) => ({
      ...state,
      equipmentLoading: false,
      error: payload,
    }),
    pawnSpellFailure: (state, { payload }) => ({
      ...state,
      equipmentLoading: false,
      error: payload,
    }),
    pawnEquipmentSuccess: (state, { payload }) => {
      const vs = payload.vs === 1
        ? 'OneVsOne'
        : (payload.vs === 2 ? 'TwoVsTwo' : (payload.vs === 3 ? 'ThreeVsThree' : payload.vs));
      const data = JSON.parse(JSON.stringify([...state.guilds[vs]]));
      const spellsState = JSON.parse(JSON.stringify(state.pawnEquipment.spells));
      const resultGuild = data.map((elem) => (elem.pawn?.nftId === payload.pawnId
      && (!Object.keys(elem).includes('consumables') || !Object.keys(elem).includes('spells'))
        ? ({
          ...elem,
          consumables: payload.data.consumables,
          spells: payload.data.spells,
        }) : ({ ...elem })));
      return ({
        ...state,
        equipmentLoading: false,
        pawnId: payload.data.pawnId,
        upgradePoints: payload.data.upgradePoints || 0,
        set: payload.data.set,
        pawnEquipment: {
          items: payload?.data?.items ? {
            ...emptyItemsObject,
            ...payload.data.items,
          } : emptyItemsObject,
          consumables: payload?.data?.consumables?.length
            ? payload.data.consumables.length === 1
              ? [...payload.data.consumables, null]
              : payload.data.consumables
            : emptyConsumablesArray,
          spells: Object.keys(payload?.data?.spells).length !== 0
            ? ({
              ...Object.keys({ ...spellsState }).map((item) => (payload.data.spells[item]
                ? ({
                  ...payload.data.spells[item],
                })
                : null)),
            })
            : emptySpellsObject,
        },
        guilds: {
          ...state.guilds,
          [vs]: resultGuild,
        },
        error: false,
      });
    },
    presetSuccess: (state, { payload }) => ({
      ...state,
      presetLoading: false,
      presetLoaded: true,
      guilds: Object.keys(payload).length !== 0 ? payload : {},
      error: false,
    }),
    addPointsToStat: (state) => ({
      ...state,
      upgradePoints: state.upgradePoints - 1,
    }),
    addPawnSpellSuccess: (state, { payload }) => {
      const result = {};
      const OneVsOne = state.guilds?.OneVsOne ? JSON
        .parse(JSON.stringify([...state.guilds.OneVsOne])).some((elem) => elem.pawn.nftId === payload.pawnId) : false;
      const TwoVsTwo = state.guilds?.TwoVsTwo ? JSON
        .parse(JSON.stringify([...state.guilds.TwoVsTwo])).some((elem) => elem.pawn.nftId === payload.pawnId) : false;
      const ThreeVsThree = state.guilds?.ThreeVsThree ? JSON
        .parse(JSON.stringify([...state.guilds.ThreeVsThree])).some((elem) => elem.pawn.nftId === payload.pawnId)
        : false;
      if (OneVsOne) {
        result.OneVsOne = JSON.parse(JSON.stringify([...state.guilds.OneVsOne]))
          .map((elem) => (elem.pawn.nftId === payload.pawnId ? ({
            ...elem,
            spells: {
              ...elem.spells,
              [payload.position]: payload.data,
            },
          }) : ({ ...elem })));
      }
      if (TwoVsTwo) {
        result.TwoVsTwo = JSON.parse(JSON.stringify([...state.guilds.TwoVsTwo]))
          .map((elem) => (elem.pawn.nftId === payload.pawnId ? ({
            ...elem,
            spells: {
              ...elem.spells,
              [payload.position]: payload.data,
            },
          }) : ({ ...elem })));
      }
      if (ThreeVsThree) {
        result.ThreeVsThree = JSON.parse(JSON.stringify([...state.guilds.ThreeVsThree]))
          .map((elem) => (elem.pawn.nftId === payload.pawnId ? ({
            ...elem,
            spells: {
              ...elem.spells,
              [payload.position]: payload.data,
            },
          }) : ({ ...elem })));
      }
      return ({
        ...state,
        equipmentLoading: false,
        pawnEquipment: {
          ...state.pawnEquipment,
          spells: {
            ...state.pawnEquipment.spells,
            [payload.position]: payload.data,
          },
        },
        guilds: {
          ...state.guilds,
          ...result,
        },
      });
    },
    addPawnEquipmentSuccess: (state, { payload }) => ({
      ...state,
      equipmentLoading: false,
      pawnEquipment: {
        ...state.pawnEquipment,
        items: {
          ...state.pawnEquipment.items,
          [payload.type]: {
            ...payload,
          },
        },
      },
    }),
    addPawnItemSuccess: (state, { payload }) => {
      const handleItems = (obj) => {
        const array = JSON.parse(JSON.stringify([...state.pawnEquipment.consumables]));
        const filterById = array.filter((elem) => !!elem).length ? array.map((item) => (item ? item.id : null)) : [];
        if (filterById.includes(obj.id)) return array;
        const findElem = array.indexOf(null);
        if (findElem === -1) return array;
        array[findElem] = obj;
        return array;
      };
      const result = {};
      const OneVsOne = state.guilds?.OneVsOne ? JSON
        .parse(JSON.stringify([...state.guilds.OneVsOne])).some((elem) => elem.pawn.nftId === payload.pawnId) : false;
      const TwoVsTwo = state.guilds?.TwoVsTwo ? JSON
        .parse(JSON.stringify([...state.guilds.TwoVsTwo])).some((elem) => elem.pawn.nftId === payload.pawnId) : false;
      const ThreeVsThree = state.guilds?.ThreeVsThree ? JSON
        .parse(JSON.stringify([...state.guilds.ThreeVsThree])).some((elem) => elem.pawn.nftId === payload.pawnId)
        : false;
      if (OneVsOne) {
        result.OneVsOne = JSON.parse(JSON.stringify([...state.guilds.OneVsOne]))
          .map((elem) => (elem.pawn.nftId === payload.pawnId ? ({
            ...elem,
            consumables: handleItems(payload.data),
          }) : ({ ...elem })));
      }
      if (TwoVsTwo) {
        result.TwoVsTwo = JSON.parse(JSON.stringify([...state.guilds.TwoVsTwo]))
          .map((elem) => (elem.pawn.nftId === payload.pawnId ? ({
            ...elem,
            consumables: handleItems(payload.data),
          }) : ({ ...elem })));
      }
      if (ThreeVsThree) {
        result.ThreeVsThree = JSON.parse(JSON.stringify([...state.guilds.ThreeVsThree]))
          .map((elem) => (elem.pawn.nftId === payload.pawnId ? ({
            ...elem,
            consumables: handleItems(payload.data),
          }) : ({ ...elem })));
      }
      return ({
        ...state,
        equipmentLoading: false,
        pawnEquipment: {
          ...state.pawnEquipment,
          consumables: handleItems(payload.data),
        },
        guilds: {
          ...state.guilds,
          ...result,
        },
      });
    },
    deletePawnSpellSuccess: (state, { payload }) => {
      const deleteSpell = (position) => {
        const spellsObj = JSON.parse(JSON.stringify(state.pawnEquipment.spells));
        spellsObj[position] = null;
        return spellsObj;
      };
      const result = {};
      const OneVsOne = state.guilds?.OneVsOne ? JSON
        .parse(JSON.stringify([...state.guilds.OneVsOne])).some((elem) => elem.pawn.nftId === payload.pawnId) : false;
      const TwoVsTwo = state.guilds?.TwoVsTwo ? JSON
        .parse(JSON.stringify([...state.guilds.TwoVsTwo])).some((elem) => elem.pawn.nftId === payload.pawnId) : false;
      const ThreeVsThree = state.guilds?.ThreeVsThree ? JSON
        .parse(JSON.stringify([...state.guilds.ThreeVsThree])).some((elem) => elem.pawn.nftId === payload.pawnId)
        : false;
      if (OneVsOne) {
        result.OneVsOne = JSON.parse(JSON.stringify([...state.guilds.OneVsOne]))
          .map((elem) => (elem.pawn.nftId === payload.pawnId ? ({
            ...elem,
            spells: deleteSpell(payload.position),
          }) : ({ ...elem })));
      }
      if (TwoVsTwo) {
        result.TwoVsTwo = JSON.parse(JSON.stringify([...state.guilds.TwoVsTwo]))
          .map((elem) => (elem.pawn.nftId === payload.pawnId ? ({
            ...elem,
            spells: deleteSpell(payload.position),
          }) : ({ ...elem })));
      }
      if (ThreeVsThree) {
        result.ThreeVsThree = JSON.parse(JSON.stringify([...state.guilds.ThreeVsThree]))
          .map((elem) => (elem.pawn.nftId === payload.pawnId ? ({
            ...elem,
            spells: deleteSpell(payload.position),
          }) : ({ ...elem })));
      }
      return ({
        ...state,
        equipmentLoading: false,
        pawnEquipment: {
          ...state.pawnEquipment,
          spells: deleteSpell(payload.position),
        },
        guilds: {
          ...state.guilds,
          ...result,
        },
      });
    },
    deletePawnEquipmentSuccess: (state, { payload }) => ({
      ...state,
      equipmentLoading: false,
      pawnEquipment: {
        ...state.pawnEquipment,
        items: {
          ...state.pawnEquipment.items,
          ...payload,
        },
      },
    }),
    deletePawnItemSuccess: (state, { payload }) => {
      const deleteItems = (id, data) => {
        const arrayItems = data ? [...data] : JSON.parse(JSON.stringify([...state.pawnEquipment.consumables]));
        const findElem = arrayItems.map((item) => (item ? item.id : null)).indexOf(id);
        if (findElem === -1) return arrayItems;
        arrayItems[findElem] = null;
        return arrayItems;
      };

      const result = {};
      const OneVsOne = state.guilds?.OneVsOne ? JSON
        .parse(JSON.stringify([...state.guilds.OneVsOne])).some((elem) => elem.pawn.nftId === payload.pawnId) : false;
      const TwoVsTwo = state.guilds?.TwoVsTwo ? JSON
        .parse(JSON.stringify([...state.guilds.TwoVsTwo])).some((elem) => elem.pawn.nftId === payload.pawnId) : false;
      const ThreeVsThree = state.guilds?.ThreeVsThree ? JSON
        .parse(JSON.stringify([...state.guilds.ThreeVsThree])).some((elem) => elem.pawn.nftId === payload.pawnId)
        : false;
      if (OneVsOne) {
        result.OneVsOne = JSON.parse(JSON.stringify([...state.guilds.OneVsOne]))
          .map((elem) => (elem.pawn.nftId === payload.pawnId ? ({
            ...elem,
            consumables: deleteItems(payload.consumableId, elem.consumables),
          }) : ({ ...elem })));
      }
      if (TwoVsTwo) {
        result.TwoVsTwo = JSON.parse(JSON.stringify([...state.guilds.TwoVsTwo]))
          .map((elem) => (elem.pawn.nftId === payload.pawnId ? ({
            ...elem,
            consumables: deleteItems(payload.consumableId, elem.consumables),
          }) : ({ ...elem })));
      }
      if (ThreeVsThree) {
        result.ThreeVsThree = JSON.parse(JSON.stringify([...state.guilds.ThreeVsThree]))
          .map((elem) => (elem.pawn.nftId === payload.pawnId ? ({
            ...elem,
            consumables: deleteItems(payload.consumableId, elem.consumables),
          }) : ({ ...elem })));
      }
      return ({
        ...state,
        equipmentLoading: false,
        pawnEquipment: {
          ...state.pawnEquipment,
          consumables: deleteItems(payload.consumableId),
        },
        guilds: {
          ...state.guilds,
          ...result,
        },
      });
    },
    savePawnsToGuilds: (state, { payload }) => ({
      ...state,
      guilds: {
        ...state.guilds,
        [payload.vs]: state.guilds[payload.vs]
          ? [...state.guilds[payload.vs], {
            pawn: payload.pawn,
          }]
          : [{ pawn: payload.pawn }],
      },
    }),
    pawnAddEquipmentToPawnPreset: (state, { payload }) => {
      const data = JSON.parse(JSON.stringify([...state.guilds[payload.vs]]));
      const result = data.map((elem) => (elem?.pawn?.nftId === payload.id
      && (!Object.keys(elem).includes('consumables') || !Object.keys(elem).includes('spells'))
        ? ({
          ...elem,
          consumables: payload.consumables,
          spells: payload.spells,
        }) : ({ ...elem })));
      return ({
        ...state,
        guilds: {
          ...state.guilds,
          [payload.vs]: result,
        },
      });
    },
    deletePawnFromGild: (state, { payload }) => ({
      ...state,
      guilds: {
        ...state.guilds,
        [payload.vs]: state.guilds[payload.vs]
          .filter((elem) => payload.id !== elem?.pawn?.nftId),
      },
    }),
    setActivePreset: (state, { payload }) => ({
      ...state,
      activePreset: payload,
    }),
    modalShowing: (state, { payload }) => ({
      ...state,
      isModalShowing: payload.modal,
      action: payload.action,
      type: payload.type,
    }),
    setEquipmentType: (state, { payload }) => ({
      ...state,
      equipmentType: payload,
    }),
  },
});

// ---- actions ----
export const {
  presetRequested, presetFailure, presetSuccess, savePawnsToGuilds, deletePawnFromGild,
  pawnEquipmentRequest, pawnEquipmentFailure, pawnEquipmentSuccess, pawnAddEquipmentToPawnPreset,
  addPawnEquipmentSuccess, deletePawnEquipmentSuccess, pawnItemRequest, pawnSpellRequest,
  deletePawnItemSuccess, addPawnItemSuccess, pawnItemFailure, pawnSpellFailure, setEquipmentType,
  addPawnSpellSuccess, deletePawnSpellSuccess, addPointsToStat, setActivePreset, modalShowing,
} = preset.actions;

// ---- request actions ----
export const putGuilds = (payload) => async (dispatch) => {
  try {
    dispatch(presetRequested());
    const response = await putPreset(payload);
    if (response.status >= 400) {
      dispatch(presetFailure(response.detail));
      dispatch(notificationShowAction(false, response.detail));
    } else if (response.status <= 204) {
      dispatch(notificationShowAction(true, 'Saved successfully'));
    }
  } catch (e) {
    dispatch(presetFailure(e.message));
  }
};

export const getPawnsGuild = (payload) => async (dispatch) => {
  try {
    dispatch(presetRequested());
    const response = await getGuilds(payload);
    if (response.status >= 400) {
      dispatch(presetFailure(response.data.message));
    } else if (response.status <= 204) {
      dispatch(presetSuccess(response.data));
    }
  } catch (e) {
    dispatch(presetFailure(e.message));
  }
};

export const getPawnEquipment = (pawnId, vs) => async (dispatch) => {
  try {
    dispatch(pawnEquipmentRequest());
    const response = await getGuildsEquipmentForPawn(pawnId);
    if (response.status >= 400) {
      dispatch(pawnEquipmentFailure(response.data.message));
    } else if (response.status <= 204) {
      dispatch(pawnEquipmentSuccess({
        data: response.data,
        pawnId,
        vs,
      }));
      if (response.data?.items) {
        const dataObj = response.data?.items;
        const stats = {};
        const arrayStats = Object.keys(response.data?.items).map((item) => (dataObj[item]?.stats
          ? ({ ...dataObj[item]?.stats })
          : null));
        arrayStats.forEach((elem) => {
          if (elem) {
            Object.keys(elem).forEach((item) => {
              const filter = Object.keys(stats).includes(item);
              if (elem[item]) {
                if (filter) {
                  stats[item] += elem[item];
                } else {
                  stats[item] = elem[item];
                }
              }
            });
          }
        });
        dispatch(updateStats(stats));
      }
    }
  } catch (e) {
    dispatch(pawnEquipmentFailure(e.message));
  }
};

export const addPawnSpell = (payload, data) => async (dispatch) => {
  try {
    dispatch(pawnSpellRequest());
    const response = await putPawnSpell(payload);
    if (response.status >= 400) {
      dispatch(pawnSpellFailure(response.data.detail));
      dispatch(notificationShowAction(false, response.data.detail || 'Something went wrong'));
    } else if (response.status <= 204) {
      dispatch(addPawnSpellSuccess({ data, position: payload.body.position, pawnId: payload.pawnId }));
    }
  } catch (e) {
    dispatch(pawnSpellFailure(e.message));
  }
};

export const addPawnEquipment = (payload, data) => async (dispatch) => {
  try {
    dispatch(pawnEquipmentRequest());
    const response = await postPawnGuildEquipment(payload);
    if (response.status >= 400) {
      dispatch(pawnEquipmentFailure(response?.data?.detail));
      dispatch(notificationShowAction(false, response?.data?.detail || 'Something went wrong'));
    } else if (response.status <= 204) {
      dispatch(addPawnEquipmentSuccess(data));
      dispatch(updateStats(data?.stats || null));
      dispatch(usingEquipment({
        id: data.id,
        pawnId: payload.pawnId,
      }));
      dispatch(getPawnEquipment(payload.pawnId, payload.vs));
    }
  } catch (e) {
    dispatch(pawnEquipmentFailure(e.message));
  }
};

export const addPawnItem = (payload, data) => async (dispatch) => {
  try {
    dispatch(pawnItemRequest());
    const response = await postPawnGuildConsumable(payload);
    if (response.status >= 400) {
      dispatch(pawnItemFailure(response?.data?.detail));
      dispatch(notificationShowAction(false, response?.data?.detail || 'Something went wrong'));
    } else if (response.status <= 204) {
      dispatch(addPawnItemSuccess({
        data,
        pawnId: payload.pawnId,
      }));
      dispatch(usingItem({
        id: data.id,
        pawnId: payload.pawnId,
      }));
    }
  } catch (e) {
    dispatch(pawnItemFailure(e.message));
  }
};

export const removePawnEquipment = (payload, equipment, stats, data = {}) => async (dispatch) => {
  try {
    dispatch(pawnEquipmentRequest());
    const response = await deletePawnGuildEquipment(payload);
    if (response.status >= 400) {
      dispatch(pawnEquipmentFailure(response.data.detail));
    } else if (response.status <= 204) {
      dispatch(deletePawnEquipmentSuccess(equipment));
      dispatch(removeSkills(stats));
      dispatch(usingEquipment({
        id: payload.itemId,
        pawnId: null,
      }));
      if (Object.keys(data).length) {
        dispatch(addPawnEquipment({
          pawnId: payload.pawnId,
          body: {
            guildEquipmentItemId: data.id,
          },
        }, {
          ...data,
        }));
      }
      dispatch(getPawnEquipment(payload.pawnId, payload.vs));
    }
  } catch (e) {
    dispatch(pawnEquipmentFailure(e.message));
  }
};

export const removePawnItem = (payload) => async (dispatch) => {
  try {
    dispatch(pawnItemRequest());
    const response = await deletePawnGuildConsumable(payload);
    if (response.status >= 400) {
      dispatch(pawnItemFailure(response.data.detail));
    } else if (response.status <= 204) {
      dispatch(deletePawnItemSuccess(payload));
      dispatch(usingItem({
        id: payload.consumableId,
        pawnId: null,
      }));
    }
  } catch (e) {
    dispatch(pawnItemFailure(e.message));
  }
};

export const removePawnSpell = (payload) => async (dispatch) => {
  try {
    dispatch(pawnSpellRequest());
    const response = await deletePawnSpell(payload);
    if (response.status >= 400) {
      dispatch(pawnSpellFailure(response.data.detail));
    } else if (response.status <= 204) {
      dispatch(deletePawnSpellSuccess(payload));
    }
  } catch (e) {
    dispatch(pawnSpellFailure(e.message));
  }
};

export const selectPresetLoading = (state) => (state.preset.loading);
export const selectGuilds = (state) => (state.preset.guilds);
export const selectPawnEquipmentLoading = (state) => (state.preset.equipmentLoading);
export const selectPawnEquipment = (state) => (state.preset.pawnEquipment.items);
export const selectPawnItems = (state) => (state.preset.pawnEquipment.consumables);
export const selectPawnSpells = (state) => (state.preset.pawnEquipment.spells);
export const selectLoadedPreset = (state) => (state.preset.presetLoaded);
export const selectEquipmentPawnId = (state) => (state.preset.pawnId);
export const selectUpgradePoints = (state) => (state.preset.upgradePoints);
export const selectPawnSet = (state) => (state.preset.set);
export const selectActivePreset = (state) => (state.preset.activePreset);
export const selectIsModalShowing = (state) => (state.preset.isModalShowing);
export const selectAction = (state) => (state.preset.action);
export const selectType = (state) => (state.preset.type);
export const selectEquipmentType = (state) => (state.preset.equipmentType);
