import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Header from './Components/Header';
import { selectEvm } from '../../store/slices/auth';
import styles from './Marketplace.module.scss';

const Marketplace = ({ children }) => {
  const [currentItem, setCurrentItem] = useState('pawns');
  const [isBurgerOpened, setIsBurgerOpened] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const address = useSelector(selectEvm);

  const location = useLocation();

  const onBurgerClick = () => {
    setIsBurgerOpened(!isBurgerOpened);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const onResize = (e) => {
      setWidth(e.target.innerWidth);
    };
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    if (location.pathname.startsWith('/marketplace/pawns')) {
      setCurrentItem('pawns');
    }
    if (location.pathname.startsWith('/marketplace/presale')) {
      setCurrentItem('presale');
    }
    if (location.pathname.startsWith('/marketplace/portraits')) {
      setCurrentItem('portraits');
    }
  }, [location]);

  return (
    <>
      <Header address={address} />
      {
        width > 880
          ? (
            <div className={styles.content_wrapper}>
              <Link to="/" className="closeWindow"><button type="button">&#10006;</button></Link>
              <div className={styles.corner_top_left} />
              <div className={styles.corner_part_top_left} />
              <div className={styles.head_top_left} />
              <div
                role="presentation"
                className={`${styles.nav_left} ${currentItem === 'pawns' ? styles.active : ''}`}
                onClick={() => {
                  navigate('/marketplace/pawns');
                }}
              >
                <div className={styles.header_top_right}>
                  Pawns
                </div>
                <div className={styles.header_corner_top_right} />
              </div>
              <div className={styles.between_left} />
              <div className={styles.head_top_centre} />
              <div
                role="presentation"
                className={`${styles.nav_centre} ${currentItem === 'portraits' ? styles.active : ''}`}
                onClick={() => {
                  navigate('/marketplace/portraits');
                }}
              >
                <div className={styles.header_top_right}>Portraits</div>
                <div className={styles.header_corner_top_right} />
              </div>
              <div className={styles.line_between} />
              {/* <div className={styles.head_top_right} /> */}
              {/* <div */}
              {/*   role="presentation" */}
              {/*   className={`${styles.nav_right} ${currentItem === 'presale' ? styles.active : ''}`} */}
              {/*   onClick={() => { */}
              {/*     // navigate('/marketplace/presale'); */}
              {/*   }} */}
              {/* > */}
              {/*   <div className={styles.header_top_right}>Presale</div> */}
              {/*   <div className={styles.header_corner_top_right} /> */}
              {/* </div> */}
              <div className={styles.top_left} />
              <div className={styles.top} />
              <div className={styles.corner_part_top_right} />
              <div className={styles.corner_top_right} />
              <div className={styles.left} />
              <div className={styles.right} />
              <div className={styles.corner_bottom_left} />
              <div className={styles.corner_part_bottom_left} />
              <div className={styles.bottom} />
              <div className={styles.corner_part_bottom_right} />
              <div className={styles.corner_bottom_right} />
              <div className={styles.main_content}>
                <div className={styles.content_decor_left} />
                {children}
                <div className={`${styles.content_decor_left} ${styles.no_margin}`} />
              </div>
            </div>
          )
          : (
            <div className={styles.content_wrapper_mobile}>
              <div className={styles.mobile__corner_top_left} />
              <div className={styles.mobile__corner_top_center}>
                <div className={styles.mobile__status_container}>
                  <div className={styles.mobile__status_container__group}>
                    <div className={styles.mobile__status_container__number}>
                      {
                        address
                          ? `${address.slice(0, 5)}...
                          ${address.slice(address.length - 4, address.length).toUpperCase()}`
                          : ''
                      }
                    </div>
                    <div className={styles.mobile__status_container__connection__connected} />
                  </div>
                  <div className={styles.mobile__status_container__balance}>0.000001</div>
                </div>
                <div className={styles.mobile__tab_container}>
                  <button
                    type="button"
                    className={currentItem === 'pawns' ? styles.mobile__tab__active : ''}
                    onClick={() => navigate('/marketplace/pawns')}
                  >
                    Pawns
                  </button>
                  <button
                    type="button"
                    className={currentItem === 'portraits' ? styles.mobile__tab__active : ''}
                    onClick={() => navigate('/marketplace/portraits')}
                  >
                    Portraits
                  </button>
                  {/* <button */}
                  {/*   type="button" */}
                  {/*   className={currentItem === 'presale' ? styles.mobile__tab__active : ''} */}
                  {/*   // onClick={() => navigate('/marketplace/presale')} */}
                  {/* > */}
                  {/*   Presale */}
                  {/* </button> */}
                </div>
              </div>
              <div className={styles.mobile__corner_top_right}>
                <button
                  type="button"
                  className={styles.mobile__burger}
                  onClick={onBurgerClick}
                >
                  &nbsp;
                </button>
                <div
                  role="presentation"
                  className={[
                    styles.mobile__burger_items__container,
                    (isBurgerOpened ? styles.mobile__burger_items__container__active : ''),
                  ].join(' ')}
                  onClick={onBurgerClick}
                  onKeyPress={onBurgerClick}
                >
                  <div className={styles.mobile__burger_items}>
                    <div className={styles.mobile__burger_items__item}>
                      <button type="button">Quests</button>
                    </div>
                    <div className={styles.mobile__burger_items__item}>
                      <button type="button">Arena</button>
                    </div>
                    <div className={styles.mobile__burger_items__item}>
                      <button type="button">Equipment</button>
                    </div>
                    <div className={styles.mobile__burger_items__item}>
                      <button type="button">Pawns</button>
                    </div>
                    <div className={styles.mobile__burger_items__item}>
                      <button type="button">Alchemist</button>
                    </div>
                    <div className={styles.mobile__burger_items__item}>
                      <button type="button">Market</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.mobile__corner_left} />
              <div className={styles.mobile__content}>
                <div className={styles.mobile_content_decor_left} />
                {children}
                <div className={styles.mobile_content_decor_left} />
              </div>
              <div className={styles.mobile__corner_right} />
              <div className={styles.mobile__corner_bottom_left_outer} />
              <div className={styles.mobile__corner_bottom_left_outer_center} />
              <div className={styles.mobile__corner_bottom} />
              <div className={styles.mobile__corner_bottom_right_outer} />
              <div className={styles.mobile__corner_bottom_right_outer_center} />
            </div>
          )
      }
    </>
  );
};

export default Marketplace;
