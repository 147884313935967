import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFieldArray, useForm } from 'react-hook-form';
import styles from '../SkillUtils.module.scss';
import Animations from './Animations';
import SkillScene from '../../../utils/skill/SkillScene';
import {
  clearSpritesFormAction,
  deleteSpriteImg,
  onSpritePost, selectClearAction,
  selectSpriteLink,
} from '../../../store/slices/skillConstructor';
import deleteImg from '../../../static/img/header/balancebar/dropdown/Decline button.png';
import { defaultSpriteValue } from './const';

const sprite = {
  width: 536,
  height: 264,
  originCurrent: '110,153',
  originOpponent: '225,153',
};

const SpriteForm = ({
  data, onSubmit, scene, game, onSpriteDelete, selectedSpire,
}) => {
  const dispatch = useDispatch();
  // const [totalFrames, setTotalFrame] = useState(0);

  const {
    register, handleSubmit, setValue, reset, control, watch,
  } = useForm({
    defaultValues: localStorage.getItem('new')
      ? JSON.parse(localStorage.getItem('new'))
      : defaultSpriteValue,
  });
  const watchAllFields = watch();
  const watchFrame = watch('frame');

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'animations',
  });

  const spriteLink = useSelector(selectSpriteLink);
  const clearSpritesForm = useSelector(selectClearAction);

  const onSpriteSelect = (file) => {
    const formData = new FormData();
    formData.append('image', file);
    dispatch(onSpritePost(formData));
  };

  useEffect(() => {
    if (spriteLink) {
      setValue('path', `https://i.bimium.link/${spriteLink}`);
    }
  }, [spriteLink]);

  // useEffect(() => {
  //   scene.setSetFrameFunc(setTotalFrame);
  // }, []);

  useEffect(() => {
    scene.setSpriteFrame(watchFrame);
  }, [watchFrame]);

  useEffect(() => {
    if (localStorage.getItem('new') && selectedSpire === 'new') {
      const result = JSON.parse(localStorage.getItem('new'));
      Object.keys(result).forEach((option) => setValue(option, result[option]));
    } else if (!localStorage.getItem('new') && selectedSpire === 'new') {
      Object.keys(defaultSpriteValue).forEach((option) => setValue(option, defaultSpriteValue[option]));
    } else {
      if (data === null || data === undefined) {
        reset();
        remove();
      }
      if (data) {
        Object.keys(data).forEach((option) => setValue(option, data[option]));
      }
    }
  }, [data, localStorage.getItem('new'), selectedSpire]);

  useEffect(() => {
    let interval;
    if (selectedSpire === 'new') {
      interval = setInterval(
        () => localStorage.setItem('new', JSON.stringify(watchAllFields)),
        2000,
      );
    }
    if (localStorage.getItem('new') && selectedSpire !== 'new') {
      localStorage.setItem('new', JSON.stringify(defaultSpriteValue));
    }
    return () => clearInterval(interval);
  }, [watchAllFields]);

  useEffect(() => {
    if (clearSpritesForm) {
      reset();
      remove();
      localStorage.removeItem('new');
      dispatch(deleteSpriteImg());
      setValue('path', '');
      dispatch(clearSpritesFormAction(false));
    }
  }, [clearSpritesForm, watchAllFields]);

  const refreshScene = () => {
    scene.createSprite(
      watchAllFields,
      SkillScene.getPosition(game, 1, true, 1),
      SkillScene.getPosition(game, 1, false, 1),
    );
  };

  return (
    <form
      className={styles.form_row}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={styles.form_row}>
        <div>Key</div>
        <input
          type="text"
          {...register('key', { required: true })}
        />
      </div>
      <div className={styles.form_row}>
        <div>Sprite URL</div>
        <div>Add link to .png image, or upload .png image</div>
        <div style={{ display: 'flex', columnGap: '10px' }}>
          <input
            type="text"
            {...register('path')}
            disabled={spriteLink}
            // value="http://localhost:3000/assets/mage/magicpower_fireball-initial.gif.png"
          />
          <img
            role="presentation"
            src={deleteImg}
            alt="del"
            width="20px"
            height="20px"
            onClick={() => {
              dispatch(deleteSpriteImg());
              setValue('path', '');
            }}
          />
        </div>
        {!spriteLink
          ? (
            <input
              style={{ marginTop: '10px' }}
              type="file"
              accept="image/png"
              onChange={(e) => onSpriteSelect(e.target.files[0])}
            />
          )
          : null}
      </div>
      {/* <div className={styles.form_row}> */}
      {/*   <div>width</div> */}
      {/*   <input */}
      {/*     type="text" */}
      {/*     {...register('width')} */}
      {/*   /> */}
      {/* </div> */}
      {/* <div className={styles.form_row}> */}
      {/*   <div>height</div> */}
      {/*   <input */}
      {/*     type="text" */}
      {/*     {...register('height')} */}
      {/*   /> */}
      {/* </div> */}
      {Object.keys(sprite).map((elem) => (
        <div key={elem} className={styles.form_row}>
          <div>
            {elem}
          </div>
          <input
            type="text"
            placeholder={sprite[elem]}
            {...register(elem)}
          />
        </div>
      ))}
      <div className={styles.form_row}>
        <button type="button" onClick={() => refreshScene()}>Refresh scene</button>
      </div>
      {/* <div className={styles.form_row} style={{ display: 'none' }}> */}
      {/*   <div> */}
      {/*     Frame */}
      {/*   </div> */}
      {/*   <input */}
      {/*     type="range" */}
      {/*     min="0" */}
      {/*     step="1" */}
      {/*     max={totalFrames} */}
      {/*     disabled={totalFrames === 0} */}
      {/*     {...register('frame')} */}
      {/*   /> */}
      {/* </div> */}
      {fields.map((elem, index) => (
        <Animations
          index={index}
          register={register}
          watch={watch}
          scene={scene}
          key={elem.id}
          onDelete={() => remove(index)}
          refreshScene={refreshScene}
        />
      ))}
      <div className={styles.buttons_block}>
        <button
          type="button"
          onClick={() => append({
            key: '',
            start: '',
            end: '',
            frameRate: '',
            repeat: '',
          })}
        >
          Add animation
        </button>
        <button type="submit">Save</button>
        {data && data.key
          ? (
            <button
              type="button"
              onClick={() => onSpriteDelete(data.id)}
            >
              Delete Sprite
            </button>
          )
          : null}
      </div>
    </form>
  );
};

export default SpriteForm;
