import React from 'react';
import Cards from '../../Pawns/Cards';
import Portrait from '../../Portraits/Portrait';
import loader from '../../../../../static/img/loader.gif';
import uniqueId from '../../../../../utils/uniqueId';
import { mintData } from '../../../../../constants/stats';
import { mainNetId } from '../../../../../utils/chooseData';
import styles from './Inventory.module.scss';

const Inventory = ({
  text, currentItem, data, loading, type, reset, canMint,
}) => (
  <div className={styles.wrapper_area}>
    <div className={styles.mobile_content_decor} />
    <div className={styles.inventory}>
      {text && (
      <div className={styles.corner_inventory_label}>
        <div className={styles.corner_inventory_label_left} />
        <div className={styles.corner_inventory_label_centre}>{text}</div>
        <div className={styles.corner_inventory_label_right} />
      </div>
      )}
      <div className={styles.corner_inventory_top_left} />
      <div className={styles.border_inventory_top} />
      <div className={styles.corner_inventory_top_right} />
      <div className={styles.border_inventory_left} />
      <div className={`${styles.inventory_portrait_content} ${text ? '' : styles.text}`}>
        {loading ? (
          <div className={styles.loader}>
            <img src={loader} alt="loading..." />
          </div>
        ) : (
          (type === 'buy'
            ? canMint || window?.ethereum?.networkVersion !== mainNetId
              ? [{ ...mintData }, ...data]
              : data
            : data).map((elem) => (
            currentItem === 'portraits'
              ? <Portrait key={uniqueId('portrait')} />
              : <Cards data={elem} key={uniqueId('portrait')} text={text} reset={reset} />
          ))
        )}
      </div>
      <div className={styles.border_inventory_right} />
      <div className={styles.corner_inventory_bottom_left} />
      <div className={styles.border_inventory_bottom} />
      <div className={styles.corner_inventory_bottom_right} />
    </div>
  </div>
);

export default Inventory;
