/* eslint-disable */
export const BATCH_ID = process.env.REACT_APP_BATCH_ID || 4;
export const API_URL = process.env.REACT_APP_API_URL || 'https://dev.sanctum.bimium.link/';
export const PROJECT_ID = process.env.REACT_APP_PROJECT_ID || '0489b7549a62114b5d661829e3713007';
export const NETWORK_ID = process.env.REACT_APP_NETWORK_ID || '4002';
export const PAWN_CONTRACT_ADDRESS = process.env.REACT_APP_PAWN_CONTRACT_ADDRESS || '0x15f4A1dE3eC436320b4A4e83219c75601Ede2AeE';
export const MARKETPLACE_CONTRACT_ADDRESS = process.env.REACT_APP_MARKETPLACE_CONTRACT_ADDRESS || '0x95807EdCA83013e920edF6Ae9047a3Cd64417542';
export const COIN_CONTRACT_ADDRESS = process.env.REACT_APP_COIN_CONTRACT_ADDRESS || '0x719d70D1BD96D4b2e33cfbDB33cD0EE0E85ECAe2';
export const WHITEMINT_CONTRACT_ADDRESS = process.env.REACT_APP_WHITEMINT_CONTRACT_ADDRESS || '0x9c57078b80918DAA801074c7C4EA82881d440886';
export const PORTRAIT_CONTRACT_ADDRESS = process.env.REACT_APP_PORTRAIT_CONTRACT_ADDRESS || '0xB697a9Bb5888FDA3411a676E08e12f4412C9081e';
export const PORTRAIT_MARKETPLACE_CONTRACT_ADDRESS = process.env.REACT_APP_PORTRAIT_MARKETPLACE_CONTRACT_ADDRESS || '0xA8549A0677ffC65BaE852CD8aEbc6e918cae16BD';
export const APP_ENV = process.env.REACT_APP_ENV || 'dev';

export const buttonsArray = ['Q', 'W', 'E', 'R', 'T', 'Y'];
