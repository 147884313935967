import armorImg from '../static/img/arena/Armor.png';
import dodgeImg from '../static/img/arena/Dodge.png';
import speedImg from '../static/img/arena/Speed.png';
import magicResistImg from '../static/img/arena/Magic resist.png';
import Warrior from '../static/img/Warrior.gif';
import Archer from '../static/img/Archer.gif';
import Mage from '../static/img/mage.gif';
import item1 from '../static/img/items/Item_00.png';
import item2 from '../static/img/items/Item_07.png';
import item3 from '../static/img/items/Item_22.png';
import item4 from '../static/img/items/Item_15.png';
import item5 from '../static/img/items/Item_43.png';

export const gifsArray = [Warrior, Archer, Mage];

export const itemArray = [item1, item2, item3, item4, item5];

export const stats = {
  armor: armorImg,
  dodge: dodgeImg,
  speed: speedImg,
  magicResist: magicResistImg,
};

export const defaultPawnValue = {
  name: '?',
  tier: 'Common',
  nftStatus: 'Mint',
  level: '?',
  stats: {
    strength: '?',
    constitution: '?',
    dexterity: '?',
    intelligence: '?',
    spirit: '?',
    charisma: '?',
  },
  traits: [{
    description: null,
  }],
};

export const mintData = {
  nftId: 'mint',
  class: 'Mage',
  tier: 'Common',
  level: '?',
  price: 'Mint',
  image: 'Mint',
};
