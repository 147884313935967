/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { addStyles, EditableMathField } from 'react-mathquill';
import styles from './FormulaField.module.scss';
import { ReactComponent as Hint } from '../../../static/img/hint.svg';
import formulaVars from '../../../constants/spell-params';
import latexToJs from '../../../utils/latex-to-js';

// inserts the required css to the <head> block.
// you can skip this, if you want to do that by yourself.
addStyles();

const FormulaField = ({ setValue, fieldName, value }) => {
  const [latex, setLatex] = useState('');
  const [mathQuill, setMathQuill] = useState(null);
  const [foundVars, setFoundVars] = useState([]);
  const [searchFormulaString, setSearchFormulaString] = useState('');

  const reverseString = (str) => ((str === '') ? '' : reverseString(str.substr(1)) + str.charAt(0));

  const isFormulaVarRegExpr = /\w*/;

  const formulaChange = (mathField) => {
    setLatex(mathField.latex());
    setValue(fieldName, latexToJs(mathField.latex()));
  };

  const getStringBefore = (s, el) => {
    const newStr = el?.ctrlSeq?.length ? el.ctrlSeq : '';
    return el['-1'] ? getStringBefore(newStr + s, el['-1']) : newStr + s;
  };

  const selectFormulaVar = (newStr) => {
    setLatex(mathQuill.latex().replace(searchFormulaString, newStr.toLocaleLowerCase()));
    setFoundVars([]);
    setSearchFormulaString('');
    mathQuill.focus();
  };

  const onKeyDown = (e) => {
    if (mathQuill) {
      setTimeout(
        () => {
          const reversedInput = reverseString(getStringBefore('', mathQuill.__controller.cursor));
          const wSymbols = isFormulaVarRegExpr.exec(reversedInput);
          const searchStringVar = reverseString(wSymbols ? wSymbols[0] : '').toLocaleLowerCase();
          setSearchFormulaString(reverseString(wSymbols ? wSymbols[0] : ''));
          if (searchStringVar && searchStringVar.length > 1) {
            // eslint-disable-next-line max-len
            const fVars = formulaVars.filter((fv) => fv.toLocaleLowerCase().includes(searchStringVar) && fv.toLocaleLowerCase() !== searchStringVar);
            setFoundVars(fVars);
            if (fVars.length === 1 && (e.code === 'Tab' || e.code === 'Enter')) {
              selectFormulaVar(fVars[0]);
            }
          } else {
            setFoundVars([]);
          }
        },
        200,
      );
      if (e.code === 'Tab' || (foundVars.length && (e.code === 'ArrowUp' || e.code === 'ArrowDown'))) {
        e.preventDefault();
        e.stopPropagation();
      }
    }
  };

  useEffect(() => {
    if (value) {
      setLatex(value);
    }
  }, [value]);

  return (
    <>
      <div className={styles.inputContainer}>
        <EditableMathField
          latex={latex}
          onChange={formulaChange}
          onKeyDown={onKeyDown}
          mathquillDidMount={(mf) => setMathQuill(mf)}
        />
        <a href="#formulaDescription">
          <Hint className={styles.hint} />
        </a>
      </div>
      {
        foundVars.length
          ? (
            <div className={styles.varDropdown}>
              {foundVars.map((v) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
                <div
                  className={styles.varDropdownItem}
                  onClick={() => selectFormulaVar(v)}
                  role="button"
                  key={v}
                >
                  { v }
                </div>
              ))}
            </div>
          )
          : null
      }
    </>
  );
};

export default FormulaField;
