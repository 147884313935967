import { createSlice } from '@reduxjs/toolkit';
import { setNavigate } from '../arena';

export const utils = createSlice({
  name: 'utils',
  initialState: {
    notificationSuccess: false,
    notificationShow: false,
    address: null,
    notificationMessage: null,
    notificationRedirect: null,
  },
  reducers: {
    notificationShow: (state, { payload }) => ({
      ...state,
      notificationSuccess: payload.success,
      notificationMessage: payload.message,
      notificationShow: true,
      notificationRedirect: payload.redirect,
    }),
    notificationHide: (state) => ({
      ...state,
      notificationShow: false,
      notificationMessage: null,
    }),
    addressSubmit: (state, { payload }) => ({
      ...state,
      address: payload,
    }),
  },
});

// ---- actions ----
export const {
  notificationShow, notificationHide, addressSubmit,
} = utils.actions;

// ---- request actions ----
export const notificationShowAction = (success, message, redirect = null, afterRedirect = null) => (dispatch) => {
  setTimeout(
    () => {
      dispatch(notificationHide());
      if (redirect) {
        dispatch(setNavigate(redirect));
        if (afterRedirect) {
          afterRedirect();
        }
      }
    },
    3000,
  );
  dispatch(notificationShow({ success, message, redirect }));
};

// ---- selectors ----
export const selectNotification = (state) => ({
  success: state.utils.notificationSuccess,
  message: state.utils.notificationMessage,
  show: state.utils.notificationShow,
  redirect: state.utils.notificationRedirect,
});
export const selectAddress = (state) => ({ address: state.utils.address });
