// eslint-disable-next-line max-classes-per-file
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Phaser from 'phaser';
import { Link } from 'react-router-dom';
import SkillScene from '../../utils/skill/SkillScene';
import { pawns } from '../../utils/skill/pawns';
import styles from './SkillUtils.module.scss';
import SpriteForm from './SpriteForm';
import SpritePreviewScene from '../../utils/skill/SpritePreviewScene';
import ActionsForm from './ActionsForm';
import Header from '../../components/Marketplace/Components/Header';
import { selectEvm } from '../../store/slices/auth';
import exampleGIF from '../../static/img/example.gif';
import resultGIF from '../../static/img/shield.gif';
import loader from '../../static/img/loader.gif';
import {
  clearNewSpriteData, clearSpritesFormAction,
  getSpellDataAction,
  getSpriteDataAction, selectActiveSpell, selectActiveSprite, selectConstructorLoading, selectNewSprite,
  selectSpells,
  selectSprites, spellDeleteAction,
  spellPostAction,
  spellsGetAction, spellUpdateAction, spriteDeleteAction,
  spritePostAction,
  spritesGetAction,
} from '../../store/slices/skillConstructor';
import SkinsForm from './SkinsForm';
import SetsForm from './SetsForm';

let game = null;
let skillScene = null;
let spiteScene = null;

const config = {
  type: Phaser.AUTO,
  parent: 'battle-container',
  transparent: true,
  backgroundColor: 'rgba(0,0,0,0)',
  'render.transparent': true,
  scale: {
    // mode: Phaser.Scale.ScaleModes.RESIZE,
    mode: Phaser.Scale.FIT,
    width: 1400,
    height: 614,
  },
};

const SkillUtils = () => {
  const dispatch = useDispatch();

  const [selectedPawn, setSelectedPawn] = useState('archer');
  const [selectedOpponentPawn, setSelectedOpponentPawn] = useState('mage');
  const [activeTab, setActiveTab] = useState('actions');
  const [sprites, setSprites] = useState([]);
  const [spells, setSpells] = useState([]);
  const [activeSprite, setActiveSprite] = useState(null);
  const [activeSpell, setActiveSpell] = useState(null);
  const [sceneReady, setSceneReady] = useState(false);

  const address = useSelector(selectEvm);
  const spritesFromRequest = useSelector(selectSprites);
  const spellsFromRequest = useSelector(selectSpells);
  const activeSpriteFromRequest = useSelector(selectActiveSprite);
  const activeSpellFromRequest = useSelector(selectActiveSpell);
  const loading = useSelector(selectConstructorLoading);

  const newSpriteData = useSelector(selectNewSprite);

  const {
    register, watch, setValue,
  } = useForm();

  const switchToSpriteCreateScene = () => {
    game.scene.start('sprite-scene');
  };
  const switchToSkillCreateScene = () => {
    game.scene.start('battle-scene');
    game.scene.stop('sprite-scene');
    // game.scene.switch(skillScene, battleScene);
  };

  const onSpriteSave = (data) => {
    dispatch(spritePostAction(data));
    // Solution in useEffect 134row
    // if (watch('selectedSprite') !== 'new') {
    //   const index = sprites.findIndex((obj) => obj.id === watch('selectedSprite'));
    //   if (index !== -1) {
    //     const tempArr = [...sprites];
    //     tempArr[index] = data;
    //     setSprites(tempArr);
    //     setValue('selectedSprite', data.key);
    //     return;
    //   }
    // }
    // const tempArr = [...sprites, data];
    // setSprites(tempArr);
    // setValue('selectedSprite', data.key);
  };

  const onSpellSave = (data) => {
    if (activeSpell === 'new' || activeSpell === null) {
      dispatch(spellPostAction({ ...data, sideEffects: data.sideEffects.filter((e) => !!e) }));
    } else {
      dispatch(spellUpdateAction(activeSpell?.id, { ...data, sideEffects: data.sideEffects.filter((e) => !!e) }));
    }
    // console.log(data);
    // skillScene.createSpell(data);
  };

  const onSpriteDelete = (id) => {
    dispatch(spriteDeleteAction(id));
    const tempArr = [...sprites];
    const index = tempArr.findIndex((object) => object.id === id);
    if (index > -1) {
      tempArr.splice(index, 1);
      setSprites(tempArr);
      setValue('selectedSprite', 'new');
    }
  };

  const onSpellDelete = (id) => {
    dispatch(spellDeleteAction(id));
    const tempArr = [...spells];
    const index = tempArr.findIndex((object) => object.id === id);
    if (index > -1) {
      tempArr.splice(index, 1);
      setSpells(tempArr);
      setValue('selectedSpell', 'new');
    }
  };

  useEffect(() => {
    if (newSpriteData) {
      if (watch('selectedSprite') !== 'new') {
        const index = sprites.findIndex((obj) => obj.id === watch('selectedSprite'));
        if (index !== -1) {
          const tempArr = [...sprites];
          tempArr[index] = newSpriteData;
          setSprites(tempArr);
          setValue('selectedSprite', newSpriteData.id);
          return;
        }
      }
      const tempArr = [...sprites, newSpriteData];
      setSprites(tempArr);
      setValue('selectedSprite', newSpriteData.id);
      dispatch(clearNewSpriteData());
    }
  }, [dispatch, newSpriteData, sprites, watch('selectedSprite')]);

  const tabSelect = () => {
    if (activeTab === 'sprites') {
      return (
        <div className={styles.form}>
          {loading
            ? (
              <div className={styles.loader_wrap}>
                <img src={loader} alt="" />
              </div>
            )
            : null}
          <div className={styles.scroll}>
            <select
              className={styles.select}
              {...register('selectedSprite')}
            >
              <option value="" hidden>Select</option>
              <option value="new">New</option>
              {sprites.map((elem) => <option value={elem.id} key={elem.id}>{elem.key}</option>)}
            </select>
            {watch('selectedSprite') === 'new' ? (
              <div
                role="presentation"
                className={styles.clearButton}
                onClick={() => dispatch(clearSpritesFormAction(true))}
              >
                Clear
              </div>
            ) : null}
            {watch('selectedSprite')
              ? (
                <SpriteForm
                  onSubmit={onSpriteSave}
                  data={activeSprite}
                  scene={spiteScene}
                  game={game}
                  onSpriteDelete={onSpriteDelete}
                  selectedSpire={watch('selectedSprite')}
                />
              )
              : null}
          </div>
        </div>
      );
    }
    if (activeTab === 'skins') {
      return (
        <SkinsForm />
      );
    }
    if (activeTab === 'sets') {
      return (
        <SetsForm
          scene={skillScene}
          sceneReady={sceneReady}
        />
      );
    }
    return (
      <div className={styles.form}>
        {loading
          ? (
            <div className={styles.loader_wrap}>
              <img src={loader} alt="" />
            </div>
          )
          : null}
        <div className={styles.scroll}>
          <div className={styles.scroll_header}>
            <select
              {...register('selectedSpell')}
            >
              <option value="" hidden>Select</option>
              <option value="new">New</option>
              {spells.map((spell) => (
                <option value={spell.id} key={spell.id}>{spell.spellName}</option>
              ))}
            </select>
          </div>
          {watch('selectedSpell')
            ? (
              <ActionsForm
                data={activeSpell}
                onSubmit={onSpellSave}
                sprites={sprites}
                selectedPawn={selectedPawn}
                setSelectedPawn={setSelectedPawn}
                scene={skillScene}
                onSpellDelete={onSpellDelete}
              />
            )
            : null}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (activeSpriteFromRequest) {
      setActiveSprite(activeSpriteFromRequest);
    }
  }, [activeSpriteFromRequest]);

  useEffect(() => {
    if (activeSpellFromRequest) {
      setActiveSpell(activeSpellFromRequest);
    }
  }, [activeSpellFromRequest]);

  useEffect(() => {
    const selectedSprite = watch('selectedSprite');
    if (selectedSprite) {
      if (selectedSprite === 'new') {
        setActiveSprite(null);
      } else {
        dispatch(getSpriteDataAction(selectedSprite));
      }
    }
  }, [watch('selectedSprite')]);

  useEffect(() => {
    const selectedSpell = watch('selectedSpell');
    if (selectedSpell) {
      if (selectedSpell === 'new') {
        setActiveSpell(null);
      } else {
        dispatch(getSpellDataAction(selectedSpell));
      }
    }
  }, [watch('selectedSpell')]);

  useEffect(() => {
    if (spritesFromRequest && spritesFromRequest.results?.length) {
      setSprites(spritesFromRequest.results);
    }
  }, [spritesFromRequest]);

  useEffect(() => {
    if (spellsFromRequest && spellsFromRequest?.length) {
      setSpells(spellsFromRequest);
    }
  }, [spellsFromRequest]);

  useEffect(() => {
    dispatch(spritesGetAction());
    dispatch(spellsGetAction());
  }, []);

  useEffect(() => {
    game = new Phaser.Game(config);
    game.events.on(
      Phaser.Core.Events.READY,
      () => {
        skillScene = new SkillScene({
          key: 'battle-scene',
          setReady: setSceneReady,
        });
        game.scene.add('battle-scene', skillScene, true);

        spiteScene = new SpritePreviewScene({
          key: 'sprite-scene',
        });
        game.scene.add('sprite-scene', spiteScene, true);

        // game.scene.start('battle-scene');
      },
    );
  }, []);

  useEffect(() => {
    switch (activeTab) {
      case 'sprites':
        switchToSpriteCreateScene();
        break;
      case 'actions':
        switchToSkillCreateScene();
        break;
      default:
        console.error('unknown tab');
    }
  }, [activeTab]);

  useEffect(() => {
    console.log('SELECTED PAWN CHANGED', selectedPawn);
    const scene = game?.scene?.getScene('battle-scene');
    if (scene && selectedPawn) {
      scene.createPawn(selectedPawn, 1);
    }
  }, [selectedPawn]);

  useEffect(() => {
    console.log('SELECTED OPPONENT PAWN CHANGED', selectedOpponentPawn);
    const scene = game?.scene?.getScene('battle-scene');
    if (scene) {
      scene.createPawn(selectedOpponentPawn, 2, false);
    }
  }, [selectedOpponentPawn]);

  return (
    <>
      <Header address={address} />
      <div style={{ position: 'relative', zIndex: 3 }} className={styles.wrap}>
        <div className={styles.wrap_left_side}>
          <div className={styles.wrap_scene}>
            <div>
              <div id="battle-container" />
            </div>
          </div>
          <div className={styles.select_pawn}>
            <div />
            {/* <select onChange={(event) => setSelectedPawn(event.target.value)} value={selectedPawn}> */}
            {/*   {pawns.map((pawn) => <option value={pawn.key}>{pawn.key}</option>)} */}
            {/* </select> */}
            <select onChange={(event) => setSelectedOpponentPawn(event.target.value)} value={selectedOpponentPawn}>
              {pawns.map((pawn) => <option key={pawn.key} value={pawn.key}>{pawn.key}</option>)}
            </select>
          </div>
          <div className={styles.helpDiv}>
            {activeTab === 'skins'
              ? (
                <>
                  <div className={styles.helpDiv2Col}>
                    <div>
                      <span className={styles.helpDescr}>
                        Skin sprite example:
                      </span>
                    </div>
                  </div>
                  <img src="/assets/mage/mage-level1.png" alt="mage example" width="100%" />
                </>
              )
              : (
                <>
                  <div className={styles.helpDiv2Col}>
                    <div>
                      <span className={styles.helpDescr}>
                        This description can help you creat a sprite. Following next steps:
                      </span>
                    </div>
                  </div>
                  <div className={styles.helpDiv2Col}>
                    <div>
                      <span className={styles.helpDescr}>
                        STEP 1: Fill in the &#39;Key&#39; field with a unique sprite name.
                      </span>
                    </div>
                  </div>
                  <div className={styles.helpDiv2Col}>
                    <img src="/assets/instructions/keysprite.png" alt="key" />
                    <div>
                      <p>
                        <span className={styles.helpKey}>Key</span>
                        <span className={styles.helpDescr}> - Unique name of sprite</span>
                      </p>
                    </div>
                  </div>
                  <div className={styles.helpDiv2Col}>
                    <div>
                      <span className={styles.helpDescr}>
                        STEP 2: Fill in the &#39;Sprite URL&#39; field with a sprite sheet
                        and &#39;height/width&#39; fields with size of one frame.
                      </span>
                    </div>
                  </div>
                  <div className={styles.helpDiv2Col}>
                    <img src={exampleGIF} alt="gif" width="150px" height="120px" />
                    <div>
                      <p>
                        <span className={styles.helpDescr}>
                          For example we have GIF as left side.
                          You need to visit a special site to convert GIF to sprite sheet with frames.
                          After converting below is an example sprite sheet with number of frames.
                          <h4 className={styles.helpKey}>https://onlinegiftools.com/convert-gif-to-sprite-sheet</h4>
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className={styles.helpDiv2Col}>
                    <img
                      src="/assets/instructions/initial-front-frames1.png"
                      alt="frames"
                      height="320px"
                      width="100%"
                    />
                  </div>
                  <div className={styles.helpDiv2Col}>
                    <img src="/assets/instructions/spriteUrl.png" alt="key" />
                    <div>
                      <p>
                        <span className={styles.helpKey}>Sprite URL</span>
                        <span className={styles.helpDescr}>
                          - URL to png image with animation
                          (example - https://marketplace-spa-ytakl.ondigitalocean.app/
                          assets/mage/shield/initial-front.png)
                        </span>
                      </p>
                      <p>
                        <span className={styles.helpKey}>Height/Width</span>
                        <span className={styles.helpDescr}>
                          - size of one frame in png (example - 640x456)
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className={styles.helpDiv2Col}>
                    <div>
                      <span className={styles.helpDescr}>
                        STEP 3: Fill in the &#39;originCurrent&#39; and &#39;originOpponent&#39; fields of
                        the Game Object position. The default value is [0,0], meaning all Game Objects
                        are positioned based on their left top corner.
                        Default position game object - center of pawn.
                      </span>
                    </div>
                  </div>
                  <div className={styles.helpDiv2Col}>
                    <div>
                      <p>
                        <span className={styles.helpKey}>originCurrent</span>
                        <span className={styles.helpDescr}>
                          - The origin maps the relationship between the size and position of the Game Object.
                          (example - 330,253)
                        </span>
                      </p>
                      <p>
                        <span className={styles.helpKey}>originOpponent</span>
                        <span className={styles.helpDescr}>
                          - Same for right pawn (example - 330,253)
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className={styles.helpDiv2Col}>
                    <img
                      src="/assets/instructions/img_2.png"
                      alt="origin"
                      height="160px"
                      width="500px"
                    />
                    <img
                      src="/assets/instructions/img_3.png"
                      alt="origin"
                      height="160px"
                      width="500px"
                      style={{ marginLeft: '50px' }}
                    />
                  </div>
                  <div className={styles.helpDiv2Col}>
                    <img src="/assets/instructions/0origin.png" alt="origin" width="500px" height="340px" />
                    <img
                      src="/assets/instructions/330origin.png"
                      alt="origin"
                      width="500px"
                      height="340px"
                      style={{ marginLeft: '50px' }}
                    />
                  </div>
                  <div className={styles.helpDiv2Col}>
                    <div>
                      <span className={styles.helpDescr}>
                        STEP 4: Choose animation from sprite and fill in the &#39;key&#39; field.
                      </span>
                    </div>
                  </div>
                  <div className={styles.helpDiv2Col}>
                    <img src="/assets/instructions/img_5.png" alt="Animation section" />
                    <div>
                      <p>
                        <span className={styles.helpKey}>Key</span>
                        <span className={styles.helpDescr}> - Unique name of each animation in sprite sheet</span>
                      </p>
                    </div>
                  </div>
                  <div className={styles.helpDiv2Col}>
                    <div>
                      <span className={styles.helpDescr}>
                        STEP 5: Fill in the &#39;Start&#39; and &#39;End&#39; fields. For example pick from 4 to 16.
                        The picture below shows the frames selected in red for playback in animation
                      </span>
                    </div>
                  </div>
                  <div className={styles.helpDiv2Col}>
                    <img src="/assets/instructions/img_4.png" alt="Animation section" />
                    <div>
                      <p>
                        <span className={styles.helpKey}>Start</span>
                        <span className={styles.helpDescr}> - Zero based frame number in sprite sheet</span>
                      </p>
                      <p>
                        <span className={styles.helpKey}>End</span>
                        <span className={styles.helpDescr}> - Until what frame number animation will play</span>
                      </p>
                    </div>
                  </div>
                  <div className={styles.helpDiv2Col}>
                    <img
                      src="/assets/instructions/initial-front-frames2.png"
                      alt="frames"
                      height="320px"
                      width="100%"
                    />
                  </div>
                  <div className={styles.helpDiv2Col}>
                    <div>
                      <span className={styles.helpDescr}>
                        STEP 6: Set up settings for playing animation by filling in the &#39;FrameRate&#39;
                        and &#39;Repeat&#39; fields.
                      </span>
                    </div>
                  </div>
                  <div className={styles.helpDiv2Col}>
                    <img src="/assets/instructions/img_6.png" alt="Animation section" />
                    <div>
                      <p>
                        <span className={styles.helpKey}>FrameRate</span>
                        <span className={styles.helpDescr}>
                          - The frame rate of playback, of the current animation, in frames per second. (10 by default)
                        </span>
                      </p>
                      <p>
                        <span className={styles.helpKey}>Repeat</span>
                        <span className={styles.helpDescr}>
                          - Number of times to repeat the animation. Set to -1 to repeat forever.
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className={styles.helpDiv2Col}>
                    <div>
                      <span className={styles.helpDescr}>
                        TOTAL RESULT:
                      </span>
                    </div>
                  </div>
                  <div className={styles.helpDiv2Col}>
                    <img src={resultGIF} alt="res" width="500px" height="350px" />
                  </div>
                  <div className={styles.helpDiv2Col} id="formulaDescription">
                    <div>
                      <span className={styles.helpDescr}>
                        Possible variables (using in skill formula field):
                      </span>
                    </div>
                  </div>
                  <div className={styles.helpDiv2Col}>
                    <div>
                      <span className={styles.helpDescr}>
                        Start typing at least 2 character, after that you can choose item from dropdown.
                      </span>
                    </div>
                  </div>
                  <div className={styles.helpDiv2Col}>
                    <div>
                      <span className={styles.helpDescr}>
                        <p>
                          <span className={styles.helpKey}>MD </span>
                          - Magic Damage, Base magic damage, natural value : 10
                        </p>
                        <p>
                          <span className={styles.helpKey}>WD </span>
                          - Weapon Damage, Base weapon damage, natural value : 10
                        </p>
                        <p>
                          <span className={styles.helpKey}>Speed </span>
                          - Start as 100 (or base speed), increase by 5 every turn. At 200, reset to base speed & gain 1
                          extra primary action immediately.
                        </p>
                        <p>
                          <span className={styles.helpKey}>Magicka </span>
                          - Fixed resource, base value 100. Regenerate 1 per turn
                        </p>
                        <p>
                          <span className={styles.helpKey}>Focus </span>
                          - Regenerates at a rate of 25 per turn, start at 100.
                        </p>
                        <p>
                          <span className={styles.helpKey}>Discipline </span>
                          - Start at 100, gain rage / discipline with each spell cast
                        </p>
                        <p>
                          <span className={styles.helpKey}>Normal </span>
                          - Is added to queue
                        </p>
                        <p>
                          <span className={styles.helpKey}>Quick </span>
                          - happens immediately
                        </p>
                        <p>
                          <span className={styles.helpKey}>Primary </span>
                          - Ends turn after use
                        </p>
                        <p>
                          <span className={styles.helpKey}>Secondary </span>
                          - Doesn&apos;t end turn
                        </p>
                        <p>
                          <span className={styles.helpKey}>Armor </span>
                          - base value 5, each one reduces physical damage by 1 (not percent)
                        </p>
                        <p>
                          <span className={styles.helpKey}>Willpower </span>
                          - base value 2, each one reduces magic damage by 1%
                        </p>
                        <p>
                          <span className={styles.helpKey}>Dodge </span>
                          - 2% base, negate all damage. Spell dodgeable unless otherwise specified.
                        </p>
                        <p>
                          <span className={styles.helpKey}>Crit </span>
                          - 3% base, does 3x damage when critting (or could have a stat increase multiples)
                        </p>
                        <p>
                          <span className={styles.helpKey}>Random </span>
                          - range of random numbers for example: random(1,10)
                        </p>
                      </span>
                    </div>
                  </div>
                </>
              )}
          </div>
        </div>
        <div className={styles.forms}>
          <Link to="/" className="closeWindow red"><button type="button">&#10006;</button></Link>
          <div className={styles.tabs}>
            <button
              type="button"
              onClick={() => setActiveTab('actions')}
              className={activeTab === 'actions' ? styles.active : ''}
            >
              Actions
            </button>
            <button
              type="button"
              onClick={() => setActiveTab('sprites')}
              className={activeTab === 'sprites' ? styles.active : ''}
            >
              Sprites
            </button>
            <button
              type="button"
              onClick={() => setActiveTab('skins')}
              className={activeTab === 'skins' ? styles.active : ''}
            >
              Skins
            </button>
            <button
              type="button"
              onClick={() => setActiveTab('sets')}
              className={activeTab === 'sets' ? styles.active : ''}
            >
              Sets
            </button>
          </div>
          {tabSelect()}
        </div>
      </div>
    </>
  );
};

export default SkillUtils;
