import React from 'react';
import styles from './Tooltip.module.scss';

const Tooltip = ({
  name, description, img, type, cost, quickness, cooldown, button,
}) => (
  <div
    role="presentation"
    className={styles.tooltip}
  >
    <div className={styles.tooltip_top_left} />
    <div className={styles.tooltip_top} />
    <div className={styles.tooltip_top_right} />
    <div className={styles.tooltip_left} />
    <div className={styles.tooltip_content}>
      <div className={styles.head}>
        <div className={styles.head_left}>
          <div className={styles.title}>
            {name?.split('_').join(' ')}
          </div>
          <div className={styles.separator} />
          <div className={styles.skill_info}>
            <div>
              <span>{type}</span>
              <span>{quickness}</span>
            </div>
            <div>
              <span>{cost}</span>
              <span>{cooldown ? `${cooldown} turn(s)` : ''}</span>
            </div>
          </div>
        </div>
        <div>
          <img
            src={img}
            alt="skill"
            width="64px"
            height="64px"
          />
        </div>
      </div>
      <div className={styles.separator} />
      <div className={styles.description}>
        {description}
      </div>
      <div className={styles.hotkey}>
        {`hotkey: ${button}`}
      </div>
    </div>
    <div className={styles.tooltip_right} />
    <div className={styles.tooltip_bottom_left} />
    <div className={styles.tooltip_bottom} />
    <div className={styles.tooltip_bottom_right} />
  </div>
);

export default Tooltip;
