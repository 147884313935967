import React, { useEffect, useState } from 'react';
import styles from './Sound.module.scss';
import sound from '../../static/img/Mute/Sound.png';
import soundHover from '../../static/img/Mute/Sound hover.png';
import soundPressed from '../../static/img/Mute/Sound pressed.png';
import mute from '../../static/img/Mute/Mute.png';
import muteHover from '../../static/img/Mute/Mute hover.png';
import mutePressed from '../../static/img/Mute/Mute pressed.png';
import sound1 from '../../static/tracks/Exploration.mp3';
import sound2 from '../../static/tracks/Kings_Feast.mp3';
import sound3 from '../../static/tracks/Lament_for_a_Warriors_Soul_REUPLOAD.mp3';
import sound4 from '../../static/tracks/Rising_Moon.mp3';
import sound5 from '../../static/tracks/The_Bards_Tale.mp3';

const tracksArray = [sound1, sound2, sound3, sound4, sound5];

const Sound = () => {
  const [check, setCheck] = useState('');
  const [isMuted, setIsMuted] = useState(localStorage.getItem('sound') === 'off' || false);
  const [songNumber, setSongNumber] = useState(Math.floor(Math.random() * tracksArray.length));
  const [audio, setAudio] = useState(new Audio(tracksArray[songNumber]));

  const selectBtnImg = () => {
    const soundStatus = {
      hover: soundHover,
      pressed: soundPressed,
    };

    const muteStatus = {
      hover: muteHover,
      pressed: mutePressed,
    };

    if (check && isMuted) {
      return muteStatus[check];
    }
    if (check && !isMuted) {
      return soundStatus[check];
    }
    if (!check && !isMuted) return sound;
    return mute;
  };

  const newRandom = () => {
    const number = Math.floor(Math.random() * tracksArray.length);
    if (number === songNumber) {
      newRandom();
    } else {
      setSongNumber(number);
      return number;
    }
    return null;
  };

  useEffect(() => {
    if (!isMuted) {
      audio.play();
    } else audio.pause();
  }, [isMuted, audio]);

  useEffect(() => {
    audio.addEventListener('ended', () => {
      setTimeout(
        () => {
          setAudio(new Audio(tracksArray[newRandom()]));
        },
        10000,
      );
    });
    return () => {
      audio.removeEventListener('ended', () => setAudio(new Audio(tracksArray[songNumber])));
    };
  }, [audio]);

  return (
    <div className={styles.wrapper}>
      <button
        type="button"
        className={styles.button}
        onMouseEnter={() => setCheck('hover')}
        onMouseDown={() => setCheck('pressed')}
        onMouseUp={() => setCheck('hover')}
        onMouseLeave={() => setCheck('')}
        onClick={() => {
          if (isMuted) {
            setIsMuted(false);
            localStorage.setItem('sound', 'on');
          } else {
            setIsMuted(true);
            localStorage.setItem('sound', 'off');
          }
        }}
      >
        <img src={selectBtnImg()} alt="" />
      </button>
    </div>
  );
};

export default Sound;
