import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deletePawnFromGild, selectActivePreset } from '../../../../store/slices/preset';
import uniqueId from '../../../../utils/uniqueId';
import ActivePawn from '../../../../static/img/equipment/Empty pawn slot active.png';
import InActivePawn from '../../../../static/img/equipment/Innactive pawn slot.png';
import EmptySpell from '../../../../static/img/equipment/pick_spell.png';
import InActiveEmptySpell from '../../../../static/img/equipment/hidden_spell.png';
import { emptySpellsObject } from '../../../../utils/skill/pawns';
import { chooseData, typeFunc } from '../../../../utils/chooseData';
import styles from '../../Equipment.module.scss';
import RemoveButton from '../RemoveButton';

const CombatItem = ({
  data, index, setSelectedPawn,
}) => {
  const dispatch = useDispatch();
  const activePreset = useSelector(selectActivePreset);
  const handleContent = useCallback((active, inActive) => {
    if ((activePreset === 1 && index === 1)
      || (activePreset === 2 && (index === 1 || index === 2))
      || (activePreset === 3 && (index === 1 || index === 2 || index === 3))) {
      return active;
    }
    return inActive;
  }, [activePreset, index]);

  const hiddenImg = (number) => Array.from({ length: number })
    .fill(<img src={handleContent(EmptySpell, InActiveEmptySpell)} alt="spell" width="52px" height="52px" />);

  return (
    <>
      <div className={styles.line} />
      <div className={styles.info_pawn}>
        <div className={styles.wrapper_info_pawn}>
          <div className={styles.wrapper_info}>
            <span className={`${styles.number} ${handleContent('', styles.disable)}`}>
              {index}
              .
            </span>
            <img
              role="presentation"
              src={chooseData[data?.pawn?.class]?.logo || handleContent(ActivePawn, InActivePawn)}
              alt="logo"
              width="55px"
              height="55px"
              onClick={() => (data?.pawn?.nftId ? setSelectedPawn(data.pawn.nftId) : {})}
              className={data?.pawn?.nftId ? styles.cursor : ''}
            />
            <span className={styles.name_pawn}>{data?.pawn?.name || handleContent('Pawn name', '')}</span>
          </div>
          {data && (
            <RemoveButton click={() => {
              dispatch(deletePawnFromGild({ vs: typeFunc(activePreset), id: data?.pawn?.nftId }));
            }}
            />
          )}
        </div>
        <div className={styles.wrapper_group_spells}>
          <div className={styles.wrapper_spell}>
            {Object.keys(data?.spells || {})?.length
              ? Object.keys({ ...emptySpellsObject, ...data?.spells }).map((item) => (
                <img
                  role="presentation"
                  key={uniqueId('images_spells')}
                  src={data?.spells[item] ? data?.spells[item].image : handleContent(EmptySpell, InActiveEmptySpell)}
                  alt=""
                  width="52px"
                  height="52px"
                />
              )) : (
                <>
                  {hiddenImg(6).map((elem, i) => (
                    <div key={[i]} className={styles.images}>
                      {elem}
                    </div>
                  ))}
                </>
              )}
          </div>
          <div className={styles.wrapper_spell}>
            {data?.consumables?.length
              ? (data.consumables.length === 1
                ? [...data.consumables, null]
                : [...data.consumables]
              ).map((elem) => (
                <img
                  role="presentation"
                  key={uniqueId('images_consumables')}
                  src={elem ? `https://i.bimium.link/${elem.image}` : handleContent(EmptySpell, InActiveEmptySpell)}
                  alt=""
                  width="52px"
                  height="52px"
                />
              )) : (
                <>
                  {hiddenImg(2).map((elem, i) => (
                    <div key={[i + 6]} className={styles.images}>
                      {elem}
                    </div>
                  ))}
                </>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(CombatItem);
