import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { AuthProvider } from 'react-oidc-context';
import { WebStorageStateStore } from 'oidc-client-ts';
import '@rainbow-me/rainbowkit/styles.css';
import { getDefaultWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import {
  configureChains, createConfig, WagmiConfig,
} from 'wagmi';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { Router } from './router';
import { store } from './store';
import arbitrum from './static/icons/arbitrum.png';
import { API_URL, APP_ENV, PROJECT_ID } from './constants/constants';
import './index.scss';

const availableChains = {
  testnet: {
    id: 421613,
    name: 'Arbitrum Testnet',
    iconUrl: arbitrum,
    iconBackground: '#fff',
    nativeCurrency: {
      decimals: 18,
      name: 'Arbitrum Testnet',
      symbol: 'ETH',
    },
    rpcUrls: {
      default: 'https://goerli-rollup.arbitrum.io/rpc/',
    },
    blockExplorers: {
      default: { name: 'Goerli Arbitrum', url: 'https://goerli.arbiscan.io/' },
    },
    testnet: true,
  },
  mainnet: {
    id: 42161,
    name: 'Arbitrum',
    iconUrl: arbitrum,
    iconBackground: '#fff',
    nativeCurrency: {
      decimals: 18,
      name: 'Arbitrum One Mainnet',
      symbol: 'ETH',
    },
    rpcUrls: {
      default: 'https://arb1.arbitrum.io/rpc/',
    },
    blockExplorers: {
      default: { name: 'Arbitrum One', url: 'https://arbiscan.io/' },
    },
    testnet: false,
  },
};
availableChains.dev = availableChains.testnet;

const { chains, publicClient } = configureChains(
  [availableChains[APP_ENV]],
  [jsonRpcProvider({ rpc: (chain) => ({ http: chain.rpcUrls.default }) })],
);

const { connectors } = getDefaultWallets({
  appName: 'My RainbowKit App',
  projectId: PROJECT_ID,
  chains,
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});

const onSignInCallback = () => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

const oidcConfig = {
  authority: API_URL,
  client_id: 'web_app',
  redirect_uri: window.location.origin,
  onSignInCallback,
  scope: 'openid offline_access',
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider {...oidcConfig}>
        <WagmiConfig config={wagmiConfig}>
          <RainbowKitProvider chains={chains}>
            <Router />
          </RainbowKitProvider>
        </WagmiConfig>
      </AuthProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
