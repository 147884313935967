import React, { useEffect, useState } from 'react';
import Phaser from 'phaser';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { socialHubSocket } from '../../utils/socialHubSocket';
import { selectLastMessage, selectOpenChatInput } from '../../store/slices/chat';
import { selectDisconnectUser, userDisconnect } from '../../store/slices/taverna';
import MapScene from './MapScene';
import TavernScene from './TavernScene';
import styles from './Social.module.scss';
import { getGuildsInfoAction } from '../../store/slices/portraits';
import Header from '../../components/Marketplace/Components/Header';
import { selectEvm } from '../../store/slices/auth';

let game = null;
let mapScene = null;
let tavernScene = null;

const config = {
  type: Phaser.AUTO,
  parent: 'social-container',
  transparent: true,
  backgroundColor: 'rgba(0,0,0,0)',
  'render.transparent': true,
  pixelArt: true,
  physics: {
    default: 'arcade',
    arcade: {
      gravity: { y: 600 },
      debug: window.location.hostname === 'localhost',
    },
  },
  scale: {
    mode: Phaser.Scale.ScaleModes.RESIZE,
    // mode: Phaser.Scale.FIT,
    width: 3200,
    height: 2400,
  },
};

let socialHubConnection = null;

const Social = ({ children }) => {
  const dispatch = useDispatch();
  const selectMessage = useSelector(selectLastMessage);
  const selectDisconnectAddress = useSelector(selectDisconnectUser);
  const isOpen = useSelector(selectOpenChatInput);
  const address = useSelector(selectEvm);
  const [mapSceneCreated, setMapSceneCreated] = useState();
  const [tavernSceneCreated, setTavernSceneCreated] = useState();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const socialContainerClick = () => {
    if (pathname !== '/') {
      navigate('/');
    }
  };

  useEffect(() => {
    socialHubConnection = socialHubSocket(address);
    dispatch(getGuildsInfoAction());
    if (socialHubConnection) {
      game = new Phaser.Game(config);
      game.events.on(
        Phaser.Core.Events.READY,
        () => {
          mapScene = new MapScene({
            key: 'map-scene',
            navigate,
            setMapSceneCreated,
          });
          game.scene.add('map-scene', mapScene, true);

          tavernScene = new TavernScene({
            key: 'tavern-scene',
            navigate,
            socialHubConnection,
            evm: address,
            setTavernSceneCreated,
          });
          game.scene.add('tavern-scene', tavernScene, false);
          // game.scene.start('map-scene');/
        },
      );
    }
  }, []);

  useEffect(() => {
    if (selectMessage) {
      tavernScene.setMessage(selectMessage);
    }
  }, [selectMessage]);

  useEffect(() => {
    if (tavernScene) {
      tavernScene.handleKey(isOpen);
    }
  }, [isOpen]);

  useEffect(() => {
    if (tavernScene && selectDisconnectAddress) {
      tavernScene.disconnectUser(selectDisconnectAddress);
      dispatch(userDisconnect({ address: null }));
    }
  }, [selectDisconnectAddress]);

  useEffect(() => {
    if (tavernScene) {
      if (pathname !== '/') {
        tavernScene.handleKey(true);
      } else {
        tavernScene.handleKey(false);
      }
    }

    if (mapScene) {
      if (pathname !== '/') {
        mapScene.handleKey(true);
      } else {
        mapScene.handleKey(false);
      }
    }
  }, [pathname, mapSceneCreated, tavernSceneCreated]);

  return (
    <>
      <div className={styles.MapContainer}>
        <div id="social-container" onClick={socialContainerClick} role="presentation" />
      </div>
      {
        pathname === '/'
          ? <Header address={address} />
          : ''
      }
      { children }
    </>
  );
};

export default Social;
