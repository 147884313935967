import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import styles from './StatusBar.module.scss';
import potion1 from '../../../../static/img/battleWindow/Potion1.png';
import potion2 from '../../../../static/img/battleWindow/Potion2.png';
import separator from '../../../../static/img/battleWindow/vertical_separator.png';
import uniqueId from '../../../../utils/uniqueId';
import ResourceBar from './ResourceBar/ResourceBar';
import { handleDoSpellAction } from '../../../../utils/arenaSocket';
import { chooseData } from '../../../../utils/chooseData';
import Tooltip from '../../../../components/Tooltip';
import { buttonsArray } from '../../../../constants/constants';
import { selectPlayersInfo, selectSpellDescriptionByPawn } from '../../../../store/slices/arena';
import { selectOpenChat } from '../../../../store/slices/chat';

const StatusBar = ({ activePawn, target, address }) => {
  const spellDescriptionByPawn = useSelector(selectSpellDescriptionByPawn);
  const userData = address === 'BOT'
    ? chooseData[activePawn.class]
    : {
      ...chooseData[activePawn.class],
      spells: Object.keys(spellDescriptionByPawn[activePawn.id] || []).map((k) => ({
        ...spellDescriptionByPawn[activePawn.id][k],
      })),
    };

  const fightData = useSelector(selectPlayersInfo);
  const openChat = useSelector(selectOpenChat);
  const [activeButton, setActiveButton] = useState(null);
  // eslint-disable-next-line consistent-return
  const handleKeyPress = (e) => {
    if (openChat) return null;
    if (target) {
      const choseSpell = (number) => {
        handleDoSpellAction(userData.spells[number]?.id, target);
        setActiveButton(userData.spells[number]);
      };
      switch (e.key.toLowerCase()) {
        case 'q':
          choseSpell(0);
          break;
        case 'w':
          choseSpell(1);
          break;
        case 'e':
          choseSpell(2);
          break;
        case 'r':
          choseSpell(3);
          break;
        case 't':
          choseSpell(4);
          break;
        case 'y':
          choseSpell(5);
          break;
        default:
          return null;
      }
    }
  };
  const findSpellIndex = (spell) => activePawn.spells.findIndex((object) => object.code === spell);

  useEffect(() => {
    window.addEventListener('keypress', handleKeyPress);

    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, [openChat]);

  useEffect(() => {
    window.addEventListener('keyup', () => setActiveButton(null));

    return () => {
      window.removeEventListener('keyup', () => setActiveButton(null));
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <div
        className={`
        ${styles.icon}
        ${fightData?.whoseTurn?.address === address ? styles.active : ''}
        `}
      >
        <img src={chooseData[activePawn.class].logo} alt="" />
      </div>
      <div className={styles.interaction}>
        <div className={styles.bars}>
          <ResourceBar
            type="hp"
            count={activePawn.health < 0 ? 0 : activePawn.health}
            maxCount={activePawn.maxHealth}
          />
          <ResourceBar
            type={activePawn.class.toLowerCase()}
            count={activePawn.actionPoints}
            maxCount={activePawn.maxActionPoints}
          />
        </div>
        <div className={styles.effects}>
          {
            Object.keys(userData.spells).map((elem, index) => (
              <div
                // className={`${styles.effects_block} ${activeSkill?.name === elem ? styles.selected_skill : ''}`}
                key={uniqueId('statusBarSpells')}
              >
                <div
                  role="presentation"
                  className={
                  `${styles.img_wrapper}
                  ${styles.img_wrapper}
                   ${activeButton === elem ? styles.push : ''}
                   ${activePawn.actionPoints < userData.spells[elem]?.cost?.count ? styles.disable_img : ''}`
                }
                  onClick={() => {
                    if (target) {
                      handleDoSpellAction(userData.spells[elem]?.id, target);
                    }
                  }}
                  data-tip
                  data-for={`${elem}Tip`}
                >
                  <img
                    role="presentation"
                    src={userData.spells[elem].image}
                    alt=""
                  />
                  {activePawn.spells[findSpellIndex(elem)]?.lockedTime
                    ? (
                      <div className={styles.cooldown_wrapper}>
                        <div className={styles.cooldown}>
                          {activePawn.spells[findSpellIndex(elem)].lockedTime}
                        </div>
                      </div>
                    )
                    : null}
                </div>
                <ReactTooltip
                  effect="solid"
                  id={`${elem}Tip`}
                  place="top"
                  backgroundColor="none"
                  arrowColor="transparent"
                  style={{ opacity: 1 }}
                >
                  <Tooltip
                    description={userData.spells[elem].description}
                    img={userData.spells[elem].image}
                    name={userData.spells[elem].spellName}
                    type={userData.spells[elem].type}
                    quickness={userData.spells[elem].quickness}
                    cooldown={userData.spells[elem].cooldown}
                    button={buttonsArray[index]}
                    cost={activePawn.spells[index]?.cost}
                  />
                </ReactTooltip>
              </div>
            ))
          }
        </div>
      </div>
      <div className={styles.vertical_separator}>
        <img src={separator} alt="" />
      </div>
      <div className={styles.potions}>
        <div className={styles.img1} data-title="Coming soon">
          <img src={potion1} alt="" />
        </div>
        <div className={styles.img1} data-title="Coming soon">
          <img src={potion2} alt="" />
        </div>
      </div>
      <div className={styles.vertical_separator}>
        <img src={separator} alt="" />
      </div>
    </div>
  );
};

export default React.memo(StatusBar);
