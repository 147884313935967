import { Parser } from 'expr-eval';
import Action from './action';

class ActionPawnAnimation extends Action {
  constructor(skill, config) {
    super(config);
    this.skill = skill;
    this.target = config.sceneTarget.objects.pawn;
    this.timeline = config.timeline;

    console.log('Action pawn animation', this.skill, this.target, config);

    const propXY = {};
    if (this.config.positionFrom && this.config.positionTo) {
      const parser = new Parser();
      const posFromArr = this.config.positionFrom.split(',');
      const posToArr = this.config.positionTo.split(',');
      const currentX = this.skill.pawn.objects.pawn.x;
      const currentY = this.skill.pawn.objects.pawn.y;
      const opponentX = this.skill.opponentPawn.objects.pawn.x;
      const opponentY = this.skill.opponentPawn.objects.pawn.y;
      propXY.x = {
        value: {
          from: parser.parse(posFromArr[0].replace('current', currentX).replace('opponent', opponentX)).evaluate(),
          to: parser.parse(posToArr[0].replace('current', currentX).replace('opponent', opponentX)).evaluate(),
        },
      };
      propXY.y = {
        value: {
          from: parser.parse(posFromArr[1].replace('current', currentY).replace('opponent', opponentY)).evaluate(),
          to: parser.parse(posToArr[1].replace('current', currentY).replace('opponent', opponentY)).evaluate(),
        },
      };
    }

    if (this.target) {
      if (Object.keys(propXY).length) {
        this.timeline.add({
          at: this.delay,
          tween: {
            targets: this.skill.pawn.objects.shadow,
            // offset: this.delay,
            duration: this.duration,
            yoyo: this.config.yoyo,
            hold: this.config.yoyoHold,
            props: {
              x: propXY.x,
            },
          },
        });
      }
      this.timeline.add({
        at: this.delay,
        tween: {
          targets: this.target,
          // offset: this.delay,
          duration: this.duration,
          ...(
            !this.config.yoyo
              ? {}
              : {
                yoyo: this.config.yoyo,
                hold: this.config.yoyoHold,
              }
          ),
          props: {
            visible: true,
            ...propXY,
          },
        },
        run: () => {
          console.log('RUN!!!!', this.target);
          this.target.anims.play({
            key: config.animation.replace(this.skill.pawn.className, this.skill.pawn.config.pawnConfig.key),
            duration: this.duration,
          });
        },
      });
    }
  }
}

export default ActionPawnAnimation;
