import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from 'react-oidc-context';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAccount, useSignMessage } from 'wagmi';
import {
  getEvm,
  postAddress, selectEvm, selectLoading, selectMessage, selectVerify, setEvm, verifyUser,
} from './store/slices/auth';
import { addressSubmit } from './store/slices/utils';
import styles from './YourComponent.module.scss';

const YourComponent = ({ children }) => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const { address } = useAccount();
  const { signMessageAsync } = useSignMessage();

  const { loading, evmLoaded } = useSelector(selectLoading);
  const evm = useSelector(selectEvm);
  const message = useSelector(selectMessage);
  const verify = useSelector(selectVerify);

  useEffect(() => {
    dispatch(getEvm(auth.user.access_token));
  }, []);

  useEffect(() => {
    if (address) {
      dispatch(addressSubmit(address));
    }
    if (evmLoaded && !evm && address) {
      dispatch(postAddress(address));
    }
  }, [address, evmLoaded]);

  useEffect(() => {
    const handleAuth = async () => {
      const signature = await signMessageAsync({ message });
      if (signature) {
        dispatch(verifyUser({
          message,
          signature,
        }));
      }
    };
    if (message && !evm && !loading) {
      handleAuth();
    }
  }, [message]);

  useEffect(() => {
    if (verify && evm && !loading) {
      dispatch(setEvm(address));
    }
  }, [verify]);

  if (evm) {
    return (
      <div className={styles.wrapper}>
        { children }
      </div>
    );
  }

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {loading
        ? <div className={styles.wrapper} />
        : (
          <div className={styles.connect_wallet_wrapper}>
            <div className={styles.connect_wallet_block}>
              <ConnectButton />
            </div>
          </div>
        )}
    </div>
  );
};

export default React.memo(YourComponent);
