import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../SkillUtils.module.scss';
import { pawnArcherSprite, pawnMageSprite, pawnWarriorSprite } from '../../../../utils/skill/pawns';
import { getSpriteData } from '../../../../utils/skillRequests';
import deleteImg from '../../../../static/img/header/balancebar/dropdown/Decline button.png';
import { deleteSound, onSoundPost, selectSoundLink } from '../../../../store/slices/skillConstructor';

const choosePawnAnimation = ({
  Archer: pawnArcherSprite.animations,
  Mage: pawnMageSprite.animations,
  Warrior: pawnWarriorSprite.animations,
});

const Actions = ({
  register, watch, index, onDelete, sprites, group, phase, setValue,
}) => {
  const dispatch = useDispatch();

  const [spriteAnimations, setSpriteAnimations] = useState([]);

  const { soundLink, soundIndex } = useSelector(selectSoundLink);

  const onSoundSelect = (file) => {
    const formData = new FormData();
    formData.append('sound', file);
    dispatch(onSoundPost(formData, index));
  };

  useEffect(() => {
    const selectedSprite = watch(`actions.${index}.selectedSprite`);
    const animation = watch(`actions.${index}.animation`);
    if (selectedSprite) {
      getSpriteData(selectedSprite).then((r) => {
        if (r.status === 200) {
          setSpriteAnimations(r?.data?.animations || []);
          setValue(`actions.${index}.path`, r?.data?.path);
          setValue(`actions.${index}.height`, r?.data?.height);
          setValue(`actions.${index}.width`, r?.data?.width);
          setValue(`actions.${index}.key`, r?.data?.key);
          setValue(`actions.${index}.originCurrent`, r?.data?.originCurrent);
          setValue(`actions.${index}.originOpponent`, r?.data?.originOpponent);
          setValue(`actions.${index}.animations`, r?.data?.animations);
          setValue(`actions.${index}.animation`, animation);
        }
      });
    }
  }, [watch(`actions.${index}.selectedSprite`)]);

  useEffect(() => {
    if (soundLink && soundIndex === index) {
      setValue(`actions.${index}.soundPath`, `https://i.bimium.link/${soundLink}`);
    }
  }, [soundLink]);

  return ((group === null && phase === null)
    || (group === null && phase !== null && watch(`actions.${index}.phase`))
    || (group !== null && phase === null && watch(`actions.${index}.group`))
    // eslint-disable-next-line max-len
    || (group !== null && phase !== null && watch(`actions.${index}.group`) === group && watch(`actions.${index}.phase`) === phase)
    ? (
      <div className={styles.animation_block} style={{ marginTop: '10px' }}>
        <div className={styles.form_row}>
          <div>
            Action Type
          </div>
          <select
            defaultValue=""
            {...register(`actions.${index}.type`)}
          >
            <option value="" hidden>Select</option>
            <option value="PawnAnimation">Pawn animation</option>
            <option value="OpponentAnimation">Opponent animation</option>
            <option value="SpriteAnimation">New sprite action</option>
            <option value="BuffOnOpponent">Buff when create separate sprite on opponent (sunder mind)</option>
            <option value="Sound">Sound</option>
            <option value="MoveAndAnim">pawn move + aim</option>
          </select>
        </div>
        <div className={styles.form_row}>
          <div>
            DELAY
            <input
              type="input"
              placeholder="0"
              {...register(`actions.${index}.delay`)}
            />
          </div>
        </div>
        <div className={styles.form_row}>
          <div>
            DURATION
            <input
              type="input"
              placeholder="500"
              {...register(`actions.${index}.duration`)}
            />
          </div>
        </div>
        {watch('application') === 'EndOpponent'
          ? (
            <div className={styles.form_row}>
              <div>
                PHASE
                <select
                  {...register(`actions.${index}.phase`)}
                >
                  <option value="" hidden>Select</option>
                  <option value="Precast">Precast</option>
                  <option value="Cast">Cast</option>
                </select>
              </div>
            </div>
          )
          : null}
        {watch(`actions.${index}.type`) === 'PawnAnimation'
          ? (
            <div className={styles.form_row}>
              <div>
                animation
              </div>
              <select
                defaultValue=""
                {...register(`actions.${index}.animation`)}
              >
                <option value="" hidden>Select</option>
                {choosePawnAnimation[watch('class')].map((animation) => (
                  <option value={animation.key} key={animation.key}>{animation.key}</option>
                ))}
              </select>
              <div className={styles.form_row}>
                <div>
                  From position x,y
                  <input
                    type="input"
                    placeholder="123,423"
                    {...register(`actions.${index}.positionFrom`)}
                  />
                </div>
              </div>
              <div className={styles.form_row}>
                <div>
                  To position x,y
                  <input
                    type="input"
                    placeholder="456,423"
                    {...register(`actions.${index}.positionTo`)}
                  />
                </div>
              </div>
              <div className={styles.form_row}>
                <div>
                  <label>
                    <input
                      type="checkbox"
                      {...register(`actions.${index}.yoyo`)}
                    />
                    YoYo
                  </label>
                </div>
              </div>
              <div className={styles.form_row}>
                <div>
                  YoYo hold, ms
                  <input
                    type="input"
                    placeholder="123,423"
                    defaultValue={0}
                    {...register(`actions.${index}.yoyoHold`)}
                  />
                </div>
              </div>
            </div>
          )
          : null}
        {watch(`actions.${index}.type`) === 'OpponentAnimation'
          ? (
            <div className={styles.form_row}>
              <div>
                animation opponent
              </div>
              <select
                defaultValue=""
                {...register(`actions.${index}.animation`)}
              >
                <option value="" hidden>Select</option>
                <option value="hit">hit</option>
                <option value="idle">idle</option>
                {/* <option value="buff">buff</option> */}
                {/* <option value="hit2">hit2</option> */}
              </select>
            </div>
          )
          : null}
        {watch(`actions.${index}.type`) === 'Sound'
          ? (
            <div className={styles.form_row}>
              <div>Add link to .waw or .mp3 sound, or upload it</div>
              <div style={{ display: 'flex', columnGap: '10px' }}>
                <input
                  type="text"
                  {...register(`actions.${index}.soundPath`)}
                />
                <img
                  role="presentation"
                  src={deleteImg}
                  alt="del"
                  width="20px"
                  height="20px"
                  onClick={() => {
                    dispatch(deleteSound());
                    setValue(`actions.${index}.soundPath`, '');
                  }}
                />
              </div>
              {!soundLink
                ? (
                  <input
                    style={{ marginTop: '10px' }}
                    type="file"
                    accept=".wav, .mp3"
                    onChange={(e) => onSoundSelect(e.target.files[0])}
                  />
                )
                : null}
            </div>
          )
          : null}
        {watch(`actions.${index}.type`) === 'SpriteAnimation'
          ? (
            <div className={styles.form_row}>
              <input
                type="hidden"
                {...register(`actions.${index}.path`)}
              />
              <input
                type="hidden"
                {...register(`actions.${index}.height`)}
              />
              <input
                type="hidden"
                {...register(`actions.${index}.width`)}
              />
              <input
                type="hidden"
                {...register(`actions.${index}.key`)}
              />
              <input
                type="hidden"
                {...register(`actions.${index}.originCurrent`)}
              />
              <input
                type="hidden"
                {...register(`actions.${index}.originOpponent`)}
              />
              <div>
                spell casting
              </div>
              <div className={styles.form_row}>
                <div>
                  Choose created sprite
                </div>
                {sprites.length
                  ? (
                    <>
                      <select
                        {...register(`actions.${index}.selectedSprite`)}
                      >
                        <option value="" hidden>Select</option>
                        {sprites.map((elem) => <option value={elem.id} key={elem.id}>{elem.key}</option>)}
                      </select>
                      <div className={styles.form_row}>
                        <div>
                          position x,y
                          <input
                            type="input"
                            placeholder="123,423"
                            {...register(`actions.${index}.position`)}
                          />
                        </div>
                      </div>
                    </>
                  )
                  : (
                    <div>
                      You don&quot;t have any sprites
                    </div>
                  )}
              </div>
              <div className={styles.form_row}>
                <div>
                  animation
                </div>
                <select
                  defaultValue=""
                  {...register(`actions.${index}.animation`)}
                >
                  <option value="" hidden>Select</option>
                  {spriteAnimations.map((animation) => (
                    <option value={animation.key} key={animation.key}>{animation.key}</option>
                  ))}
                </select>
              </div>
              <div className={styles.form_row}>
                <div>
                  Depth
                  <input
                    type="input"
                    placeholder="30"
                    {...register(`actions.${index}.depth`)}
                  />
                </div>
              </div>
              <div className={styles.form_row}>
                <div>
                  From position x,y
                  <input
                    type="input"
                    placeholder="123,423"
                    {...register(`actions.${index}.positionFrom`)}
                  />
                </div>
              </div>
              <div className={styles.form_row}>
                <div>
                  To position x,y
                  <input
                    type="input"
                    placeholder="456,423"
                    {...register(`actions.${index}.positionTo`)}
                  />
                </div>
              </div>
              <div className={styles.form_row}>
                <div>
                  Move duration ms from to
                  <input
                    type="input"
                    placeholder="1500"
                    {...register(`actions.${index}.moveDuration`)}
                  />
                </div>
              </div>
            </div>
          )
          : null}
        {watch(`actions.${index}.type`) === 'BuffOnOpponent'
          ? (
            <div className={styles.form_row}>
              <div>
                Choose created sprite
              </div>
              {sprites.length
                ? (
                  <>
                    <select
                      {...register(`actions.${index}.selectedSprite`)}
                    >
                      <option value="" hidden>Select</option>
                      {sprites.map((elem) => <option value={elem.id} key={elem.id}>{elem.key}</option>)}
                    </select>
                    <div className={styles.form_row}>
                      <div>
                        position x,y
                        <input
                          type="input"
                          placeholder="123,423"
                          {...register(`actions.${index}.position`)}
                        />
                      </div>
                    </div>
                  </>
                )
                : (
                  <div>
                    You don&quot;t have any sprites
                  </div>
                )}
            </div>
          )
          : null}
        {watch(`actions.${index}.type`) === 'MoveAndAnim'
          ? (
            <div className={styles.form_row}>
              <div>
                spell casting
              </div>
              <div className={styles.form_row}>
                <div>
                  From position x,y
                  <input
                    type="input"
                    placeholder="123,423"
                    {...register(`actions.${index}.positionFrom`)}
                  />
                </div>
              </div>
              <div className={styles.form_row}>
                <div>
                  To position x,y
                  <input
                    type="input"
                    placeholder="456,423"
                    {...register(`actions.${index}.positionTo`)}
                  />
                </div>
              </div>
              <div className={styles.form_row}>
                <div>
                  Move duration ms from to
                  <input
                    type="input"
                    placeholder="1500"
                    {...register(`actions.${index}.moveDuration`)}
                  />
                </div>
              </div>
              <div className={styles.form_row}>
                <div>
                  Pawn animation
                </div>
                <select
                  defaultValue=""
                  {...register(`actions.${index}.animation`)}
                >
                  <option value="" hidden>Select</option>
                  {choosePawnAnimation[watch('class')].map((animation) => (
                    <option value={animation.key} key={animation.key}>{animation.key}</option>
                  ))}
                </select>
              </div>
            </div>
          )
          : null}
        <button
          type="button"
          onClick={() => onDelete()}
        >
          Delete action
        </button>
      </div>
    )
    : null);
};

export default Actions;
