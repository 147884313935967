import React from 'react';
import loader from '../../../../../static/img/loader.gif';
import styles from './Grid.module.scss';
import SinglePortrait from './SinglePortrait';

const Grid = ({
  text, data, loading,
}) => (
  <div className={styles.wrapper_area}>
    <div className={styles.mobile_content_decor} />
    <div className={styles.inventory}>
      {text && (
        <div className={styles.corner_inventory_label}>
          <div className={styles.corner_inventory_label_left} />
          <div className={styles.corner_inventory_label_centre}>{text}</div>
          <div className={styles.corner_inventory_label_right} />
        </div>
      )}
      <div className={styles.corner_inventory_top_left} />
      <div className={styles.border_inventory_top} />
      <div className={styles.corner_inventory_top_right} />
      <div className={styles.border_inventory_left} />
      <div className={`${styles.inventory_portrait_content} ${text ? '' : styles.text}`}>
        {loading
          ? (
            <div className={styles.loader}>
              <img src={loader} alt="loading..." />
            </div>
          )
          : (
            <>
              {data.map((portrait) => (
                <SinglePortrait
                  text={text}
                  elem={portrait}
                  key={portrait.nftId}
                />
              ))}
            </>
          )}
      </div>
      <div className={styles.border_inventory_right} />
      <div className={styles.corner_inventory_bottom_left} />
      <div className={styles.border_inventory_bottom} />
      <div className={styles.corner_inventory_bottom_right} />
    </div>
  </div>
);

export default Grid;
