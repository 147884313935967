// eslint-disable-next-line import/no-extraneous-dependencies
import {
  writeContract, watchContractEvent, getAccount, readContract,
} from '@wagmi/core';
import { parseEther } from 'viem';
import {
  COIN_CONTRACT_ADDRESS,
  PORTRAIT_CONTRACT_ADDRESS,
  PORTRAIT_MARKETPLACE_CONTRACT_ADDRESS,
} from '../constants/constants';
import PortraitMarketplaceABI from '../contracts/PortraitMarketplace.json';
import PortraitABI from '../contracts/Portrait.json';
import SanctumCoinABI from '../contracts/SanctumCoin.json';

const getCurrentUserAddress = () => {
  const { address } = getAccount();
  return address;
};

const offerPortrait = (portraitNftId, price) => readContract({
  address: PORTRAIT_CONTRACT_ADDRESS,
  abi: PortraitABI,
  functionName: 'isApprovedForAll',
  args: [getCurrentUserAddress(), PORTRAIT_MARKETPLACE_CONTRACT_ADDRESS],
}).then((approved) => {
  if (!approved) {
    return writeContract({
      address: PORTRAIT_CONTRACT_ADDRESS,
      abi: PortraitABI,
      functionName: 'setApprovalForAll',
      args: [PORTRAIT_MARKETPLACE_CONTRACT_ADDRESS, true],
    });
  }

  return null;
})
  .then(() => writeContract({
    address: PORTRAIT_MARKETPLACE_CONTRACT_ADDRESS,
    abi: PortraitMarketplaceABI,
    functionName: 'placeOffering',
    args: [PORTRAIT_CONTRACT_ADDRESS, portraitNftId, parseEther(price)],
  }));

const buyPortrait = (offeringId) => {
  let portraitPrice;

  return readContract({
    address: PORTRAIT_MARKETPLACE_CONTRACT_ADDRESS,
    abi: PortraitMarketplaceABI,
    functionName: 'viewOfferingNFT',
    args: [offeringId],
  }).then(([, , price]) => {
    portraitPrice = price;

    return readContract({
      address: COIN_CONTRACT_ADDRESS,
      abi: SanctumCoinABI,
      functionName: 'allowance',
      args: [getCurrentUserAddress(), PORTRAIT_MARKETPLACE_CONTRACT_ADDRESS],
    });
  }).then((allowance) => {
    if (allowance >= portraitPrice) {
      return writeContract({
        address: PORTRAIT_MARKETPLACE_CONTRACT_ADDRESS,
        abi: PortraitMarketplaceABI,
        functionName: 'closeOffering',
        args: [offeringId],
      });
    }
    return writeContract({
      address: COIN_CONTRACT_ADDRESS,
      abi: SanctumCoinABI,
      functionName: 'approve',
      args: [PORTRAIT_MARKETPLACE_CONTRACT_ADDRESS, portraitPrice.toString()],
    }).then(() => writeContract({
      address: PORTRAIT_MARKETPLACE_CONTRACT_ADDRESS,
      abi: PortraitMarketplaceABI,
      functionName: 'closeOffering',
      args: [offeringId],
    }));
  });
};

const cancelPortraitOffering = (offeringId) => writeContract({
  address: PORTRAIT_MARKETPLACE_CONTRACT_ADDRESS,
  abi: PortraitMarketplaceABI,
  functionName: 'cancelOffering',
  args: [offeringId],
});

const onNewPortraitOffering = (callback) => watchContractEvent({
  address: PORTRAIT_MARKETPLACE_CONTRACT_ADDRESS,
  abi: PortraitMarketplaceABI,
  eventName: 'OfferingPlaced',
}, callback);

const onPortraitOfferingPurchased = (callback) => watchContractEvent({
  address: PORTRAIT_MARKETPLACE_CONTRACT_ADDRESS,
  abi: PortraitMarketplaceABI,
  eventName: 'OfferingClosed',
}, callback);

const onPortraitOfferingCancelled = (callback) => watchContractEvent({
  address: PORTRAIT_MARKETPLACE_CONTRACT_ADDRESS,
  abi: PortraitMarketplaceABI,
  eventName: 'OfferingCancelled',
}, callback);

const transferPortrait = (to, nftId) => writeContract({
  address: PORTRAIT_CONTRACT_ADDRESS,
  abi: PortraitABI,
  functionName: 'transferFrom',
  args: [getCurrentUserAddress(), to, nftId],
});

export {
  offerPortrait, cancelPortraitOffering, onNewPortraitOffering, onPortraitOfferingCancelled,
  onPortraitOfferingPurchased, transferPortrait, buyPortrait,
};
