/* eslint-disable */
import { ethers } from 'ethers';
import MarketplaceArtifact from '../contracts/Marketplace.json';
import SanctumCoinArtifact from '../contracts/SanctumCoin.json';
import PawnArtifact from '../contracts/Pawn.json';
import WhiteMintArtifact from '../contracts/WhiteMint.json';

import {
  API_URL,
  COIN_CONTRACT_ADDRESS,
  MARKETPLACE_CONTRACT_ADDRESS,
  PAWN_CONTRACT_ADDRESS,
  WHITEMINT_CONTRACT_ADDRESS,
} from '../constants/constants';

const responseParse = async (response) => ({
  data: await response.json(),
  status: response.status,
});

// eslint-disable-next-line no-return-await
const getUserAddress = async () => {
  const [currentAddress] = await window.ethereum.enable();
  return currentAddress;
};

const isAuthorize = async () => {
  const response = await fetch(`${API_URL}authorize?api-version=1.0`);
  return responseParse(response);
};
// queryType - "Quests"
const getQuests = async () => {
  const token = localStorage.getItem('token')
  const response = await fetch(`${API_URL}api/quests`, { headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }});
  return responseParse(response);
};

const getQuestById = async (id) => {
  const token = localStorage.getItem('token')
  const response = await fetch(`${API_URL}api/quests/${id}`, { headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }});
  return responseParse(response);
};

const startQuest = async (
  body,
) => {
  const token = localStorage.getItem('token')
  const response = await fetch(`${API_URL}api/guilds/quest`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body),
  })
  if (response.status >= 400) {
    return responseParse(response)
  } else {
    return response;
  }
}

const putReward = async (id) => {
  const token = localStorage.getItem('token')
  const response = await fetch(`${API_URL}api/guilds/quest/${id}/reward`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
  })
  return responseParse(response);
}

// queryType - equipment, consumables, spells

const putPawnSpell = async (payload) => {
  const token = localStorage.getItem('token')
  const response = await fetch(`${API_URL}api/guilds/pawns/${payload.pawnId}/equipment/spells`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload.body),
  })
  if (response.status >= 400) {
    return responseParse(response)
  } else {
    return response;
  }
}

const deletePawnSpell = async (
  payload,
) => {
  const token = localStorage.getItem('token')
  const result = await fetch(`${API_URL}api/guilds/pawns/${payload.pawnId}/equipment/spells/${payload.position}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
  })
  return result
}

const getGuildsEquipmentForPawn = async (
  pawnId,
) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`${API_URL}api/guilds/pawns/${pawnId}/equipment`, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-type': 'application/json'
    },
  });
  return responseParse(response);
}

const getEquipment = async (
  payload,
) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`${API_URL}api/equipment-constructor/items?limit=${payload.limit}&offset=${payload.offset}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-type': 'application/json'
    },
  });
  return responseParse(response);
}

const getGuildsEquipment = async (
  payload,
) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`${API_URL}api/guilds/equipment/items?${new URLSearchParams(payload)}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-type': 'application/json'
    },
  });
  if (response.status === 204) {
    return {
      status: 204,
      data: {
        results: [],
      },
    };
  } else {
    return responseParse(response)
  }
}

const postPawnGuildEquipment = async (
  payload,
) => {
  const token = localStorage.getItem('token')
  const response = await fetch(`${API_URL}api/guilds/pawns/${payload.pawnId}/equipment/items`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload.body),
  })
  if (response.status >= 400) {
    return responseParse(response)
  } else {
    return response;
  }
}

const deletePawnGuildEquipment = async (
  payload,
) => {
  const token = localStorage.getItem('token')
  const result = await fetch(`${API_URL}api/guilds/pawns/${payload.pawnId}/equipment/items/${payload.itemId}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
  })
  return result
}

const getItemById = async (
  id,
) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`${API_URL}api/equipment-constructor/items/${id}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-type': 'application/json'
    },
  });
  return responseParse(response);
}

const getSpellById = async (
  id,
) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`${API_URL}api/skills-constructor/spells/${id}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-type': 'application/json'
    },
  });
  return responseParse(response);
}

const postCreateEquipment = async (
  formatData,
) => {
  const token = localStorage.getItem('token');
  return await fetch(`${API_URL}api/equipment-constructor/items`, {
    method: 'POST',
    headers: {
      'accept': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: formatData,
  });
}

const getConsumables = async (
  payload,
) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`${API_URL}api/equipment-constructor/consumables?limit=${payload.limit}&offset=${payload.offset}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-type': 'application/json'
    },
  });
  return responseParse(response);
}

const getGuildsConsumables = async (
  payload,
) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`${API_URL}api/guilds/equipment/consumables?${new URLSearchParams(payload)}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-type': 'application/json'
    },
  });
  if (response.status === 204) {
    return {
      status: 204,
      data: {
        results: [],
      },
    };
  } else {
    return responseParse(response)
  }
}

const postPawnGuildConsumable = async (
  payload,
) => {
  const token = localStorage.getItem('token')
  const response = await fetch(`${API_URL}api/guilds/pawns/${payload.pawnId}/equipment/consumables`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload.body),
  })
  if (response.status >= 400) {
    return responseParse(response)
  } else {
    return response;
  }
}

const deletePawnGuildConsumable = async (
  payload,
) => {
  const token = localStorage.getItem('token')
  const result = await fetch(`${API_URL}api/guilds/pawns/${payload.pawnId}/equipment/consumables/${payload.consumableId}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
  })
  return result
}

const getConsumableById = async (
  id,
) => {
  const token = localStorage.getItem('token');
  const response = fetch(`${API_URL}api/equipment-constructor/consumables/${id}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-type': 'application/json'
    },
  });
  return responseParse(response);
}

const postCreateConsumable = async (
  formatData,
) => {
  const token = localStorage.getItem('token');
  return await fetch(`${API_URL}api/equipment-constructor/consumables`, {
    method: 'POST',
    headers: {
      'accept': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: formatData,
  });
}

const getSpells = async (
  payload,
) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`${API_URL}api/skills-constructor/spells?${new URLSearchParams(payload)}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-type': 'application/json'
    },
  });
  return responseParse(response)
}

// crafting

const postCrafting = async (
    body,
) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`${API_URL}api/crafting`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return responseParse(response)
}

// queryType - "marketplace" or "inventory"
const getListedItems = async (payload) => {
  const response = await fetch(`${API_URL}api/pawns/listed?${new URLSearchParams(payload)}`);
  return responseParse(response);
};

const getMyItems = async (payload) => {
  const response = await fetch(`${API_URL}api/pawns/my?${new URLSearchParams(payload)}`);
  return responseParse(response);
};

const getItem = async (
  itemId,
) => {
  const result = await fetch(`${API_URL}api/pawns/${itemId}`);
  return responseParse(result);
};

const getStats = async (pawnId) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`${API_URL}api/pawns/${pawnId}/stats`, {
    headers: {
      'accept': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });
  return responseParse(response);
}

const patchPawnStat = async (payload) => {
  const token = localStorage.getItem('token');
  return fetch(`${API_URL}api/pawns/${payload.id}/stats/${payload.stat}`, {
    method: 'PATCH',
    headers: {
      'accept': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });
}

const getGuilds = async (address) => {
  const token = localStorage.getItem('token')
  const result = await fetch(`${API_URL}api/guilds/presets?address=${address}`, { headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }})
  return responseParse(result);
}

const putPreset = async (
  body,
) => {
  const token = localStorage.getItem('token')
  const result = await fetch(`${API_URL}api/guilds/preset`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body),
  })
  return result
}

// const getEvmRequest = async () => {
//   const result = await fetch(`${API_URL}api/users/evm/address`);
//   console.log(result, 'some');
//   console.log(responseParse(result, 'some'));
//   return responseParse(result);
// };

const getEvmRequest = async (token) => {
  const result = await fetch(`${API_URL}api/users/evm/address`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
  return responseParse(result);
}

const postAddressRequest = async (
  address,
) => {
  const token = localStorage.getItem('token');
  const result = await fetch(`${API_URL}api/users/connect/evm/request`, {
    method: 'POST',
    headers: {
      'accept': 'application/json',
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({address: address}),
  })
  return responseParse(result);
}

const postUsersVerify = async (
  body,
) => {
  const token = localStorage.getItem('token');
  return await fetch(`${API_URL}api/users/connect/evm/verify`, {
    method: 'POST',
    headers: {
      'accept': 'application/json',
      'Authorization': `Bearer ${token}`,
      'Content-type': 'application/json'
    },
    body: JSON.stringify(body),
  });
}

const postDisconnect = async () => {
  const token = localStorage.getItem('token');
  const result = await fetch(`${API_URL}api/users/disconnect/evm`, {
    method: 'POST',
    headers: {
      'accept': 'application/json',
      'Authorization': `Bearer ${token}`,
      'Content-type': 'application/json'
    },
  })
  return await responseParse(result);
}

const postSpriteImg = async (
  data,
) => {
  const token = localStorage.getItem('token');
  const result = await fetch(`${API_URL}api/skills-constructor/image`, {
    method: 'POST',
    headers: {
      'accept': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: data,
  });
  return responseParse(result);
}

const postSound = async (
  data,
) => {
  const token = localStorage.getItem('token');
  const result = await fetch(`${API_URL}api/skills-constructor/sound`, {
    method: 'POST',
    headers: {
      'accept': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: data,
  });
  return responseParse(result);
}

/**
 *
 * @param batchId
 * @returns {Promise<{data: *, status: *}>}
 *
 * @example
 *
 * getBatchInfo(4)
 */
const getBatchInfo = async (
  batchId,
) => {
  const result = await fetch(`${API_URL}api/pawnbatch/${batchId}`);
  return responseParse(result);
};

const getContractInstance = (contract_address, contract_artifact) => {
  // eslint-disable-next-line no-underscore-dangle
  const _provider = new ethers.providers.Web3Provider(window.ethereum);
  const contract = new ethers.Contract(contract_address, contract_artifact, _provider.getSigner(0));

  return contract;
};

const listItem = async (nftId, priceInEth) => {
  const marketplaceContract = getContractInstance(MARKETPLACE_CONTRACT_ADDRESS, MarketplaceArtifact);
  const pawnContract = getContractInstance(PAWN_CONTRACT_ADDRESS,PawnArtifact);
  const wei = ethers.utils.parseEther(priceInEth);

  const isApprovedForAll = await pawnContract.isApprovedForAll(await getUserAddress(), MARKETPLACE_CONTRACT_ADDRESS);
  if(!isApprovedForAll) {
    const tx = await pawnContract.setApprovalForAll(MARKETPLACE_CONTRACT_ADDRESS, true);
    await tx.wait();
  }
  const placeOfferingTx = await marketplaceContract.placeOffering(PAWN_CONTRACT_ADDRESS, nftId, wei);
  placeOfferingTx.wait();
};

const cancelListing = async (offeringId) => {
  const marketplaceContract = getContractInstance(MARKETPLACE_CONTRACT_ADDRESS, MarketplaceArtifact);
  await marketplaceContract.cancelOffering(offeringId);
};

const claimNft = async (offeringId) => {
  const marketplaceContract = getContractInstance(MARKETPLACE_CONTRACT_ADDRESS, MarketplaceArtifact);
  const sanctumCoinContract = getContractInstance(COIN_CONTRACT_ADDRESS, SanctumCoinArtifact);
  const [nftContract, tokenId, price, closed] = await marketplaceContract.viewOfferingNFT(offeringId);
  const priceInWei = price.toString();
  const approvedTokens = await sanctumCoinContract.allowance(await getUserAddress(), MARKETPLACE_CONTRACT_ADDRESS);
  if(approvedTokens.gte(price)) {
      const tx = await marketplaceContract.closeOffering(offeringId);
      await tx.wait();
      return;
  }
  const approveTx = await sanctumCoinContract.approve(MARKETPLACE_CONTRACT_ADDRESS, priceInWei);
  await approveTx.wait();
  const closeOfferingTx = await marketplaceContract.closeOffering(offeringId);
  await closeOfferingTx.wait();
  console.log(closeOfferingTx);
};

const transferPawn = async (to, nftId) => {
  const pawnContract = getContractInstance(PAWN_CONTRACT_ADDRESS,PawnArtifact);
  const tx = await pawnContract.transferFrom(await getUserAddress(), to, nftId);
  const receipt = await tx.wait();
  return receipt.status;
};

const amIWhitelisted = async () => {
  const whiteMintContract = getContractInstance(WHITEMINT_CONTRACT_ADDRESS, WhiteMintArtifact);
  return await whiteMintContract.get(await getUserAddress());
};

const mintByAddress = async () => {
   const whiteMintContract = getContractInstance(WHITEMINT_CONTRACT_ADDRESS, WhiteMintArtifact);
   return await whiteMintContract.mint(await getUserAddress());
};

// params(offeringId, nftContract, offerer, tokenId, price)
const onNewOffering = async (callback) => {
  const _provider = new ethers.providers.Web3Provider(window.ethereum);
  const marketplaceContract = getContractInstance(MARKETPLACE_CONTRACT_ADDRESS, MarketplaceArtifact);
  _provider.once('block', () => {
    marketplaceContract.on('OfferingPlaced', callback);
  });
};

// params(offeringId, buyer, offerer, tokenId, price);
const onOfferingClosed = async (callback) =>  {
  const _provider = new ethers.providers.Web3Provider(window.ethereum);
  const marketplaceContract = getContractInstance(MARKETPLACE_CONTRACT_ADDRESS, MarketplaceArtifact);
  _provider.once('block', () => {
    marketplaceContract.on('OfferingClosed', callback);
  });
};

const onLogOut = async () => {
  const token = localStorage.getItem('token');
  const result = fetch(`${API_URL}api/users/logout`, {
    method: 'POST',
    headers: {
      'accept': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    credentials : 'include',
    mode : 'cors',
  });
  return result;
}


//params(offeringId, nftContract, tokenId)
const onOfferingCancelled = async (callback) =>  {
  const _provider = new ethers.providers.Web3Provider(window.ethereum);
  const marketplaceContract = getContractInstance(MARKETPLACE_CONTRACT_ADDRESS, MarketplaceArtifact);
  _provider.once('block', () => {
    marketplaceContract.on('OfferingCancelled', callback);
  });
};

export {
  getUserAddress, getMyItems, getListedItems, getItem, listItem, cancelListing, claimNft,
  onNewOffering, onOfferingClosed, onOfferingCancelled,
  getEquipment, getConsumables, postCreateEquipment, postCreateConsumable,
  getGuildsEquipmentForPawn, getGuildsConsumables, getGuildsEquipment,
  postPawnGuildEquipment, deletePawnGuildEquipment, getStats,
  postPawnGuildConsumable,deletePawnGuildConsumable,
  getBatchInfo, putPreset, getGuilds, isAuthorize, getEvmRequest, postAddressRequest, postUsersVerify,
  postDisconnect, getQuests, getQuestById, startQuest, putReward, mintByAddress, postSpriteImg,
  getSpells, getSpellById, putPawnSpell, deletePawnSpell, patchPawnStat, onLogOut, amIWhitelisted,
  transferPawn, postSound, postCrafting,
};
