import React from 'react';
import styles from './StatusBarHero.module.scss';
import uniqueId from '../../../../utils/uniqueId';
import { mageData } from '../../../../constants/mage';
import { archerData } from '../../../../constants/archer';
import { warriorData } from '../../../../constants/warrior';
import volatility from '../../../../static/img/mage/volatility.png';

const StatusBarHero = ({ buff, reverse, playerClass }) => {
  const chooseData = {
    Archer: archerData,
    Mage: mageData,
    Warrior: warriorData,
  };

  const userData = chooseData[playerClass];

  return (
    <div className={`${styles.wrapper} ${reverse ? styles.end : ''}`}>
      {buff && buff.length
        ? (
          <div className={styles.wrapper_buff}>
            {buff.map((item) => (
              <img
                width="32px"
                height="32px"
                key={uniqueId('statusBarHero')}
                src={item.parameter === 'Volatility' ? volatility : userData.spells[item.type].img}
                alt="buff"
              />
            ))}
          </div>
        )
        : null}
    </div>
  );
};

export default StatusBarHero;
