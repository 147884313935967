import { createSlice } from '@reduxjs/toolkit';

export const arena = createSlice({
  name: 'arena',
  initialState: {
    activeSkill: null,
    searchFight: false,
    acceptModalData: null,
    isOpponentAccepted: false,
    fight: null,
    fightActions: [],
    fightPawn: null,
    fightInfo: null,
    players: [],
    noPawnsError: false,
    inviteFrom: null,
    inviteTo: null,
    navigateTo: null,
    currentPlayerInfo: {},
    opponentPlayerInfo: {},
    opponentAddress: null,
    whoseTurn: null,
    results: [],
    tempResult: null,
    addressEnemy: null,
    turn: null,
    logTurn: null,
    log: {},
    lastTurn: null,
    currentPlayerPawns: [],
    opponentPlayerPawns: [],
    exp: null,
    spellDescriptionByPawn: {},
    currentPlayerSetsByPawn: {},
    opponentPlayerSetsByPawn: {},
  },
  reducers: {
    searchFight: (state, { payload }) => ({
      ...state,
      searchFight: payload,
    }),
    acceptFightModal: (state, { payload }) => ({
      ...state,
      acceptModalData: payload,
    }),
    waitingOpponent: (state, { payload }) => ({
      ...state,
      isOpponentAccepted: payload,
    }),
    setNoPawnsError: (state, { payload }) => ({
      ...state,
      noPawnsError: payload,
    }),
    setPlayers: (state, { payload }) => ({
      ...state,
      players: [...payload],
    }),
    removePlayer: (state, { payload }) => ({
      ...state,
      players: [...state.players.filter((i) => i.address !== payload.address)],
    }),
    addPlayer: (state, { payload }) => ({
      ...state,
      players: [...state.players, payload].reduce(
        (acc, curr) => {
          if (acc.find((i) => i.address === curr.address)) {
            return acc;
          }
          acc.push(curr);
          return acc;
        },
        [],
      ),
    }),
    setInviteFrom: (state, { payload }) => ({
      ...state,
      inviteFrom: payload,
    }),
    setInviteTo: (state, { payload }) => ({
      ...state,
      inviteTo: payload,
    }),
    setFight: (state, { payload }) => ({
      ...state,
      fight: {
        address: payload.address,
        fightId: payload.fightId,
      },
    }),
    clearFightAll: (state) => ({
      ...state,
      activeSkill: null,
      fight: null,
      fightActions: [],
      fightInfo: null,
      currentPlayerInfo: {},
      opponentPlayerInfo: {},
      whoseTurn: null,
      addressEnemy: null,
      turn: null,
      log: {},
      lastTurn: null,
      tempResult: null,
      currentPlayerPawns: [],
      opponentPlayerPawns: [],
      currentPlayerSetsByPawn: {},
      opponentPlayerSetsByPawn: {},
      exp: null,
    }),
    setNavigate: (state, { payload }) => ({
      ...state,
      navigateTo: payload,
    }),
    setFightPawn: (state, { payload }) => ({
      ...state,
      fightPawn: payload,
    }),
    setFightId: (state, { payload }) => ({
      ...state,
      fight: { ...state.fight, fightId: payload },
    }),
    setFightData(state, { payload }) {
      // console.log('------Set fight data', payload);
      state.lastTurn = payload.turn && payload.turn !== state.lastTurn ? payload.turn : state.lastTurn;
      state.fightActions = payload.actions || [];
      state.currentPlayerPawns = payload.currentPlayerPawns || [];
      state.opponentPlayerPawns = payload.opponentPlayerPawns || [];

      // if (payload.currentPlayerPawns) {
      //   // state.currentPlayerPawns = payload.currentPlayerPawns || [];
      //   state.currentPlayerPawns = payload.currentPlayerPawns.map(
      //     (p) => ({ ...p, spellDescription: payload.currentPlayerSpellsByPawn[p.id] }),
      //   ) || [];
      // }
      // if (payload.opponentPlayerPawns) {
      //   // state.opponentPlayerPawns = payload.opponentPlayerPawns || [];
      //   state.opponentPlayerPawns = payload.opponentPlayerPawns.map(
      //     (p) => ({ ...p, spellDescription: payload.opponentPlayerSpellsByPawn[p.id] }),
      //   ) || [];
      // }
      state.opponentAddress = payload.opponentAddress;
      state.turn = payload.turn;
      state.whoseTurn = payload.whoseTurn;
    },
    setSpellDescription(state, { payload }) {
      state.spellDescriptionByPawn = {
        ...payload.currentPlayerSpellsByPawn,
        ...payload.opponentPlayerSpellsByPawn,
      };
    },
    setSetsDescription(state, { payload }) {
      state.currentPlayerSetsByPawn = payload.currentPlayerSetsByPawn;
      state.opponentPlayerSetsByPawn = payload.opponentPlayerSetsByPawn;
    },
    setExpData(state, { payload }) {
      state.exp = payload;
    },
    // setFightData: (state, { payload }) => ({
    //   ...state,
    //   lastTurn: payload.turn && payload.turn !== state.lastTurn ? payload.turn : state.lastTurn,
    //   // log:
    //   //     payload.action
    //   //       ? payload.turn && payload.turn !== state.lastTurn
    //   //         ? [...state.log, { type: '_turn', message: `Turn ${payload.turn}` }]
    //   //         : [...state.log]
    //   //       : [...state.log],
    //   fightActions: payload.actions || [],
    //   currentPlayerPawns: payload.currentPlayerPawns || [],
    //   opponentPlayerPawns: payload.opponentPlayerPawns || [],
    //   opponentAddress: payload.opponentAddress,
    //   turn: payload.turn,
    //   whoseTurn: payload.whoseTurn,
    // }),
    setActiveSkill: (state, { payload }) => ({
      ...state,
      activeSkill: payload,
    }),
    setFightResult(state, { payload }) {
      state.results = [...state.results.splice(-4), payload];
      state.fightInfo = payload;
    },
    setTempFightResult(state, { payload }) {
      state.tempResult = payload;
    },
    setAddressEnemy: (state, { payload }) => ({
      ...state,
      addressEnemy: payload,
    }),
    clearActions: (state) => ({
      ...state,
      fightActions: [],
    }),
    setSpellToLog(state, action) {
      state.log[action.payload.turn] = state.log[action.payload.turn]
        ? [...state.log[action.payload.turn], action.payload]
        : action.payload.type === 'turn'
          ? []
          : [action.payload];
    },
  },
});
// ---- actions ----
export const {
  setPlayers, removePlayer, addPlayer,
  setNoPawnsError, setAddressEnemy,
  setInviteTo, setInviteFrom,
  setFight, clearActions,
  setFightPawn, setFightData,
  setNavigate, setActiveSkill,
  setFightResult, setTempFightResult, clearFightAll,
  setFightId, setLogTurn, searchFight,
  setSpellToLog, acceptFightModal, waitingOpponent, setExpData,
  setSpellDescription, setSetsDescription,
} = arena.actions;

// ---- selectors ----
export const selectPlayers = (state) => ({ players: state.arena.players });
export const selectNoPawnsError = (state) => ({ noPawnsError: state.arena.noPawnsError });
export const selectInviteFrom = (state) => ({ inviteFrom: state.arena.inviteFrom });
export const selectInviteTo = (state) => ({ inviteTo: state.arena.inviteTo });
export const selectNavigateTo = (state) => ({ navigateTo: state.arena.navigateTo });
export const selectFightPawn = (state) => ({
  currentPlayerPawns: state.arena.currentPlayerPawns,
  opponentPlayerPawns: state.arena.opponentPlayerPawns,
  currentPlayerSetsByPawn: state.arena.currentPlayerSetsByPawn,
  opponentPlayerSetsByPawn: state.arena.opponentPlayerSetsByPawn,
  turnData: state.arena.whoseTurn,
});

export const selectPlayersInfo = (state) => ({
  player: state.arena.currentPlayerInfo,
  opponent: state.arena.opponentPlayerInfo,
  whoseTurn: state.arena.whoseTurn,
});

export const selectIsActiveFight = (state) => (
  state.arena.fight?.fightId
);
export const selectIsPickPawnState = () => false;
// export const selectIsActiveFight = () => false;

export const selectSearchFight = (state) => state.arena.searchFight;
export const selectActiveSkill = (state) => state.arena.activeSkill;
export const selectFightResults = (state) => state.arena.results;
export const selectFightActions = (state) => state.arena.fightActions;
export const selectOpponentAddress = (state) => state.arena.opponentAddress;
export const selectAddressEnemy = (state) => state.arena.addressEnemy;
export const selectTurn = (state) => state.arena.turn;
export const selectFightInfo = (state) => state.arena.fightInfo;

export const selectLog = (state) => state.arena.log;
export const selectExp = (state) => state.arena.exp;

export const selectFightId = (state) => state.arena.fight?.fightId;

export const selectAcceptFightData = (state) => state.arena.acceptModalData;
export const selectIsOpponentAccepted = (state) => state.arena.isOpponentAccepted;

export const selectFightState = (state) => ({
  fightActions: state.arena.fightActions,
  turn: state.arena.turn,
  fightPawns: [state.arena.currentPlayerPawns, state.arena.opponentPlayerPawns],
});

export const selectTempResult = (state) => (state.arena.tempResult);

export const selectFightPawns = (state) => ([state.arena.currentPlayerPawns, state.arena.opponentPlayerPawns]);

export const selectSpellDescriptionByPawn = (state) => state.arena.spellDescriptionByPawn;
