import React, { useState, useEffect } from 'react';
import { gifsArray } from '../../../../../constants/stats';
import styles from './MintCard.module.scss';

const MintCard = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentIndex === gifsArray.length - 1) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex(currentIndex + 1);
      }
    }, 3000);
    return () => clearInterval(intervalId);
  }, [currentIndex]);

  return <img className={styles.img_content} src={gifsArray[currentIndex]} alt="pawn" />;
};

export default MintCard;
