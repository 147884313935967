import React, { useState, useEffect } from 'react';
import { itemArray } from '../../../constants/stats';

const PotentialRewards = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentIndex === itemArray.length - 1) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex(currentIndex + 1);
      }
    }, 3000);
    return () => clearInterval(intervalId);
  }, [currentIndex]);

  return (<img src={itemArray[currentIndex]} alt="img" />);
};

export default PotentialRewards;
