export const DEFAULT_FRAME_RATE = 10;

export function getPawnConfigByKey(pawns, pawnKey) {
  return pawns.find((p) => p.key.toLowerCase() === pawnKey.toLowerCase());
}

export function getSpriteByName(pawnConfig, spriteKey) {
  return pawnConfig.sprites.find((s) => s.key.toLowerCase() === spriteKey.toLowerCase());
}

export function getSpriteOrigin(sprite, isCurrentPlayer) {
  return isCurrentPlayer ? sprite.originCurrent : sprite.originOpponent;
}
