import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Sound from '../Sound';
import Chat from '../Chat';
import { selectOpenChat, selectOpenChatInput, setOpenChatInput } from '../../store/slices/chat';
import styles from './Wrapper.module.scss';
import Social from '../../pages/Social';

const Wrapper = ({ children }) => {
  const dispatch = useDispatch();
  const isInputOpen = useSelector(selectOpenChatInput);
  const isOpen = useSelector(selectOpenChat);

  const upHandler = (event) => {
    if (event.code === 'Enter' && !isInputOpen && !isOpen) {
      dispatch(setOpenChatInput(true));
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keyup', upHandler);
    };
  }, [window, isOpen, isInputOpen]);

  return (
    <div className={styles.wrap}>
      <Social />
      { children }
      <Sound />
      <div className={`${styles.chat} ${isInputOpen ? styles.active : ''}`}>
        <Chat />
      </div>
    </div>
  );
};

export default Wrapper;
