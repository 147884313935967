import logo from '../static/img/archer/archer_icon.png';
import shoot from '../static/img/archer/shoot.png';
import trap from '../static/img/archer/trap.png';
import bolaTrow from '../static/img/archer/bola_throw.png';
import legShot from '../static/img/archer/leg_shot.png';
import headshot from '../static/img/archer/headshot.png';
import throwingKnife from '../static/img/archer/throwing_knife.png';

export const archerData = {
  logo,
  spells: {
    shoot: {
      cost: {
        count: 25,
        type: 'focus',
      },
      type: 'secondary',
      quickness: 'normal',
      application: 'end_opponent',
      cooldown: 0,
      description: '(1 * WD)',
      img: shoot,
    },
    throwing_knife: {
      cost: {
        count: 50,
        type: 'focus',
      },
      type: 'primary',
      quickness: 'quick',
      application: 'immediately',
      cooldown: 0,
      description: '(1.5 * WD), 20% chance to do 2x damage',
      img: throwingKnife,
    },
    trap: {
      cost: {
        count: 35,
        type: 'focus',
      },
      type: 'secondary',
      quickness: 'quick',
      application: 'immediately',
      cooldown: 3,
      description: 'Applies (player) buff : "Bear Trap"',
      img: trap,
    },
    bola_throw: {
      cost: {
        count: 35,
        type: 'focus',
      },
      type: 'secondary',
      quickness: 'quick',
      application: 'immediately',
      cooldown: 5,
      description: 'Reduces your opponent\'s speed by 40%',
      img: bolaTrow,
    },
    leg_shot: {
      cost: {
        count: 35,
        type: 'focus',
      },
      type: 'primary',
      quickness: 'normal',
      application: 'end_opponent',
      cooldown: 0,
      description: '(1 * WD), -25 speed',
      img: legShot,
    },
    head_shot: {
      cost: {
        count: 50,
        type: 'focus',
      },
      type: 'primary',
      quickness: 'normal',
      application: 'end_opponent',
      cooldown: 0,
      description: '(5 * WD)',
      img: headshot,
    },
  },
};
