import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import styles from './FightHistory.module.scss';
import uniqueId from '../../../utils/uniqueId';
import { selectLog, selectSpellDescriptionByPawn } from '../../../store/slices/arena';

const FightHistory = ({ address, pawns }) => {
  const bottomRef = useRef(null);

  const log = useSelector(selectLog);
  const spells = useSelector(selectSpellDescriptionByPawn);

  const fightEvent = {
    Buff: 'used buff',
    Damage: 'damaged',
    Dodged: 'dodged',
    Casting: 'casts',
    Other: 'used',
  };

  const selectName = (id) => {
    const index = pawns.map((object) => object.id).indexOf(id);
    const find = pawns?.[index]?.name.indexOf('_');
    return pawns?.[index]?.name.slice(0, find) || '';
  };

  const chooseType = (elem) => {
    if (elem.type === 'Skip') {
      return (
        <>
          <span className={address === elem.from.address ? styles.auth : styles.enemy}>
            {elem.from.address === 'BOT'
              ? 'BOT'
              : selectName(elem.from.id)}
          </span>
          <span className={styles.event}>
            {' '}
            Skipped Turn
          </span>
        </>
      );
    }

    return (
      <>
        <span className={address === elem.from.address ? styles.auth : styles.enemy}>
          {elem.from.address === 'BOT'
            ? 'BOT'
            : selectName(elem.from.id)}
        </span>
        <span className={styles.event}>
          {' '}
          {fightEvent[elem?.type] || ''}
          {' '}
        </span>
        <span className={styles.buff}>
          {spells[elem.from.id]?.[elem?.spell]?.spellName?.replace('_', ' ') || ''}
        </span>
        <span className={styles.red}>
          {' '}
          {elem?.damage ? `${elem.damage} DMG` : ''}
        </span>
      </>
    );
  };

  useEffect(() => {
    bottomRef.current.scrollTo(0, bottomRef.current.scrollHeight);
  }, [log]);

  return (
    <div className={styles.wrapper} ref={bottomRef}>
      <div className={styles.message}>
        {Object.keys(log).length ? Object.keys(log).map((part, index) => (
          <div style={{ width: '100%' }} key={uniqueId('fightHistory')}>
            <div className={styles.turn_message}>{`Turn ${Object.keys(log)[index]}`}</div>
            {log[part].map((elem) => (
              <div key={uniqueId('fightHistory')} className={styles.group_events}>
                {chooseType(elem)}
              </div>
            ))}
          </div>
        ))
          : (
            <div className={styles.started}>
              <span>Battle has started!</span>
            </div>
          )}
      </div>
    </div>
  );
};

export default FightHistory;
