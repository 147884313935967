import { createSlice } from '@reduxjs/toolkit';
import { notificationShowAction } from '../utils';
import { postCrafting } from '../../../utils/requests';
import { addConsumableAfterCrafting, addEquipmentAfterCrafting } from '../constructor';

export const crafting = createSlice({
  name: 'crafting',
  initialState: {
    craftingItems: {
      firstItem: null,
      secondItem: null,
      error: null,
    },
    loading: false,
    craftingResult: null,
    error: null,
  },
  reducers: {
    craftingRequest: (state) => ({
      ...state,
      loading: true,
    }),
    setItemForCrafting: (state, { payload }) => ({
      ...state,
      craftingItems: !state.craftingItems.firstItem ? {
        ...state.craftingItems,
        firstItem: payload,
      } : {
        ...state.craftingItems,
        secondItem: payload,
      },
    }),
    removeSetupItem: (state, { payload }) => ({
      ...state,
      craftingItems: {
        ...state.craftingItems,
        [payload]: null,
      },
    }),
    craftingSuccess: (state, { payload }) => ({
      ...state,
      craftingResult: payload,
      error: null,
      loading: false,
      craftingItems: {
        firstItem: null,
        secondItem: null,
        error: null,
      },
    }),
    craftingFailure: (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }),
    clearSetup: (state) => ({
      ...state,
      craftingItems: {
        firstItem: null,
        secondItem: null,
      },
    }),
    clearCraftingResult: (state) => ({
      ...state,
      craftingResult: null,
    }),
  },
});

export const {
  setItemForCrafting,
  clearSetup,
  removeSetupItem,
  craftingRequest,
  craftingFailure,
  craftingSuccess,
  clearCraftingResult,
} = crafting.actions;

export const craftingAction = (payload) => async (dispatch) => {
  try {
    dispatch(craftingRequest());
    const response = await postCrafting(payload.data);
    if (response.status >= 400) {
      dispatch(notificationShowAction(false, response?.data?.detail || 'Something went wrong'));
      dispatch(craftingFailure(response.data));
    } else if (response.status <= 204) {
      dispatch(craftingSuccess(response.data));
      console.log(response.data);
      setTimeout(() => {
        if (payload.type === 'Equipment') {
          dispatch(addEquipmentAfterCrafting(response.data));
        }
        if (payload.type === 'Items') {
          dispatch(addConsumableAfterCrafting(response.data));
        }
      }, 5000);
    }
  } catch (e) {
    console.log(e);
  }
};

export const selectCraftingSetup = (state) => ({
  firstPosition: state.crafting.craftingItems.firstItem,
  secondPosition: state.crafting.craftingItems.secondItem,
});

export const selectLoadingCrafting = (state) => state.crafting.loading;

export const selectCraftingResult = (state) => state.crafting.craftingResult;
