import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import mage from '../../static/img/mage/mage-level1-idle.gif';
import warrior from '../../static/img/warrior/warrior-level1-idle.gif';
import archer from '../../static/img/archer/archer-level1-idle.gif';
import levelImg from '../../static/img/endofbattle/Mission_02.png';
import styles from './Experience.module.scss';

let animCount = 1;
const expArray = [0, 250, 275, 302, 332, 365, 401, 441, 485, 533, 586, 644, 708, 778, 855, 940, 1034, 1137, 1250, 1375,
  1512, 1663, 1829, 2011, 2212, 2433, 2676, 2943, 3237, 3560, 3916, 4307, 4737, 5210, 5731, 6304, 6934, 7627, 8389,
  9227, 10149, 11163, 12279, 13506, 14856, 16341, 17975, 19772, 21749, 23923, 26315, 28946, 31840, 35024, 38526, 42378,
  46615, 51276, 56403, 62043, 68247, 75071, 82578, 90835, 99918, 109909, 120899, 132988, 146286, 160914, 177005, 194705,
  214175, 235592, 259151, 285066, 313572, 344929, 379421, 417363, 459099, 505008, 555508, 611058, 672163, 739379,
  813316, 894647, 984111, 1082522, 1190774, 1309851, 1440836, 1584919, 1743410, 1917751, 2109526, 2320478, 2552525];

const ProgressBar = ({ exp }) => (
  <div style={{ width: `${exp}px` }} className={styles.progress} />
);

export const selectPawnAnimation = ({
  Mage: mage,
  Warrior: warrior,
  Archer: archer,
});

const Experience = ({
  pawnClass, newLevel, name = 'Grisha', pawnLevel = 1, startExp = 50, expGain = 1000,
}) => {
  // eslint-disable-next-line max-len
  const [exp, setExp] = useState(Math.floor(((startExp - expArray.slice(0, pawnLevel).reduce((acc, val) => acc + val, 0)) * 100) / expArray[pawnLevel]) * 2);
  const [levelToDisplay, setLevelToDisplay] = useState(pawnLevel);
  const [erase, setErase] = useState(false);
  const some = (newExp, level) => {
    let result;
    if (animCount === 1) {
      result = startExp - expArray.slice(0, pawnLevel).reduce((acc, val) => acc + val, 0);
    } else {
      result = 0;
    }
    if (newExp + result < expArray[level]) {
      setExp(Math.floor(((result + newExp) * 100) / expArray[level]) * 2);
    } else {
      animCount += 1;
      const ost = newExp - (expArray[level] - result);
      setExp(200);
      setTimeout(() => {
        setErase(true);
      }, 1000);
      setTimeout(() => {
        setExp(0);
        setErase(false);
        setLevelToDisplay(level + 1);
        setTimeout(() => {
          some(ost, level + 1);
        }, 10);
      }, 1010);
    }
  };

  useEffect(() => {
    some(expGain, pawnLevel);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.name}>
        {name}
      </div>
      <img src={selectPawnAnimation[pawnClass]} alt="" />
      <div className={styles.level_wrapper}>
        <div>
          <div className={styles.inventory}>
            <div className={styles.corner_inventory_top_left} />
            <div className={styles.border_inventory_top} />
            <div className={styles.corner_inventory_top_right} />
            <div className={styles.border_inventory_left} />
            <div className={styles.progress_div} style={{ width: '200px' }}>
              {!erase && <ProgressBar exp={exp} pawnLevel={pawnLevel} />}
            </div>
            <div className={styles.border_inventory_right} />
            <div className={styles.corner_inventory_bottom_left} />
            <div className={styles.border_inventory_bottom} />
            <div className={styles.corner_inventory_bottom_right} />
          </div>
        </div>
        <div className={styles.level_block}>
          <img src={levelImg} alt="" width="36px" height="40px" />
          <span>{levelToDisplay}</span>
        </div>
      </div>
      <CountUp
        className={styles.name}
        end={expGain}
        duration={newLevel - pawnLevel === 0 ? 1 : newLevel - pawnLevel + 1}
        prefix="+ "
        suffix=" EXP"
      />
    </div>
  );
};

export default Experience;
