import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Portrait.module.scss';
import loader from '../../../../../static/img/loader.gif';
import SetPortraitModal from '../SetPortraitModal';
import { clearPortrait, getPortraitsAction, selectIsNewPortraitSet } from '../../../../../store/slices/portraits';

const Portrait = ({ info, loading }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const isNewPortraitSet = useSelector(selectIsNewPortraitSet);

  useEffect(() => {
    if (isNewPortraitSet) {
      dispatch(clearPortrait());
      setShowModal(false);
    }
  }, [isNewPortraitSet]);

  return (
    <>
      {showModal
        ? <SetPortraitModal setShowModal={setShowModal} />
        : null}
      <div className={styles.portrait}>
        <div className={styles.portrait_top_left} />
        <div className={styles.portrait_top} />
        <div className={styles.portrait_top_right} />
        <div className={styles.portrait_left} />
        <div className={styles.portrait_content}>
          {loading && !info?.image
            ? <img src={loader} alt="loading" />
            : (
              <img
                src={`https://i.bimium.link/${info?.image}`}
                alt="portrait"
                role="presentation"
                onClick={() => {
                  dispatch(getPortraitsAction({
                    limit: 300,
                    offset: 0,
                    sort_by: 'Default',
                    order_by: 'Asc',
                  }));
                  setShowModal(true);
                }}
              />
            )}
        </div>
        <div className={styles.portrait_right} />
        <div className={styles.portrait_bottom_left} />
        <div className={styles.portrait_bottom} />
        <div className={styles.portrait_bottom_right} />
      </div>
    </>
  );
};

export default Portrait;
