export default () => {
  const colorsArray = [
    '#2F4F4FFF',
    '#2e3547',
    '#714c33',
    '#dec89c',
    '#627013',
    '#6e7726',
    '#2b7726',
    '#176013',
    '#123810',
    '#058d5d',
    '#5c8a7b',
    '#6d7570',
    '#01617c',
    '#4d7281',
    '#0088b4',
    '#02389d',
    '#0054cc',
    '#5c00cc',
    '#523870',
    '#853872',
    '#5d0149',
    '#d94a81',
    '#b70140',
    '#5b0029',
  ];
  return colorsArray[Math.floor(Math.random() * colorsArray.length)];
};
