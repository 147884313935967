import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import uniqueId from '../../../../utils/uniqueId';
import { selectAllPawnStats, selectStatsLoading } from '../../../../store/slices/pawnSelect';
import loader from '../../../../static/img/loader.gif';
import styles from './Tooltip.module.scss';

const Tooltip = ({ name }) => {
  const loading = useSelector(selectStatsLoading);
  const pawnStats = useSelector(selectAllPawnStats);

  const tooltipContent = useMemo(() => {
    if (loading) {
      return (
        <div className={styles.loader}>
          <img src={loader} alt="loading..." width="44px" height="44px" />
        </div>
      );
    }
    if (Object.keys(pawnStats).length) {
      return (
        Object.keys(pawnStats).map((item) => (
          <div className={styles.stat} key={uniqueId('pawn_stats')}>
            <span className={styles.name_stat}>{item?.replace('_', ' ')}</span>
            <div className={styles.stat_item}>
              {pawnStats[item]}
            </div>
          </div>
        ))
      );
    }
    return null;
  }, [loading]);

  return (
    <div
      role="presentation"
      className={styles.tooltip}
    >
      <div className={styles.tooltip_top_left} />
      <div className={styles.tooltip_top} />
      <div className={styles.tooltip_top_right} />
      <div className={styles.tooltip_left} />
      <div className={styles.tooltip_content}>
        <div className={styles.head}>
          <div className={styles.head_left}>
            <div className={styles.title}>
              {name?.split('_').join(' ')}
            </div>
            <div className={styles.separator} />
            {tooltipContent}
          </div>
        </div>
      </div>
      <div className={styles.tooltip_right} />
      <div className={styles.tooltip_bottom_left} />
      <div className={styles.tooltip_bottom} />
      <div className={styles.tooltip_bottom_right} />
    </div>
  );
};

export default Tooltip;
