import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styles from './BattleWindow.module.scss';
import StatusBar from './StatusBar/StatusBar';
import TurnControl from './TurnControl/TurnControl';
import chain from '../../../static/img/battleWindow/Bottom separator.png';
import Inspect from './Inspect';
import { selectFightInfo, selectFightPawn, selectIsActiveFight } from '../../../store/slices/arena';
import Timer from './Timer';
import FightHistory from '../../Arena/FightHistory';
import Test from '../index';
import Header from '../../../components/Marketplace/Components/Header';
import EndOfBattle from '../../../components/EndOfBattle';
import { selectEvm } from '../../../store/slices/auth';

const BattleWindow = () => {
  const navigate = useNavigate();

  const [chosenWindow, setChosenWindow] = useState('log');

  const [selectedPawn, setSelectedPawn] = useState();
  const [activePawn, setActivePawn] = useState();

  const address = useSelector(selectEvm);
  const {
    currentPlayerPawns, opponentPlayerPawns, turnData,
    currentPlayerSetsByPawn, opponentPlayerSetsByPawn,
  } = useSelector(selectFightPawn);
  const isActiveFight = useSelector(selectIsActiveFight);
  const result = useSelector(selectFightInfo);

  useEffect(() => {
    if (currentPlayerPawns && currentPlayerPawns.length && opponentPlayerPawns && opponentPlayerPawns.length) {
      if (!selectedPawn) {
        setSelectedPawn(opponentPlayerPawns[0]);
      } else {
        setSelectedPawn(opponentPlayerPawns.filter((elem) => elem.id === selectedPawn.id)[0]);
      }
    }
  }, [currentPlayerPawns, opponentPlayerPawns]);

  useEffect(() => {
    if (currentPlayerPawns) {
      setActivePawn(currentPlayerPawns[0]);
    }
  }, []);

  useEffect(() => {
    if (turnData?.id) {
      const pawn = currentPlayerPawns.find((elem) => elem.id === turnData.id);
      if (pawn) {
        setActivePawn(pawn);
      }
    }
  }, [turnData]);

  const onPawnSelect = (id) => {
    if (opponentPlayerPawns.length && id) {
      setSelectedPawn(opponentPlayerPawns.find((p) => p.id === id) || currentPlayerPawns.find((p) => p.id === id));
    }
  };

  useEffect(() => {
    if (!isActiveFight) {
      navigate('/arena-hub');
    }
  }, [isActiveFight]);

  return (
    <>
      <Header address={address} />
      <div className={styles.mainWindow}>
        <div className={styles.wrapper}>
          <div className={styles.timer}>
            <div className={styles.time}>
              <Timer />
            </div>
          </div>
          <div className={styles.container}>
            <div className={styles.battleSceneContainer}>
              <Test
                selectedPawn={selectedPawn}
                currentPlayerPawns={currentPlayerPawns}
                opponentPlayerPawns={opponentPlayerPawns}
                currentPlayerSetsByPawn={currentPlayerSetsByPawn}
                opponentPlayerSetsByPawn={opponentPlayerSetsByPawn}
                current
                onPawnSelect={onPawnSelect}
              />
            </div>
            <div className={styles.interaction_section}>
              <div className={styles.chain_separator}>
                <img src={chain} alt="" />
              </div>
              <div className={styles.interaction_section_container}>
                <div className={styles.player}>
                  {activePawn
                    ? <StatusBar activePawn={activePawn} target={selectedPawn?.id} address={address} />
                    : null}
                </div>
                <div className={styles.enemy}>
                  <div className={styles.enemy_block}>
                    <div className={styles.enemy_block_nav}>
                      <div
                        className={`
                      ${styles.log_btn}
                      ${chosenWindow === 'log' ? styles.log_btn_active : ''}
                      `}
                        onClick={() => setChosenWindow('log')}
                        role="presentation"
                      >
                        Log
                      </div>
                      <div
                        className={`
                      ${styles.inspect_btn}
                      ${chosenWindow === 'inspect' ? styles.inspect_btn_active : ''}
                      `}
                        onClick={() => setChosenWindow('inspect')}
                        role="presentation"
                      >
                        Inspect
                      </div>
                    </div>
                    <div className={styles.info}>
                      {chosenWindow === 'log'
                        ? <FightHistory address={address} pawns={[...currentPlayerPawns, ...opponentPlayerPawns]} />
                        : <Inspect target={selectedPawn} />}
                    </div>
                  </div>
                  <div className={styles.turn}>
                    <TurnControl />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {result ? <EndOfBattle pawns={currentPlayerPawns} /> : null}
      </div>
    </>
  );
};

export default BattleWindow;
