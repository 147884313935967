import React from 'react';
import { useSelector } from 'react-redux';
import armor from '../../../../static/img/battleWindow/armor.png';
import speed from '../../../../static/img/battleWindow/speed.png';
import dodge from '../../../../static/img/battleWindow/dodge.png';
// import resist from '../../../../static/img/battleWindow/resist.png';
import styles from './Inspect.module.scss';
import { chooseData } from '../../../../utils/chooseData';
import uniqueId from '../../../../utils/uniqueId';
import { selectSpellDescriptionByPawn } from '../../../../store/slices/arena';

const Inspect = ({ target }) => {
  const spells = useSelector(selectSpellDescriptionByPawn);
  return (
    <div className={styles.wrapper}>
      <div className={styles.head}>
        <div className={styles.head_left}>
          <div className={styles.points}>
            <span>{`HP ${target.health}/${target.maxHealth}`}</span>
            <span>{`MP ${target.actionPoints}/${target.maxActionPoints}`}</span>
          </div>
          <div className={styles.stats}>
            <div>
              <img src={armor} alt="" />
              <span>{target.armor}</span>
            </div>
            <div>
              <img src={speed} alt="" />
              <span>{target.speed}</span>
            </div>
            <div>
              <img src={dodge} alt="" />
              <span>{target.dodge}</span>
            </div>
            {/* <div> */}
            {/*   <img src={resist} alt="" /> */}
            {/*   <span>20</span> */}
            {/* </div> */}
          </div>
        </div>
        <div className={styles.info}>
          <div className={styles.who}>
            <div className={styles.name}>
              {target.name}
            </div>
            <div className={styles.divider} />
            <div className={styles.class}>
              {target.class}
            </div>
          </div>
          <div className={styles.logo}>
            <img src={chooseData[target.class].logo} alt="" />
          </div>
        </div>
      </div>
      <div>
        <div className={styles.effects}>
          {
            Object.keys(spells[target.id]).map((elem) => (
              <div
                // className={`${styles.effects_block} ${activeSkill?.name === elem ? styles.selected_skill : ''}`}
                key={uniqueId('statusBarSpells')}
              >
                <div
                  role="presentation"
                  className={styles.img_wrapper}
                >
                  <img
                    role="presentation"
                    src={spells[target.id][elem].image}
                    alt=""
                  />
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default Inspect;
