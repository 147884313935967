import { HubConnectionBuilder } from '@microsoft/signalr';
import { store } from '../store';
import {
  acceptFightModal,
  addPlayer,
  removePlayer, searchFight, setAddressEnemy, setExpData,
  setFight,
  setFightData, setFightId,
  setFightResult,
  setInviteFrom,
  setInviteTo, setLogTurn,
  setNoPawnsError,
  setPlayers, setSpellDescription, setTempFightResult, waitingOpponent, setSetsDescription,
} from '../store/slices/arena';
// import { notificationShowAction } from '../store/slices/utils';
import { API_URL } from '../constants/constants';
import {
  setArenaChat, setErrorChat, setFightChat, setNewMessage,
} from '../store/slices/chat';
import { notificationShowAction } from '../store/slices/utils';

let connection = null;

let lastTurn = 0;
let whoseTurn = null;
let prevCurrentPlayerPawns = [];
let prevOpponentPlayerPawns = [];

const fightStartedInit = () => {
  lastTurn = 0;
  whoseTurn = null;
  prevCurrentPlayerPawns = [];
  prevOpponentPlayerPawns = [];
};

export const connectSocket = (address, accessToken = localStorage.getItem('token')) => {
  if (connection) {
    connection.stop();
  }

  const url = `${API_URL}gamehub?address=${address}`;

  connection = new HubConnectionBuilder()
    .withUrl(url, { withCredentials: true, accessTokenFactory: () => accessToken })
    .build();

  connection.start().catch((err) => {
    console.error(err.toString());
  });

  connection.on('NotValidAddress', () => {
    console.log('NotValidAddress - you will be disconnected');
  });

  connection.on('UserHasNotPawns', () => {
    store.dispatch(setNoPawnsError(true));
  });

  connection.on('ArenaPlayers', (serverObj) => {
    console.log('ArenaPlayers: ', serverObj);
    store.dispatch(setPlayers(serverObj));
  });

  connection.on('MyInfo', (serverObj, fightInfo) => {
    // store.dispatch(notificationShowAction(false, 'GetMy info OK'));
    console.log('MyInfo: ', serverObj, fightInfo);
    store.dispatch(setFight({
      address: serverObj.address || null,
      fightId: serverObj.fightId,
    }));
    if (serverObj.status === 'FindingFight') {
      store.dispatch(searchFight(true));
    }
    if (serverObj.status === 'FightFound') {
      store.dispatch(acceptFightModal(serverObj.foundFight));
      if (serverObj.foundFight.accepted) {
        store.dispatch(waitingOpponent(true));
      }
    }
    if (fightInfo) {
      // fightInfo.currentPlayerSpellsByPawn
      // fightInfo.opponentPlayerSpellsByPawn
      store.dispatch(setFightData(fightInfo));
      store.dispatch(setSpellDescription(fightInfo));
      store.dispatch(setSetsDescription(fightInfo));
      store.dispatch(setAddressEnemy(fightInfo.opponentAddress));
    }
    if (serverObj?.address) {
      store.dispatch(setLogTurn({
        address: serverObj.address,
        turn: null,
      }));
    }
  });

  connection.on('EnteredToTheArena', (serverObj) => {
    console.log('EnteredToTheArena: ', serverObj);
    store.dispatch(addPlayer(serverObj));
  });

  connection.on('LeftFromTheArena', (serverObj) => {
    console.log('LeftFromTheArena: ', serverObj);
    store.dispatch(removePlayer(serverObj));
  });

  connection.on('FightRequest', (serverObj) => {
    console.log('FightRequest: ', serverObj);
    if (serverObj.fromAddress) {
      store.dispatch(setInviteFrom(serverObj.fromAddress));
      store.dispatch(setAddressEnemy(serverObj.fromAddress));
      store.dispatch(setArenaChat({
        ...serverObj,
        fightRequest: true,
        message: 'You are called to fight!!!',
      }));
    }
    if (serverObj.toAddress) {
      store.dispatch(setInviteTo(serverObj.toAddress));
      store.dispatch(setAddressEnemy(serverObj.toAddress));
    }
  });

  connection.on('FightRequestFailed', (serverObj) => {
    console.log('FightRequestFailed: ', serverObj);
    store.dispatch(
      notificationShowAction(
        false,
        serverObj.message,
      ),
    );
  });

  connection.on('FightRequestCancelled', (serverObj) => {
    console.log('FightRequestCancelled: ', serverObj);
    const error = (err) => {
      console.error(err.toString());
    };
    const handleRejectFight = (opponentAddress) => {
      connection
        .invoke('RejectFightRequest', opponentAddress)
        .catch(error);
    };
    handleRejectFight(serverObj.fromAddress);
    store.dispatch(setInviteFrom(null));
  });

  connection.on('CancelFightRequestFailed', (serverObj) => {
    console.log('CancelFightRequestFailed: ', serverObj);
  });

  connection.on('FightStarted', (serverObj) => {
    console.log('FightStarted: ', serverObj);
    fightStartedInit();
    store.dispatch(acceptFightModal(null));
    store.dispatch(waitingOpponent(false));
    store.dispatch(setFightData(serverObj));
    store.dispatch(setFightId(serverObj.fightId));
    store.dispatch(setSpellDescription(serverObj));
    store.dispatch(setSetsDescription(serverObj));
  });

  connection.on('FightUpdated', (serverObj) => {
    console.log('FightUpdated: ', serverObj, serverObj.turn, lastTurn);
    whoseTurn = serverObj?.whoseTurn || whoseTurn;
    if (serverObj.turn !== lastTurn) {
      lastTurn = serverObj.turn;
      // console.log('SHEET WITHOUT SPELL', serverObj, );
      store.dispatch(setFightData({
        ...serverObj,
        whoseTurn,
        currentPlayerPawns: prevCurrentPlayerPawns,
        opponentPlayerPawns: prevOpponentPlayerPawns,
        actions: [{
          type: 'turn',
          from: serverObj.action.from,
          to: serverObj.action.to,
          turn: serverObj.turn,
        }],
        address: whoseTurn.address,
        turn: serverObj.turn,
      }));
    }
    store.dispatch(setFightData({
      ...serverObj,
      whoseTurn,
      actions: serverObj.action ? [{ ...serverObj.action, turn: serverObj.turn }] : [],
      action: serverObj.action,
      address: whoseTurn.address,
      turn: serverObj.turn,
    }));

    prevCurrentPlayerPawns = serverObj.currentPlayerPawns;
    prevOpponentPlayerPawns = serverObj.opponentPlayerPawns;
  });

  connection.on('YouWonFight', (serverObj) => {
    console.log('YouWonFight: ', serverObj);
    setTimeout(() => {
      store.dispatch(setTempFightResult('won'));
      store.dispatch(setExpData(serverObj));
    }, 1000);
  });

  connection.on('YouLostFight', (serverObj) => {
    console.log('YouLostFight: ', serverObj);
    setTimeout(() => {
      store.dispatch(setTempFightResult('lost'));
      store.dispatch(setExpData(serverObj));
    }, 1000);
  });

  connection.on('DrawInFight', (serverObj) => {
    console.log('DrawInFight: ', serverObj);
    setTimeout(() => {
      store.dispatch(setTempFightResult('draw'));
      store.dispatch(setExpData(serverObj));
    }, 1000);
  });

  connection.on('AcceptFightRequestFailed', (serverObj) => {
    console.log('AcceptFightRequestFailed: ', serverObj);
  });

  connection.on('FightRequestRejected', (serverObj) => {
    console.log('FightRequestRejected: ', serverObj);
    if (serverObj.fromAddress) {
      store.dispatch(setInviteFrom(null));
    }
    if (serverObj.toAddress) {
      store.dispatch(setInviteTo(null));
    }
  });

  connection.on('RejectFightRequestFailed', (serverObj) => {
    console.log('RejectFightRequestFailed: ', serverObj);
  });

  connection.on('FightRequestAccepted', (serverObj) => {
    console.log('FightRequestAccepted: ', serverObj);
    store.dispatch(setFight(serverObj));
    // if (serverObj.toAddress) {
    //   store.dispatch(setNavigate('/pick'));
    // }
    store.dispatch(setInviteFrom(null));
    store.dispatch(setInviteTo(null));
  });

  connection.on('ArenaMessageReceived', async (serverObj) => {
    store.dispatch(setArenaChat({
      ...serverObj,
      date: [new Date().getHours(), new Date().getMinutes()].map((x) => (x < 10 ? `0${x}` : x)).join(':'),
    }));
    console.log(serverObj, 'ArenaMessageReceived');
    if (address?.toLowerCase() !== serverObj?.address?.toLowerCase()) {
      store.dispatch(setNewMessage(true));
    }
  });

  connection.on('FightMessageReceived', (serverObj) => {
    console.log('FightMessageReceived: ', serverObj);
    store.dispatch(setFightChat({
      ...serverObj,
      date: [new Date().getHours(), new Date().getMinutes()].map((x) => (x < 10 ? `0${x}` : x)).join(':'),
    }));
    store.dispatch(setNewMessage(true));
  });

  connection.on('SendMessageToTheFightFailed', (serverObj) => {
    console.log('SendMessageToTheFightFailed: ', serverObj);
    store.dispatch(setErrorChat(serverObj));
    store.dispatch(
      notificationShowAction(
        false,
        serverObj.message,
      ),
    );
  });

  connection.on('YouLeftTheFight', (serverObj) => {
    console.log('YouLeftTheFight: ', serverObj);
    store.dispatch(setFightResult('lost'));
    store.dispatch(setExpData(serverObj));
  });

  connection.on('OpponentLeftTheFight', (serverObj) => {
    console.log('OpponentLeftTheFight: ', serverObj);
    store.dispatch(setFightResult('won'));
    store.dispatch(setExpData(serverObj));
  });

  connection.on('FindingFightStarted', (serverObj) => {
    console.log('FindingFightStarted: ', serverObj);
    store.dispatch(searchFight(true));
  });

  connection.on('FindFightFailed', (serverObj) => {
    console.log('FindFightFailed: ', serverObj);
    store.dispatch(
      notificationShowAction(
        false,
        serverObj.message,
      ),
    );
  });

  connection.on('FindingFightCancelled', (serverObj) => {
    console.log('FindingFightCancelled: ', serverObj);
    store.dispatch(searchFight(false));
  });

  connection.on('CancelFindingFightFailed', (serverObj) => {
    console.log('CancelFindingFightFailed: ', serverObj);
    store.dispatch(
      notificationShowAction(
        false,
        serverObj.message,
      ),
    );
  });

  connection.on('FightFound', (serverObj) => {
    console.log('FightFound: ', serverObj);
    store.dispatch(searchFight(false));
    store.dispatch(acceptFightModal(serverObj));
  });

  connection.on('FoundFightAccepted', (serverObj) => {
    console.log('FoundFightAccepted: ', serverObj);
    store.dispatch(waitingOpponent(true));
  });

  connection.on('FoundFightAcceptedByOpponent', (serverObj) => {
    console.log('FoundFightAcceptedByOpponent: ', serverObj);
    store.dispatch(waitingOpponent(false));
  });

  connection.on('AcceptFoundFightFailed', (serverObj) => {
    console.log('AcceptFoundFightFailed: ', serverObj);
    store.dispatch(
      notificationShowAction(
        false,
        serverObj.message,
      ),
    );
  });

  connection.on('FoundFightRejected', (serverObj) => {
    console.log('FoundFightRejected: ', serverObj);
    store.dispatch(acceptFightModal(null));
    store.dispatch(searchFight(false));
    store.dispatch(waitingOpponent(false));
  });

  connection.on('RejectFoundFightFailed', (serverObj) => {
    console.log('RejectFoundFightFailed: ', serverObj);
  });
};

export const catchError = (err) => {
  console.error(err.toString());
};

export const handleMakeFightRequest = (opponentAddress, fightType) => {
  connection.invoke('MakeFightRequest', opponentAddress, fightType).catch(catchError);
};

export const handleCancelFightRequest = (opponentAddress) => {
  connection
    .invoke('CancelFightRequest', opponentAddress)
    .catch(catchError);
};

export const handleAcceptFightRequest = (opponentAddress) => {
  connection
    .invoke('AcceptFightRequest', opponentAddress)
    .catch(catchError);
};

export const handleRejectFightRequest = (opponentAddress) => {
  connection
    .invoke('RejectFightRequest', opponentAddress)
    .catch(catchError);
};

export const handleSelectPawn = (nftId) => {
  connection
    .invoke('SelectPawn', +nftId)
    .catch(catchError);
};

export const handleDoSpellAction = (action, targetId) => {
  console.log('handleDoSpellAction', action);
  connection
    .invoke('DoSpellAction', action, targetId)
    .catch(catchError);
};

export const handlePassTurn = () => {
  connection
    .invoke('PassTurn')
    .catch(catchError);
};

/* FOR TEST PURPOSE  */
export const handleResetFightInfo = () => {
  connection
    .invoke('Reset')
    .catch(catchError);
};
/* FOR TEST PURPOSE */

export const handleSendMessageToTheArena = (message) => {
  connection
    .invoke('SendMessageToTheArena', message)
    .catch(catchError);
};

export const handleSendMessageToTheFight = (message) => {
  connection
    .invoke('SendMessageToTheFight', message)
    .catch(catchError);
};

export const handleLeaveTheFight = () => {
  connection.invoke('LeaveTheFight')
    .catch(catchError);
};

export const handleFindFight = (fightType) => {
  connection
    .invoke('FindFight', fightType)
    .catch(catchError);
};

export const handleCancelFindingFight = () => {
  connection
    .invoke('CancelFindingFight')
    .catch(catchError);
};

export const handleAcceptFoundFight = () => {
  connection
    .invoke('AcceptFoundFight')
    .catch(catchError);
};

export const handleRejectFoundFight = () => {
  connection
    .invoke('RejectFoundFight')
    .catch(catchError);
};
