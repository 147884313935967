import React from 'react';
import styles from './MatchmakingButton.module.scss';

const InfoButton = ({
  text, isClick, onClick,
}) => (
  <div
    role="presentation"
    className={`${styles.button_wrapper} ${styles.adaptive_button} ${(isClick) && styles.active}`}
    onClick={onClick}
  >
    <div className={styles.button_left} />
    <div className={styles.button_content}>
      <h1>{text}</h1>
    </div>
    <div className={styles.button_right} />
  </div>
);

export default InfoButton;
