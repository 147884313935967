import { createSlice } from '@reduxjs/toolkit';
import uniqueColor from '../../../utils/uniqueColor';

export const chat = createSlice({
  name: 'chat',
  initialState: {
    arenaChat: [],
    fightChat: [],
    error: null,
    openChat: false,
    openInputChat: false,
    isNewMessage: false,
    lastMessage: null,
  },
  reducers: {
    setOpenChat(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.openChat = action.payload;
    },
    setOpenChatInput(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.openInputChat = action.payload;
    },
    setNewMessage: (state, { payload }) => ({
      ...state,
      isNewMessage: payload,
    }),
    setArenaChat: (state, { payload }) => {
      console.log(payload, 'payload');
      const findUniqueAddress = [...state.arenaChat, ...state.fightChat]
        .filter((elem) => elem.address === payload.address);
      return ({
        ...state,
        arenaChat: [...state.arenaChat, {
          ...payload,
          color: findUniqueAddress.length
            ? findUniqueAddress[0].color
            : uniqueColor(),
        }],
      });
    },
    setFightChat: (state, { payload }) => {
      const findUniqueAddress = [...state.arenaChat, ...state.fightChat]
        .filter((elem) => elem.address === payload.address);
      return ({
        ...state,
        fightChat: [...state.fightChat, {
          ...payload,
          color: findUniqueAddress.length
            ? findUniqueAddress[0].color
            : uniqueColor(),
        }],
      });
    },
    setLastMessage: (state, { payload }) => ({
      ...state,
      lastMessage: payload,
    }),
    setErrorChat: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
  },
});

// ---- actions ----
export const {
  setArenaChat, setFightChat, setErrorChat, setOpenChat, setNewMessage, setLastMessage, setOpenChatInput,
} = chat.actions;

export const selectArenaChat = (state) => (state.chat.arenaChat);
export const selectFightChat = (state) => (state.chat.fightChat);
export const selectOpenChat = (state) => (state.chat.openChat);
export const selectOpenChatInput = (state) => (state.chat.openInputChat);
export const selectIsNewMessage = (state) => (state.chat.isNewMessage);
export const selectLastMessage = (state) => (state.chat.lastMessage);
