import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notificationHide, selectNotification } from '../../../../store/slices/utils';
import styles from './Notification.module.scss';

export const closeNotificationRemoveClass = () => {
  const container = document.getElementById('notification');
  container.classList.remove('notification_container_show');
};

const Notification = () => {
  const dispatch = useDispatch();
  const [isClosed, setIsClosed] = useState(false);
  const { success, show, message = 'Transaction Failed' } = useSelector(selectNotification);
  const onClose = () => {
    dispatch(notificationHide());
  };
  return (
    <div
      className={`${styles.notification_container} ${show ? styles.notification_container_show : ''}`}
    >
      <div className={styles.wrapper}>
        <div className={styles.corner_left} />
        <div className={styles.left} />
        <div className={success ? styles.success : styles.failed} />
        <div className={styles.top} />
        <div className={styles.content}>
          {success ? message || 'Transaction Successful' : message}
        </div>
        <div className={styles.bottom} />
        <div className={styles.right} />
        <div
          role="presentation"
          className={`${styles.closed} ${isClosed && styles.active}`}
          onMouseDown={() => setIsClosed(true)}
          onMouseUp={() => setIsClosed(false)}
          onClick={() => onClose()}
        />
        <div className={styles.corner_right} />
      </div>
    </div>
  );
};

export default Notification;
