import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectEquipmentType } from '../../../store/slices/preset';
import TransmuteButton from '../../../static/img/crafting/transmute.png';
import DefaultItems from '../../../static/img/crafting/crafting_empty_item.png';
import DefaultConsume from '../../../static/img/crafting/crafting_empty_spell.png';
import ProgressLine from '../../../static/img/crafting/progress line.png';
import {
  clearCraftingResult,
  craftingAction,
  removeSetupItem, selectCraftingResult,
  selectCraftingSetup,
  selectLoadingCrafting,
} from '../../../store/slices/crafting';
import loader from '../../../static/img/loader.gif';
import { backToEquip, backToItems } from '../../../store/slices/constructor';
import styles from './Transmute.module.scss';

const Transmute = () => {
  const dispatch = useDispatch();
  const [isClicked, setIsClicked] = useState(false);

  const equipmentType = useSelector(selectEquipmentType);
  const { firstPosition, secondPosition } = useSelector(selectCraftingSetup);
  const isLoading = useSelector(selectLoadingCrafting);
  const craftingResult = useSelector(selectCraftingResult);

  const handleDefaultSrc = (type) => {
    if (type === 'Items') {
      return DefaultConsume;
    }
    return DefaultItems;
  };

  const handleAction = (type, data, itemNumber) => {
    if (type === 'Items') {
      dispatch(backToItems(data));
    }
    if (type === 'Equipment') {
      dispatch(backToEquip(data));
    }
    dispatch(removeSetupItem(itemNumber));
  };

  const crafting = () => {
    dispatch(craftingAction({
      data: {
        guildEquipment: [firstPosition.id, secondPosition.id],
      },
      type: equipmentType,
    }));
  };

  useEffect(() => {
    let timer;
    if (craftingResult) {
      timer = setTimeout(() => dispatch(clearCraftingResult()), 5000);
    }
    return () => clearTimeout(timer);
  }, [craftingResult]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {firstPosition && secondPosition ? <img src={ProgressLine} alt="line" className={styles.progressLine} /> : null}
        <button
          onClick={firstPosition ? () => {
            handleAction(equipmentType, firstPosition, 'firstItem');
          } : () => {}}
          type="button"
          className={styles.right_Img}
        >
          <img
            src={firstPosition ? `https://i.bimium.link/${firstPosition.image}` : handleDefaultSrc(equipmentType)}
            alt="right_img"
          />
        </button>
        <button
          className={`${styles.transmute} ${isClicked ? styles.clicked : ''}`}
          type="button"
          onMouseDown={() => setIsClicked(true)}
          onMouseUp={() => setIsClicked(false)}
          onClick={() => {
            if (firstPosition && secondPosition) {
              crafting();
            }
          }}
        >
          {isLoading
            ? (
              <div className={styles.loader}>
                <img src={loader} alt="loading" />
              </div>
            )
            : (
              <img
                src={craftingResult
                  ? `https://i.bimium.link/${craftingResult?.equipment?.image || TransmuteButton}`
                  : TransmuteButton}
                alt="transmute"
              />
            )}
        </button>
        <button
          onClick={secondPosition ? () => handleAction(equipmentType, secondPosition, 'secondItem') : () => {}}
          type="button"
          className={styles.left_Img}
        >
          <img
            src={secondPosition ? `https://i.bimium.link/${secondPosition.image}` : handleDefaultSrc(equipmentType)}
            alt="left_img"
          />
        </button>
      </div>
    </div>
  );
};

export default Transmute;
