import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BigNumber, ethers } from 'ethers';
import {
  selectItemList,
} from '../../../../../store/slices/tradeItem';
import loader from '../../../../../static/img/loader.gif';
import IcoGold from '../../../../../static/img/IcoGold-alternate.png';
import { preventInvalidSymbols } from '../../../../../utils/format';
import { notificationShowAction } from '../../../../../store/slices/utils';
import styles from './PortraitsButtonsListBuy.module.scss';
import {
  buyPortrait,
  cancelPortraitOffering,
  offerPortrait,
  onNewPortraitOffering, onPortraitOfferingCancelled,
  onPortraitOfferingPurchased, transferPortrait,
} from '../../../../../utils/portraitsWeb3';
import { selectEvm } from '../../../../../store/slices/auth';
import {
  cancelPortraitOfferingReducer,
  onNewPortraitOfferingReducer, onPortraitRequestLoader,
  selectPortraitRequestLoader,
} from '../../../../../store/slices/portraits';

const PortraitsButtonsListBuy = ({
  text, itemPrice, portrait, register, setValue,
}) => {
  const dispatch = useDispatch();
  const [isClick, setIsClick] = useState(false);
  console.log(itemPrice, 'itemPrice -----------------------------------------');
  const {
    buyLoading, listLoading, unListLoading, isTransferLoading,
  } = useSelector(selectItemList);
  const address = useSelector(selectEvm);
  const isLoading = useSelector(selectPortraitRequestLoader);
  console.log(address);
  const listed = portrait?.nftStatus === 'Listed';

  const request = async (nftId, price) => {
    const result = await offerPortrait(nftId, price);
    console.log(result);
  };

  const cancelPortraitOfferingRequest = async (offeringId) => {
    console.log(offeringId, 'unlist-------------');
    dispatch(onPortraitRequestLoader(true));
    const result = await cancelPortraitOffering(offeringId);
    return result;
  };

  const buyPortraitOfferingRequest = async (offeringId) => {
    console.log(offeringId, 'buyPortraitOfferingRequest-------------');
    dispatch(onPortraitRequestLoader(true));
    const result = await buyPortrait(offeringId);
    console.log(result);
  };

  const transferPortraitRequest = async (to, nftId) => {
    dispatch(onPortraitRequestLoader(true));
    const result = await transferPortrait(to, nftId);
    console.log(result);
  };

  const onButtonCLick = () => {
    if (text === 'Transfer' && itemPrice) {
      if (ethers.utils.isAddress(itemPrice)) {
        transferPortraitRequest(itemPrice, portrait?.nftId);
      } else {
        dispatch(notificationShowAction(false, 'Input valid address'));
      }
    } else if (text === 'Buy') {
      buyPortraitOfferingRequest(portrait?.offerId);
    } else if (text === 'List' && !listed && itemPrice) {
      request(portrait.nftId, itemPrice);
    } else if (itemPrice) {
      cancelPortraitOfferingRequest(portrait?.offerId);
    }
  };

  const tradeType = () => {
    if (text === 'List') {
      if (listed) return 'Unlist';
      return text;
    }
    return text;
  };

  useEffect(() => {
    if (portrait) {
      if (portrait.price && text !== 'Transfer') {
        setValue('price', portrait.price);
      }
      if (portrait.price && text === 'Transfer') {
        setValue('price', '');
      }
    }
  }, [portrait]);

  useEffect(() => {
    onNewPortraitOffering((e) => {
      console.log(e, '----onNewPortraitOffering---');
      dispatch(notificationShowAction(true, 'Portrait Listed Successfully'));
      dispatch(onPortraitRequestLoader(false));
      dispatch(onNewPortraitOfferingReducer({
        id: e[0].args.tokenId.toString(),
        price: ethers.utils.formatEther(BigNumber.from((e[0].args.price))),
        offerId: e[0].args.offeringId.toString(),
      }));
    });
    onPortraitOfferingPurchased((e) => {
      console.log(e, '----onPortraitOfferingPurchased---');
      dispatch(onPortraitRequestLoader(false));
      dispatch(notificationShowAction(true, 'Portrait Bought Successfully'));
    });
    onPortraitOfferingCancelled((e) => {
      console.log(e, '----onPortraitOfferingCancelled---');
      // console.log(e.)
      dispatch(onPortraitRequestLoader(false));
      dispatch(notificationShowAction(true, 'Portrait unlisted Successfully'));
      dispatch(cancelPortraitOfferingReducer(e[0].args.tokenId.toString()));
    });
  }, []);

  return (
    <div className={styles.wrapper_buttons}>
      <div className={`${styles.button_info} ${text === 'Transfer' ? styles.transfer_info : ''}`}>
        <div className={styles.input_left} />
        <div className={styles.input_content}>
          <input
            placeholder={text === 'Transfer' ? 'Address' : ''}
            {...register('price')}
            disabled={text === 'Buy' || text === 'Mint' || (text === 'Transfer' && !portrait)}
            onKeyPress={(e) => (text === 'Transfer' ? {} : preventInvalidSymbols(e))}
            value={ethers.utils.isAddress(itemPrice) ? `${itemPrice.slice(0, 7)}...${itemPrice.slice(-7)}` : itemPrice}
          />
          <img src={IcoGold} alt="ico" className={styles.input_content_coin} />
          {
            listLoading || unListLoading || buyLoading || isTransferLoading || isLoading
              ? (
                <div className={styles.loader}>
                  <img src={loader} alt="loading" />
                </div>
              )
              : (
                <div
                  role="presentation"
                  className={`
                  ${styles.buttons_list_buy} ${isClick && styles.active}`}
                  onMouseDown={() => setIsClick(true)}
                  onMouseUp={() => setIsClick(false)}
                  onMouseLeave={() => setIsClick(false)}
                  onClick={() => onButtonCLick()}
                >
                  <div className={`${styles.button_list} ${text === 'Transfer' ? styles.transfer : ''}`}>
                    <span>{tradeType()}</span>
                  </div>
                </div>
              )
          }
        </div>
        <div className={styles.input_right} />
      </div>
    </div>
  );
};

export default PortraitsButtonsListBuy;
