import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import InfoButton from '../Presale/InfoButton';
import styles from './HeadContent.module.scss';
import { clearPawn } from '../../../../store/slices/pawnSelect';

const HeadContent = ({
  setType, setPage, type, setFilter, setSort, name = 'Marketplace',
}) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      <div className={styles.mobile_content_decor} />
      <div className={styles.header_wrapper}>
        <div className={styles.header_wrapper_right}>
          <div className={styles.tittle}>{name}</div>
          <div className={styles.button_wrapper}>
            <div
              role="presentation"
              onClick={() => {
                if (type !== 'buy') {
                  setType('buy');
                  dispatch(clearPawn());
                  setPage(1);
                  setFilter('Filter');
                  setSort('Sort by');
                  navigate(`/marketplace/${name === 'Marketplace' ? 'pawns' : 'portraits'}/buy`);
                }
              }}
            >
              <InfoButton text="Buy" isOpenBuy={type === 'buy'} />
            </div>
            <div
              role="presentation"
              onClick={() => {
                if (type !== 'sell') {
                  setType('sell');
                  dispatch(clearPawn());
                  setPage(1);
                  setFilter('Filter');
                  setSort('Sort by');
                  navigate(`/marketplace/${name === 'Marketplace' ? 'pawns' : 'portraits'}/sell`);
                }
              }}
            >
              <InfoButton text="Sell" isOpenSell={type === 'sell'} />
            </div>
            <div
              role="presentation"
              onClick={() => {
                if (type !== 'transfer') {
                  setType('transfer');
                  dispatch(clearPawn());
                  setPage(1);
                  setFilter('Filter');
                  setSort('Sort by');
                  navigate(`/marketplace/${name === 'Marketplace' ? 'pawns' : 'portraits'}/transfer`);
                }
              }}
            >
              <InfoButton text="Transfer" isOpenSell={type === 'transfer'} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeadContent;
