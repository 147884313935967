import {
  API_URL,
} from '../constants/constants';

const responseParse = async (response) => ({
  data: await response.json(),
  status: response.status,
});

const getGuildsInfo = async () => {
  const token = localStorage.getItem('token');
  const response = await fetch(`${API_URL}api/guilds/info`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-type': 'application/json',
    },
  });
  return responseParse(response);
};

const putPortrait = async (payload) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`${API_URL}api/guilds/portrait`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
  return response.status === 200 ? { status: 200 } : responseParse(response);
};

const getPortraits = async (payload) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`${API_URL}api/portraits/?${new URLSearchParams(payload)}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-type': 'application/json',
    },
  });
  return responseParse(response);
};

const getMyPortraits = async (payload) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`${API_URL}api/portraits/my?${new URLSearchParams(payload)}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-type': 'application/json',
    },
  });
  return responseParse(response);
};

const getListedPortraits = async (payload) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`${API_URL}api/portraits/listed?${new URLSearchParams(payload)}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-type': 'application/json',
    },
  });
  return responseParse(response);
};

export {
  getGuildsInfo, getPortraits, putPortrait, getMyPortraits, getListedPortraits,
};
