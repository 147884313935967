import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useSelector } from 'react-redux';
import { connectSocket } from '../../utils/arenaSocket';
import { selectEvm } from '../../store/slices/auth';
import YourComponent from '../../YourComponent';
import Wrapper from '../Wrapper';
import styles from '../ConnectWallet.module.scss';

const Auth = ({ children }) => {
  const auth = useAuth();
  const evm = useSelector(selectEvm);

  useEffect(() => {
    if (auth.user?.access_token) {
      localStorage.setItem('token', auth.user?.access_token);
    }
  }, [auth.user?.access_token]);

  if (auth.isAuthenticated && localStorage.getItem('token')) {
    connectSocket(evm);
    return (
      <YourComponent>
        <Wrapper>
          { children }
        </Wrapper>
      </YourComponent>
    );
  }

  if (auth.isLoading) {
    return (
      <div className={styles.wrapper} />
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.modal}>
        <div className={styles.buttons_block}>
          <div
            role="presentation"
            className={styles.button}
            onClick={() => auth.signinRedirect()}
          >
            Log In
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Auth);
