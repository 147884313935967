import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import HeadContent from '../HeadContent';
import styles from './Portraits.module.scss';
import Grid from './Grid';
import Pagination from '../Presale/Pagination';
import {
  getListedPortraitsAction,
  getMyPortraitsAction,
  selectMyPortraits,
  selectSelectedPortrait,
} from '../../../../store/slices/portraits';
import PortraitsFilter from './PortraitsFilter';
import PortraitsButtonsListBuy from './PortraitsButtonsListBuy';
import midplaceDefault from '../../../../static/img/portraits/Crafting_animated_2023_1-(1)_09.png';

const Portraits = () => {
  const dispatch = useDispatch();
  const [type, setType] = useState('buy');
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState('Filter');
  const [sort, setSort] = useState('Sort by');
  const [tier, setTier] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  const {
    reset, register, watch, setValue,
  } = useForm();

  const tierType = {
    Common: 'Common',
    Uncommon: 'Uncommon',
    Rare: 'Rare',
    Epic: 'Epic',
    Legendary: 'Legendary',
  };

  const sortType = {
    'Sort by': false,
    Price: true,
  };

  const {
    portraits, loading, next,
  } = useSelector(selectMyPortraits);

  const selectedPortrait = useSelector(selectSelectedPortrait);
  console.log(portraits, '-----------------portraits--------------');
  console.log(selectedPortrait, '-----------------selectedPortrait--------------');
  const generateRequest = () => {
    const obj = {
      limit: 12,
      offset: (page - 1) * 12,
    };
    if (tierType[tier]) {
      obj.tier = tierType[tier];
    }
    if (sortType[sort]) {
      obj.sort_by = 'Price';
      if (sortDirection === 'asc') {
        obj.order_by = 'Asc';
      } else {
        obj.order_by = 'Desc';
      }
    } else {
      obj.sort_by = 'Default';
      setSortDirection('');
    }
    return obj;
  };

  const onPageInc = () => {
    if (next) {
      setPage(page + 1);
    }
  };

  const onPageDec = () => {
    if (page - 1 > 0) {
      setPage(page - 1);
    }
  };

  const onInventoryPageInc = () => {
    if (next) {
      setPage(page + 1);
    }
  };

  const onInventoryPageDec = () => {
    if (page - 1 > 0) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    if (location.pathname === '/marketplace/portraits') {
      navigate('/marketplace/portraits/buy');
    }
    if (location.pathname.split('/').some((item) => item === 'buy')) {
      setType('buy');
    } else if ((location.pathname.split('/').some((item) => item === 'sell'))) {
      setType('sell');
    } else if ((location.pathname.split('/').some((item) => item === 'transfer'))) {
      setType('transfer');
    } else {
      navigate('/marketplace/portraits/buy');
    }
    // const id = location.pathname.match(/\d+/)?.[0];

    // if (id) {
    //   dispatch(getPawn(id));
    // }
    // return () => dispatch(clearPawn());
  }, [location]);

  useEffect(() => {
    setPage(1);
  }, [filter, sort]);

  useEffect(() => {
    if (type === 'buy') {
      dispatch(getListedPortraitsAction(generateRequest()));
      reset({
        price: '',
      });
    } else {
      dispatch(getMyPortraitsAction(generateRequest()));
      reset({
        price: '',
      });
    }
  }, [page, type, filter, sort, sortDirection, tier]);

  return (
    <div className={styles.portraits_wrapper}>
      <div className={styles.portraits_wrapper_filters}>
        <PortraitsFilter
          sort={sort}
          text={['Sort by', 'Price']}
          setSort={setSort}
          filter={filter}
          tier={tier}
          setTier={setTier}
          setFilter={setFilter}
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
        />
      </div>
      <HeadContent
        setType={setType}
        setPage={setPage}
        type={type}
        setFilter={setFilter}
        setSort={setSort}
        name="Portraits"
      />
      <div className={styles.content_decor} />
      <Grid
        text={type === 'buy' ? 'Market' : 'Inventory'}
        loading={loading}
        data={portraits}
      />
      <div className={styles.midplace_wrapper}>
        <div className={styles.midplace}>
          <div className={styles.midplace_img}>
            <img src={selectedPortrait ? `https://i.bimium.link/${selectedPortrait.image}` : midplaceDefault} alt="" />
          </div>
        </div>
      </div>
      {/* <div className={styles.rightPlace}> */}
      {/*   <div className={styles.right_place_img}> */}
      {/*     <img */}
      {/*       src={selectedPortrait */}
      {/*         ? `https://i.bimium.link/${selectedPortrait.image}` */}
      {/*         : rightPlaceDefault} */}
      {/*       alt="" */}
      {/*     /> */}
      {/*   </div> */}
      {/* </div> */}
      <Pagination
        page={page}
        onPageInc={type === 'buy' ? onPageInc : onInventoryPageInc}
        onPageDec={type === 'buy' ? onPageDec : onInventoryPageDec}
      />
      <PortraitsButtonsListBuy
        text={type === 'buy' ? 'Buy' : type === 'transfer' ? 'Transfer' : 'List'}
        portrait={selectedPortrait}
        register={register}
        itemPrice={watch('price')}
        reset={reset}
        setValue={setValue}
        generateRequest={generateRequest}
      />
    </div>
  );
};

export default Portraits;
