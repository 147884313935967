import React from 'react';
import RemoveButton from '../RemoveButton';
import AgreedButton from '../AgreedButton';
import Close from '../../../../static/img/quests/Exit.png';
import styles from '../../../QuestPage/PickPawnModal/PickPawnModal.module.scss';

const ConfirmModal = ({ cancel, submit }) => (
  <div
    className={styles.modalWrapper}
    role="presentation"
    onClick={() => cancel()}
  >
    <div
      className={styles.wrapper_confirm}
      role="presentation"
      onClick={(e) => e.stopPropagation()}
    >
      <div
        className={styles.close_confirm}
        role="presentation"
        onClick={() => cancel()}
      >
        <img src={Close} alt="exit" width="14px" height="14px" />
      </div>
      <div className={styles.modalContent}>
        <div>This item is being worn by another pawn. Are you sure you want to remove it?</div>
        <div className={styles.button_wrap}>
          <RemoveButton click={cancel} />
          <AgreedButton click={submit} />
        </div>
      </div>
    </div>
  </div>
);

export default ConfirmModal;
