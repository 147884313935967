import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import InfoButton from '../../components/Arena/MatchmakingButton';
import ConsumablesUtils from './ConsumablesUtils';
import ItemsUtils from './ItemsUtils';
import Header from '../../components/Marketplace/Components/Header';
import { selectEvm } from '../../store/slices/auth';
import styles from './InventoryUtils.module.scss';

const Constructor = () => {
  const [page, setPage] = useState('equipment');
  const [isClick, setIsClick] = useState(false);
  const [isClickItem, setIsClickItem] = useState(false);

  const address = useSelector(selectEvm);

  const pages = {
    items: <ConsumablesUtils />,
    equipment: <ItemsUtils />,
  };

  return useMemo(() => (
    <>
      <Header address={address} />
      <div className={styles.button_wrap}>
        <div
          className={`${styles.wrapper_button_match} ${page === 'equipment' ? styles.active : ''}`}
          role="presentation"
          onMouseUp={() => setIsClick(false)}
          onMouseDown={() => setIsClick(true)}
          onClick={() => setPage('equipment')}
        >
          <InfoButton
            text="Equipment Constructor"
            isClick={isClick}
          />
        </div>
        <div
          className={`${styles.wrapper_button_match} ${page === 'items' ? styles.active : ''}`}
          role="presentation"
          onMouseUp={() => setIsClickItem(false)}
          onMouseDown={() => setIsClickItem(true)}
          onClick={() => setPage('items')}
        >
          <InfoButton
            text="Items Constructor"
            isClick={isClickItem}
          />
        </div>
        <Link to="/" className="closeWindow closeWindowBlock red"><button type="button">&#10006;</button></Link>
      </div>
      <div className={page === 'items' ? styles.wrap_items : styles.wrap_equip}>
        {pages[page]}
      </div>
    </>
  ), [page, isClick, isClickItem, pages]);
};

export default Constructor;
