export const animationsObject = [
  {
    sprite: {
      x: 1652,
      y: 1707,
      frameName: 'Tavern-Animations-1',
      path: 'Fireplace/Sprites_fireplace_Imported animation_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 25 },
  },
  {
    sprite: {
      x: 901,
      y: 2167,
      frameName: 'Tavern-Animations-1',
      path: 'Cauldron/Cauldron_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 15 },
  },
  {
    sprite: {
      x: 4684,
      y: 2156,
      frameName: 'Tavern-Animations-1',
      path: 'Dancers/Sprites_dancers_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 33 },
  },

  {
    sprite: {
      x: 3954,
      y: 2163,
      frameName: 'Tavern-Animations-1',
      path: 'Prisoner6/Sprites_prisoner6_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 22 },
  },
  {
    sprite: {
      x: 4656,
      y: 2205,
      frameName: 'Tavern-Animations-1',
      path: 'Crime Boss/Sprites_han_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 41 },
    messages: [
      "There's a price for\nevery secret.",
      'Looking to make some coin?',
      'New meat.',
      'Looking for a bed?',
      'Relax a while.',
      'Enjoy yourself,\nthe pox is fresh.',
    ],
    interactMessages: [
      "Cross me, and you'll see\nthe depths of darkness.",
      'Intrigue, deception, power -\nthe dance never ends.',
      'If it happens here,\nI already know.',
    ],
  },
  {
    sprite: {
      x: 4615,
      y: 2241,
      frameName: 'Tavern-Animations-1',
      path: 'Smokers/Sprites_smokers_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 37 },
  },
  {
    sprite: {
      x: 3229,
      y: 1626,
      frameName: 'Tavern-Animations-1',
      path: 'Drunkards/Sprites_drunkards_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 59 },
  },
  {
    sprite: {
      x: 4846,
      y: 2245,
      frameName: 'Tavern-Animations-1',
      path: 'Lounging Pair/Sprites_pair_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 29 },
  },
  {
    sprite: {
      x: 4166,
      y: 2206,
      frameName: 'Tavern-Animations-1',
      path: 'Dryad/Sprites_dryad waiter_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 60 },
  },
  {
    sprite: {
      x: 3262,
      y: 2207,
      frameName: 'Tavern-Animations-1',
      path: 'Gatekeeper/Sprites_gatekeeper_Imported animation_00.png',
      scaleX: 3,
      scaleY: 3,
    },
    frames: { start: 0, end: 16 },
    messages: [
      'You best behave yourself.',
      'No sudden moves, friend.',
      'Keep walking, outsider.',
      'Looking to spill\nsome blood?',
    ],
  },
  {
    sprite: {
      x: 389,
      y: 2203,
      frameName: 'Tavern-Animations-1',
      path: 'Alchemist/Sprites_old man _Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 14 },
    messages: [
      'An ailment that\nneeds treating?',
      "Can't you see I'm busy?",
      'Watch your step, lest you\nblow up this whole tavern!',
      'Not another distraction...',
      'Can I help you?',
    ],
    interactMessages: [
      "The line between potion and\npoison? Thinner than you'd think.",
      'Mixtures, brews, concoctions;\nevery drop tells a tale.',
      'Beyond the mundane, lies\nthe realm of the alchemical.',
      'Quick quick, I have a potion\nbrewing!',
      'What can I do for you?',
      'A draught for courage? Or\none to glimpse the shadows?',
    ],
  },
  {
    sprite: {
      x: 386,
      y: 1631,
      frameName: 'Tavern-Animations-1',
      path: 'Gardener/Sprites_gardener_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 59 },
  },
  {
    sprite: {
      x: 3754,
      y: 2202,
      frameName: 'Tavern-Animations-1',
      path: 'Prisoner5/Sprites_prisoner5_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 26 },
  },
  {
    sprite: {
      x: 4246,
      y: 2229,
      frameName: 'Tavern-Animations-1',
      path: 'Candle/Sprites_candle_Imported animation_00.png',
      scaleX: 3,
      scaleY: 3,
    },
    frames: { start: 0, end: 25 },
  },
  {
    sprite: {
      x: 5017,
      y: 2229,
      frameName: 'Tavern-Animations-1',
      path: 'Candle/Sprites_candle_Imported animation_00.png',
      scaleX: 3,
      scaleY: 3,
    },
    frames: { start: 0, end: 25 },
  },
  {
    sprite: {
      x: 2473,
      y: 1611,
      frameName: 'Tavern-Animations-1',
      path: 'Barmen/Sprites_barmen_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 27 },
    messages: [
      'Another round, stranger?',
      'Stay a while and listen...',
      'You look like you\n could use a drink.',
      'Looking for a bed?',
      'Warm meal? Tall ale?\nClean bed?',
      'From kings to knaves,\n all find a seat here.',
      'May the Martyr protect\nus...',
      'Few places remember\n like this inn does.',
    ],
    interactMessages: [
      "I'll pour you a fresh one.",
      'Not the finest ale,\nbut it does the trick.',
      'Freshly brewed...',
      "Hope you've got\nthe coin, stranger.",
      'Rough day, huh?',
      'This will help.',
      'Every guest leaves a memory,\nsomelinger longer.',
      'We all seek refuge, some just\nfind it at the bottom of a mug.',
    ],
  },
  {
    sprite: {
      x: 2777,
      y: 2217,
      frameName: 'Tavern-Animations-1',
      path: 'Spike Trap/Sprites_prisoner1_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 19 },
    interactMessages: [
      "Looks fun... but I'll pass.",
      "Today's trivial note could\nbe tomorrow's pivotal record.",
      "That's something you don't\n see every day...",
      'Maybe not today.',
    ],
  },
  {
    sprite: {
      x: 2343,
      y: 2249,
      frameName: 'Tavern-Animations-1',
      path: 'Prisoner4/Sprites_prisoner4_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 26 },
  },
  {
    sprite: {
      x: 4030,
      y: 2203,
      frameName: 'Tavern-Animations-1',
      path: 'Prisoner1/Sprites_prisoner2_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 19 },
  },
  {
    sprite: {
      x: 1999,
      y: 1377,
      frameName: 'Tavern-Animations-1',
      path: 'Chandelier/Sprites_chandelier_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 23 },
  },
  {
    sprite: {
      x: 1961,
      y: 898,
      frameName: 'Tavern-Animations-1',
      path: 'Chandelier/Sprites_chandelier_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 23 },
  },
  {
    sprite: {
      x: 1961,
      y: 898,
      frameName: 'Tavern-Animations-1',
      path: 'Chandelier/Sprites_chandelier_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 23 },
  },
  {
    sprite: {
      x: 3023,
      y: 898,
      frameName: 'Tavern-Animations-1',
      path: 'Chandelier/Sprites_chandelier_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 23 },
  },
  {
    sprite: {
      x: 3638,
      y: 898,
      frameName: 'Tavern-Animations-1',
      path: 'Chandelier/Sprites_chandelier_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 23 },
  },
  {
    sprite: {
      x: 500,
      y: 1854,
      frameName: 'Tavern-Animations-1',
      path: 'Chandelier/Sprites_chandelier_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 23 },
  },
  {
    sprite: {
      x: 3029,
      y: 1629,
      frameName: 'Tavern-Animations-1',
      path: 'Waiter/Sprites_waiter_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 63 },
    interactMessages: [
      "Take a seat, I'll bring your\nfood when it's ready.",
      'Hands to yourself!',
    ],
  },
  {
    sprite: {
      x: 3474,
      y: 2219,
      frameName: 'Tavern-Animations-1',
      path: 'Prisoner3/Sprites_prisoner3_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 35 },
  },
  {
    sprite: {
      x: 366,
      y: 1982,
      frameName: 'Tavern-Animations-1',
      path: 'Raven/Raven_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 41 },
  },
  {
    sprite: {
      x: 2096,
      y: 1537,
      frameName: 'Tavern-Animations-1',
      path: 'Torch/Sprites_torch_1_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 29 },
  },
  {
    sprite: {
      x: 1778,
      y: 2102,
      frameName: 'Tavern-Animations-1',
      path: 'Torch_dark/dark torch_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 29 },
  },
  {
    sprite: {
      x: 2470,
      y: 2102,
      frameName: 'Tavern-Animations-1',
      path: 'Torch_dark/dark torch_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 29 },
  },
  {
    sprite: {
      x: 2813,
      y: 1173,
      frameName: 'Tavern-Animations-1',
      path: 'Torch/Sprites_torch_1_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 29 },
  },
  {
    sprite: {
      x: 2813,
      y: 1173,
      frameName: 'Tavern-Animations-1',
      path: 'Torch/Sprites_torch_1_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 29 },
  },
  {
    sprite: {
      x: 2801,
      y: 666,
      frameName: 'Tavern-Animations-1',
      path: 'Torch_dark/dark torch_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 29 },
  },
  {
    sprite: {
      x: 3218,
      y: 666,
      frameName: 'Tavern-Animations-1',
      path: 'Torch_dark/dark torch_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 29 },
  },
  {
    sprite: {
      x: 2972,
      y: 1537,
      frameName: 'Tavern-Animations-1',
      path: 'Torch/Sprites_torch_1_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 29 },
  },

  {
    sprite: {
      x: 4339,
      y: 1599,
      frameName: 'Tavern-Animations-1',
      path: 'Torch_dark/dark torch_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 29 },
  },
  {
    sprite: {
      x: 4555,
      y: 1481,
      frameName: 'Tavern-Animations-1',
      path: 'Torch_dark/dark torch_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 29 },
  }, {
    sprite: {
      x: 4856,
      y: 1238,
      frameName: 'Tavern-Animations-1',
      path: 'Torch_dark/dark torch_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 29 },
  }, {
    sprite: {
      x: 4347,
      y: 951,
      frameName: 'Tavern-Animations-1',
      path: 'Torch_dark/dark torch_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 29 },
  },

  {
    sprite: {
      x: 3057,
      y: 2102,
      frameName: 'Tavern-Animations-1',
      path: 'Torch_dark/dark torch_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 29 },
  }, {
    sprite: {
      x: 3467,
      y: 2102,
      frameName: 'Tavern-Animations-1',
      path: 'Torch_dark/dark torch_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 29 },
  },

  {
    sprite: {
      x: 4619,
      y: 672,
      frameName: 'Tavern-Animations-1',
      path: 'Lord/Sprites_lord_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 29 },
    messages: [
      'Paper holds more power\nthansteel, at times.',
      'Order in ink, chaos kept\nat bay.',
      'All transactions must be\nrecorded.',
      'What brings you here?',
      'Order must be maintained,\nno matter the cost.',
      'Another hero?',
    ],
    interactMessages: [
      'Documents sealed,\ndestinies bound.',
      "Today's trivial note could be\n tomorrow's pivotal record.",
      'Lost something in the maze\n of protocol? I can assist.',
      'Dates, stamps, seals;\nthey govern more than you know.',
      "Let's take a look.",
      'Happy to help.',
    ],
  },
  {
    sprite: {
      x: 625,
      y: 2136,
      frameName: 'Tavern-Animations-1',
      path: 'Alchemy Workbench/Alchemist_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 23 },
  },
  {
    sprite: {
      x: 2578,
      y: 1113,
      frameName: 'Tavern-Animations-1',
      path: 'Small Candle/sprite_candlestick_Imported animation_00.png',
      scaleX: 1,
      scaleY: 1,
    },
    frames: { start: 0, end: 11 },
  },
];

export const pawnsObject = {
  default_female: {
    path: 'assets/social/sprites/skins/def_female.png',
    frameConfig: { frameWidth: 90, frameHeight: 279 },
    displayOrigin: { x: 45, y: 278 },
    anims: {
      'anim-male-idle': { start: 0, end: 23 },
      'anim-male-beer': { start: 24, end: 55 },
      'anim-male-jump': { start: 56, end: 68 },
      'anim-male-walk': { start: 69, end: 92 },
    },
  },
  default_male: {
    path: 'assets/social/sprites/skins/def_male.png',
    frameConfig: { frameWidth: 90, frameHeight: 279 },
    displayOrigin: { x: 45, y: 278 },
    anims: {
      'anim-male-idle': { start: 0, end: 23 },
      'anim-male-beer': { start: 24, end: 55 },
      'anim-male-jump': { start: 56, end: 68 },
      'anim-male-walk': { start: 69, end: 92 },
    },
  },
};

export const randomValue = [true, false, false, false, false];

export const ladderXY = [
  [1798, 2310, true, 654, 1730, true], // first to bottom floor
  [3717, 1730, true, 2771, 1250, false], // first to above barmen floor
  [1615, 1250, true, 2550, 770, true], // stairs on second floor
  [1615, 770, true, 2550, 295, false], // stairs on third floor
  [4295, 1730, true, 5050, 1345, true], // first stairs in closet
  [4770, 1345, false, 4215, 1060, true], // second stairs in closet
  [4490, 1060, false, 5050, 770, true], // third stairs in closet
];

export const interactMessages = (texArray) => texArray[Math.floor(Math.random() * texArray.length)];
export const randomShowing = () => randomValue[Math.floor(Math.random() * randomValue.length)];
