import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectActivePreset, setActivePreset } from '../../../../store/slices/preset';
import { btnStatus } from '../../../../utils/chooseData';

const VsButton = ({
  vs, loading, setIsChanged, btnNumber,
}) => {
  const dispatch = useDispatch();
  const [buttonState, setButtonState] = useState('');

  const activePreset = useSelector(selectActivePreset);

  return (
    <button
      type="button"
      disabled={loading}
      onMouseEnter={() => (activePreset !== btnNumber ? setButtonState('hover') : setButtonState('pressed'))}
      onMouseDown={() => setButtonState('pressed')}
      onMouseLeave={() => setButtonState('')}
      onClick={() => {
        if (activePreset !== btnNumber) {
          setIsChanged(false);
          dispatch(setActivePreset(btnNumber));
        }
      }}
    >
      <img
        src={buttonState
          ? btnStatus(vs)[buttonState]
          : activePreset === btnNumber
            ? btnStatus(vs).pressed
            : btnStatus(vs).unActive}
        alt="btn"
      />
    </button>
  );
};

export default VsButton;
