import { ActionDamage } from './actionDamage';
import { ActionCasting } from './actionCasting';
import { ActionBuff } from './actionBuff';

export class Manager {
  constructor(
    address,
    // animations,
    // setAnimations,
    setActionSelf,
    setActionOpponent,
    pawnData,
    setBackdropAnimation,
  ) {
    // this.animations = animations;
    this.address = address;
    // this.setAnimations = setAnimations;
    this.setActionSelf = setActionSelf;
    this.setActionOpponent = setActionOpponent;
    this.pawnData = pawnData;
    this.setBackdropAnimation = setBackdropAnimation;

    this.animations = new Promise((resolve) => {
      resolve(true);
    });
  }

  addActions(actions) {
    // sort actions, casting first
    const as = [...actions].sort((a) => (a.type === 'Casting' ? -1 : 0));
    // create actions and add to animation promise
    as.forEach(
      (action) => {
        Manager.createAction(
          this.address,
          action,
          this.pawnData,
          this.setActionSelf,
          this.setActionOpponent,
          this.stateBackdrop,
          this.setBackdropAnimation,
        )
          ?.toPromises()
          ?.forEach((p) => {
            this.animations = this.animations.then(p);
          });
      },
    );
  }

  setBackdropState(stateBackdrop) {
    this.stateBackdrop = stateBackdrop;
  }

  // eslint-disable-next-line consistent-return
  static createAction(address, action, pawnData, setActionSelf, setActionOpponent, backdrop, setBackdrop) {
    switch (action.type) {
      case 'Damage':
        return new ActionDamage(address, action, pawnData, setActionSelf, setActionOpponent, backdrop, setBackdrop);
      case 'Casting':
        return new ActionCasting(address, action, pawnData, setActionSelf, setActionOpponent, backdrop, setBackdrop);
      case 'Buff':
        return new ActionBuff(address, action, pawnData, setActionSelf, setActionOpponent, backdrop, setBackdrop);
      default:
        break;
    }
  }
}
