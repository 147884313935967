import logo from '../static/img/warrior/warrior_icon.png';
import slash from '../static/img/warrior/slash.png';
import exploit from '../static/img/warrior/exploit_weakness.png';
import shieldBash from '../static/img/warrior/shield_bash.png';
import enchantedShield from '../static/img/warrior/enchanted_shield.png';
import recklessSwing from '../static/img/warrior/leap.png';
import lastStand from '../static/img/warrior/last_stand.png';

export const warriorData = {
  logo,
  spells: {
    slash: {
      cost: {
        count: 0,
        type: 'range',
      },
      type: 'primary',
      quickness: 'normal',
      application: 'end_opponent',
      cooldown: 0,
      description: '(1 * WD) + 5 discipline',
      img: slash,
    },
    exploit: {
      cost: {
        count: -10,
        type: 'range',
      },
      type: 'secondary',
      quickness: 'normal',
      application: 'end_opponent',
      cooldown: 1,
      description: '(0.5 * WD * X). X being number of times used in the fight.',
      img: exploit,
    },
    shield_bash: {
      cost: {
        count: 0,
        type: 'range',
      },
      type: 'secondary',
      quickness: 'quick',
      application: 'immediately',
      cooldown: 1,
      description: '(1 * WD) + increases physical armor by 1, +10 discipline',
      img: shieldBash,
    },
    enchanted_shield: {
      cost: {
        count: 0,
        type: 'range',
      },
      type: 'primary',
      quickness: 'quick',
      application: 'immediately',
      cooldown: 5,
      description: 'Converts all incoming damage to HP, lasts 1 turn - +15 discipline',
      img: enchantedShield,
    },
    reckless_swing: {
      cost: {
        count: -30,
        type: 'range',
      },
      type: 'primary',
      quickness: 'normal',
      application: 'end_opponent',
      cooldown: 0,
      description: '(5*WD), reduce (user\'s) armor to 0 during the attack.',
      img: recklessSwing,
    },
    last_stand: {
      cost: {
        count: 0,
        type: 'range',
      },
      type: 'secondary',
      quickness: 'quick',
      application: 'immediately',
      cooldown: 4,
      description: '50% of hp restored, +"vulnerability", increased dmg taken by 100%',
      img: lastStand,
    },
  },
};
