import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { requests } from './slices/requests';
import { pawnSelect } from './slices/pawnSelect';
import { tradeItem } from './slices/tradeItem';
import { presale } from './slices/presale';
import { utils } from './slices/utils';
import { arena } from './slices/arena';
import { chat } from './slices/chat';
import { preset } from './slices/preset';
import { quests } from './slices/quests';
import { constructorForm } from './slices/constructor';
import { auth } from './slices/auth';
import { skillConstructor } from './slices/skillConstructor';
import { taverna } from './slices/taverna';
import { portraits } from './slices/portraits';
import { crafting } from './slices/crafting';

const rootReducer = combineReducers({
  requests: requests.reducer,
  auth: auth.reducer,
  pawnSelect: pawnSelect.reducer,
  tradeItem: tradeItem.reducer,
  presale: presale.reducer,
  utils: utils.reducer,
  arena: arena.reducer,
  chat: chat.reducer,
  preset: preset.reducer,
  quests: quests.reducer,
  constructorForm: constructorForm.reducer,
  skillConstructor: skillConstructor.reducer,
  taverna: taverna.reducer,
  portraits: portraits.reducer,
  crafting: crafting.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
});
