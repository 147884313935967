import Action from './action';
import { ACTION_EVENTS } from './event';

class ActionOpponentAnimation extends Action {
  constructor(skill, config) {
    super(config);
    this.skill = skill;
    this.target = config.sceneTarget.objects.pawn;
    this.timeline = config.timeline;
    console.log('CREATE OPPONENT ANIMATION ACTION', config, skill);
    if (this.target) {
      this.timeline.add({
        at: this.delay,
        ...(config.animation.toLowerCase() === 'hit' ? { event: ACTION_EVENTS.ACTION_EVENT_HIT } : this),
        target: this,
        tween: {
          targets: this.target,
          duration: this.duration,
          props: {
            visible: true,
            // x: { value: { from: this.target.x, to: this.target.x } },
            // y: { value: { from: this.target.y, to: this.target.y } },
          },
        },
        run: () => {
          this.target.anims.play({
            // eslint-disable-next-line max-len
            key: `anim-${this.skill.opponentPawn.config.pawnConfig.key.toLowerCase()}-${config.animation.toLowerCase()}`,
            duration: this.duration,
          });
        },
      });
    }
  }
}

export default ActionOpponentAnimation;
