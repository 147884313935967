import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAuth } from 'react-oidc-context';
import coin from '../../../../../static/img/header/balancebar/coin.png';
import buy from '../../../../../static/img/header/balancebar/Doors.png';
import sell from '../../../../../static/img/header/balancebar/sell-1920.png';
import buyActive from '../../../../../static/img/header/balancebar/Door pressed.png';
import sellActive from '../../../../../static/img/header/balancebar/sell-1920-active.png';
import fight from '../../../../../static/img/header/balancebar/fight.png';
import notification from '../../../../../static/img/header/balancebar/notification.png';
import fightPressed from '../../../../../static/img/header/balancebar/button_fight_pressed.png';
import styles from './balancebar.module.scss';
import FightNotification from './FightNotification';
import { selectInviteFrom, selectInviteTo } from '../../../../../store/slices/arena';
import { onLogOut } from '../../../../../utils/requests';

const BalanceBar = () => {
  const auth = useAuth();
  const [isBuyActive, setIsBuyActive] = useState(false);
  const [isSellActive, setIsSellActive] = useState(false);
  const [isFight, setIsFight] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [IsNotificationIndicator, setIsNotificationIndicator] = useState(false);
  const { inviteFrom } = useSelector(selectInviteFrom);
  const { inviteTo } = useSelector(selectInviteTo);

  useEffect(() => {
    if (inviteFrom) {
      setIsNotificationIndicator(true);
    } else {
      setIsNotificationIndicator(false);
    }
  }, [inviteFrom]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.corner_left}>
          <img src={coin} alt="sell" />
        </div>
        <div className={styles.head} />
        <div className={styles.text_area} />
        <div className={styles.corner_right} />
        <div className={styles.part_right} />
        <div className={styles.corner_bottom_left} />
        <div className={styles.bottom_centre} />
        <div className={styles.corner_bottom_right} />
        <div className={styles.place}>
          <div className={styles.button_group_balanceBar}>
            <div
              role="presentation"
              onMouseDown={() => setIsFight(true)}
              onMouseUp={() => setIsFight(false)}
              onMouseLeave={() => setIsFight(false)}
              onClick={() => setIsNotification(!isNotification)}
              className={styles.button_fight}
            >
              {IsNotificationIndicator && <img src={notification} alt="notification" className={styles.notification} />}
              <img src={isFight ? fightPressed : fight} alt="fight" className={styles.button_balance} />
            </div>
            <div
              role="presentation"
              onClick={() => window
                .open(
                  'https://app.uniswap.org/#/tokens/arbitrum/0x810cf1757b51963102e0b4fe9fdfcdf749dea98a',
                  '_blank',
                )}
              onMouseDown={() => setIsSellActive(true)}
              onMouseUp={() => setIsSellActive(false)}
              onMouseLeave={() => setIsSellActive(false)}
            >
              <img src={!isSellActive ? sell : sellActive} alt="buy" className={styles.button_balance} />
            </div>
            <div
              role="presentation"
              onMouseDown={() => setIsBuyActive(true)}
              onMouseUp={() => setIsBuyActive(false)}
              onMouseLeave={() => setIsBuyActive(false)}
              onClick={() => {
                onLogOut().then((response) => {
                  if (response.status === 200) {
                    localStorage.removeItem('token');
                    auth.removeUser();
                  }
                });
              }}
            >
              <img src={!isBuyActive ? buy : buyActive} alt="sell" className={styles.button_balance} />
            </div>
          </div>
        </div>
      </div>
      <FightNotification
        setIsNotification={setIsNotification}
        isNotification={isNotification}
        setIsNotificationIndicator={setIsNotificationIndicator}
        opponentAddress={inviteFrom || inviteTo}
      />
    </div>
  );
};

export default BalanceBar;
