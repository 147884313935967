import { createSlice } from '@reduxjs/toolkit';
import { getItem, getListedItems, getMyItems } from '../../../utils/requests';

export const requests = createSlice({
  name: 'requests',
  initialState: {
    loading: false,
    marketplace: [],
    marketplaceItems: '',
    inventory: [],
    inventoryForEquipment: [],
    inventoryMobileEquipment: [],
    inventoryItems: '',
    count: null,
    isChangePage: true,
    page: 1,
    prevPage: 0,
  },
  reducers: {
    getPawnsBuyRequested: (state) => ({
      ...state,
      loading: true,
    }),
    getPawnsBuyFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    getPawnsBuySuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: false,
      marketplace: payload.results,
      marketplaceItems: payload.next,
      count: payload.count,
      inventoryMobileEquipment: payload.results,
    }),
    getPawnsSellRequested: (state) => ({
      ...state,
      loading: true,
    }),
    getPawnsSellFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    getPawnsSellSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: false,
      inventory: payload.results,
      inventoryItems: payload.next,
      count: payload.count,
      inventoryMobileEquipment: payload.results,
    }),
    getPawnsSellForEquipmentSuccess: (state, { payload }) => {
      const pageCount = JSON.parse(JSON.stringify(state.page));
      const currentState = JSON.parse(JSON.stringify([...state.inventoryForEquipment]));
      return ({
        ...state,
        loading: false,
        error: false,
        inventoryForEquipment: payload.offset ? [...currentState, ...payload.data.results] : payload.data.results,
        count: payload.data.count,
        inventoryMobileEquipment: payload.data.results,
        prevPage: pageCount,
      });
    },
    getPage: (state, { payload }) => ({
      ...state,
      page: payload,
    }),
    getPageForEquipment: (state, { payload }) => ({
      ...state,
      pageForEquipment: payload,
    }),
    getPageForItems: (state, { payload }) => ({
      ...state,
      pageForItems: payload,
    }),
    boughtSuccessfully: (state, { payload }) => {
      const newState = JSON.parse(JSON.stringify(state.marketplace));
      let arrayIndex = null;
      newState.forEach((elem, index) => {
        if (elem.nftId.toString() === payload.toString()) {
          arrayIndex = index;
        }
      });
      if (arrayIndex) {
        newState.splice(arrayIndex, 1);
      }
      return {
        ...state,
        marketplace: newState,
      };
    },
    listedSuccessfully: (state, { payload }) => {
      const newState = JSON.parse(JSON.stringify(state.inventory));
      let arrayIndex = null;
      newState.forEach((elem, index) => {
        if (elem.nftId === +payload) {
          arrayIndex = index;
        }
      });
      if (arrayIndex) {
        newState[arrayIndex].nftStatus = 'Listed';
      }
      return {
        ...state,
        inventory: newState,
      };
    },
    unListedSuccessfully: (state, { payload }) => {
      const newState = JSON.parse(JSON.stringify(state.inventory));
      let arrayIndex = null;
      newState.forEach((elem, index) => {
        if (elem.nftId === +payload) {
          arrayIndex = index;
        }
      });
      if (arrayIndex) {
        newState[arrayIndex].nftStatus = 'Minted';
      }
      return {
        ...state,
        inventory: newState,
      };
    },
    getNewInventory: (state, { payload }) => {
      const newState = JSON.parse(JSON.stringify(state.inventory));
      return {
        ...state,
        inventoryMobileEquipment: newState.slice((payload.page - 1) * 9, (payload.page) * 9),
      };
    },
    getNewInventoryForEquipment: (state, { payload }) => {
      const newState = JSON.parse(JSON.stringify(state.inventoryForEquipment));
      return {
        ...state,
        inventoryMobileEquipment: newState.slice((payload.page - 1) * 9, (payload.page) * 9),
      };
    },
    getNewPawnSuccess: (state, { payload }) => {
      const newState = JSON.parse(JSON.stringify(state.marketplace));
      newState.splice(11, 1);
      return {
        ...state,
        marketplace: [payload, ...newState],
      };
    },
    updatePointsMyPawns: (state, { payload }) => {
      const newState = JSON.parse(JSON.stringify(state.inventoryForEquipment));
      const result = newState.map((elem) => (elem.nftId === payload ? ({
        ...elem,
        upgradePoints: elem.upgradePoints - 1,
      }) : ({ ...elem })));
      return {
        ...state,
        inventoryForEquipment: [...result],
      };
    },
    lastPawnsData: (state, { payload }) => ({
      ...state,
      wasLastPawns: payload,
    }),
    changePage: (state, { payload }) => ({
      ...state,
      isChangePage: payload,
    }),
    resetInventory: (state) => ({
      ...state,
      inventory: [],
    }),
    resetInventoryForEquipment: (state) => ({
      ...state,
      page: 1,
      prevPage: 0,
      inventoryForEquipment: [],
      wasLastPawns: false,
      isChangePage: true,
      count: null,
    }),
  },
});

// ---- actions ----
export const {
  getPawnsBuySuccess, getPawnsBuyRequested, getPawnsBuyFailure,
  getPawnsSellSuccess, getPawnsSellRequested, getPawnsSellFailure,
  listedSuccessfully, unListedSuccessfully, boughtSuccessfully,
  getNewPawnSuccess, getNewInventory, resetInventory, lastPawnsData,
  getPawnsSellForEquipmentSuccess, getPage, resetInventoryForEquipment,
  changePage, getNewInventoryForEquipment, updatePointsMyPawns,
} = requests.actions;

// ---- request actions ----
export const getPawnsBuy = (payload) => async (dispatch) => {
  try {
    dispatch(getPawnsBuyRequested());
    const response = await getListedItems(payload);
    if (response.status >= 400) {
      dispatch(getPawnsBuyFailure(response.data.message));
    } else if (response.status <= 204) {
      dispatch(getPawnsBuySuccess(response.data));
    }
  } catch (e) {
    dispatch(getPawnsBuyFailure(e.message));
  }
};

export const getPawnsSell = (payload) => async (dispatch) => {
  try {
    dispatch(getPawnsSellRequested());
    const response = await getMyItems(payload);
    if (response.status >= 400) {
      dispatch(getPawnsSellFailure(response.data.message));
    } else if (response.status <= 204) {
      dispatch(getPawnsSellSuccess(response.data));
    }
  } catch (e) {
    dispatch(getPawnsSellFailure(e.message));
  }
};

export const getPawnsSellForEquipment = (payload, offset = false) => async (dispatch) => {
  try {
    if (!offset) {
      dispatch(getPawnsSellRequested());
    }
    const response = await getMyItems(payload);
    if (response.status >= 400) {
      dispatch(getPawnsSellFailure(response.data.message));
    } else if (response.status <= 204) {
      if (response.data?.results?.length < 12
        || response.data?.count === response.data?.results?.length) {
        dispatch(lastPawnsData(true));
      }
      if (response.data?.count > 12 && response.data?.results?.length === 12) {
        dispatch(lastPawnsData(false));
      }
      dispatch(getPawnsSellForEquipmentSuccess({
        data: response.data,
        offset,
      }));
      dispatch(changePage(true));
    }
  } catch (e) {
    dispatch(getPawnsSellFailure(e.message));
  }
};

export const getNewPawn = (payload) => async (dispatch) => {
  try {
    const response = await getItem(payload);
    if (response.status <= 204) {
      dispatch(getNewPawnSuccess(response.data));
    }
  } catch (e) {
    console.log(e.message);
  }
};

// ---- selectors ----
export const selectMarketplace = (state) => (state.requests);
export const selectLoading = (state) => (state.requests.loading);
export const selectPage = (state) => (state.requests.page);
export const selectPageForSpells = (state) => (state.requests.pageForSpells);
export const selectWasLastSpells = (state) => (state.requests.wasLastSpells);
export const selectPawnsForEquipment = (state) => (state.requests.inventoryForEquipment);
export const selectWasLastPawns = (state) => (state.requests.wasLastPawns);
export const selectIsChangePage = (state) => (state.requests.isChangePage);
