import React, {
  useEffect, useLayoutEffect,
  useMemo, useRef,
} from 'react';
import ReactTooltip from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import uniqueId from '../../../../utils/uniqueId';
import { addPawnSpell } from '../../../../store/slices/preset';
import Tooltip from '../../components/Tooltip';
import styles from '../Inventory.module.scss';
import loader from '../../../../static/img/loader.gif';
import {
  getPageForSpells,
  getSpellsData, lastSpellsData,
  selectLoadingSpells, selectPageForSpell, selectSpells, selectWasLastSpell,
} from '../../../../store/slices/constructor';
import { filterApplication, filterClass, filterTypeData } from '../../../../utils/chooseData';
import { selectEvm } from '../../../../store/slices/auth';

const PawnSpells = ({
  pawnGuildSpells, pawn, selectedPawn,
  filterByApplication, filterByType, filterByClassForSpell,
}) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const firstUpdate = useRef(true);

  const address = useSelector(selectEvm);
  const loading = useSelector(selectLoadingSpells);
  const wasLastSpells = useSelector(selectWasLastSpell);
  const page = useSelector(selectPageForSpell);
  const spellsCards = useSelector(selectSpells);

  const handleHeight = (value) => {
    if (window.innerWidth > 1921) {
      if (value > 1400) {
        return 2.35;
      }
      if (value > 1300) {
        return 2.68;
      }
      if (value > 1200) {
        return 2.72;
      }
      if (value > 1100) {
        return 2.8;
      }
      return 2.85;
    }
    return pathname.includes('crafting') ? 2.6 : 2.76;
  };

  const generateRequest = () => ({
    limit: 48,
    address,
    offset: (page - 1) * 48,
    application: filterApplication[filterByApplication],
    type: filterTypeData[filterByType],
    class: filterClass[filterByClassForSpell],
  });

  const fetchSpells = (params, offset, wasLast) => {
    if (wasLast) return;
    dispatch(getSpellsData(params, offset));
    dispatch(getPageForSpells(page + 1));
  };

  useLayoutEffect(() => {
    if (wasLastSpells) return;
    if (firstUpdate.current) {
      dispatch(getSpellsData(generateRequest()));
      dispatch(getPageForSpells(2));
      firstUpdate.current = false;
      return;
    }
    dispatch(getSpellsData({
      limit: 48,
      offset: 0,
      application: filterApplication[filterByApplication],
      type: filterTypeData[filterByType],
      class: filterClass[filterByClassForSpell],
    }));
    dispatch(lastSpellsData(false));
    dispatch(getPageForSpells(2));
  }, [filterByApplication, filterByType, filterByClassForSpell, wasLastSpells]);

  useEffect(() => () => dispatch(getPageForSpells(1)), []);

  return useMemo(() => {
    if (loading && !spellsCards?.length) {
      return (
        <div className={styles.loader}>
          <img src={loader} alt="loading..." />
        </div>
      );
    }
    if (spellsCards?.length) {
      return (
        <div className={styles.wrapper_content}>
          {/* <div className={styles.wrapper_spells}> */}
          <InfiniteScroll
            dataLength={spellsCards?.length}
            next={() => fetchSpells(generateRequest(), true, wasLastSpells)}
            hasMore={!wasLastSpells}
            scrollableTarget="scrollableDiv"
            loader={(
              <div className={styles.loader}>
                <img src={loader} alt="loading..." />
              </div>
            )}
            className={styles.wrapper_spells}
            height={window.innerHeight / handleHeight(window.innerHeight)}
          >
            {spellsCards.map((elem) => (
              <div key={uniqueId('pawns_spells')}>
                <div
                  className={styles.img_wrap}
                  role="presentation"
                  onClick={(Object.keys(pawn).length
                  && !Object.values(pawnGuildSpells)
                    .map((item) => (item ? item.id : null))
                    .includes(elem.id)
                    ? () => {
                      dispatch(addPawnSpell({
                        pawnId: selectedPawn,
                        body: {
                          spellId: elem.id,
                          position: Object.values(pawnGuildSpells).indexOf(null),
                        },
                      }, {
                        image: elem.image,
                        id: elem.id,
                      }));
                    }
                    : () => {
                    })}
                >
                  <img
                    src={elem.image}
                    alt="spell"
                    width="52px"
                    height="52px"
                    data-tip
                    data-for={`${elem.id}spell`}
                  />
                </div>
                <ReactTooltip
                  id={`${elem.id}spell`}
                  place="top"
                  backgroundColor="none"
                  arrowColor="transparent"
                  delayShow={100}
                >
                  <Tooltip
                    name={elem.spellName}
                    stats={{
                      application: elem?.application,
                      class: elem?.class,
                      cost: elem?.cost,
                      cooldown: elem?.cooldown,
                      type: elem?.type,
                      quickness: elem?.quickness,
                      effect_duration: elem?.effect_duration,
                      target: elem?.target,
                    }}
                  />
                </ReactTooltip>
              </div>
            ))}
            {/* </div> */}
          </InfiniteScroll>
        </div>
      );
    }
    return (
      <div className={styles.no_data}>
        You do not have any spells
      </div>
    );
  }, [pawnGuildSpells, loading, page]);
};

export default PawnSpells;
