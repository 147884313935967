import { createSlice } from '@reduxjs/toolkit';
import {
  getEquipment,
  // getConsumables,
  // getEquipment,
  getGuildsConsumables,
  getGuildsEquipment, getSpellById, getSpells,
  postCreateConsumable,
  postCreateEquipment,
} from '../../../utils/requests';
import { notificationShowAction } from '../utils';

export const constructorForm = createSlice({
  name: 'constructor',
  initialState: {
    spellLoading: false,
    spellsLoading: false,
    allItemsLoading: false,
    equipmentLoading: false,
    consumablesLoading: false,
    equipment: null,
    allItems: null,
    consumables: null,
    spells: null,
    spellById: null,
    error: null,
    pageForSpells: 1,
    pageForItems: 1,
    pageForEquipment: 1,
    prevPageConsumables: 0,
    prevPageEquipment: 0,
    wasLastItem: false,
    wasLastEquipment: false,
    wasLastSpells: false,
  },
  reducers: {
    spellByIdRequested: (state) => ({
      ...state,
      spellLoading: true,
    }),
    spellsRequested: (state) => ({
      ...state,
      spellsLoading: true,
    }),
    equipmentRequested: (state) => ({
      ...state,
      equipmentLoading: true,
    }),
    consumablesRequested: (state) => ({
      ...state,
      consumablesLoading: true,
    }),
    spellsFailure: (state, { payload }) => ({
      ...state,
      spellsLoading: false,
      error: payload,
    }),
    spellByIdFailure: (state, { payload }) => ({
      ...state,
      spellLoading: false,
      error: payload,
    }),
    equipmentFailure: (state, { payload }) => ({
      ...state,
      equipmentLoading: false,
      error: payload,
    }),
    consumablesFailure: (state, { payload }) => ({
      ...state,
      consumablesLoading: false,
      error: payload,
    }),
    spellsSuccess: (state, { payload }) => {
      const pageCount = JSON.parse(JSON.stringify(state.pageForSpells));
      return ({
        ...state,
        spellsLoading: false,
        spells: payload.offset ? [...state.spells, ...payload.data.results] : payload.data.results,
        countSpells: payload.data.count,
        prevPageEquipment: pageCount,
        error: false,
      });
    },
    spellByIdSuccess: (state, { payload }) => ({
      ...state,
      spellLoading: false,
      spellById: payload && Object.keys(payload).length ? {
        application: payload?.application,
        class: payload?.class,
        cost: payload?.cost,
        cooldown: payload?.cooldown,
        type: payload?.type,
        quickness: payload?.quickness,
        effect_duration: payload?.effect_duration,
        target: payload?.target,
      } : null,
      error: false,
    }),
    equipmentSuccess: (state, { payload }) => {
      const pageCount = JSON.parse(JSON.stringify(state.pageForItems));
      return ({
        ...state,
        equipmentLoading: false,
        equipment: payload.offset ? [...state.equipment, ...payload.data.results] : payload.data.results,
        countEquipment: payload.data.count,
        prevPageEquipment: pageCount,
        error: false,
      });
    },
    addEquipmentAfterCrafting: (state, { payload }) => ({
      ...state,
      equipment: [...state.equipment, payload.equipment],
    }),
    filterEquipmentCard: (state, { payload }) => ({
      ...state,
      equipment: state.equipment.filter((elem) => elem.id !== payload),
    }),
    backToEquip: (state, { payload }) => ({
      ...state,
      equipment: [...state.equipment, payload],
    }),
    filterItemsCard: (state, { payload }) => ({
      ...state,
      consumables: state.consumables.filter((elem) => elem.id !== payload),
    }),
    backToItems: (state, { payload }) => ({
      ...state,
      consumables: [...state.consumables, payload],
    }),
    consumablesSuccess: (state, { payload }) => {
      const pageCount = JSON.parse(JSON.stringify(state.pageForItems));
      return ({
        ...state,
        consumablesLoading: false,
        consumables: payload.offset ? [...state.consumables, ...payload.data.results] : payload.data.results,
        count: payload.data.count,
        error: false,
        prevPageConsumables: pageCount,
      });
    },
    addConsumableAfterCrafting: (state, { payload }) => ({
      ...state,
      consumables: [...state.consumables, payload.equipment],
    }),
    getPageForItems: (state, { payload }) => ({
      ...state,
      pageForItems: payload,
    }),
    getPageForEquipment: (state, { payload }) => ({
      ...state,
      pageForEquipment: payload,
    }),
    getPageForSpells: (state, { payload }) => ({
      ...state,
      pageForSpells: payload,
    }),
    lastEquipmentData: (state, { payload }) => ({
      ...state,
      wasLastEquipment: payload,
    }),
    lastItemsData: (state, { payload }) => ({
      ...state,
      wasLastItem: payload,
    }),
    lastSpellsData: (state, { payload }) => ({
      ...state,
      wasLastSpells: payload,
    }),
    equipmentCreatedSuccessFully: (state) => ({
      ...state,
      equipmentLoading: false,
      error: false,
    }),
    consumableCreatedSuccessFully: (state) => ({
      ...state,
      consumablesLoading: false,
      error: false,
    }),
    usingItem: (state, { payload }) => {
      const consumablesArray = JSON.parse(JSON.stringify([...state.consumables]));
      const result = consumablesArray.map((elem) => (elem.id === payload.id ? ({
        ...elem,
        usedByPawn: payload.pawnId || null,
      }) : ({ ...elem })));
      return ({
        ...state,
        consumables: result,
      });
    },
    usingEquipment: (state, { payload }) => {
      const equipmentArray = JSON.parse(JSON.stringify([...state.equipment]));
      const result = equipmentArray.map((elem) => (elem.id === payload.id ? ({
        ...elem,
        usedByPawn: payload.pawnId || null,
      }) : ({ ...elem })));
      return ({
        ...state,
        equipment: result,
      });
    },
    clearDataEquipment: (state) => ({
      ...state,
      equipment: null,
      consumables: null,
      spells: null,
      pageForSpells: 1,
      pageForEquipment: 1,
      pageForItems: 1,
      prevPageConsumables: 0,
      wasLastItem: false,
      wasLastEquipment: false,
      wasLastSpells: false,
      count: null,
      countEquipment: null,
      countSpells: null,
    }),
    clearEquipment: (state) => ({
      ...state,
      equipment: null,
    }),
    clearConsumables: (state) => ({
      ...state,
      consumables: null,
    }),
    clearSpellsData: (state) => ({
      ...state,
      spells: null,
    }),
    clearSpell: (state) => ({
      ...state,
      spellById: null,
    }),
    getAllItemsRequested: (state) => ({
      ...state,
      allItemsLoading: true,
    }),
    getAllItemsFailure: (state, { payload }) => ({
      ...state,
      allItemsLoading: false,
      error: payload,
    }),
    getAllItemsSuccess: (state, { payload }) => ({
      ...state,
      allItemsLoading: false,
      allItems: payload.data.results,
      error: false,
    }),
  },
});

// ---- actions ----
export const {
  equipmentRequested, consumablesRequested, equipmentFailure, consumablesFailure, spellsFailure, spellsRequested,
  equipmentSuccess, consumablesSuccess, consumableCreatedSuccessFully, equipmentCreatedSuccessFully, spellsSuccess,
  clearDataEquipment, usingItem, usingEquipment, clearEquipment, spellByIdRequested, spellByIdFailure, spellByIdSuccess,
  clearSpell, clearConsumables,
  getAllItemsRequested, getAllItemsFailure, getAllItemsSuccess, clearSpellsData,
  filterEquipmentCard, backToEquip,
  backToItems, filterItemsCard,
  getPageForItems, lastItemsData,
  getPageForEquipment, lastEquipmentData,
  addEquipmentAfterCrafting, addConsumableAfterCrafting,
  getPageForSpells, lastSpellsData,
} = constructorForm.actions;

// ---- request actions ----
export const getEquipmentData = (payload, offset = false) => async (dispatch) => {
  try {
    dispatch(equipmentRequested());
    const response = await getGuildsEquipment(payload);
    if (response.status >= 400) {
      dispatch(equipmentFailure(response.detail));
      dispatch(notificationShowAction(false, response.title));
    } else if (response.status <= 204) {
      if (!response.data.next || response.data?.results?.length < 48
          || response.data?.count === response.data?.results?.length) {
        dispatch(lastEquipmentData(true));
      }
      if (response.data.next || (response.data?.count > 48 && response.data?.results?.length === 48)) {
        dispatch(lastEquipmentData(false));
      }
      dispatch(equipmentSuccess({ data: response.data, offset }));
    }
  } catch (e) {
    dispatch(equipmentFailure(e.message));
  }
};

export const getAllItems = (payload, offset = false) => async (dispatch) => {
  try {
    dispatch(getAllItemsRequested());
    const response = await getEquipment(payload);
    if (response.status >= 400) {
      dispatch(getAllItemsFailure(response.detail));
      dispatch(notificationShowAction(false, response.title));
    } else if (response.status <= 204) {
      dispatch(getAllItemsSuccess({
        data: response.data,
        offset,
      }));
    }
  } catch (e) {
    dispatch(getAllItemsFailure(e.message));
  }
};

export const getConsumablesData = (payload, offset = false) => async (dispatch) => {
  try {
    dispatch(consumablesRequested());
    const response = await getGuildsConsumables(payload);
    if (response.status >= 400) {
      dispatch(consumablesFailure(response.detail));
      dispatch(notificationShowAction(false, response.title));
    } else if (response.status <= 204) {
      if (!response.data.next || response.data?.results?.length < 48
          || response.data?.count === response.data?.results?.length) {
        dispatch(lastItemsData(true));
      }
      if (response.data.next || (response.data?.count > 48 && response.data?.results?.length === 48)) {
        dispatch(lastItemsData(false));
      }
      dispatch(consumablesSuccess({ data: response.data, offset }));
    }
  } catch (e) {
    dispatch(consumablesFailure(e.message));
  }
};

export const getSpellsData = (payload, offset = false) => async (dispatch) => {
  try {
    dispatch(spellsRequested());
    const response = await getSpells(payload);
    if (response.status >= 400) {
      dispatch(spellsFailure(response.detail));
      dispatch(notificationShowAction(false, response.title));
    } else if (response.status <= 204) {
      if (!response.data.next || response.data?.results?.length < 48
          || response.data?.count === response.data?.results?.length) {
        dispatch(lastSpellsData(true));
      }
      if (response.data.next || (response.data?.count > 48 && response.data?.results?.length === 48)) {
        dispatch(lastSpellsData(false));
      }
      dispatch(spellsSuccess({ data: response.data, offset }));
    }
  } catch (e) {
    dispatch(spellsFailure(e.message));
  }
};

export const getSpellsDataById = (payload) => async (dispatch) => {
  try {
    dispatch(spellByIdRequested());
    const response = await getSpellById(payload);
    if (response.status >= 400) {
      dispatch(spellByIdFailure(response.detail));
      dispatch(notificationShowAction(false, response.title));
    } else if (response.status <= 204) {
      dispatch(spellByIdSuccess(response.data));
    }
  } catch (e) {
    dispatch(spellByIdFailure(e.message));
  }
};

export const createEquipment = (formData, reset) => async (dispatch) => {
  try {
    dispatch(equipmentRequested());
    const response = await postCreateEquipment(formData);
    if (response.status >= 400) {
      dispatch(equipmentFailure(response.data.detail));
      dispatch(notificationShowAction(false, response.data.detail));
    } else if (response.status <= 204) {
      dispatch(equipmentCreatedSuccessFully());
      dispatch(notificationShowAction(true, 'Item was created successfully'));
      reset();
    }
  } catch (e) {
    dispatch(equipmentFailure(e.message));
  }
};

export const createConsumable = (formatData, reset) => async (dispatch) => {
  try {
    dispatch(consumablesRequested());
    const response = await postCreateConsumable(formatData);
    if (response.status >= 400) {
      dispatch(consumablesFailure(response.data.detail));
      dispatch(notificationShowAction(false, response.data.detail));
    } else if (response.status <= 204) {
      dispatch(consumableCreatedSuccessFully());
      dispatch(notificationShowAction(true, 'Consumable was created successfully'));
      reset();
    }
  } catch (e) {
    dispatch(consumablesFailure(e.message));
  }
};

export const selectConsumables = (state) => state.constructorForm.consumables;
export const selectLoadingConsumables = (state) => state.constructorForm.consumablesLoading;
export const selectEquipment = (state) => state.constructorForm.equipment;
export const selectWasLastEquipment = (state) => state.constructorForm.wasLastEquipment;
export const selectPageForEquipment = (state) => state.constructorForm.pageForEquipment;
export const selectLoadingEquipment = (state) => state.constructorForm.equipmentLoading;
export const selectAllItems = (state) => state.constructorForm.allItems;
export const selectLoadingAllItems = (state) => state.constructorForm.allItemsLoading;
export const selectSpells = (state) => state.constructorForm.spells;
export const selectLoadingSpells = (state) => state.constructorForm.spellsLoading;
export const selectSpellLoading = (state) => state.constructorForm.spellLoading;
export const selectSpellStats = (state) => state.constructorForm.spellById;
export const selectPageForItems = (state) => state.constructorForm.pageForItems;
export const selectWasLastItem = (state) => state.constructorForm.wasLastItem;
export const selectWasLastSpell = (state) => state.constructorForm.wasLastSpells;
export const selectPageForSpell = (state) => state.constructorForm.pageForSpells;
