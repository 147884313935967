/* eslint-disable prefer-object-spread */
import Phaser from 'phaser';
import {
  DEFAULT_FRAME_RATE,
  getSpriteByName,
  getSpriteOrigin,
} from './const';

class Pawn extends Phaser.GameObjects.Group {
  constructor(config) {
    super(config.scene);
    console.log('PAWN SKILL CREATE CONFIG', config);
    const newConfig = {
      ...config,
      pawnConfig: {
        // ...Object.assign({}, config.pawnConfig),
        ...{ ...config.pawnConfig, sprites: [...config.pawnConfig.sprites.map((s) => ({ ...s }))] },
        key: config.pawnConfig.key.toLowerCase(),
      },
    };
    this.config = newConfig;
    this.objects = {};
    newConfig.scene.add.existing(this);
    this.isCurrentPlayer = newConfig.isCurrentPlayer;
    this.pawnId = newConfig.idPawn;
    this.idPawn = newConfig.idPawn;

    this.pawnInfo = config.info;
    this.pawnInfoNext = null;

    this.className = this.config.pawnConfig.key;

    if (config.set) {
      this.setSet(config.set, this.config.pawnConfig);
    } else {
      this.afterLoadSprite();
    }

    this.buffState = [];
  }

  setSet(set, pawnConfig) {
    this.set = set;
    this.config.pawnConfig = pawnConfig;
    pawnConfig.key = set.id;
    pawnConfig.sprites[0].path = set.skin.path;
    pawnConfig.sprites[0].key = `${set.id}-main`;
    const s = pawnConfig.sprites[0];
    this.scene.load.spritesheet(
      pawnConfig.sprites[0].key,
      pawnConfig.sprites[0].path,
      { frameWidth: s.width, frameHeight: s.height },
    );
    this.scene.load.once(Phaser.Loader.Events.COMPLETE, () => {
      this.afterLoadSprite();
    });
    this.scene.load.start();
  }

  afterLoadSprite() {
    this.createInternal(this.config);
    this.add(this.objects.pawn);
    if (this.pawnInfo && !this.pawnInfo.isAlive) {
      this.objects.pawn.anims.play(`anim-${this.config.pawnConfig.key}-death`);
    }
  }

  static preload(scene, conf) {
    conf.sprites.forEach(
      (s) => {
        scene.load.spritesheet(s.key, s.path, { frameWidth: s.width, frameHeight: s.height });
      },
    );
  }

  createAnimations(config) {
    config.pawnConfig.sprites.forEach(
      (s) => {
        s.animations.forEach(
          (a) => {
            const gfnConf = {};
            if (Object.hasOwn(a, 'start')) {
              gfnConf.start = a.start;
            }
            if (Object.hasOwn(a, 'end')) {
              gfnConf.end = a.end;
            }
            this.scene.anims.create({
              key: a.key.replace(this.className, this.config.pawnConfig.key),
              frames: this.scene.anims.generateFrameNumbers(s.key, gfnConf),
              frameRate: a.frameRate || DEFAULT_FRAME_RATE,
              repeat: Object.hasOwn(a, 'repeat') ? a.repeat : 0,
            });
          },
        );
      },
    );
  }

  createInternal(config) {
    this.createAnimations(config);

    const mainSpriteConfig = getSpriteByName(config.pawnConfig, `${config.pawnConfig.key.toLowerCase()}-main`);
    const mainSpriteOrigin = getSpriteOrigin(mainSpriteConfig, this.isCurrentPlayer);

    this.objects.interactiveArea = this.scene.add
      .circle(config.x, config.y, 110)
      .setInteractive({
        cursor: 'pointer',
        // hitArea: (new Phaser.Geom.Rectangle(config.x, config.y, 50, 200)),
        // hitAreaCallback: Pawn.hitAreaHandler,
      })
      .on('pointerdown', this.onClick.bind(this));
    if (this.config.debug) {
      this.objects.interactiveArea.setStrokeStyle(2, 0x1a65ac);
    }
    this.objects.shadow = this.create(config.x, config.y, 'heroShadow');
    this.objects.shadow.setDisplayOrigin(150, -65);
    this.objects.shadow.alpha = 0.8;

    this.objects.pawn = this.create(config.x, config.y, `${config.pawnConfig.key}-main`)
      .setDisplayOrigin(...mainSpriteOrigin)
      .setDepth(20);
    this.objects.pawn.anims.play(`anim-${config.pawnConfig.key}-idle`);
    if (!this.isCurrentPlayer) {
      this.objects.pawn.flipX = true;
    }
  }

  onClick() {
    console.log('CLICK ON PAWN', this);
    this.scene.selectPawn(this);
  }

  // eslint-disable-next-line class-methods-use-this
  checkBuffEnd(buffs, queuePromise) {
    return queuePromise;
    // let newQueue = queuePromise;
    // Object.keys(this.buffState).forEach(
    //   (parameter) => {
    //     if (this.buffState[parameter] && !buffs.some((b) => b.parameter === parameter)) {
    //       newQueue = newQueue.then(
    //         () => new Promise((resolve) => {
    //           // console.log('---Run Check buff promises');
    //           this.buffState = this.buffState.filter((s) => s !== parameter);
    //           this.fireballBuffEnd(resolve);
    //         }),
    //       );
    //     }
    //   },
    // );
    // return newQueue;
  }

  setNextInfo(info) {
    return new Promise((resolve) => {
      this.pawnInfoNext = info;
      resolve();
    });
  }

  switchInfo() {
    return new Promise((resolve) => {
      // console.log('---Run Switch info promise');
      if (this.pawnInfo && this.pawnInfoNext && this.pawnInfo.isAlive !== this.pawnInfoNext.isAlive) {
        this.objects.pawn.anims
          .play(`anim-${this.config.pawnConfig.key}-death`)
          .once(
            `${Phaser.Animations.Events.ANIMATION_COMPLETE_KEY}anim-${this.config.pawnConfig.key}-death`,
            () => {
              this.pawnInfo = this.pawnInfoNext;
              resolve();
            },
          );
      } else {
        this.pawnInfo = this.pawnInfoNext;
        resolve();
      }
    });
  }

  showFloatingText(timeline, at, duration, text, color, onPawn) {
    const pawn = onPawn || this.objects.pawn;
    const tObj = this.scene.add.text(
      pawn.x,
      pawn.y - 150,
      `${text || ''}`,
      { font: '46px soextended', fill: color, align: 'center' },
    );
    // eslint-disable-next-line dot-notation
    tObj['originX'] = 0.5;
    // eslint-disable-next-line dot-notation
    tObj['originY'] = 0.5;
    tObj.setShadow(3, 3, 'rgba(0,0,0,0.5)', 0);
    timeline.add({
      at,
      tween: {
        targets: tObj,
        persist: false,
        props: {
          y: {
            duration,
            ease: 'Quad.easeOut',
            value: { from: pawn.y - 150, to: pawn.y - 250 },
          },
        },
      },
      run: () => {
        setTimeout(() => {
          tObj.destroy();
        }, duration);
      },
    });
  }
}

export default Pawn;
