import React from 'react';
import styles from './HeaderCasting.module.scss';
import uniqueId from '../../../../utils/uniqueId';
import { archerData } from '../../../../constants/archer';
import { mageData } from '../../../../constants/mage';
import { warriorData } from '../../../../constants/warrior';

const HeaderCasting = ({
  actions, address, opponent, player,
}) => {
  const chooseData = {
    Archer: archerData,
    Mage: mageData,
    Warrior: warriorData,
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_buff}>
        <div className={styles.buff}>
          {/* eslint-disable-next-line array-callback-return,consistent-return */}
          {actions.map((item) => {
            if (item.from === address && item.type === 'Casting') {
              return (
                <img
                  height="32px"
                  width="32px"
                  key={uniqueId('headerCasting')}
                  src={chooseData[player.class].spells[item.spell].img}
                  alt="buff"
                />
              );
            }
          })}
        </div>
        <div className={styles.separator} />
        <div className={styles.casting}>
          <span className={styles.text}>Casting</span>
        </div>
        <div className={styles.separator} />
        <div className={styles.buff}>
          {/* eslint-disable-next-line array-callback-return,consistent-return */}
          {actions.map((item) => {
            if (item.from !== address && item.type === 'Casting') {
              return (
                <img
                  width="32px"
                  height="32px"
                  key={uniqueId('headerCasting')}
                  src={chooseData[opponent.class].spells[item.spell].img}
                  alt="buff"
                />
              );
            }
          })}
        </div>
      </div>
      <div className={styles.bottom_separator} />
    </div>
  );
};

export default HeaderCasting;
