import { createSlice } from '@reduxjs/toolkit';
import {
  getQuestById, getQuests, putReward, startQuest,
} from '../../../utils/requests';
import { notificationShowAction } from '../utils';

export const quests = createSlice({
  name: 'quests',
  initialState: {
    loading: false,
    startQuestLoading: false,
    questLoading: false,
    rewardLoading: false,
    questsData: null,
    questInfo: null,
    selectedPawn: [],
    reward: null,
    startQuest: false,
    error: null,
  },
  reducers: {
    questsRequested: (state) => ({
      ...state,
      loading: true,
    }),
    questRequested: (state) => ({
      ...state,
      startQuestLoading: true,
    }),
    questInfoRequested: (state) => ({
      ...state,
      questLoading: true,
    }),
    getRewardRequested: (state) => ({
      ...state,
      rewardLoading: true,
    }),
    questsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    questFailure: (state, { payload }) => ({
      ...state,
      questLoading: false,
      error: payload,
    }),
    questInfoFailure: (state, { payload }) => ({
      ...state,
      questLoading: false,
      error: payload,
    }),
    getRewardFailure: (state, { payload }) => ({
      ...state,
      rewardLoading: false,
      error: payload,
    }),
    questsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      questsData: payload,
      error: false,
    }),
    questStartedSuccess: (state) => ({
      ...state,
      questLoading: false,
      startQuest: true,
      error: false,
    }),
    questInfoSuccess: (state, { payload }) => ({
      ...state,
      questLoading: false,
      questInfo: payload,
      error: false,
    }),
    getRewardSuccess: (state, { payload }) => ({
      ...state,
      rewardLoading: false,
      reward: payload,
      error: false,
    }),
    resetDataQuests: (state) => ({
      ...state,
      questsData: null,
    }),
    resetQuestInfo: (state) => ({
      ...state,
      questInfo: null,
    }),
    resetStartQuest: (state) => ({
      ...state,
      startQuest: false,
    }),
    resetGetReward: (state) => ({
      ...state,
      reward: null,
    }),
    selectedPawn: (state, { payload }) => ({
      ...state,
      selectedPawn: [...state.selectedPawn, payload],
    }),
    removePawn: (state, { payload }) => ({
      ...state,
      selectedPawn: [...state.selectedPawn].filter((elem) => elem.nftId !== payload.nftId),
    }),
    clearPawns: (state) => ({
      ...state,
      selectedPawn: [],
    }),
  },
});

// ---- actions ----
export const {
  questsRequested, questsFailure,
  questsSuccess, questInfoRequested,
  questInfoFailure, questInfoSuccess,
  resetDataQuests, resetQuestInfo, selectedPawn, removePawn, clearPawns,
  questRequested, questFailure, questStartedSuccess,
  getRewardSuccess, getRewardFailure, getRewardRequested,
  resetStartQuest, resetGetReward,
} = quests.actions;

// ---- request actions ----
export const getQuestsData = () => async (dispatch) => {
  try {
    dispatch(questsRequested());
    const response = await getQuests();
    if (response.status >= 400) {
      dispatch(questsFailure(response.detail));
      dispatch(notificationShowAction(false, response.detail));
    } else if (response.status <= 204) {
      dispatch(questsSuccess(response.data));
    }
  } catch (e) {
    dispatch(questsFailure(e.message));
  }
};

export const getQuestInfo = (payload) => async (dispatch) => {
  try {
    dispatch(questInfoRequested());
    const response = await getQuestById(payload);
    if (response.status >= 400) {
      dispatch(questInfoFailure(response.detail));
      dispatch(notificationShowAction(false, response.detail));
    } else if (response.status <= 204) {
      dispatch(questInfoSuccess(response.data));
    }
  } catch (e) {
    dispatch(questsFailure(e.message));
  }
};

export const putQuest = (payload) => async (dispatch) => {
  try {
    dispatch(questRequested());
    const response = await startQuest(payload);
    if (response.status >= 400) {
      dispatch(questFailure(response.data.detail));
      dispatch(notificationShowAction(false, response.data.detail));
    } else if (response.status <= 204) {
      dispatch(questStartedSuccess());
    }
  } catch (e) {
    dispatch(questFailure(e.message));
  }
};

export const getReward = (payload) => async (dispatch) => {
  try {
    dispatch(getRewardRequested());
    const response = await putReward(payload);
    if (response.status >= 400) {
      dispatch(getRewardFailure(response.data.detail));
      dispatch(notificationShowAction(false, response.data.detail));
    } else if (response.status <= 204) {
      dispatch(getRewardSuccess(response.data));
    }
  } catch (e) {
    dispatch(getRewardFailure(e.message));
  }
};

export const selectQuestsLoading = (state) => state.quests.loading;
export const selectQuests = (state) => state.quests.questsData;

export const selectQuestInfoLoading = (state) => state.quests.questLoading;
export const selectQuestInfo = (state) => state.quests.questInfo;
export const selectPickPawn = (state) => state.quests.selectedPawn;

export const selectStartQuest = (state) => state.quests.startQuest;
export const selectReward = (state) => state.quests.reward;
