import { createSlice } from '@reduxjs/toolkit';
import {
  getGuildsInfo, getListedPortraits, getMyPortraits, getPortraits, putPortrait,
} from '../../../utils/portraits';

export const portraits = createSlice({
  name: 'portraits',
  initialState: {
    portraitsError: '',
    portraitsLoading: false,
    portraits: [],
    portraitsNext: null,
    portraitsCount: 0,
    myPortraitsError: '',
    myPortraitsLoading: false,
    myPortraits: [],
    myPortraitsNext: null,
    myPortraitsCount: 0,
    listedPortraitsError: '',
    listedPortraitsLoading: false,
    listedPortraits: [],
    listedPortraitsNext: null,
    listedPortraitsCount: 0,
    guildsInfo: null,
    guildsInfoLoading: false,
    guildsInfoError: '',
    setNewPortrait: false,
    selectedPortrait: null,
    portraitRequestLoader: false,
  },
  reducers: {
    getPortraitsRequested: (state) => ({
      ...state,
      portraitsLoading: true,
    }),
    getPortraitsFailure: (state, { payload }) => ({
      ...state,
      portraitsLoading: false,
      portraitsError: payload,
    }),
    getPortraitsSuccess: (state, { payload }) => ({
      ...state,
      portraitsLoading: false,
      portraitsLoadingError: '',
      portraits: payload.results,
      portraitsNext: payload.next,
      portraitsCount: payload.count,
    }),
    getMyPortraitsRequested: (state) => ({
      ...state,
      myPortraitsLoading: true,
    }),
    getMyPortraitsFailure: (state, { payload }) => ({
      ...state,
      myPortraitsLoading: false,
      myPortraitsError: payload,
    }),
    getMyPortraitsSuccess: (state, { payload }) => ({
      ...state,
      myPortraitsLoading: false,
      myPortraitsLoadingError: '',
      myPortraits: payload.results,
      myPortraitsNext: payload.next,
      myPortraitsCount: payload.count,
    }),
    getListedPortraitsRequested: (state) => ({
      ...state,
      listedPortraitsLoading: true,
    }),
    getListedPortraitsFailure: (state, { payload }) => ({
      ...state,
      listedPortraitsLoading: false,
      listedPortraitsError: payload,
    }),
    getListedPortraitsSuccess: (state, { payload }) => ({
      ...state,
      listedPortraitsLoading: false,
      listedPortraitsLoadingError: '',
      listedPortraits: payload.results,
      listedPortraitsNext: payload.next,
      listedPortraitsCount: payload.count,
    }),
    getGuildsInfoRequested: (state) => ({
      ...state,
      guildsInfoLoading: true,
    }),
    getGuildsInfoFailure: (state, { payload }) => ({
      ...state,
      guildsInfoLoading: false,
      guildsInfoError: payload,
    }),
    getGuildsInfoSuccess: (state, { payload }) => ({
      ...state,
      guildsInfoLoading: false,
      guildsInfoError: '',
      guildsInfo: payload.portrait,
    }),
    putPortraitRequested: (state) => ({
      ...state,
      putPortraitLoading: true,
    }),
    putPortraitFailure: (state, { payload }) => ({
      ...state,
      putPortraitLoading: false,
      putPortraitError: payload,
    }),
    putPortraitSuccess: (state, { payload }) => ({
      ...state,
      putPortraitLoading: false,
      putPortraitError: '',
      guildsInfo: payload,
      setNewPortrait: true,
    }),
    onPortraitRequestLoader: (state, { payload }) => ({
      ...state,
      portraitRequestLoader: payload,
    }),
    setSelectedPortrait: (state, { payload }) => ({
      ...state,
      selectedPortrait: payload,
    }),
    clearPortrait: (state) => ({
      ...state,
      setNewPortrait: false,
    }),
    onBuyPortraitOfferingReducer(state, { payload }) {
      const tempArr = [...state.myPortraits];
      tempArr.forEach((portrait) => {
        if (portrait.nftId.toString() === payload.id) {
          portrait.nftStatus = 'Listed';
          portrait.price = payload.price;
          if (state.selectedPortrait.nftId.toString() === payload.id) {
            state.selectedPortrait.price = payload.price;
            state.selectedPortrait.nftStatus = 'Listed';
            state.selectedPortrait.offerId = payload.offerId;
          }
        }
      });
      state.myPortraits = tempArr;
    },
    onNewPortraitOfferingReducer(state, { payload }) {
      const tempArr = [...state.myPortraits];
      tempArr.forEach((portrait) => {
        if (portrait.nftId.toString() === payload.id) {
          portrait.nftStatus = 'Listed';
          portrait.price = payload.price;
          if (state.selectedPortrait.nftId.toString() === payload.id) {
            state.selectedPortrait.price = payload.price;
            state.selectedPortrait.nftStatus = 'Listed';
            state.selectedPortrait.offerId = payload.offerId;
          }
        }
      });
      state.myPortraits = tempArr;
    },
    cancelPortraitOfferingReducer(state, { payload }) {
      const tempArr = [...state.myPortraits];
      tempArr.forEach((portrait) => {
        if (portrait.nftId.toString() === payload) {
          portrait.nftStatus = 'Minted';
          portrait.price = '0';
          portrait.offerId = null;
          if (state.selectedPortrait.nftId.toString() === payload) {
            state.selectedPortrait.price = '0';
            state.selectedPortrait.nftStatus = 'Minted';
            state.selectedPortrait.offerId = null;
          }
        }
      });
    },
  },
});

// ---- actions ----
export const {
  getMyPortraitsSuccess, getMyPortraitsRequested, getMyPortraitsFailure,
  getListedPortraitsSuccess, getListedPortraitsRequested, getListedPortraitsFailure,
  getPortraitsSuccess, getPortraitsRequested, getPortraitsFailure,
  getGuildsInfoSuccess, getGuildsInfoRequested, getGuildsInfoFailure,
  putPortraitSuccess, putPortraitRequested, putPortraitFailure, clearPortrait,
  onNewPortraitOfferingReducer, cancelPortraitOfferingReducer, setSelectedPortrait,
  onPortraitRequestLoader, onBuyPortraitOfferingReducer,
} = portraits.actions;

// ---- request actions ----
export const getPortraitsAction = (payload) => async (dispatch) => {
  try {
    dispatch(getPortraitsRequested());
    const response = await getPortraits(payload);
    if (response.status >= 400) {
      dispatch(getPortraitsFailure(response.data.message));
    } else if (response.status <= 204) {
      dispatch(getPortraitsSuccess(response.data));
    }
  } catch (e) {
    dispatch(getPortraitsFailure(e.message));
  }
};

export const getMyPortraitsAction = (payload) => async (dispatch) => {
  try {
    dispatch(getMyPortraitsRequested());
    const response = await getMyPortraits(payload);
    if (response.status >= 400) {
      dispatch(getMyPortraitsFailure(response.data.message));
    } else if (response.status <= 204) {
      dispatch(getMyPortraitsSuccess(response.data));
    }
  } catch (e) {
    dispatch(getMyPortraitsFailure(e.message));
  }
};

export const getListedPortraitsAction = (payload) => async (dispatch) => {
  try {
    dispatch(getMyPortraitsRequested());
    const response = await getListedPortraits(payload);
    if (response.status >= 400) {
      dispatch(getMyPortraitsFailure(response.data.message));
    } else if (response.status <= 204) {
      dispatch(getMyPortraitsSuccess(response.data));
    }
  } catch (e) {
    dispatch(getMyPortraitsFailure(e.message));
  }
};

export const putPortraitAction = (portrait) => async (dispatch) => {
  try {
    dispatch(putPortraitRequested());
    const response = await putPortrait({ portrait: portrait.id.toString() });
    if (response.status >= 400) {
      dispatch(putPortraitFailure(response.data.message));
    } else if (response.status <= 204) {
      dispatch(putPortraitSuccess(portrait));
    }
  } catch (e) {
    dispatch(putPortraitFailure(e.message));
  }
};

export const getGuildsInfoAction = () => async (dispatch) => {
  try {
    dispatch(getGuildsInfoRequested());
    const response = await getGuildsInfo();
    if (response.status >= 400) {
      dispatch(getGuildsInfoFailure(response.data.message));
    } else if (response.status <= 204) {
      dispatch(getGuildsInfoSuccess(response.data));
    }
  } catch (e) {
    dispatch(getGuildsInfoFailure(e.message));
  }
};

// ---- selectors ----
export const selectPortraits = (state) => ({
  portraits: state.portraits.portraits,
  next: state.portraits.portraitsNext,
  count: state.portraits.portraitsCount,
  loading: state.portraits.portraitsLoading,
});

export const selectMyPortraits = (state) => ({
  portraits: state.portraits.myPortraits,
  next: state.portraits.myPortraitsNext,
  count: state.portraits.myPortraitsCount,
  loading: state.portraits.myPortraitsLoading,
});

export const selectListedPortraits = (state) => ({
  portraits: state.portraits.listedPortraits,
  next: state.portraits.listedPortraitsNext,
  count: state.portraits.listedPortraitsCount,
  loading: state.portraits.listedPortraitsLoading,
});

export const selectGuildsInfo = (state) => ({
  infoLoading: state.portraits.guildsInfoLoading,
  guildsInfo: state.portraits.guildsInfo,
});

export const selectSelectedPortrait = (state) => state.portraits.selectedPortrait;
export const selectPortraitRequestLoader = (state) => state.portraits.portraitRequestLoader;

export const selectIsNewPortraitSet = (state) => state.portraits.setNewPortrait;
