import React from 'react';
import disciplineLeft from '../../static/img/resource_bars/discipline_1.png';
import disciplineMid from '../../static/img/resource_bars/discipline_2.png';
import disciplineRight from '../../static/img/resource_bars/discipline_3.png';
import focusLeft from '../../static/img/resource_bars/focus_1.png';
import focusMid from '../../static/img/resource_bars/focus_2.png';
import focusRight from '../../static/img/resource_bars/focus_3.png';
import hpLeft from '../../static/img/resource_bars/hp_1.png';
import hpMid from '../../static/img/resource_bars/hp_2.png';
import hpRight from '../../static/img/resource_bars/hp_3.png';
import mpLeft from '../../static/img/resource_bars/mana_1.png';
import mpMid from '../../static/img/resource_bars/mana_2.png';
import mpRight from '../../static/img/resource_bars/mana_3.png';
import emptyLeft from '../../static/img/resource_bars/empty_1.png';
import emptyMid from '../../static/img/resource_bars/empty_2.png';
import emptyRight from '../../static/img/resource_bars/empty_3.png';
import uniqueId from '../../utils/uniqueId';

const character = {
  archer: {
    left: focusLeft,
    mid: focusMid,
    right: focusRight,
  },
  mage: {
    left: mpLeft,
    mid: mpMid,
    right: mpRight,
  },
  warrior: {
    left: focusLeft,
    mid: focusMid,
    right: focusRight,
  },
  hp: {
    left: hpLeft,
    mid: hpMid,
    right: hpRight,
  },
  discipline: {
    left: disciplineLeft,
    mid: disciplineMid,
    right: disciplineRight,
  },
  empty: {
    left: emptyLeft,
    mid: emptyMid,
    right: emptyRight,
  },
};

const ResourceBar = ({ type, count, maxCount }) => {
  const barsCount = (itemCount) => {
    if (itemCount === 0) return Array.from({ length: 7 }, () => false);
    if (itemCount < maxCount) {
      return [
        ...Array.from({ length: Math.ceil(itemCount / (maxCount / 6)) }, () => true),
        ...Array.from({ length: 7 - Math.ceil(itemCount / (maxCount / 6)) }, () => false),
      ];
    }
    return Array.from({ length: 7 }, () => true);
  };

  return (
    <div>
      {barsCount(count).map((elem, index) => {
        if (elem && index === 0) {
          return <img key={uniqueId('bar')} src={character[type].left} alt="" />;
        }
        if (!elem && index === 0) {
          return <img key={uniqueId('bar')} src={character.empty.left} alt="" />;
        }
        if (elem && index === 6) {
          return <img key={uniqueId('bar')} src={character[type].right} alt="" />;
        }
        if (!elem && index === 6) {
          return <img key={uniqueId('bar')} src={character.empty.right} alt="" />;
        }
        if (elem) {
          return <img key={uniqueId('bar')} src={character[type].mid} alt="" />;
        }
        return <img key={uniqueId('bar')} src={character.empty.mid} alt="" />;
      })}
    </div>
  );
};

export default ResourceBar;
