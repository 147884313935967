import React, {
  useMemo, useEffect, useState, useRef, useLayoutEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  getPage,
  getPawnsSellForEquipment, lastPawnsData, resetInventoryForEquipment, selectLoading,
  selectPage,
  selectPawnsForEquipment, selectWasLastPawns,
} from '../../../store/slices/requests';
import { selectEvm } from '../../../store/slices/auth';
import loader from '../../../static/img/loader.gif';
import Close from '../../../static/img/quests/Exit.png';
import uniqueId from '../../../utils/uniqueId';
import CardPawn from './CardPawn';
import StatsPreview from './StatsPreview';
import Filters from '../../../components/Marketplace/Components/Pawns/Filters';
import { filterType, tierType } from '../../../utils/chooseData';
import styles from './PickPawnModal.module.scss';

const sortType = {
  'Sort by': '',
  Level: 'Level',
};

const PickPawnModal = ({ setShowPickPawnModal, pickedPawns }) => {
  const dispatch = useDispatch();
  const firstUpdate = useRef(true);
  const firstUpdateFilter = useRef(true);

  const [filter, setFilter] = useState('Filter');
  const [sort, setSort] = useState('Sort by');
  const [sortDirection, setSortDirection] = useState('');
  const [tier, setTier] = useState('');
  const [search, setSearch] = useState('');

  const address = useSelector(selectEvm);
  const loading = useSelector(selectLoading);
  const wasLastPawn = useSelector(selectWasLastPawns);
  const page = useSelector(selectPage);
  const pawns = useSelector(selectPawnsForEquipment);

  const generateRequest = () => {
    const obj = {
      limit: 12,
      address,
      sort_by: 'Default',
      offset: (page - 1) * 12,
    };
    if (search) {
      obj.name = search;
    }
    if (filterType[filter]) {
      obj.class = filterType[filter];
    }
    if (tierType[tier]) {
      obj.tier = tierType[tier];
    }
    if (sortType[sort]) {
      obj.sort_by = 'Level';
      if (sortDirection === 'asc') {
        obj.order_by = 'Asc';
      } else {
        obj.order_by = 'Desc';
      }
    }
    if (!sortType[sort]) {
      obj.sort_by = 'Default';
    }
    return obj;
  };

  const fetchPawns = (params, offset) => {
    dispatch(getPawnsSellForEquipment(params, offset));
    dispatch(getPage(page + 1));
  };

  const filteredPawns = () => {
    if (pickedPawns.length) {
      return pawns.filter((elem) => !pickedPawns.some((item) => item.nftId === elem.nftId));
    }
    return pawns;
  };

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    const timeOutId = setTimeout(() => {
      dispatch(getPawnsSellForEquipment({
        limit: 12,
        address,
        sort_by: sortType[sort] || 'Default',
        order_by: sortDirection === 'asc' ? 'Asc' : 'Desc',
        offset: 0,
        class: filterType[filter] || '',
        tier: tierType[tier] || '',
        name: search,
      }));
    }, 1000);
    dispatch(lastPawnsData(false));
    dispatch(getPage(2));
    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timeOutId);
  }, [search]);

  useLayoutEffect(() => {
    if (firstUpdateFilter.current) {
      firstUpdateFilter.current = false;
      return;
    }
    dispatch(getPawnsSellForEquipment({
      limit: 12,
      address,
      sort_by: sortType[sort] || 'Default',
      order_by: sortDirection === 'asc' ? 'Asc' : 'Desc',
      offset: 0,
      class: filterType[filter] || '',
      tier: tierType[tier] || '',
      name: search,
    }));
    dispatch(lastPawnsData(false));
    dispatch(getPage(2));
  }, [filter, tier, sort, sortDirection]);

  useEffect(() => {
    dispatch(getPawnsSellForEquipment({
      limit: 12,
      address,
      sort_by: 'Default',
      offset: 0,
    }, true));
    dispatch(getPage(2));
  }, []);

  const content = useMemo(() => {
    if (loading && !pawns.length) {
      return (
        <div className={styles.loader}>
          <img src={loader} alt="loading..." />
        </div>
      );
    }
    if (pawns) {
      return (
        <div id="scrollableDiv">
          <InfiniteScroll
            dataLength={filteredPawns()?.length || 0}
            next={() => fetchPawns(generateRequest(), true)}
            hasMore={!wasLastPawn}
            scrollableTarget="scrollableDiv"
            loader={(
              <div className={styles.loader}>
                <img src={loader} alt="loading..." />
              </div>
            )}
            className={styles.wrapper_card}
            height={482}
          >
            {filteredPawns().map((elem, index) => (
              <div key={uniqueId('pawns_cards')}>
                <div
                  data-tip="show"
                  data-for={elem?.nftId?.toString() || String(index)}
                >
                  <CardPawn
                    data={elem}
                    setShowPickPawnModal={setShowPickPawnModal}
                  />
                </div>
                <StatsPreview
                  id={elem?.nftId?.toString() || String(index)}
                  pawnId={elem.nftId}
                  name={elem.name}
                  stats={elem.stats}
                />
              </div>
            ))}
          </InfiniteScroll>
        </div>
      );
    }
    return null;
  }, [loading, pawns]);

  return (
    <div
      className={styles.modalWrapper}
      role="presentation"
      onClick={() => {
        setShowPickPawnModal(false);
        dispatch(resetInventoryForEquipment());
      }}
    >
      <div
        className={styles.wrapper_inventory}
        role="presentation"
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className={styles.close}
          role="presentation"
          onClick={() => {
            setShowPickPawnModal(false);
            dispatch(resetInventoryForEquipment());
          }}
        >
          <img src={Close} alt="exit" />
        </div>
        <div className={styles.button_group}>
          <Filters
            start
            text={['Sort by', 'Level']}
            sort={sort}
            setSort={setSort}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
            filter={filter}
            tier={tier}
            setTier={setTier}
            setFilter={setFilter}
            search={search}
            setSearch={setSearch}
          />
        </div>
        <div className={styles.separator} />
        {content}
      </div>
    </div>
  );
};

export default PickPawnModal;
