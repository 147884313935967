import React, { useState } from 'react';
import styles from './TextButton.module.scss';

const TextButton = ({ text, onClick }) => {
  const [isClick, setIsClick] = useState(false);

  return (
    <div
      role="presentation"
      className={`${styles.buttons_list_buy} ${isClick && styles.active}`}
      onMouseDown={() => setIsClick(true)}
      onMouseUp={() => setIsClick(false)}
      onMouseLeave={() => setIsClick(false)}
      onClick={() => onClick()}
    >
      <div className={styles.button_left} />
      <div className={styles.button_list}>
        <span>{text}</span>
      </div>
      <div className={styles.button_right} />
    </div>
  );
};

export default TextButton;
