export class ActionCasting {
  constructor(address, action, pawnData, setActionSelf, setActionOpponent, backdrop, setBackdrop) {
    this.address = address;
    this.action = action;
    this.pawnData = pawnData;
    this.setActionSelf = setActionSelf;
    this.setActionOpponent = setActionOpponent;
    this.backdrop = backdrop;
    this.setBackdrop = setBackdrop;
  }

  toPromises() {
    let backdrop = Promise.resolve();
    // let backdropPersistent = Promise.resolve();
    if (
      this.pawnData.typeAnimation.casting.backdrop
      && this.pawnData.typeAnimation.casting.backdrop[this.action.spell]
    ) {
      // BACKDROP ANIMATION CASTING START ---------------------------------------------
      const { type } = this.pawnData.typeAnimation.casting.backdrop[this.action.spell];
      const persistentType = this.pawnData.typeAnimation.casting.backdropPersistent[this.action.spell].type;
      backdrop = new Promise((resolve) => {
        if (this.action.from === this.address) {
          this.setBackdrop({
            player: [...this.backdrop.player, type],
            opponent: [...this.backdrop.opponent],
          });
        } else {
          this.setBackdrop({
            player: [...this.backdrop.player],
            opponent: [...this.backdrop.opponent, type],
          });
        }
        setTimeout(() => {
          const player = [...this.backdrop.player.filter((i) => i !== type)];
          const opponent = [...this.backdrop.opponent.filter((i) => i !== type)];
          if (persistentType) {
            // some persistant animation after promise ready
            if (this.action.from === this.address) {
              player.push(persistentType);
            } else {
              opponent.push(persistentType);
            }
          }
          this.setBackdrop({ player, opponent });
          resolve(true);
        }, this.pawnData.typeAnimation.attack.backdropHit.duration);
      });
      // BACKDROP ANIMATION CASTING END -----------------------------------------------
    }
    return [
      () => Promise.all([
        new Promise((resolve) => {
          if (this.action.from === this.address) {
            this.setActionSelf(this.pawnData.typeAnimation.casting.img);
          } else {
            this.setActionOpponent(this.pawnData.typeAnimation.casting.img);
          }
          this.clear(resolve, this.pawnData.typeAnimation.casting.duration);
        }),
        backdrop,
      ]),
    ];
  }

  clear(resolve, duration) {
    setTimeout(() => {
      this.setActionSelf(this.pawnData.spellsAnimations.idle);
      this.setActionOpponent(this.pawnData.spellsAnimations.idle);
      resolve(true);
    }, duration);
  }
}
