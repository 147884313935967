import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styles from './QuestRewards.module.scss';
import Experience from '../Experience';
import uniqueId from '../../utils/uniqueId';
import TextButton from '../TextButton';
import { notificationShowAction } from '../../store/slices/utils';
import { resetGetReward } from '../../store/slices/quests';

const QuestRewards = ({ reward }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className={styles.wrapper}>
      {reward?.pawns?.length
        ? (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className={styles.status}>
              {reward.status}
            </div>
            <div style={{ display: 'flex', columnGap: '30px' }}>
              {reward.pawns.map((pawn) => (
                <div className={styles.exp_block}>
                  <Experience
                    pawnClass={pawn.class}
                    name={pawn.name}
                    expGain={pawn.gainedExperience}
                    pawnLevel={pawn.level}
                    startExp={pawn.experience}
                    newLevel={pawn.newLevel}
                  />
                </div>
              ))}
            </div>
          </div>
        )
        : null}
      <div className={styles.main}>
        <div className={styles.quest_wrapper}>
          <div className={styles.quest_info}>
            {reward?.challengeMessages && reward.challengeMessages.map((elem) => (
              <span key={uniqueId('quest')}>{elem}</span>
            ))}
            <span>
              {reward?.finalMessage && reward.finalMessage}
            </span>
            {reward?.equipment?.image
              ? (
                <div className={styles.item}>
                  Item received:
                  <img
                    width="32px"
                    height="32px"
                    src={`https://i.bimium.link/${reward.equipment.image}`}
                    alt=""
                  />
                </div>
              )
              : null}
          </div>
        </div>
        <TextButton
          text={reward.status === 'Success' ? 'Claim' : 'Confirm'}
          onClick={() => {
            navigate('/quests');
            dispatch(notificationShowAction(true, 'The reward was received successfully'));
            dispatch(resetGetReward());
          }}
        />
      </div>
    </div>
  );
};

export default QuestRewards;
