import React, { useState } from 'react';
import { passStatus } from '../../../../utils/chooseData';
import passImg from '../../../../static/img/header/balancebar/dropdown/Decline button.png';
import styles from '../../Equipment.module.scss';

const RemoveButton = ({ click }) => {
  const [pass, setPass] = useState('');
  return (
    <button
      type="button"
      className={styles.button}
      onMouseEnter={() => setPass('hover')}
      onMouseDown={() => setPass('pressed')}
      onMouseUp={() => setPass('hover')}
      onMouseLeave={() => setPass('')}
      onClick={() => {
        click();
        setPass('');
      }}
    >
      <img src={pass ? passStatus[pass] : passImg} alt="ok" width="62px" height="52px" />
    </button>
  );
};

export default RemoveButton;
