import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Header from '../../components/Marketplace/Components/Header';
import Cards from './Cards';
import uniqueId from '../../utils/uniqueId';
import MobileLayout from '../../components/MobileLayout';
import styles from './Quests.module.scss';
import { selectEvm } from '../../store/slices/auth';
import {
  getQuestsData, resetDataQuests,
  selectQuests, selectQuestsLoading,
} from '../../store/slices/quests';
import loader from '../../static/img/loader.gif';

const Quests = () => {
  const dispatch = useDispatch();
  const address = useSelector(selectEvm);
  const questsData = useSelector(selectQuests);
  const isLoading = useSelector(selectQuestsLoading);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    dispatch(getQuestsData());
  }, []);

  useEffect(() => () => dispatch(resetDataQuests()), []);

  useEffect(() => {
    const onResize = (e) => {
      setWidth(e.target.innerWidth);
    };
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const questsContent = useMemo(() => {
    if (isLoading) {
      return (
        <div className={styles.loader}>
          <img src={loader} alt="loading" />
        </div>
      );
    }
    if (questsData) {
      return (
        <div className={styles.wrap_content}>
          <div className={styles.wrap_shadows}>
            <div className={styles.shadows}>
              <div className={styles.background_shadow}>City Council</div>
              <div className={styles.background_shadow_1}>Magisters</div>
              <div className={styles.background_shadow_2}>Syndicate</div>
            </div>
          </div>
          <div className={styles.content}>
            {questsData.map((item, index) => (
              <div
                key={uniqueId('quests')}
                role="presentation"
                className={`
                ${index < 3 || width <= 880 ? styles.wrapper_cards : ''}
                ${index === 1 ? styles.margin_bottom : ''}
                ${index === 3 ? styles.margin_bottom_1 : ''}
                ${index === 5 ? styles.margin_bottom_2 : ''}
                ${(!index || index === 2) ? styles.padding_top : ''}
                `}
              >
                <Cards data={item} />
              </div>
            ))}
          </div>
        </div>
      );
    }
    return null;
  }, [isLoading, width]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      { width > 880 ? (
        <>
          <Header address={address} />
          <div className={styles.wrapper}>
            <Link to="/" className="closeWindow"><button type="button">&#10006;</button></Link>
            {questsContent}
          </div>
        </>
      ) : (
        <MobileLayout>
          {questsContent}
        </MobileLayout>
      )}
    </>
  );
};

export default Quests;
