import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DropDownItem from './DropDownItem';
import NavButton from './NavButton/index';
import { selectNavigateTo, setNavigate } from '../../../../../store/slices/arena';
import { setEquipmentType } from '../../../../../store/slices/preset';
import styles from './MainBar.module.scss';

const MainBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpenAdventure, setIsOpenAdventure] = useState(false);
  const [isOpenInventory, setIsOpenInventory] = useState(false);
  const [isOpenAlchemist, setIsOpenAlchemist] = useState(false);
  const { navigateTo } = useSelector(selectNavigateTo);

  const redirect = (button) => {
    switch (button) {
      case 'Arena':
        navigate('/arena-hub');
        break;
      case 'Sell':
        navigate('/marketplace/pawns/sell');
        break;
      case 'Equipment':
        navigate('/equipment');
        dispatch(setEquipmentType('Pawns'));
        break;
      case 'Skills Constructor':
        navigate('/skill-utils');
        break;
      case 'Inventory Constructor':
        navigate('/inventory-utils');
        break;
      case 'Quests':
        navigate('/quests');
        break;
      case 'Crafting':
        navigate('/crafting');
        dispatch(setEquipmentType('Equipment'));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (navigateTo) {
      navigate(navigateTo);
      dispatch(setNavigate(null));
    }
  }, [navigateTo]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.nav_left} />
      <div className={styles.nav_middle_left} onMouseLeave={() => setIsOpenAdventure(false)}>
        <div
          role="presentation"
          onClick={() => setIsOpenAdventure(!isOpenAdventure)}
        >
          <NavButton text="Adventure" isOpenAdventure={isOpenAdventure} />
        </div>
        {isOpenAdventure
          && <DropDownItem text={['Quests', 'Arena']} redirect={redirect} />}
      </div>
      <div className={styles.nav_middle_between} />
      <div className={styles.nav_middle} onMouseLeave={() => setIsOpenInventory(false)}>
        <div
          role="presentation"
          onClick={() => setIsOpenInventory(!isOpenInventory)}
        >
          <NavButton text="Inventory" isOpenInventory={isOpenInventory} />
        </div>
        {isOpenInventory
          && (
          <DropDownItem
            text={['Equipment', 'Sell']}
            redirect={redirect}
          />
          )}
      </div>
      <div className={styles.nav_middle_between_middle} />
      {/* <div role="presentation" className={styles.nav_middle_centre} onClick={() => navigate('/pick')}> */}
      {/*   <NavButton text="Alchemist" /> */}
      {/* </div> */}
      <div className={styles.nav_middle_centre} onMouseLeave={() => setIsOpenAlchemist(false)}>
        <div
          role="presentation"
          onClick={() => setIsOpenAlchemist(!isOpenAlchemist)}
        >
          <NavButton text="Alchemist" isOpenAlchemist={isOpenAlchemist} />
        </div>
        {isOpenAlchemist
          && (
            <DropDownItem
              text={['Crafting', 'Skills Constructor', 'Inventory Constructor']}
              redirect={redirect}
            />
          )}
      </div>
      <div className={styles.nav_middle_between_right} />
      <div
        role="presentation"
        className={styles.nav_middle_right}
        onClick={() => navigate('/marketplace/pawns/')}
      >
        <NavButton text="Marketplace" />
      </div>
      <div className={styles.nav_right} />
    </div>
  );
};

export default MainBar;
