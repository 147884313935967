import React from 'react';
import uniqueId from '../../../../utils/uniqueId';
import styles from './Tooltip.module.scss';

const Tooltip = ({ name, stats }) => {
  const objResult = { ...stats };
  Object.keys(objResult).forEach((elem) => {
    if (objResult[elem] === undefined) {
      delete objResult[elem];
    }
  });
  return (
    <div
      role="presentation"
      className={styles.tooltip}
    >
      <div className={styles.tooltip_top_left} />
      <div className={styles.tooltip_top} />
      <div className={styles.tooltip_top_right} />
      <div className={styles.tooltip_left} />
      <div className={styles.tooltip_content}>
        <div className={styles.head}>
          <div className={styles.head_left}>
            <div className={styles.title}>
              {name?.split('_').join(' ')}
            </div>
            <div className={styles.separator} />
            {Object.keys(objResult).map((item) => (
              objResult[item] ? (
                <div className={styles.stat} key={uniqueId('pawn_stats')}>
                  <span className={styles.name_stat}>{item?.replace('_', ' ')}</span>
                  <div className={styles.stat_item}>
                    {objResult[item]}
                  </div>
                </div>
              ) : null
            ))}
          </div>
        </div>
      </div>
      <div className={styles.tooltip_right} />
      <div className={styles.tooltip_bottom_left} />
      <div className={styles.tooltip_bottom} />
      <div className={styles.tooltip_bottom_right} />
    </div>
  );
};

export default Tooltip;
