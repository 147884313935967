import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { ethers } from 'ethers';
import {
  buyItem, listInventoryItem, onTransfer,
  selectItemList, unlistInventoryItem,
} from '../../../../../store/slices/tradeItem';
import loader from '../../../../../static/img/loader.gif';
import IcoGold from '../../../../../static/img/IcoGold-alternate.png';
import { onMint, selectMintLoading } from '../../../../../store/slices/presale';
import { preventInvalidSymbols } from '../../../../../utils/format';
import { notificationShowAction } from '../../../../../store/slices/utils';
import styles from './ButtonsListBuy.module.scss';
import { clearPawn } from '../../../../../store/slices/pawnSelect';
import { getPawnsSell } from '../../../../../store/slices/requests';

const ButtonsListBuy = ({
  text, itemPrice, pawn, register, reset, setValue, generateRequest,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isClick, setIsClick] = useState(false);

  const {
    buyLoading, listLoading, unListLoading, isTransferLoading,
  } = useSelector(selectItemList);
  const isMintLoading = useSelector(selectMintLoading);

  const listed = pawn?.nftStatus === 'Listed';

  const onButtonCLick = () => {
    if (text === 'Mint') {
      dispatch(onMint()).then(() => {
        reset({
          price: '',
        });
      });
    } else if (text === 'Transfer' && itemPrice) {
      if (ethers.utils.isAddress(itemPrice)) {
        dispatch(onTransfer(itemPrice, pawn?.nftId)).then(() => {
          reset({
            price: '',
          });
          navigate('/marketplace/pawns/transfer');
          dispatch(clearPawn());
          dispatch(getPawnsSell(generateRequest()));
        });
      } else {
        dispatch(notificationShowAction(false, 'Input valid address'));
      }
    } else if (text === 'Buy') {
      dispatch(buyItem(pawn?.offerId)).then(() => {
        reset({
          price: '',
        });
      });
    } else if (text === 'List' && !listed && itemPrice) {
      dispatch(listInventoryItem(pawn?.nftId, itemPrice)).then(() => {
        reset({
          price: '',
        });
      });
    } else if (itemPrice) {
      dispatch(unlistInventoryItem(pawn?.offerId)).then(() => {
        reset({
          price: '',
        });
      });
    }
  };

  const tradeType = () => {
    if (text === 'List') {
      if (listed) return 'Unlist';
      return text;
    }
    return text;
  };

  useEffect(() => {
    if (pawn.price && text !== 'Transfer') {
      setValue('price', pawn.price);
    }
    if (pawn.price && text === 'Transfer') {
      setValue('price', '');
    }
  }, [pawn]);

  return (
    <div className={styles.wrapper_buttons}>
      <div className={`${styles.button_info} ${text === 'Transfer' ? styles.transfer_info : ''}`}>
        <div className={styles.input_left} />
        <div className={styles.input_content}>
          <input
            placeholder={text === 'Transfer' ? 'Address' : ''}
            {...register('price')}
            disabled={text === 'Buy' || text === 'Mint' || (text === 'Transfer' && !Object.keys(pawn).length)}
            onKeyPress={(e) => (text === 'Transfer' ? {} : preventInvalidSymbols(e))}
            value={ethers.utils.isAddress(itemPrice) ? `${itemPrice.slice(0, 7)}...${itemPrice.slice(-7)}` : itemPrice}
          />
          <img src={IcoGold} alt="ico" className={styles.input_content_coin} />
          {
            listLoading || unListLoading || buyLoading || isMintLoading || isTransferLoading
              ? (
                <div className={styles.loader}>
                  <img src={loader} alt="loading" />
                </div>
              )
              : (
                <div
                  role="presentation"
                  className={`
                  ${styles.buttons_list_buy} ${isClick && styles.active}`}
                  onMouseDown={() => setIsClick(true)}
                  onMouseUp={() => setIsClick(false)}
                  onMouseLeave={() => setIsClick(false)}
                  onClick={() => onButtonCLick()}
                >
                  <div className={`${styles.button_list} ${text === 'Transfer' ? styles.transfer : ''}`}>
                    <span>{tradeType()}</span>
                  </div>
                </div>
              )
          }
        </div>
        <div className={styles.input_right} />
      </div>
    </div>
  );
};

export default ButtonsListBuy;
