import React from 'react';
import styles from '../../SkillUtils.module.scss';
import FormulaField from '../../FormulaField';
import formulaVars from '../../../../constants/spell-params';

const Modifications = ({
  register, index, onDelete, setValue,
}) => (
  <div style={{ marginLeft: 15 }}>
    <div className={styles.form_row}>
      <div>
        Parameter
        <select
          type="input"
          {...register(`effect.modifications.${index}.parameter`)}
        >
          {formulaVars.map((v) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
            <option
              key={v}
              value={v.toLowerCase()}
            >
              { v }
            </option>
          ))}
        </select>
      </div>
    </div>
    <div className={styles.form_row} style={{ position: 'relative' }}>
      <div>
        Formula
        {/* <input */}
        {/*   type="input" */}
        {/*   {...register(`modifications.${index}.formula`)} */}
        {/* /> */}
      </div>
      <FormulaField
        setValue={setValue}
        fieldName={`effect.modifications.${index}.formula`}
      />
    </div>
    <button
      type="button"
      onClick={() => onDelete()}
    >
      Delete action
    </button>
  </div>
);

export default Modifications;
