import React from 'react';
import styles from '../Pawns.module.scss';
import mage from '../../../../../static/img/pawns/items/Mage-UI.png';
import warrior from '../../../../../static/img/pawns/items/Warrior-UI.png';
import archer from '../../../../../static/img/pawns/items/Archer-UI.png';
import loader from '../../../../../static/img/loader.gif';
import MintCard from '../MintCard';

const itemType = {
  Mage: mage,
  Warrior: warrior,
  Archer: archer,
};

const Item = ({ item, itemLoading }) => (
  <div className={styles.wrapper_cardinfo}>
    {
      itemLoading
        ? (
          <>
            <div className={styles.loader_wrap} />
            <img src={loader} alt="loader" className={styles.loader} />
          </>
        )
        : null
    }
    <div className={`${styles.cardinfo} ${item ? styles[item.tier] : styles.filter}`}>
      <div className={styles.corner_cardinfo_top_left} />
      <div className={styles.corner_cardinfo_top_left_part} />
      <div className={styles.corner_cardinfo_line_top_left} />
      <div className={styles.border_cardinfo_topline_left} />
      <div className={styles.border_cardinfo_top_left_centre} />
      <div className={styles.border_cardinfo_topline_left_centre} />
      <div className={styles.border_cardinfo_top_centre} />
      <div className={styles.border_cardinfo_topline_right_centre} />
      <div className={styles.border_cardinfo_top_right_centre} />
      <div className={styles.border_cardinfo_topline_right} />
      <div className={styles.border_cardinfo_leftline} />
      <div className={styles.corner_cardinfo_top_right} />
      <div className={styles.corner_cardinfo_line_bottom_left} />
      <div className={styles.corner_cardinfo_lineBottom_right} />
      <div className={styles.corner_part_left} />
      <div className={styles.corner_part_right} />
      <div className={styles.corner_part_centre} />
      <div className={styles.corner_part_centre_bottom} />
      <div className={styles.content_left}>
        <div className={styles.content_left_img}>
          {item
            && (
            <div className={styles.wrapper_pawn}>
              <div className={styles.pawn}>
                <div className={styles.pawn_img}>
                  {item.image === 'Mint'
                    ? <MintCard />
                    : (
                      <img
                        src={item?.image?.includes('characters')
                          ? `https://i.bimium.link/${item.image}`
                          : item.image}
                        alt="pawn"
                        className={styles.content_left_hero}
                      />
                    )}
                </div>
              </div>
              <span>{item?.nftId ? `ID:${item.nftId}` : ''}</span>
            </div>
            )}
        </div>
        <div className={styles.content_left_pattern} />
      </div>
      <div className={styles.border_line_left} />
      <div className={styles.border_line_centre} />
      <div className={styles.border_line_right} />
      <div className={styles.border_lineIn_left} />
      <div className={styles.border_lineIn_right} />
      <div className={styles.content_right}>
        <div className={styles.content_right_character}>
          <span className={styles.content_right_item}>{item?.tier}</span>
        </div>
        <div className={styles.content_right_character_img}>
          <div className={styles.content_name_img}>
            {item && item?.class && <img src={itemType[item.class]} alt="type" />}
          </div>
        </div>
        <div className={styles.content_right_character}>
          {item && (
          <span className={styles.content_right_item}>
            level
            {' '}
            {item.level}
          </span>
          )}
        </div>
        <div
          className={`${styles.content_right_pattern} ${styles.content_right_pattern_rotate}`}
        />
        <div className={styles.content_right_main_info}>
          <span className={styles.content_right_text}>str</span>
          <div className={styles.content_right_num}>
            {item.stats?.strength ? item.stats.strength : ''}
          </div>

          <span className={styles.content_right_text}>dex</span>
          <div className={styles.content_right_num}>
            {item.stats?.dexterity ? item.stats.dexterity : ''}
          </div>

          <span className={styles.content_right_text}>spr</span>
          <div className={styles.content_right_num}>
            {item.stats?.spirit ? item.stats.spirit : ''}
          </div>

          <span className={styles.content_right_text}>con</span>
          <div className={styles.content_right_num}>
            {item.stats?.constitution ? item.stats.constitution : ''}
          </div>

          <span className={styles.content_right_text}>int</span>
          <div className={styles.content_right_num}>
            {item.stats?.intelligence ? item.stats.intelligence : ''}
          </div>

          <span className={styles.content_right_text}>cha</span>
          <div className={styles.content_right_num}>
            {item.stats?.charisma ? item.stats.charisma : ''}
          </div>
        </div>

        <div className={styles.content_right_pattern} />

        <div className={styles.content_right_main}>
          {Array.isArray(item.traits) && item.traits.map((elem, index) => (
            <div className={styles.content_right_main_text} key={[index]}>
              <span className={styles.content_right_main_text_name}>{elem.name}</span>
              &nbsp;
              <span className={styles.content_right_main_text_skill}>{elem?.description || '?'}</span>
            </div>
          ))}
        </div>
        <div className={styles.content_right_pattern} />
      </div>
      <div className={styles.content_name}>
        <div className={styles.content_name_text}>{item?.name}</div>
      </div>
      <div className={styles.corner_cardinfo_bottom_left} />
      <div className={styles.border_cardinfo_bottomline_left} />
      <div className={styles.border_cardinfo_bottom_left_centre} />
      <div className={styles.border_cardinfo_bottomline_left_centre} />
      <div className={styles.border_cardinfo_bottom_centre} />
      <div className={styles.border_cardinfo_bottomline_right_centre} />
      <div className={styles.border_cardinfo_bottom_right_centre} />
      <div className={styles.border_cardinfo_bottomline_right} />
      <div className={styles.corner_cardinfo_bottom_right} />
    </div>
  </div>
);

export default Item;
