import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './TurnControl.module.scss';
import checkImg from '../../../static/img/header/balancebar/dropdown/Accept button.png';
import checkHover from '../../../static/img/header/balancebar/dropdown/Accept button hover.png';
import checkPressed from '../../../static/img/header/balancebar/dropdown/Accept button hover pressed.png';
import passImg from '../../../static/img/header/balancebar/dropdown/Decline button.png';
import passHover from '../../../static/img/header/balancebar/dropdown/Decline button hover.png';
import passPressed from '../../../static/img/header/balancebar/dropdown/Decline button pressed.png';
import { handleDoSpellAction, handlePassTurn } from '../../../utils/arenaSocket';
import { selectActiveSkill, selectTurn, setActiveSkill } from '../../../store/slices/arena';

const TurnControl = () => {
  const [check, setCheck] = useState('');
  const [pass, setPass] = useState('');
  const [timer, setTimer] = useState(59);
  const activeSkill = useSelector(selectActiveSkill);

  const gameTurn = useSelector(selectTurn);

  useEffect(() => {
    const timerOut = setInterval(() => {
      setTimer((prev) => (prev ? timer - 1 : 0));
    }, 1000);
    return () => clearInterval(timerOut);
  }, [timer]);

  useEffect(() => {
    setTimer(59);
  }, [gameTurn]);

  const checkStatus = {
    hover: checkHover,
    pressed: checkPressed,
  };

  const passStatus = {
    hover: passHover,
    pressed: passPressed,
  };

  const downHandler = (event) => {
    if (event.code === 'Space') {
      if (activeSkill && activeSkill.name) {
        handleDoSpellAction(activeSkill.name);
        setActiveSkill(null);
      }
      setCheck('pressed');
    }
    if (event.key === 'Backspace') {
      setPass('pressed');
      handlePassTurn();
    }
  };

  const upHandler = (event) => {
    if (event.code === 'Space') {
      setCheck('');
    }
    if (event.code === 'Backspace') {
      setPass('');
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', downHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [window]);

  useEffect(() => {
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keyup', upHandler);
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.spell_info}>
        <div className={styles.spell_info_block}>
          <span className={styles.spell_info_type}>
            {activeSkill && activeSkill.type ? activeSkill.type : ''}
          </span>
          <span className={styles.spell_name}>
            {activeSkill && activeSkill.name ? activeSkill.name.split('_').join(' ') : 'Choose Skill'}
          </span>
        </div>
        <div className={styles.spell_text}>
          {activeSkill && activeSkill.description ? activeSkill.description : ''}
        </div>
      </div>
      <div className={styles.button_wrapper}>
        <div className={styles.button_content}>
          <button
            type="button"
            className={styles.button1}
            onMouseEnter={() => setCheck('hover')}
            onMouseDown={() => setCheck('pressed')}
            onMouseUp={() => setCheck('hover')}
            onMouseLeave={() => setCheck('')}
            onClick={() => {
              if (activeSkill && activeSkill.name) {
                handleDoSpellAction(activeSkill.name);
                setActiveSkill(null);
              }
            }}
            onKeyUp={(e) => upHandler(e)}
            onKeyDown={(e) => downHandler(e)}
          >
            <img src={check ? checkStatus[check] : checkImg} alt="ok" className={styles.passButton} />
          </button>
          {/* <div className={styles.button_bg}> */}
          {/*   SPC */}
          {/* </div> */}
        </div>
        <div className={styles.button_content}>
          <button
            type="button"
            className={styles.button}
            onMouseEnter={() => setPass('hover')}
            onMouseDown={() => setPass('pressed')}
            onMouseUp={() => setPass('hover')}
            onMouseLeave={() => setPass('')}
            onClick={() => {
              handlePassTurn();
            }}
            onKeyUp={(e) => upHandler(e)}
            onKeyDown={(e) => downHandler(e)}
          >
            <img src={pass ? passStatus[pass] : passImg} alt="ok" />
            <div className={styles.timer}>{timer}</div>
          </button>
          {/* <div className={styles.button_bg}> */}
          {/*   BS */}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default TurnControl;
