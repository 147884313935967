import React from 'react';
import BalanceBar from './BalanceBar';
import IDBar from './IDBar';
import MainBar from './MainBar';
import Notification from '../Notification';
import styles from './Header.module.scss';

const Header = ({ address }) => (
  <div className={styles.wrapper}>
    <IDBar address={address} />
    <MainBar />
    <BalanceBar address={address} />
    <Notification />
  </div>
);

export default Header;
