import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from 'react-oidc-context';
import Portrait from '../../Portraits/Portrait';
import light from '../../../../../static/img/header/infobar/online.png';
import styles from './IDBar.module.scss';
import { getGuildsInfoAction, selectGuildsInfo } from '../../../../../store/slices/portraits';
import NameBlock from '../../Portraits/NameBlock';

const IDBar = ({ address }) => {
  const dispatch = useDispatch();
  const auth = useAuth();

  const { infoLoading, guildsInfo } = useSelector(selectGuildsInfo);

  useEffect(() => {
    dispatch(getGuildsInfoAction());
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header_line} />
      <div className={styles.header_top_line} />
      <div className={styles.header_top_line_right} />
      <div className={styles.corner_right}>
        <img src={light} alt="green" />
      </div>
      <div className={styles.line_left} />
      <div className={styles.name_content}>
        <NameBlock address={address} user={auth?.user?.profile?.name} />
      </div>
      <div className={styles.between_line} />
      <div className={styles.between_line_centre} />
      <div className={styles.line_centre} />
      <div className={styles.foto_content}>
        <Portrait info={guildsInfo} loading={infoLoading} />
      </div>
      <div className={styles.bottom_line_left} />
      <div className={styles.bottom_line_centre} />
      <div className={styles.bottom_line_right} />
    </div>
  );
};

export default IDBar;
