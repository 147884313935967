/* eslint-disable object-curly-newline */

import Pawn from './pawn';
import Armor from '../../static/img/equipment/def.png';
import Sword from '../../static/img/equipment/mech.png';
import Boots from '../../static/img/equipment/speed.png';
import Helmet from '../../static/img/equipment/shlem.png';
import Band from '../../static/img/equipment/shmot.png';
import Ring from '../../static/img/equipment/ring.png';

export const pawnMageSprite = {
  key: 'mage-main',
  path: 'assets/mage/mage-level1.png',
  width: 380,
  height: 288,
  originCurrent: [166, 189],
  originOpponent: [225, 189],
  animations: [
    { key: 'anim-mage-hit', start: 0, end: 6, frameRate: 10 },
    { key: 'anim-mage-idle', start: 7, end: 18, repeat: -1 },
    { key: 'anim-mage-death', start: 19, end: 35 },
    { key: 'anim-mage-buff', start: 36, end: 61 },
    { key: 'anim-mage-attack', start: 62, end: 83 },
    { key: 'anim-mage-potion', start: 84, end: 114 },
    { key: 'anim-mage-idle-break', start: 115, end: 135 },
  ],
};

export const pawnArcherSprite = {
  key: 'archer-main',
  path: 'assets/archer/archer-level1.png',
  width: 420,
  height: 320,
  originCurrent: [155, 214],
  originOpponent: [260, 214],
  animations: [
    { key: 'anim-archer-throw', start: 0, end: 23 },
    { key: 'anim-archer-shoot', start: 24, end: 37 },
    { key: 'anim-archer-death', start: 38, end: 55 },
    { key: 'anim-archer-hit', start: 56, end: 61 },
    { key: 'anim-archer-idle', start: 62, end: 79, repeat: -1 },
    { key: 'anim-archer-idle-break', start: 80, end: 115 },
    { key: 'anim-archer-attack_v2', start: 116, end: 136 },
    { key: 'anim-archer-potion', start: 137, end: 167 },
    { key: 'anim-archer-knife', start: 168, end: 190 },
    { key: 'anim-archer-buff', start: 191, end: 214 },
    { key: 'anim-archer-debuff', start: 215, end: 241 },
  ],
};

export const pawnWarriorSprite = {
  key: 'warrior-main',
  path: 'assets/warrior/warrior-level1.png',
  width: 720,
  height: 420,
  originCurrent: [250, 308],
  originOpponent: [475, 308],
  animations: [
    { key: 'anim-warrior-idle', start: 0, end: 21, repeat: -1 },
    { key: 'anim-warrior-idle-break', start: 22, end: 37 },
    { key: 'anim-warrior-hit', start: 38, end: 44 },
    { key: 'anim-warrior-attack', start: 45, end: 57 },
    { key: 'anim-warrior-shield-bash', start: 58, end: 69 },
    { key: 'anim-warrior-attack-reckless', start: 70, end: 87 },
    { key: 'anim-warrior-buff', start: 88, end: 101 },
    { key: 'anim-warrior-death', start: 102, end: 120 },
    { key: 'anim-warrior-potion', start: 121, end: 155 },
    { key: 'anim-warrior-debuff', start: 156, end: 178 },
    { key: 'anim-warrior-shield', start: 179, end: 194 },
  ],
};

export const pawnMage = {
  key: 'Mage',
  class: Pawn,
  lvl: 1,
  sprites: [pawnMageSprite],
};

export const pawnArcher = {
  key: 'Archer',
  class: Pawn,
  lvl: 1,
  sprites: [pawnArcherSprite],
};

export const pawnWarrior = {
  key: 'Warrior',
  class: Pawn,
  lvl: 1,
  sprites: [pawnWarriorSprite],
};

export const pawns = [
  pawnMage,
  pawnArcher,
  pawnWarrior,
];

export const emptySpellsObject = {
  0: null,
  1: null,
  2: null,
  3: null,
  4: null,
  5: null,
};

export const emptyItemsObject = {
  Chest: '', Weapon: '', Boots: '', Helmet: '', Shield: '', Ring: '',
};

export const emptyConsumablesArray = [null, null];

export const defaultEquipment = {
  0: Armor,
  1: Sword,
  2: Boots,
};

export const defaultEquipment2 = {
  0: Helmet,
  1: Band,
  2: Ring,
};
