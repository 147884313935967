import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Cards.module.scss';

const Cards = ({ data }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      <div
        role="presentation"
        className={`${styles.wrapper_img} ${data.status === 'Running'
          ? styles.running : data.status === 'Finished' ? styles.finished : styles.hover}`}
        data-title={data.status}
        onClick={() => navigate(`/quests/${data.slot}`)}
      >
        <div className={`${styles.border_wrapper} ${styles[data.rarity]}`} />
        <img src={`https://i.bimium.link/${data.image}`} alt="quest" />
      </div>
      <div className={styles.wrapper_info}>
        <div className={styles.text}>
          {data.name}
        </div>
        <div className={styles.decorator} />
      </div>
    </div>
  );
};

export default Cards;
