import Phaser from 'phaser';
// import dat from 'dat.gui';
// eslint-disable-next-line import/no-extraneous-dependencies,import/extensions
import TextTyping from 'phaser3-rex-plugins/plugins/texttyping.js';
import { store } from '../../store';
import { handleUpdateUserPosition } from '../../utils/socialHubSocket';
import Ladder from './Ladder';
import {
  animationsObject, interactMessages, pawnsObject, randomShowing,
} from './const';

let tavernStoreSubscription = null;

class TavernScene extends Phaser.Scene {
  constructor(config) {
    super(config);
    this.navigate = config.navigate;
    this.users = {};
    this.NPCs = {};
    this.storePreviousValue = null;
    this.storeCurrentValue = null;
    this.userPositionsQueue = {};
    this.socialHubConnection = config.socialHubConnection;
    this.evm = config.evm;
    this.setTavernSceneCreated = config.setTavernSceneCreated;
  }

  preload() {
    this.pawns = pawnsObject;
    this.frameConfig = { frameWidth: 90, frameHeight: 279 };
    this.animConfig = {
      'anim-male-idle': { start: 0, end: 23 },
      'anim-male-beer': { start: 24, end: 55 },
      'anim-male-jump': { start: 56, end: 68 },
      'anim-male-walk': { start: 69, end: 92 },
    };
    // this.load.image('bgtavern', 'assets/Battle background.png');
    this.pawnKey = store.getState().portraits?.guildsInfo?.id || 'default_male';
    this.animationPath = `https://i.bimium.link/${store.getState().portraits.guildsInfo?.animation}`;
    this.load.spritesheet(
      this.pawnKey,
      this.animationPath,
      this.frameConfig,
    );
  }

  createTavern() {
    // 'Sprites_fireplace_Imported animation_00.gif.png'
    this.bgImage = this.add.image(0, 0, 'bgtavern').setDisplayOrigin(0, 0);

    animationsObject.forEach((el) => {
      const sprite = this.add.sprite(el.sprite.x, el.sprite.y, el.sprite.frameName, el.sprite.path)
        .setScale(el.sprite.scaleX, el.sprite.scaleY)
        .setInteractive();
        // .setOrigin(0, 1);
      const prefix = el.sprite.path.match(/(.*)\d\d\.png/);
      const frameNames = this.anims.generateFrameNames(el.sprite.frameName, {
        start: el.frames.start,
        end: el.frames.end,
        zeroPad: 2,
        prefix: prefix.length === 2 ? prefix[1] : null,
        suffix: '.png',
      });
      if (el.messages || el.interactMessages) {
        this.NPCs[el.sprite.path.split('/')[0]] = sprite;
        this.anims.create({
          key: `anim-${el.sprite.path}`,
          frames: frameNames,
          frameRate: 10,
          repeat: -1,
        });
        this.NPCs[el.sprite.path.split('/')[0]].anims.play(`anim-${el.sprite.path}`);
        if (el.messages) {
          this.NPCs[el.sprite.path.split('/')[0]].interact = el.messages;
        }
        if (el.interactMessages) {
          this.NPCs[el.sprite.path.split('/')[0]].interactOnButton = el.interactMessages;
        }
      } else {
        this.anims.create({
          key: `anim-${el.sprite.path}`,
          frames: frameNames,
          frameRate: 10,
          repeat: -1,
        });
        sprite.anims.play(`anim-${el.sprite.path}`);
      }
    });
    // Enable drag tavern items for dev
    // this.input.on('pointerdown', this.dragStart, this);
  }

  dragStart(pointer, targets) {
    if (targets.length) {
      console.log(pointer, targets);
      this.input.off('pointerdown', this.dragStart, this);
      // eslint-disable-next-line prefer-destructuring
      this.dragObject = targets[0];
      this.input.on('pointermove', this.dragMove, this);
      this.input.on('pointerup', this.dragEnd, this);
    }
  }

  dragMove(pointer) {
    this.dragObject.x = pointer.worldX;
    this.dragObject.y = pointer.worldY;
  }

  dragEnd() {
    this.input.on('pointerdown', this.dragStart, this);
    this.input.off('pointermove', this.dragMove, this);
    this.input.off('pointerup', this.dragEnd, this);
    console.log(`${this.dragObject.x}, ${this.dragObject.y}`, this.dragObject.frame.name);
  }

  // eslint-disable-next-line class-methods-use-this
  createLadders() {
    this.ladders = Ladder.createLadders();
  }

  create() {
    this.createTavern();
    this.createPlatforms();
    this.createAnimation();
    this.createLadders();
    this.createCurrentUser();
    this.createInteractMessage();

    this.cameras.main.setBounds(0, 0, 5349, 2397);
    this.physics.world.setBounds(0, 0, 5349, 2397, true, true, true, true);
    // this.physics.add.collider(this.npc, this.platforms);
    this.cursors = this.input.keyboard.createCursorKeys();

    this.input.keyboard.on('keydown', (event) => {
      if (event.keyCode === 65) {
        this.cursors.left.isDown = true;
      }
      if (event.keyCode === 68) {
        this.cursors.right.isDown = true;
      }
      if (event.keyCode === 87) {
        this.cursors.up.isDown = true;
      }
      if (event.keyCode === 83) {
        this.cursors.down.isDown = true;
      }
      if (event.keyCode === 38) {
        this.goOnStairs = true;
      }
      if (event.keyCode === 40) {
        this.goDownStairs = true;
      }
    }, this);

    this.input.keyboard.on(
      'keyup',
      (event) => {
        if (event.keyCode === 65) {
          this.cursors.left.isDown = false;
        }
        if (event.keyCode === 68) {
          this.cursors.right.isDown = false;
        }
        if (event.keyCode === 87) {
          this.cursors.up.isDown = false;
        }
        if (event.keyCode === 83) {
          this.cursors.down.isDown = false;
        }
        if (event.keyCode === 69) {
          if (this.exit) {
            this.navigate('/');
            this.scene.sleep('tavern-scene').run('map-scene');
          }
          if (this.questBoard) {
            this.navigate('/quests');
          }
          if (this.NPCAround) {
            if (this.NPCAround[0] === 'Gatekeeper') {
              this.navigate('/arena');
            }
            if (this.NPCAround[0] === 'Barmen') {
              this.drinkBeer = true;
              this.input.keyboard.disableGlobalCapture();
              this.input.keyboard.enabled = false;
              this.handleUserPosition(false, 'beer');
              setTimeout(() => {
                this.input.keyboard.enableGlobalCapture();
                this.input.keyboard.enabled = true;
                this.drinkBeer = false;
              }, 3100);
            }
            if (this.npcInteractTextTimeOut) {
              this.npcInteractText.setVisible(false);
              this.npcInteractTextBackground.setVisible(false);
              clearTimeout(this.npcInteractTextTimeOut);
            }
            this.npcText.setVisible(false);
            this.npcTextBackground.setVisible(false);
            if (this.NPCs[this.NPCAround[0]]?.interactOnButton && randomShowing()) {
              const randomMessage = interactMessages(this.NPCs[this.NPCAround[0]].interactOnButton);
              this.createInteractMessageOnButton(
                this.NPCs[this.NPCAround[0]].x,
                this.NPCs[this.NPCAround[0]].y,
                randomMessage.length > 27 ? 10 : 0,
                this.NPCAround[0],
              );
              this.typingInteractMessage = new TextTyping(this.npcInteractText);
              this.typingInteractMessage.start(randomMessage, 70);
              this.typingInteractMessage.on('complete', () => {
                if (this.NPCAround[0] === 'Alchemist') {
                  this.navigate('/crafting');
                }
                if (this.NPCAround[0] === 'Lord') {
                  this.navigate('/marketplace/pawns/');
                }
                this.npcInteractTextTimeOut = setTimeout(() => {
                  this.npcInteractText.setVisible(false);
                  this.npcInteractTextBackground.setVisible(false);
                }, 5000);
              });
            } else {
              if (this.NPCAround[0] === 'Alchemist') {
                this.navigate('/crafting');
              }
              if (this.NPCAround[0] === 'Lord') {
                this.navigate('/marketplace/pawns/');
              }
            }
          }
        }
        if (event.keyCode === 38) {
          this.goOnStairs = false;
        }
        if (event.keyCode === 40) {
          this.goDownStairs = false;
        }
      },
      this,
    );
    // this.sound.play('steps');
    this.soundSteps = this.sound.add('steps', {
      volume: 1,
      loop: true,
    });
    this.soundJumps = this.sound.add('jumps', {
      volume: 1,
      loop: false,
      seek: 1,
    });
    this.soundTouchdown = this.sound.add('touchdown', {
      volume: 1,
      loop: false,
    });

    if (tavernStoreSubscription) {
      tavernStoreSubscription.unsubscribe();
    }
    tavernStoreSubscription = store.subscribe(this.handleStoreChange.bind(this));
    this.socialHubConnection.on('UserStatesUpdated', (response) => {
      response?.userStates?.forEach((message) => {
        if (this.userPositionsQueue[message.address]) {
          this.userPositionsQueue[message.address].push(message);
        } else {
          this.userPositionsQueue[message.address] = [message];
        }
      });
    });
    this.setTavernSceneCreated(true);
  }

  handleStoreChange() {
    const state = store.getState();
    this.evm = state.auth.evm;
  }

  createInteractMessage(x, y, messageLength, npc) {
    this.npcTextBackground = this.add.sprite(
      x,
      y,
      'backgroundMessage',
    ).setDisplayOrigin(144, npc === 'Crime Boss' || npc === 'Gatekeeper' ? 180 : 150).setScale(1, 1);
    this.npcText = this.add.text(
      x,
      y,
      '',
      {
        font: '21px alagard',
        wordWrap: 'break-word',
        fill: '#573229',
        align: 'center',
        fixedHeight: 81,
        fixedWidth: 288,
      },
    ).setDisplayOrigin(144, npc === 'Crime Boss' || npc === 'Gatekeeper' ? 150 + messageLength : 120 + messageLength);
  }

  createInteractMessageOnButton(x, y, messageLength, npc) {
    this.npcInteractTextBackground = this.add.sprite(
      x,
      y,
      'backgroundMessage',
    ).setDisplayOrigin(144, npc === 'Crime Boss' || npc === 'Gatekeeper' ? 180 : 150).setScale(1, 1);
    this.npcInteractText = this.add.text(
      x,
      y,
      '',
      {
        font: '21px alagard',
        wordWrap: 'break-word',
        fill: '#573229',
        align: 'center',
        fixedHeight: 81,
        fixedWidth: 288,
      },
    ).setDisplayOrigin(144, npc === 'Crime Boss' || npc === 'Gatekeeper' ? 150 + messageLength : 120 + messageLength);
  }

  createPlatforms() {
    const floor1 = this.add.rectangle(0, 2310, 5349, 5, 0x6666ff).setDisplayOrigin(0, 0).setAlpha(0);
    const floor2 = this.add.rectangle(0, 1730, 5349, 30, 0x6666ff).setDisplayOrigin(0, 0).setAlpha(0);
    const floor3 = this.add.rectangle(0, 1250, 2780, 90, 0x6666ff).setDisplayOrigin(0, 0).setAlpha(0);
    const floor2Middle = this.add.rectangle(1320, 1320, 90, 200, 0x6666ff).setDisplayOrigin(0, 0).setAlpha(0);
    const floor3Middle = this.add.rectangle(3350, 1250, 570, 90, 0x6666ff).setDisplayOrigin(0, 0).setAlpha(0);
    const floor3Right = this.add.rectangle(4750, 1345, 550, 90, 0x6666ff).setDisplayOrigin(0, 0).setAlpha(0);
    const floor4Right = this.add.rectangle(3950, 1060, 550, 80, 0x6666ff).setDisplayOrigin(0, 0).setAlpha(0);
    const floor4 = this.add.rectangle(1320, 770, 3980, 80, 0x6666ff).setDisplayOrigin(0, 0).setAlpha(0);
    const floor5 = this.add.rectangle(2540, 295, 1440, 80, 0x6666ff).setDisplayOrigin(0, 0).setAlpha(0);
    const wallLeft = this.add.rectangle(50, 1250, 30, 1200, 0x6666ff).setDisplayOrigin(0, 0).setAlpha(0);
    const wallRight = this.add.rectangle(5280, 0, 30, 2400, 0x6666ff).setDisplayOrigin(0, 0).setAlpha(0);
    const wall3Floor = this.add.rectangle(1320, 0, 90, 1040, 0x6666ff).setDisplayOrigin(0, 0).setAlpha(0);
    const wallBetween4and5Floor = this.add.rectangle(3910, 0, 90, 560, 0x6666ff).setDisplayOrigin(0, 0).setAlpha(0);
    const wallBetween3and4Floor = this.add.rectangle(3910, 800, 90, 715, 0x6666ff).setDisplayOrigin(0, 0).setAlpha(0);
    const roof = this.add.rectangle(1320, 10, 3980, 70, 0x6666ff).setDisplayOrigin(0, 0).setAlpha(0);
    this.platforms = this.physics.add.staticGroup(
      [
        wallLeft,
        wallRight,
        wall3Floor,
        floor1, floor2, floor3, floor4, floor5,
        wallBetween4and5Floor, wallBetween3and4Floor,
        floor3Right, floor4Right, floor3Middle, floor2Middle,
        roof,
      ],
    );
  }

  onlineUsers(onlinePlayers) {
    onlinePlayers.forEach((player) => {
      if (this.evm !== player.address) {
        if (this.users[player.address] === undefined) {
          this.createCompetitor(
            player.address,
            player.position.x,
            player.position.y,
            player.discordName,
            player.portrait,
          );
        } else {
          if (Math.abs(this.users[player.address].nextX - player.position.x) > 2) {
            if (this.users[player.address].nextX < player.position.x) {
              this.users[player.address].setVelocityX(320);
              this.users[player.address].flipX = false;
            } else {
              this.users[player.address].setVelocityX(-320);
              this.users[player.address].flipX = true;
            }
            if (this.users[player.address].body.touching.down && !this.users[player.address].onStais) {
              this.users[player.address].anims.play(`${this.users[player.address].key}anim-male-walk`, true);
            }
          }
          if (player.action === 'beer') {
            this.users[player.address].drinkBeer = true;
            this.users[player.address].setVelocityX(0);
            this.users[player.address].anims
              .play(`${this.users[player.address].key}anim-male-beer`, true)
              .once(
                `${Phaser.Animations.Events.ANIMATION_COMPLETE_KEY}${this.users[player.address].key}anim-male-beer`,
                () => {
                  this.users[player.address].drinkBeer = false;
                  this.users[player.address].anims.play(`${this.users[player.address].key}anim-male-idle`, true);
                },
              );
          }
          if (player.action === 'jump') {
            this.users[player.address].setVelocityY(-330);
            this.users[player.address].anims.play(`${this.users[player.address].key}anim-male-jump`, true);
          }
          if (player.action === 'onStairs') {
            this.physics.world.remove(this.users[player.address].body);
            this.users[player.address].onStais = true;
          }
          if (player.action === 'outStairs') {
            this.physics.world.add(this.users[player.address].body);
            this.users[player.address].onStais = false;
          }
          if (this.users[player.address].onStais) {
            if (this.users[player.address].x !== player.position.x
                && this.users[player.address].y !== player.position.y) {
              this.users[player.address].anims.play(`${this.users[player.address].key}anim-male-walk`, true);
              this.users[player.address].setPosition(player.position.x, player.position.y);
            }
            if (player.action === 'walkStop') {
              this.users[player.address].anims.play(`${this.users[player.address].key}anim-male-idle`, true);
            }
          }
          this.users[player.address].nextX = player.position.x;
          this.users[player.address].nextY = player.position.y;
        }
      }
    });
  }

  createCurrentUser() {
    if (store.getState().portraits.guildsInfo?.id.includes('default')) {
      this.pawnKey = store.getState().portraits.guildsInfo?.id;
    }
    this.player = this.physics.add.sprite(143, 1730, this.pawnKey);
    this.player.key = this.pawnKey;
    this.player.setOrigin(0.5, 1);
    this.player.setCollideWorldBounds(true);
    this.physics.add.collider(this.player, this.platforms);
    this.cameras.main.startFollow(this.player, true, 0.05, 0.05);
    this.player.setDisplayOrigin(45, 278);
    this.player.setBodySize(90, 172, true);
    this.player.setOffset(0, 106);
  }

  createCompetitor(address, x, y, name, portrait) {
    this.load.spritesheet(
      portrait.animation,
      `https://i.bimium.link/${portrait.animation}`,
      { frameWidth: 90, frameHeight: 279 },
    );
    this.load.once(Phaser.Loader.Events.COMPLETE, () => {
      if (this.users[address] === undefined) {
        Object.keys(this.animConfig).forEach((animName) => {
          this.anims.create({
            key: portrait.animation + animName,
            frames: this.anims.generateFrameNumbers(portrait.animation, this.animConfig[animName]),
            frameRate: 10,
            repeat: animName.endsWith('anim-male-beer') ? 0 : -1,
          });
        });
        this.users[address] = this.physics.add.sprite(x, y, portrait.animation);
        this.users[address].key = portrait.animation;
        this.users[address].setOrigin(0.5, 1);
        this.users[address].setBodySize(90, 170, true);
        this.users[address].setOffset(0, 106);
        this.users[address].nextX = x;
        this.users[address].nextY = y;
        this.users[address].setCollideWorldBounds(true);
        this.physics.add.collider(this.users[address], this.platforms);
        this.users[address].userName = this.add.text(
          this.users[address].x,
          this.users[address].y,
          name?.length > 30 ? `${name.slice(0, 30)}...` : name || ' ',
          {
            font: '16px alagard',
            wordWrap: 'break-word',
            fill: '#52c974',
            align: 'center',
          },
        ).setDisplayOrigin(44, 190);
        if (Ladder.checkNearStairsForCompetitor(x, y, this.ladders)) {
          this.physics.world.remove(this.users[address].body);
          this.users[address].onStais = true;
        }
      }
    });
    this.load.start();
  }

  createAnimation() {
    Object.keys(this.animConfig).forEach((animName) => {
      this.anims.create({
        key: this.pawnKey + animName,
        frames: this.anims.generateFrameNumbers(this.pawnKey, this.animConfig[animName]),
        frameRate: 10,
        repeat: -1,
      });
    });
  }

  disconnectUser(address) {
    if (this.users[address]) {
      this.users[address].destroy();
      if (this.users[address].userMessage) {
        this.users[address].userMessage.setVisible(false);
        this.users[address].messageBackground.setVisible(false);
      }
      this.users[address].userName.setVisible(false);
      delete this.users[address];
    }
  }

  update(time, deltaTime) {
    this.controls?.update(deltaTime);

    const newMessages = Object.keys(this.userPositionsQueue)
      .map((k) => this.userPositionsQueue[k].shift()).filter((m) => m);
    if (newMessages.length) {
      this.onlineUsers(newMessages);
    }

    if (this.player.userMessage) {
      this.player.userMessage.x = this.player.x;
      this.player.userMessage.y = this.player.y;
      this.player.messageBackground.x = this.player.x;
      this.player.messageBackground.y = this.player.y;
    }
    // eslint-disable-next-line no-restricted-syntax,guard-for-in
    for (const usersKey in this.users) {
      if (
        Math.abs(this.users[usersKey].nextX - this.users[usersKey].x) < 2
        && !this.users[usersKey].onStais
        && !this.users[usersKey].drinkBeer
      ) {
        this.users[usersKey].setVelocityX(0);
        this.users[usersKey].anims.play(`${this.users[usersKey].key}anim-male-idle`, true);
      }
      if (this.users[usersKey].userMessage) {
        this.users[usersKey].userMessage.setPosition(this.users[usersKey].x, this.users[usersKey].y);
        this.users[usersKey].messageBackground.setPosition(this.users[usersKey].x, this.users[usersKey].y);
      }
      this.users[usersKey].userName.setPosition(this.users[usersKey].x, this.users[usersKey].y);
    }
    if (this.playerClimbing) {
      const isOnLadder = this.playerClimbing.playerMoving(
        this.player,
        this.cursors,
        this.walk.bind(this),
        this.walkStop.bind(this),
        this.player.key,
      );
      if (isOnLadder) {
        this.handleUserPosition(false);
        return;
      }

      this.physics.world.add(this.player.body);
      this.player.setVelocityY(0);
      this.player.setVelocityX(0);
      this.playerClimbing = false;
      this.handleUserPosition(false, 'outStairs');
    }

    if (this.cursors.left.isDown) {
      this.player.setVelocityX(-320);
      this.player.flipX = true;
      if (this.player.body.touching.down) {
        this.player.anims.play(`${this.player.key}anim-male-walk`, true);
        this.walk();
      }
    } else if (this.cursors.right.isDown) {
      this.player.setVelocityX(320);
      this.player.flipX = false;
      if (this.player.body.touching.down) {
        this.player.anims.play(`${this.player.key}anim-male-walk`, true);
        this.walk();
      }
    } else if (this.player) {
      this.player.setVelocityX(0);
      if (this.player.body.touching.down) {
        if (this.drinkBeer) {
          this.player.anims.play(`${this.player.key}anim-male-beer`, true);
        } else {
          this.player.anims.play(`${this.player.key}anim-male-idle`, true);
        }
        this.walkStop();
      }
    }

    if (this.cursors.space.isDown && this.player.body.touching.down) {
      this.player.setVelocityY(-330);
      this.player.anims.play(`${this.player.key}anim-male-jump`);
      this.handleUserPosition(false, 'jump');
      this.soundJumps.play();
      this.walkStop();
    }

    if (!this.player.body.touching.down) {
      this.handleUserPosition(false);
    }
    // check for touching down to play sound
    if (!this.player.body.touching.down) {
      this.flying = true;
    }
    if (this.player.body.touching.down && this.flying) {
      this.flying = false;
      this.soundTouchdown.play();
    }
    this.handleUserPosition(time);
    this.checkNpcInteraction();
    this.checkStairs();
    this.handleQuestBoard();
    this.checkExit();
  }

  walk() {
    if (!this.walking) {
      this.soundSteps.play();
      this.walking = true;
    }
  }

  walkStop() {
    if (this.walking) {
      this.walking = false;
      this.soundSteps.stop();
      this.handleUserPosition(false, 'walkStop');
    }
  }

  handleUserPosition(time, action = null, arg = null) {
    if (time - this.lastTimeHandlePosition > 100 || !this.lastTimeHandlePosition || !time) {
      this.lastTimeHandlePosition = time;
      if (this.lastX !== this.player.x || this.lastY !== this.player.y || action !== null) {
        this.lastX = this.player.x;
        this.lastY = this.player.y;
        handleUpdateUserPosition(this.player.x, this.player.y, action, arg);
      }
    }
  }

  handleQuestBoard() {
    this.questBoard = this.player.x > 800 && this.player.x < 1010 && this.player.y === 1730;
  }

  checkExit() {
    this.exit = this.player.x < 140 && Math.round(this.player.y) === 1730;
  }

  checkNpcInteraction() {
    this.playerNearNPC = Object.keys(this.NPCs).some((elem) => Math.abs(this.player.x - this.NPCs[elem].x) < 200
      && Math.abs(this.player.y - this.NPCs[elem].y) < 200);
    this.NPCAround = Object.keys(this.NPCs).filter((elem) => Math.abs(this.player.x - this.NPCs[elem].x) < 200
      && Math.abs(this.player.y - this.NPCs[elem].y) < 200);
    if (this.playerNearNPC && !this.npcTextVisible) {
      this.npcTextVisible = true;
      if (randomShowing() && this.NPCs[this.NPCAround[0]]?.interact) {
        const randomMessage = interactMessages(this.NPCs[this.NPCAround[0]].interact);
        this.createInteractMessage(
          this.NPCs[this.NPCAround[0]].x,
          this.NPCs[this.NPCAround[0]].y,
          randomMessage.length > 27 ? 10 : 0,
          this.NPCAround[0],
        );
        this.npcText.setVisible(true);
        this.npcTextBackground.setVisible(true);
        this.typing = new TextTyping(this.npcText);
        this.typing.start(randomMessage, 70);
        this.typing.on('complete', () => {
          this.npcTextTimeOut = setTimeout(() => {
            this.npcText.setVisible(false);
            this.npcTextBackground.setVisible(false);
          }, 5000);
        });
      }
    }

    if (!this.playerNearNPC && this.npcTextVisible) {
      this.npcTextVisible = false;
      this.npcText.setVisible(false);
      this.npcTextBackground.setVisible(false);
      if (this.npcInteractText) {
        this.npcInteractText.setVisible(false);
        this.npcInteractTextBackground.setVisible(false);
      }
      if (this.npcTextTimeOut) {
        clearTimeout(this.npcTextTimeOut);
      }
      if (this.npcInteractTextTimeOut) {
        clearTimeout(this.npcInteractTextTimeOut);
      }
    }
  }

  checkStairs() {
    const onLadder = this.playerClimbing
      || Ladder.checkNearStairs(this.player.x, this.player.y, this.ladders, this.cursors, this.flying);
    if (onLadder) {
      if (!this.playerClimbing) {
        this.flying = false;
        this.player.body.touching.down = true;
        this.physics.world.remove(this.player.body);
        this.handleUserPosition(false, 'onStairs');
      }
      this.playerClimbing = onLadder;
    }
  }

  handleKey(isOpen) {
    if (isOpen) {
      this.input.keyboard.disableGlobalCapture();
      this.input.keyboard.enabled = false;
    } else {
      this.input.keyboard.enableGlobalCapture();
      this.input.keyboard.enabled = true;
    }
  }

  setMessage(playerMessage) {
    const text = playerMessage.message;
    if (playerMessage.address === this.evm) {
      if (!this.player.userMessage) {
        this.player.messageBackground = this.add
          .sprite(this.player.x, this.player.y, 'backgroundMessage')
          .setDisplayOrigin(144, 280);
        this.player.userMessage = this.add.text(
          this.player.x,
          this.player.y,
          text?.length > 30 ? `${text.slice(0, 25)}...` : text || ' ',
          {
            font: '22px alagard',
            wordWrap: 'break-word',
            fill: '#573229',
            align: 'center',
            fixedWidth: 272,
            fixedHeight: 81,
          },
        ).setDisplayOrigin(134, 250);
        this.player.userMessage.setVisible(true);
        // eslint-disable-next-line max-len
        this.player.userMessage.timeOut = setTimeout(() => {
          this.player.userMessage.setVisible(false);
          this.player.messageBackground.setVisible(false);
        }, 10000);
      } else {
        this.player.messageBackground.setVisible(true);
        this.player.userMessage.setText(text?.length > 30
          ? `${text.slice(0, 30)}...` : text || ' ');
        this.player.userMessage.setVisible(true);
        if (this.player.userMessage.timeOut) {
          clearTimeout(this.player.userMessage.timeOut);
        }
        this.player.userMessage.timeOut = setTimeout(() => {
          this.player.userMessage.setVisible(false);
          this.player.messageBackground.setVisible(false);
        }, 10000);
      }
    }
    if (playerMessage.address !== this.evm) {
      if (this.users[playerMessage.address] && !this.users[playerMessage.address].userMessage) {
        this.users[playerMessage.address].messageBackground = this.add.sprite(
          this.users[playerMessage.address].x,
          this.users[playerMessage.address].y,
          'backgroundMessage',
        ).setDisplayOrigin(144, 280);
        this.users[playerMessage.address].userMessage = this.add.text(
          this.users[playerMessage.address].x,
          this.users[playerMessage.address].y,
          text?.length > 30 ? `${text.slice(0, 30)}...` : text || ' ',
          {
            font: '22px alagard',
            wordWrap: 'break-word',
            fill: '#573229',
            align: 'center',
            fixedHeight: 81,
            fixedWidth: 272,
          },
        ).setDisplayOrigin(134, 250);
        this.users[playerMessage.address].userMessage.setVisible(true);
        this.users[playerMessage.address].userMessage.timeOut = setTimeout(() => {
          this.users[playerMessage.address].userMessage.setVisible(false);
          this.users[playerMessage.address].messageBackground.setVisible(false);
        }, 10000);
      } else if (this.users[playerMessage.address] && this.users[playerMessage.address].userMessage) {
        this.users[playerMessage.address].messageBackground.setVisible(true);
        this.users[playerMessage.address].userMessage.setText(text?.length > 30
          ? `${text.slice(0, 30)}...` : text || ' ');
        this.users[playerMessage.address].userMessage.setVisible(true);
        if (this.users[playerMessage.address].userMessage.timeOut) {
          clearTimeout(this.users[playerMessage.address].userMessage.timeOut);
        }
        this.users[playerMessage.address].userMessage.timeOut = setTimeout(() => {
          this.users[playerMessage.address].userMessage.setVisible(false);
          this.users[playerMessage.address].messageBackground.setVisible(false);
        }, 10000);
      }
    }
  }
}

export default TavernScene;
