/* eslint-disable class-methods-use-this */
import createActionByConfig from '../actions/action-factory';
import { DEFAULT_FRAME_RATE } from '../const';
import { ACTION_EVENTS } from '../actions/event';

export class SkillBase {
  constructor(
    scene,
    pawn,
    opponentPawn,
    actions,
    skillConfig,
    serverAction = undefined,
    checkIfBuff = undefined,
  ) {
    console.log('CREATE SKILL', scene, pawn, opponentPawn, actions, skillConfig, serverAction);
    this.scene = scene;
    this.pawn = pawn;
    this.opponentPawn = opponentPawn;
    this.config = skillConfig;
    this.serverAction = serverAction;
    this.timeline = this.scene.add.timeline();
    this.timelineInitial = this.scene.add.timeline({
      run: () => {
        if (serverAction?.cost) {
          this.pawn.showFloatingText(
            this.timelineInitial,
            0,
            1200,
            `-${serverAction.cost}`,
            '#ee8507',
          );
        }
      },
    });
    this.timelineEnd = this.scene.add.timeline({
      run: () => {
        console.log('START CLEAR!!!!!!!!', this.timeline);
        this.timeline.events.forEach((event) => {
          if (event.tween) {
            event.tween.targets.setVisible(false);
          }
        });
      },
    });
    console.log('CREATE SKILL', this);
    actions.forEach((a) => createActionByConfig(this, { ...a, timeline: this.getTimeline(a) }));
    const initialTimelineDuration = actions.filter((a) => a.group === 'Initial' || a.group === null)
      .reduce((acc, cur) => (acc > cur.duration + cur.delay ? acc : cur.duration + cur.delay), 0);

    this.timelineInitial.add({
      at: initialTimelineDuration,
      event: 'COMPLETED',
    });
    this.timeline.add({
      at: actions.filter((a) => a.group === 'Persistent')
        .reduce((acc, cur) => (acc > cur.duration + cur.delay ? acc : cur.duration + cur.delay), 0),
      event: 'COMPLETED',
    });
    this.timelineEnd.add({
      at: actions.filter((a) => a.group === 'End')
        .reduce((acc, cur) => (acc > cur.duration + cur.delay ? acc : cur.duration + cur.delay), 0),
      event: 'COMPLETED',
    });

    // assign buff floating text
    if (this.config.hasEffect && serverAction && checkIfBuff) {
      this.timelineInitial.on('COMPLETED', () => {
        const text = this.config.spellName.replace('_', ' ').toUpperCase();
        const color = this.pawn.isCurrentPlayer ? '#36a131' : '#F8EF58';
        this.pawn.showFloatingText(
          this.timelineInitial,
          initialTimelineDuration,
          1200,
          text,
          color,
        );
      });
    }

    this.timelineInitial.on(ACTION_EVENTS.ACTION_EVENT_HIT, this.onHitEvent);
    this.timeline.on(ACTION_EVENTS.ACTION_EVENT_HIT, this.onHitEvent);
    this.timelineEnd.on(ACTION_EVENTS.ACTION_EVENT_HIT, this.onHitEvent);
  }

  onHitEvent(e) {
    if (e.skill.serverAction) {
      e.skill.opponentPawn.showFloatingText(
        this,
        e.config.delay,
        1200,
        e.skill.serverAction.damage?.toString()
          ? (
            parseInt(e.skill.serverAction.damage.toString(), 10) === 0
              ? 'Absorbed'
              : `-${e.skill.serverAction.damage.toString()}`)
          : '',
        '#F8EF58',
      );
    }
  }

  getTimeline(action) {
    if (this.config.hasEffect) {
      switch (action.group) {
        case 'Initial':
          return this.timelineInitial;
        case 'Persistent':
          return this.timeline;
        case 'End':
          return this.timelineEnd;
        default:
          return this.timeline;
      }
    }
    return this.timelineInitial;
  }

  // run action group
  run(spellPart = undefined) {
    if (!spellPart) {
      return new Promise((resolve) => {
        this.timeline.on('COMPLETED', () => {
          console.log('----Timeline finished TIMELINE');
          // if (this.config.hasEffect) {
          //   this.opponentPawn.buffState.push(this.config.effect.name);
          //   this.opponentPawn.buffState = this.opponentPawn.filter(
          //     (value, index, array) => (array.indexOf(value) === index),
          //   );
          // }
          resolve();
        });
        // this.timeline.play();
        this.timelineInitial.play();
        this.timelineInitial.on('COMPLETED', () => {
          console.log('----Timeline finished INITIAL');
          this.timeline.play();
          // resolve();
        });
      });
    }
    return new Promise((resolve) => {
      this.timelineEnd.play();
      this.timelineEnd.on('COMPLETED', () => {
        console.log('----Timeline finished END');
        resolve();
      });
    });
  }

  createAnimations(config) {
    config.animations.forEach(
      (a) => {
        console.log('CREATE PHASER ANIMATION FOR SKILL', a);
        if (a.key && a.start.toString().length && a.end.toString().length) {
          const gfnConf = {};
          if (Object.hasOwn(a, 'start')) {
            gfnConf.start = parseInt(a.start.toString(), 10);
          }
          if (Object.hasOwn(a, 'end')) {
            gfnConf.end = parseInt(a.end.toString(), 10);
          }
          if (this.scene.anims.exists(a.key)) {
            this.scene.anims.remove(a.key);
          }
          this.scene.anims.create({
            key: a.key,
            frames: this.scene.anims.generateFrameNumbers(config.key, gfnConf),
            frameRate: parseInt(a.frameRate.toString(), 10) || DEFAULT_FRAME_RATE,
            repeat: Object.hasOwn(a, 'repeat') ? parseInt(a.repeat.toString(), 10) : 0,
          });
        }
      },
    );
  }
}
