import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './SetPortraitModal.module.scss';
import { putPortraitAction, selectPortraits } from '../../../../../store/slices/portraits';
import loader from '../../../../../static/img/loader.gif';
import deleteImg from '../../../../../static/img/header/balancebar/dropdown/Decline button.png';

const SetPortraitModal = ({ setShowModal }) => {
  const dispatch = useDispatch();
  const ref = useRef(null);

  const {
    portraits, loading,
  } = useSelector(selectPortraits);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowModal(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return (
    <div
      role="presentation"
      className={styles.wrapper}
    >
      <div className={styles.content} ref={ref}>
        <img
          className={styles.close_button}
          role="presentation"
          src={deleteImg}
          alt="del"
          width="24px"
          height="24px"
          onClick={() => {
            setShowModal(false);
          }}
        />
        {loading
          ? <img src={loader} alt="loading" />
          : (
            <div className={styles.scroll}>
              {portraits.length
                ? (
                  <div className={styles.portraits_grid}>
                    {portraits.map((portrait) => (
                      <img
                        key={portrait.id}
                        src={`https://i.bimium.link/${portrait.image}`}
                        alt=""
                        width="80px"
                        height="80px"
                        role="presentation"
                        onClick={() => dispatch(putPortraitAction(portrait))}
                      />
                    ))}
                  </div>
                )
                : null}
            </div>
          )}
      </div>
    </div>
  );
};

export default SetPortraitModal;
