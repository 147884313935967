import { createSlice } from '@reduxjs/toolkit';

export const taverna = createSlice({
  name: 'quests',
  initialState: {
    users: [],
    time: null,
    disconnect: null,
  },
  reducers: {
    usersState: (state, { payload }) => ({
      ...state,
      users: payload?.userStates,
      time: Date.now(),
    }),
    userDisconnect: (state, { payload }) => ({
      ...state,
      disconnect: payload.address,
    }),
  },
});

export const {
  usersState, userDisconnect,
} = taverna.actions;

export const selectUserState = (state) => state.taverna.users;
export const selectDisconnectUser = (state) => state.taverna.disconnect;
