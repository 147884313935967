import Phaser from 'phaser';
import { pawns } from './pawns';
import Pawn from './pawn';
import { getPawnConfigByKey } from './const';

class EquipmentScene extends Phaser.Scene {
  constructor(config) {
    super(config);
    this.setReady = config.setReady;
    this.queue = new Promise((resolve) => {
      resolve(true);
    });
  }

  preload() {
    // this.load.image('background', 'assets/Battle background.png');
    // this.load.image('selectArrow', 'assets/battle/Select arrow.png');
    this.load.image('heroShadow', 'assets/hero-shadow.png');
    pawns.forEach((p) => {
      p.class.preload(this, p);
    });
  }

  create() {
    this.currentPlayerGroup = new Phaser.GameObjects.Group(this, [], { name: 'currentPlayerGroup' });
    const cam = this.cameras.main;
    cam.setBounds(0, 0, 420, 420);
    cam.centerOn(210, 210);
    setTimeout(() => {
      this.setReady(true);
    }, 1500);
  }

  createPawn(pawnKey, idPawn, isCurrentPlayer = true, set = null) {
    const screenCenterX = this.cameras.main.worldView.x + this.cameras.main.width / 2;
    const screenCenterY = this.cameras.main.worldView.y + this.cameras.main.height / 2;

    const groupPawns = isCurrentPlayer ? this.currentPlayerGroup : this.opponentPlayerGroup;
    const pawn = groupPawns.children.getArray().find((i) => i.idPawn === idPawn);
    // console.log(groupPawns.children.getArray(), pawnKey, pawnId, isCurrentPlayer, this);
    if (pawn) {
      pawn.destroy(true, true);
      this.children.remove(pawn);
    }
    groupPawns.add(
      new Pawn({
        scene: this,
        x: screenCenterX,
        y: screenCenterY,
        idPawn,
        isCurrentPlayer,
        pawnConfig: getPawnConfigByKey(pawns, pawnKey),
        debug: false,
        set,
      }),
    );
  }
}

export default EquipmentScene;
