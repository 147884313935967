import React from 'react';
import styles from './InfoButton.module.scss';

const InfoButton = ({
  text, isOpenSell, isOpenBuy, sort,
}) => (
  <div
    role="presentation"
    className={`${styles.button_wrapper} ${
      (isOpenSell || isOpenBuy) && styles.active
    } ${text === sort ? styles.active_btn : ''} ${
      (text === 'Buy' || text === 'Sell' || text === 'Transfer') && styles.adaptive_button
    } ${!text && styles.hidden}`}
  >
    <div className={styles.button_left} />
    <div className={styles.button_content}>
      <h1>{text}</h1>
    </div>
    <div className={styles.button_right} />
  </div>
);

export default InfoButton;
