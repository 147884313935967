import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import loader from '../../../../../static/img/loader.gif';
import uniqueId from '../../../../../utils/uniqueId';
import { selectSpellLoading, selectSpellStats } from '../../../../../store/slices/constructor';
import styles from '../../../../QuestPage/PickPawnModal/Tooltip/Tooltip.module.scss';

const Tooltip = ({ name }) => {
  const loading = useSelector(selectSpellLoading);
  const pawnSpell = useSelector(selectSpellStats);

  const tooltipContent = useMemo(() => {
    if (loading) {
      return (
        <div className={styles.loader}>
          <div className={styles.wrap_loader}>
            <img src={loader} alt="loading..." />
          </div>
        </div>
      );
    }
    if (pawnSpell && Object.keys(pawnSpell).length) {
      return (
        Object.keys(pawnSpell).map((item) => (
          pawnSpell[item] ? (
            <div className={styles.stat} key={uniqueId('pawn_spell')}>
              <span className={styles.name_stat}>{item?.replace('_', ' ')}</span>
              <div className={styles.stat_item}>
                {pawnSpell[item]}
              </div>
            </div>
          ) : null
        ))
      );
    }
    return null;
  }, [loading, pawnSpell]);

  return (
    <div
      role="presentation"
      className={styles.tooltip}
    >
      <div className={styles.tooltip_top_left} />
      <div className={styles.tooltip_top} />
      <div className={styles.tooltip_top_right} />
      <div className={styles.tooltip_left} />
      <div className={styles.tooltip_content}>
        <div className={styles.head}>
          <div className={styles.head_left}>
            <div className={styles.title}>
              {name?.split('_').join(' ')}
            </div>
            <div className={styles.separator} />
            {tooltipContent}
          </div>
        </div>
      </div>
      <div className={styles.tooltip_right} />
      <div className={styles.tooltip_bottom_left} />
      <div className={styles.tooltip_bottom} />
      <div className={styles.tooltip_bottom_right} />
    </div>
  );
};

export default Tooltip;
