import React, { useEffect, useState } from 'react';
import Phaser from 'phaser';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import EmptySpell from '../../../static/img/equipment/pick_spell.png';
import loader from '../../../static/img/loader.gif';
import uniqueId from '../../../utils/uniqueId';
import Plus from '../../../static/img/presale/plus.png';
import {
  removePawnEquipment,
  removePawnItem, removePawnSpell, selectActivePreset,
  selectPawnEquipment,
  selectPawnEquipmentLoading,
  selectPawnItems, selectPawnSet, selectPawnSpells, selectUpgradePoints,
} from '../../../store/slices/preset';
import { selectDefaultStats, selectPawn, updatePawnStats } from '../../../store/slices/pawnSelect';
import { defaultEquipment, defaultEquipment2 } from '../../../utils/skill/pawns';
import Tooltip from '../components/Tooltip';
import SpellPreview from './SpellPreview';
import styles from './SetPawn.module.scss';
import EquipmentScene from '../../../utils/skill/equipmentScene';

let eGame = null;
let equipmentScene = null;

const equipmentSceneConfig = {
  type: Phaser.AUTO,
  parent: 'equipment-container',
  transparent: true,
  backgroundColor: 'rgba(0,0,0,0)',
  'render.transparent': true,
  scale: {
    mode: Phaser.Scale.ScaleModes.FIT,
    width: 420,
    height: 420,
  },
};
const SetPawn = ({
  data, loading, selectedPawn,
}) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const pawnGuildEquipmentLoading = useSelector(selectPawnEquipmentLoading);
  const pawnGuildEquipment = useSelector(selectPawnEquipment);
  const pawnGuildItems = useSelector(selectPawnItems);
  const pawnGuildSpells = useSelector(selectPawnSpells);
  const points = useSelector(selectUpgradePoints);
  const pawn = useSelector(selectPawn);
  const defaultStats = useSelector(selectDefaultStats);
  const set = useSelector(selectPawnSet);
  const activePreset = useSelector(selectActivePreset);

  const [sceneReady, setSceneReady] = useState(false);

  const handleColor = (elem, value) => {
    if (defaultStats[elem] > value) {
      return styles.red;
    }
    if (defaultStats[elem] < value) {
      return styles.green;
    }
    return '';
  };

  useEffect(() => {
    eGame = new Phaser.Game(equipmentSceneConfig);
    eGame.events.on(
      Phaser.Core.Events.READY,
      () => {
        equipmentScene = new EquipmentScene({
          key: 'equipment-scene',
          setReady: setSceneReady,
        });
        eGame.scene.add('equipment-scene', equipmentScene, true);
      },
    );
  }, []);

  useEffect(() => {
    if (sceneReady && pawn && pawn.class) {
      equipmentScene.createPawn(pawn.class, 1, true, set);
    }
  }, [sceneReady, set, pawn]);

  return (
    <div className={`${styles.wrapper} ${data ? styles[data.tier] : ''}`}>
      {
        loading || (pawnGuildEquipmentLoading && Object.keys(pawn).length !== 0)
          ? (
            <>
              <div className={styles.loader_wrap} />
              <img src={loader} alt="loader" className={styles.loader} />
            </>
          )
          : null
      }
      <div id="equipment-container" className={styles.content_hero_phaser} />
      {data && (
        <>
          <div className={styles.title}>
            <div className={styles.points}>{`Upgrade points: ${points}`}</div>
            <div className={styles.pawn_info}>
              <div className={styles.name}>
                {data.name}
              </div>
              <div className={`${styles.tier} ${styles[data.tier]}`}>
                {`${data.tier} level ${data.level}`}
              </div>
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.wrap_stat}>
              {Object.keys(data.stats).map((item) => (
                <div className={styles.stat} key={uniqueId('pawn_stats')}>
                  <span className={styles.name_stat}>{item}</span>
                  <div className={`${styles.wrap_plus_stat} ${points ? '' : styles.notPoints}`}>
                    {points ? (
                      <div
                        role="presentation"
                        className={styles.img_plus}
                        onClick={() => {
                          dispatch(updatePawnStats({
                            id: data.nftId,
                            stat: item,
                          }));
                        }}
                      >
                        <img src={Plus} alt="plus" />
                      </div>
                    ) : null}
                    <div className={`${styles.stats_layout} ${handleColor(item, data.stats[item])}`}>
                      {data.stats[item]}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.hero}>
              <div className={styles.wrap_items}>
                {Object.keys(pawnGuildEquipment).slice(0, 3).map((elem, index) => (
                  <div key={[index]}>
                    <div
                      data-tip
                      data-for={`${pawnGuildEquipment[elem].id}${elem}`}
                    >
                      <img
                        role="presentation"
                        src={pawnGuildEquipment[elem]
                          ? `https://i.bimium.link/${pawnGuildEquipment[elem].image}`
                          : defaultEquipment[index]}
                        alt="equipment"
                        width="64px"
                        height="64px"
                        className={pawnGuildEquipment[elem] ? styles.cursor : ''}
                        onClick={() => (pawnGuildEquipment[elem] ? dispatch(removePawnEquipment({
                          pawnId: selectedPawn,
                          itemId: pawnGuildEquipment[elem].id,
                          vs: activePreset,
                        }, {
                          [elem]: '',
                        }, pawnGuildEquipment[elem]?.stats || null)) : {})}
                      />
                    </div>
                    { pawnGuildEquipment[elem] ? (
                      <ReactTooltip
                        id={`${pawnGuildEquipment[elem]?.id}${elem}`}
                        place="top"
                        backgroundColor="none"
                        arrowColor="transparent"
                        delayShow={100}
                      >
                        <Tooltip
                          name={pawnGuildEquipment[elem]?.name || '?'}
                          stats={{
                            ...pawnGuildEquipment[elem]?.stats,
                            health: pawnGuildEquipment[elem]?.additionalStats?.health?.value
                              ? `${pawnGuildEquipment[elem]?.additionalStats?.health?.value}
                              ${pawnGuildEquipment[elem]?.additionalStats?.health?.type === 'Percent'
                                ? '%'
                                : ''}`
                              : undefined,
                            magicka: pawnGuildEquipment[elem]?.additionalStats?.magicka?.value
                              ? `${pawnGuildEquipment[elem]?.additionalStats?.magicka?.value}
                              ${pawnGuildEquipment[elem]?.additionalStats?.magicka?.type === 'Percent'
                                ? '%'
                                : ''}`
                              : undefined,
                            willpower: pawnGuildEquipment[elem]?.additionalStats?.willpower?.value
                              ? `${pawnGuildEquipment[elem]?.additionalStats?.willpower?.value}
                              ${pawnGuildEquipment[elem]?.additionalStats?.willpower?.type
                              === 'Percent'
                                ? '%'
                                : ''}`
                              : undefined,
                            armor: pawnGuildEquipment[elem]?.additionalStats?.armor?.value
                              ? `${pawnGuildEquipment[elem]?.additionalStats?.armor?.value}
                              ${pawnGuildEquipment[elem]?.additionalStats?.armor?.type === 'Percent'
                                ? '%'
                                : ''}`
                              : undefined,
                            speed: pawnGuildEquipment[elem]?.additionalStats?.speed?.value
                              ? `${pawnGuildEquipment[elem]?.additionalStats?.speed?.value}
                              ${pawnGuildEquipment[elem]?.additionalStats?.speed?.type === 'Percent'
                                ? '%'
                                : ''}`
                              : undefined,
                            magic_damage: pawnGuildEquipment[elem]?.additionalStats?.magicDamage?.value
                              ? `${pawnGuildEquipment[elem]?.additionalStats?.magicDamage?.value}
                              ${pawnGuildEquipment[elem]?.additionalStats?.magicDamage?.type
                              === 'Percent'
                                ? '%'
                                : ''}`
                              : undefined,
                            weapon_damage: pawnGuildEquipment[elem]?.additionalStats?.weaponDamage?.value
                              ? `${pawnGuildEquipment[elem]?.additionalStats?.weaponDamage?.value}
                              ${pawnGuildEquipment[elem]?.additionalStats?.weaponDamage?.type
                              === 'Percent'
                                ? '%'
                                : ''}`
                              : undefined,
                            critical_strike: pawnGuildEquipment[elem]?.additionalStats?.criticalStrike?.value
                              ? `${pawnGuildEquipment[elem]?.additionalStats?.criticalStrike?.value}
                              ${pawnGuildEquipment[elem]?.additionalStats?.criticalStrike?.type
                              === 'Percent'
                                ? '%'
                                : ''}`
                              : undefined,
                            dodge: pawnGuildEquipment[elem]?.additionalStats?.dodge?.value
                              ? `${pawnGuildEquipment[elem]?.additionalStats?.dodge?.value}
                              ${pawnGuildEquipment[elem]?.additionalStats?.dodge?.type === 'Percent'
                                ? '%'
                                : ''}`
                              : undefined,
                          }}
                        />
                      </ReactTooltip>
                    ) : null}
                  </div>
                ))}
              </div>
              {/* <img */}
              {/*   src={`https://i.bimium.link/${data.image}`} */}
              {/*   alt="hero" */}
              {/*   className={styles.content_hero} */}
              {/* /> */}
              <div className={styles.wrap_items}>
                {Object.keys(pawnGuildEquipment).slice(3, 6).map((elem, index) => (
                  <div key={[index + 3]}>
                    <div
                      data-tip
                      data-for={`${pawnGuildEquipment[elem].id}${elem}`}
                    >
                      <img
                        role="presentation"
                        src={pawnGuildEquipment[elem]
                          ? `https://i.bimium.link/${pawnGuildEquipment[elem].image}`
                          : defaultEquipment2[index]}
                        alt="equipment"
                        width="64px"
                        height="64px"
                        className={pawnGuildEquipment[elem] ? styles.cursor : ''}
                        onClick={() => (pawnGuildEquipment[elem] ? dispatch(removePawnEquipment({
                          pawnId: selectedPawn,
                          itemId: pawnGuildEquipment[elem].id,
                          vs: activePreset,
                        }, {
                          [elem]: '',
                        }, pawnGuildEquipment[elem]?.stats || null)) : {})}
                      />
                    </div>
                    { pawnGuildEquipment[elem] ? (
                      <ReactTooltip
                        id={`${pawnGuildEquipment[elem]?.id}${elem}`}
                        place="top"
                        backgroundColor="none"
                        arrowColor="transparent"
                        delayShow={100}
                      >
                        <Tooltip
                          name={pawnGuildEquipment[elem]?.name || '?'}
                          stats={{
                            ...pawnGuildEquipment[elem]?.stats,
                            health: pawnGuildEquipment[elem]?.additionalStats?.health?.value
                              ? `${pawnGuildEquipment[elem]?.additionalStats?.health?.value}
                              ${pawnGuildEquipment[elem]?.additionalStats?.health?.type === 'Percent'
                                ? '%'
                                : ''}`
                              : undefined,
                            magicka: pawnGuildEquipment[elem]?.additionalStats?.magicka?.value
                              ? `${pawnGuildEquipment[elem]?.additionalStats?.magicka?.value}
                              ${pawnGuildEquipment[elem]?.additionalStats?.magicka?.type === 'Percent'
                                ? '%'
                                : ''}`
                              : undefined,
                            willpower: pawnGuildEquipment[elem]?.additionalStats?.willpower?.value
                              ? `${pawnGuildEquipment[elem]?.additionalStats?.willpower?.value}
                              ${pawnGuildEquipment[elem]?.additionalStats?.willpower?.type
                              === 'Percent'
                                ? '%'
                                : ''}`
                              : undefined,
                            armor: pawnGuildEquipment[elem]?.additionalStats?.armor?.value
                              ? `${pawnGuildEquipment[elem]?.additionalStats?.armor?.value}
                              ${pawnGuildEquipment[elem]?.additionalStats?.armor?.type === 'Percent'
                                ? '%'
                                : ''}`
                              : undefined,
                            speed: pawnGuildEquipment[elem]?.additionalStats?.speed?.value
                              ? `${pawnGuildEquipment[elem]?.additionalStats?.speed?.value}
                              ${pawnGuildEquipment[elem]?.additionalStats?.speed?.type === 'Percent'
                                ? '%'
                                : ''}`
                              : undefined,
                            magic_damage: pawnGuildEquipment[elem]?.additionalStats?.magicDamage?.value
                              ? `${pawnGuildEquipment[elem]?.additionalStats?.magicDamage?.value}
                              ${pawnGuildEquipment[elem]?.additionalStats?.magicDamage?.type
                              === 'Percent'
                                ? '%'
                                : ''}`
                              : undefined,
                            weapon_damage: pawnGuildEquipment[elem]?.additionalStats?.weaponDamage?.value
                              ? `${pawnGuildEquipment[elem]?.additionalStats?.weaponDamage?.value}
                              ${pawnGuildEquipment[elem]?.additionalStats?.weaponDamage?.type
                              === 'Percent'
                                ? '%'
                                : ''}`
                              : undefined,
                            critical_strike: pawnGuildEquipment[elem]?.additionalStats?.criticalStrike?.value
                              ? `${pawnGuildEquipment[elem]?.additionalStats?.criticalStrike?.value}
                              ${pawnGuildEquipment[elem]?.additionalStats?.criticalStrike?.type
                              === 'Percent'
                                ? '%'
                                : ''}`
                              : undefined,
                            dodge: pawnGuildEquipment[elem]?.additionalStats?.dodge?.value
                              ? `${pawnGuildEquipment[elem]?.additionalStats?.dodge?.value}
                              ${pawnGuildEquipment[elem]?.additionalStats?.dodge?.type === 'Percent'
                                ? '%'
                                : ''}`
                              : undefined,
                          }}
                        />
                      </ReactTooltip>
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.wrapper_group_spells}>
            <div className={styles.wrapper_spell}>
              {Object.keys(pawnGuildSpells).map((item, index) => (
                <div
                  className={styles.wrap_img_spell}
                  data-tip="show"
                  data-for={pawnGuildSpells[item]?.id?.toString() || String(index)}
                  key={uniqueId('image-spells_items')}
                >
                  <img
                    role="presentation"
                    onClick={() => (pawnGuildSpells[item] ? dispatch(removePawnSpell({
                      pawnId: selectedPawn,
                      position: +item,
                    })) : {})}
                    src={pawnGuildSpells[item]?.image || EmptySpell}
                    alt=""
                    className={pawnGuildSpells[item] ? styles.cursor : ''}
                  />
                  {pawnGuildSpells[item] ? (
                    <SpellPreview
                      id={pawnGuildSpells[item]?.id?.toString() || String(index)}
                      spellId={pawnGuildSpells[item].id}
                      name={pawn.name}
                    />
                  ) : null}
                </div>
              ))}
            </div>
            <div className={styles.wrapper_item}>
              {pawnGuildItems.map((item) => (
                <div className={styles.wrap_img_spell} key={uniqueId('image_items')}>
                  <img
                    role="presentation"
                    onClick={() => (item ? dispatch(removePawnItem({
                      pawnId: selectedPawn,
                      consumableId: item.id,
                    })) : {})}
                    src={item ? `https://i.bimium.link/${item.image}` : EmptySpell}
                    alt=""
                    className={item ? styles.cursor : ''}
                    data-tip
                    data-for={`${item ? item.id : ''}itemOnPawn`}
                  />
                  {item ? (
                    <ReactTooltip
                      id={`${item.id}itemOnPawn`}
                      place="top"
                      backgroundColor="none"
                      arrowColor="transparent"
                      delayShow={100}
                    >
                      <Tooltip
                        name={item.name}
                        stats={{
                          level: item?.level,
                          tier: item?.tier,
                          health: item?.additionalStats?.health?.value
                            ? `${item?.additionalStats?.health?.value}${
                              item?.additionalStats?.health?.type === 'Percent'
                                ? '%'
                                : ''}`
                            : undefined,
                          magicka: item?.additionalStats?.magicka?.value
                            ? `${item?.additionalStats?.magicka?.value}${
                              item?.additionalStats?.magicka?.type === 'Percent'
                                ? '%'
                                : ''}`
                            : undefined,
                        }}
                      />
                    </ReactTooltip>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
          <div className={`${styles.dropdown_menu} ${isOpen ? styles.open : ''}`}>
            <div className={styles.wrapper_stats_info}>
              {Array.isArray(data.traits) && data.traits.map((elem) => (
                <div className={styles.content_right_main_text} key={uniqueId('traits-pawns')}>
                  <span className={styles.content_right_main_text_name}>{elem.name}</span>
                  &nbsp;
                  <span className={styles.content_right_main_text_skill}>{elem.description}</span>
                </div>
              ))}
            </div>
          </div>
          <div
            className={`${styles.expand} ${isOpen ? styles.open : ''}`}
            role="presentation"
            onClick={() => setIsOpen(!isOpen)}
          />
        </>
      )}
    </div>
  );
};

export default SetPawn;
