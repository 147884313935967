import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { resetInventoryForEquipment, selectMarketplace, selectPawnsForEquipment } from '../../store/slices/requests';
import {
  clearPawn,
  getPawn,
  selectPawn, selectPawnLoading,
} from '../../store/slices/pawnSelect';
import { selectEvm } from '../../store/slices/auth';
import {
  getPawnEquipment,
  getPawnsGuild, modalShowing,
  putGuilds, removePawnEquipment, removePawnItem, selectAction, selectActivePreset,
  selectGuilds, selectIsModalShowing, selectType, setActivePreset,
} from '../../store/slices/preset';
import { clearDataEquipment } from '../../store/slices/constructor';
import Header from '../../components/Marketplace/Components/Header';
import Filters from '../Pick/Filters';
import Inventory from './Inventory';
import SetPawn from './SetPawn';
import MobileLayout from '../../components/MobileLayout';
import Separator from '../../components/LayoutWindow/Separator';
import CombatPreset from './CombatPreset';
import { typeFunc } from '../../utils/chooseData';
import ConfirmModal from './components/ConfirmModal';
import styles from './Equipment.module.scss';

const Equipment = () => {
  const dispatch = useDispatch();
  const [selectedPawn, setSelectedPawn] = useState();
  const [width, setWidth] = useState(window.innerWidth);
  const [isChanged, setIsChanged] = useState(false);
  const [filter, setFilter] = useState('Filter');
  const [filterByTier, setFilterByTier] = useState('Tier');
  const [filterBySlot, setFilterBySlot] = useState('Slot');
  const [filterByClass, setFilterByClass] = useState('Class');
  const [filterByClassForSpell, setFilterByClassForSpell] = useState('Class');
  const [filterByType, setFilterByType] = useState('Type');
  const [filterByTierForItems, setFilterByTierForItems] = useState('Tier');
  const [filterByApplication, setFilterByApplication] = useState('Application');
  const [tier, setTier] = useState('');
  const [search, setSearch] = useState('');

  const guild = useSelector(selectGuilds);
  const pawn = useSelector(selectPawn);
  const pawnLoading = useSelector(selectPawnLoading);
  const address = useSelector(selectEvm);
  const pawnInventory = useSelector(selectPawnsForEquipment);
  const activePreset = useSelector(selectActivePreset);
  const showModal = useSelector(selectIsModalShowing);
  const action = useSelector(selectAction);
  const type = useSelector(selectType);

  useEffect(() => {
    if (Object.keys(pawn).length) {
      setFilterByClass(pawn.class);
      setFilterByClassForSpell(pawn.class);
      setFilterByTier(pawn.tier);
      setFilterByTierForItems(pawn.tier);
      dispatch(clearDataEquipment());
    }
  }, [pawn]);

  const {
    loading, count, inventoryMobileEquipment,
  } = useSelector(selectMarketplace);

  useEffect(() => {
    const onResize = (e) => {
      setWidth(e.target.innerWidth);
    };
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    dispatch(getPawnsGuild(address));
  }, []);

  useEffect(() => {
    if (selectedPawn) {
      dispatch(getPawn(selectedPawn));
    }
  }, [selectedPawn]);

  useEffect(() => {
    if (selectedPawn) {
      dispatch(getPawnEquipment(selectedPawn, typeFunc(activePreset)));
    }
  }, [selectedPawn]);

  useEffect(() => {
    if (activePreset === guild[typeFunc(activePreset)]?.length && isChanged) {
      const guilds = {
        address,
        presetType: typeFunc(activePreset),
        pawns: guild[typeFunc(activePreset)].map((item) => item?.pawn?.nftId),
      };
      dispatch(putGuilds(guilds));
      setIsChanged(false);
    }
  }, [activePreset, guild[typeFunc(activePreset)]?.length, isChanged]);

  useEffect(() => () => {
    dispatch(clearPawn());
    dispatch(clearDataEquipment());
    dispatch(resetInventoryForEquipment());
    dispatch(setActivePreset(1));
  }, []);

  return (
    width > 880 ? (
      <>
        <Header address={address} />
        {showModal && (
        <ConfirmModal
          cancel={() => dispatch(modalShowing({ modal: false, action: null, type: null }))}
          submit={async () => {
            if (type) {
              await dispatch(removePawnItem({ ...action }));
            } else {
              await dispatch(removePawnEquipment({ ...action }));
            }
            dispatch(modalShowing({ modal: false, action: null, type: null }));
          }}
        />
        )}
        <div className={styles.wrapper}>
          <Link to="/" className="closeWindow"><button type="button">&#10006;</button></Link>
          <div className={styles.container}>
            <div className={styles.header}>
              <div className={styles.separator} />
              <div className={styles.titleBox}>
                <div className={styles.button_group}>
                  <Filters
                    start
                    filterByClass={filterByClass}
                    filterByTierForItems={filterByTierForItems}
                    filterByTier={filterByTier}
                    filterBySlot={filterBySlot}
                    filterByApplication={filterByApplication}
                    filterByType={filterByType}
                    filterByClassForSpell={filterByClassForSpell}
                    setFilterByClass={setFilterByClass}
                    setFilterByTier={setFilterByTier}
                    setFilterBySlot={setFilterBySlot}
                    setFilterByApplication={setFilterByApplication}
                    setFilterByTierForItems={setFilterByTierForItems}
                    setFilterByType={setFilterByType}
                    setFilterByClassForSpell={setFilterByClassForSpell}
                    filter={filter}
                    tier={tier}
                    setTier={setTier}
                    setFilter={setFilter}
                    search={search}
                    setSearch={setSearch}
                  />
                </div>
                <span>Equipment</span>
              </div>
              <div className={styles.separator} />
            </div>
            <div className={styles.content}>
              <div className={styles.set_equipment}>
                <Inventory
                  data={pawnInventory}
                  selectedPawn={selectedPawn}
                  setSelectedPawn={setSelectedPawn}
                  setIsChanged={setIsChanged}
                  filter={filter}
                  search={search}
                  tier={tier}
                  filterByClass={filterByClass}
                  filterByTier={filterByTier}
                  filterBySlot={filterBySlot}
                  filterByApplication={filterByApplication}
                  filterByTierForItems={filterByTierForItems}
                  filterByClassForSpell={filterByClassForSpell}
                  filterByType={filterByType}
                />
                <SetPawn
                  loading={pawnLoading}
                  data={pawn && Object.keys(pawn).length !== 0 ? pawn : ''}
                  selectedPawn={selectedPawn}
                />
              </div>
              <div className={styles.combat_wrapper}>
                <CombatPreset
                  setIsChanged={setIsChanged}
                  setSelectedPawn={setSelectedPawn}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    ) : (
      <MobileLayout>
        <div className={styles.header}>
          <div className={styles.separator} />
          <div className={styles.titleBox}>
            <div className={styles.button_group}>
              <Filters
                start
                filterByClass={filterByClass}
                filterByTier={filterByTier}
                filterBySlot={filterBySlot}
                setFilterByClass={setFilterByClass}
                setFilterByTier={setFilterByTier}
                setFilterBySlot={setFilterBySlot}
                filter={filter}
                tier={tier}
                setTier={setTier}
                setFilter={setFilter}
                search={search}
                setSearch={setSearch}
              />
            </div>
            <span>Equipment</span>
          </div>
          <div className={`${styles.separator} ${styles.rotate}`} />
        </div>
        <div className={styles.dec}>
          <Separator />
        </div>
        <div className={styles.content}>
          <div className={styles.set_equipment}>
            <Inventory
              data={inventoryMobileEquipment}
              selectedPawn={selectedPawn}
              setSelectedPawn={setSelectedPawn}
              setIsChanged={setIsChanged}
              filter={filter}
              search={search}
              tier={tier}
              count={count}
              loading={loading}
            />
            <Separator />
            <SetPawn
              loading={pawnLoading}
              data={pawn && Object.keys(pawn).length !== 0 ? pawn : ''}
              selectedPawn={selectedPawn}
            />
          </div>
          <Separator reverse />
          <CombatPreset
            setIsChanged={setIsChanged}
            setSelectedPawn={setSelectedPawn}
          />
        </div>
      </MobileLayout>
    )
  );
};

export default Equipment;
