import React, {
  useEffect,
  useLayoutEffect, useMemo, useRef,
} from 'react';
import ReactTooltip from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import uniqueId from '../../../../utils/uniqueId';
import { addPawnItem, modalShowing } from '../../../../store/slices/preset';
import Tooltip from '../../components/Tooltip';
import { setItemForCrafting } from '../../../../store/slices/crafting';
import {
  filterItemsCard,
  getConsumablesData, getPageForItems, lastItemsData, selectConsumables, selectLoadingConsumables,
  selectPageForItems,
  selectWasLastItem,
} from '../../../../store/slices/constructor';
import styles from '../Inventory.module.scss';
import { tierType } from '../../../../utils/chooseData';
import { selectEvm } from '../../../../store/slices/auth';
import loader from '../../../../static/img/loader.gif';

const PawnItems = ({
  pawnGuildItems, pawn, selectedPawn, filterByTierForItems,
}) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const firstUpdate = useRef(true);

  const address = useSelector(selectEvm);
  const loading = useSelector(selectLoadingConsumables);
  const wasLastItem = useSelector(selectWasLastItem);
  const page = useSelector(selectPageForItems);
  const itemsCards = useSelector(selectConsumables);

  const generateRequest = () => ({
    limit: 48,
    address,
    offset: (page - 1) * 48,
    tier: tierType[filterByTierForItems] || '',
  });

  const fetchItems = (params, offset, wasLast) => {
    if (wasLast) return;
    dispatch(getConsumablesData(params, offset));
    dispatch(getPageForItems(page + 1));
  };

  useLayoutEffect(() => {
    if (wasLastItem) return;
    if (firstUpdate.current) {
      dispatch(getConsumablesData(generateRequest()));
      dispatch(getPageForItems(2));
      firstUpdate.current = false;
      return;
    }
    dispatch(getConsumablesData({
      limit: 48,
      address,
      offset: 0,
      tier: tierType[filterByTierForItems] || '',
    }));
    dispatch(lastItemsData(false));
    dispatch(getPageForItems(2));
  }, [filterByTierForItems, wasLastItem]);

  useEffect(() => () => dispatch(getPageForItems(1)), []);

  const handleHeight = (value) => {
    if (window.innerWidth > 1921) {
      if (value > 1400) {
        return 2.35;
      }
      if (value > 1300) {
        return 2.68;
      }
      if (value > 1200) {
        return 2.72;
      }
      if (value > 1100) {
        return 2.8;
      }
      return 2.85;
    }
    return pathname.includes('crafting') ? 2.6 : 2.76;
  };

  return useMemo(() => {
    if (loading && !itemsCards?.length) {
      return (
        <div className={styles.loader}>
          <img src={loader} alt="loading..." />
        </div>
      );
    }
    if (itemsCards?.length) {
      return (
        <div className={styles.wrapper_content}>
          <InfiniteScroll
            dataLength={itemsCards?.length}
            next={() => fetchItems(generateRequest(), true, wasLastItem)}
            hasMore={!wasLastItem}
            scrollableTarget="scrollableDiv"
            loader={(
              <div className={styles.loader}>
                <img src={loader} alt="loading..." />
              </div>
            )}
            className={styles.wrapper_spells}
            height={window.innerHeight / handleHeight(window.innerHeight)}
          >
            {/* <div className={styles.wrapper_spells}> */}
            {itemsCards.map((elem) => (
              <div key={uniqueId('pawns_Items')}>
                <div
                  className={`${styles.img_wrap} ${elem?.usedByPawn && !pathname.includes('crafting')
                    ? styles.gray_img : ''} `}
                  role="presentation"
                  onClick={((elem?.usedByPawn !== selectedPawn
                  || !pawnGuildItems.map((item) => (item ? item.id : null)).includes(elem.id))
                  && !pathname.includes('crafting')
                    ? () => {
                      if (elem?.usedByPawn) {
                        dispatch(modalShowing({
                          modal: true,
                          type: 'items',
                          action: {
                            pawnId: elem?.usedByPawn,
                            consumableId: elem.id,
                          },
                        }));
                      }
                      if (Object.keys(pawn).length && !elem?.usedByPawn) {
                        dispatch(addPawnItem({
                          pawnId: selectedPawn,
                          body: {
                            guildEquipmentConsumableId: elem.id,
                          },
                        }, {
                          ...elem,
                        }));
                      }
                    }
                    : () => {
                      dispatch(filterItemsCard(elem.id));
                      dispatch(setItemForCrafting(elem));
                    })}
                >
                  <img
                    src={`https://i.bimium.link/${elem.image}`}
                    alt="item"
                    width="52px"
                    height="52px"
                    data-tip
                    data-for={`${elem.id}item`}
                  />
                </div>
                <ReactTooltip
                  id={`${elem.id}item`}
                  place="top"
                  backgroundColor="none"
                  arrowColor="transparent"
                  delayShow={100}
                >
                  <Tooltip
                    name={elem.name}
                    stats={{
                      status: elem?.usedByPawn ? 'Used' : 'Not used',
                      level: elem?.level,
                      tier: elem?.tier,
                      health: elem?.additionalStats?.health?.value
                        ? `${elem?.additionalStats?.health?.value}${elem?.additionalStats?.health?.type === 'Percent'
                          ? '%'
                          : ''}`
                        : undefined,
                      magicka: elem?.additionalStats?.magicka?.value
                        ? `${elem?.additionalStats?.magicka?.value}${elem?.additionalStats?.magicka?.type === 'Percent'
                          ? '%'
                          : ''}`
                        : undefined,
                    }}
                  />
                </ReactTooltip>
              </div>
            ))}
            {/* </div> */}
          </InfiniteScroll>
        </div>
      );
    }
    return (
      <div className={styles.no_data}>
        You do not have any items
      </div>
    );
  }, [itemsCards, loading, page]);
};

export default PawnItems;
