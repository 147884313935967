import Phaser from 'phaser';
import { store } from '../../store';
import { setFightResult, setSpellToLog } from '../../store/slices/arena';
import { SkillBase } from '../skill/skills/skillBase';
import Pawn from '../skill/pawn';
import { pawns as PawnDef } from '../skill/pawns';
// import { SkillBase } from '../skill/skills/skillBase';

class BattleScene extends Phaser.Scene {
  constructor(config) {
    super(config);
    this.setReady = config.setReady;
    this.setPawnsReady = config.setPawnsReady;
    this.onOpponentPawnSelect = config.onOpponentPawnSelect;
    this.setCurrentPlayerStatusBarPositions = config.setCurrentPlayerStatusBarPositions;
    this.setOpponentPlayerStatusBarPositions = config.setOpponentPlayerStatusBarPositions;
    this.setTransformScale = config.setTransformScale;
    this.onPawnSelect = config.onPawnSelect;

    this.spells = {};
    this.setSpells(config.spells);

    this.queue = new Promise((resolve) => {
      resolve(true);
    });
  }

  setSpells(spells) {
    if (Object.keys(spells || {}).length) {
      try {
        // preload sprites
        const spellsList = Object.keys(spells).reduce((acc, addr) => ({ ...acc, ...spells[addr] }), {});
        const spriteActions = [
          ...new Map(
            Object.keys(
              spellsList,
            ).reduce((acc, spell) => [...acc, ...spellsList[spell].actions], [])
              .map((spell) => spell.sprite)
              .filter((sprite) => !!sprite)
              .map((sprite) => [sprite.id, sprite]),
          ).values(),
        ];
        spriteActions.forEach(
          (sprite) => {
            this.load.spritesheet(
              sprite.key,
              sprite.path,
              { frameWidth: sprite.width, frameHeight: sprite.height },
            );
          },
        );
        // preload sounds
        const soundActions = [
          ...new Map(
            Object.keys(
              spellsList,
            ).reduce((acc, spell) => [...acc, ...spellsList[spell].actions], [])
              .map((spell) => spell.soundPath)
              .filter((soundPath) => !!soundPath)
              .map((soundPath) => [(/skill-sounds\/(.*).(?:mp3|wav)/gm).exec(soundPath)[1], soundPath]),
          ).values(),
        ];
        soundActions.forEach((path) => {
          const key = (/skill-sounds\/(.*).(?:mp3|wav)/gm).exec(path)[1];
          this.load.audio(key, path);
        });
        this.load.start();
      } catch (e) {
        console.error(e);
      }
      // preload sprites
      this.spells = spells;
    }
  }

  preload() {
    this.load.image('background', 'assets/Battle background.png');
    this.load.image('selectArrow', 'assets/battle/Select arrow.png');
    this.load.image('heroShadow', 'assets/hero-shadow.png');

    PawnDef.forEach((p) => {
      p.class.preload(this, p);
    });
  }

  create() {
    this.bgImage = this.add.image(700, 307, 'background');
    this.bgImageFade = this.add.rectangle(700, 307, 1400, 614, 0x000000, 0);

    this.selectArrow = this.add.image(50, 50, 'selectArrow').setVisible(false);
    this.selectArrowOpponent = this.add.image(50, 50, 'selectArrow').setVisible(false);

    this.currentPlayerGroup = new Phaser.GameObjects.Group(this, [], { name: 'currentPlayerGroup' });
    this.opponentPlayerGroup = new Phaser.GameObjects.Group(this, [], { name: 'opponentPlayerGroup' });

    this.selectedPawn = null;
    this.selectedOpponentPawn = null;
    this.battleScene = false;
    this.setReady(true);
  }

  createPawns(currentPlayerPawns, opponentPlayerPawns, currentPlayerSetsByPawn, opponentPlayerSetsByPawn) {
    this.createPawnGroup(currentPlayerPawns, true, currentPlayerSetsByPawn);
    this.createPawnGroup(opponentPlayerPawns, false, opponentPlayerSetsByPawn);
    // this.selectedPawn = this.selectPawn(this.currentPlayerGroup.children.entries[0]);
    // this.selectedOpponentPawn = this.selectPawn(this.opponentPlayerGroup.children.entries[0]);
    setTimeout(() => {
      const currentPlayerStatusBarsPosition = [];
      const opponentPlayerStatusBarsPosition = [];
      this.currentPlayerGroup.children.each(
        (group) => {
          currentPlayerStatusBarsPosition.push({
            x: group.objects.pawn.x / this.scale.displayScale.x,
            y: (614 - 68) / this.scale.displayScale.y,
          });
        },
      );
      this.opponentPlayerGroup.children.each(
        (group) => {
          opponentPlayerStatusBarsPosition.push({
            x: group.objects.pawn.x / this.scale.displayScale.x,
            y: (614 - 68) / this.scale.displayScale.y,
          });
        },
      );
      this.setCurrentPlayerStatusBarPositions(currentPlayerStatusBarsPosition);
      this.setOpponentPlayerStatusBarPositions(opponentPlayerStatusBarsPosition);
      this.setTransformScale(1 / this.scale.displayScale.x);
      this.setPawnsReady(true);
    }, 500);
  }

  onCurrentPlayerPawnSelect(idPawn) {
    // this.currentPlayerGroup.children, this.currentPlayerGroup.children.entries);
    this.selectPawn(this.currentPlayerGroup.children.entries.find((p) => p.idPawn === idPawn));
  }

  selectPawn(pawn) {
    if (pawn) {
      this.selectedPawn = pawn;
      this.onPawnSelect(pawn.idPawn);
    }
  }

  createPawnGroup(pawns, isCurrentPlayer, sets) {
    pawns.forEach(
      (p, index) => {
        let pawn = null;
        pawn = new Pawn({
          scene: this,
          x: BattleScene.getPosition(this.game, index, isCurrentPlayer, pawns.length)[0],
          y: BattleScene.getPosition(this.game, index, isCurrentPlayer, pawns.length)[1],
          idPawn: p.id,
          isCurrentPlayer,
          info: p,
          pawnConfig: PawnDef.find((pp) => pp.key === p.class),
          set: sets[p.id] ? sets[p.id] : null,
        });
        if (pawn) {
          if (isCurrentPlayer) {
            this.currentPlayerGroup.add(pawn);
          } else {
            this.opponentPlayerGroup.add(pawn);
          }
        }
      },
    );
  }

  static getPosition(game, position, isCurrentPlayer, pawnsCount) {
    const gameWidth = game.scale.width;
    const correctPosition = isCurrentPlayer ? (pawnsCount - 1) - position : position;
    if (pawnsCount === 3) {
      switch (correctPosition) {
        case 0:
          return [isCurrentPlayer ? 150 : gameWidth - 570, 410];
        case 1:
          return [isCurrentPlayer ? 360 : gameWidth - 360, 370];
        case 2:
          return [isCurrentPlayer ? 570 : gameWidth - 150, 400];
        default:
          return [0, 0];
      }
    }
    if (pawnsCount === 2) {
      switch (correctPosition) {
        case 0:
          return [isCurrentPlayer ? 255 : gameWidth - 465, isCurrentPlayer ? 370 : 410];
        case 1:
          return [isCurrentPlayer ? 465 : gameWidth - 255, isCurrentPlayer ? 410 : 370];
        // case 2:
        //   return [isCurrentPlayer ? 570 : gameWidth - 150, 450];
        default:
          return [0, 0];
      }
    }

    return [isCurrentPlayer ? 360 : gameWidth - 360, 410];
  }

  battleZoom(pawnFrom, pawnTo, action, resolve) {
    // const playerPawn = this.selectedPawn;
    // const opponentPawn = this.selectedOpponentPawn;
    this.battleScene = true;
    const prepareScreenTransitionTime = 1000;
    const battleAnimationTime = 3000;
    const timeline = this.add.timeline({
      at: battleAnimationTime + prepareScreenTransitionTime * 2,
      event: 'COMPLETED',
    });
    timeline.on('COMPLETED', () => {
      this.battleScene = false;
      resolve();
      this.setTransformScale(1 / this.scale.displayScale.x);
    });
    this.setTransformScale(0);
    this.beforeBattleScene(timeline, prepareScreenTransitionTime, battleAnimationTime, pawnFrom, pawnTo);
    this.cast(action, prepareScreenTransitionTime, pawnFrom, pawnTo);
    timeline.play();
  }

  cast(action, prepareScreenTransitionTime, pawnFrom, pawnTo, castResolve) {
    console.log(
      'DRAW SPELL',
      this,
      action,
      prepareScreenTransitionTime,
      pawnFrom,
      pawnTo,
      this.spells,
    );
    let actions = (this.spells[pawnFrom.idPawn][action.spell]?.actions || []).map(
      (a) => {
        if (a.type.toLowerCase() === 'pawnanimation') {
          return {
            ...a,
            sceneTarget: pawnFrom,
          };
        }
        if (a.type.toLowerCase() === 'opponentanimation') {
          return {
            ...a,
            sceneTarget: pawnTo,
          };
        }
        return a;
      },
    );
    console.log(this.spells[pawnFrom.idPawn][action.spell]);
    if (
      this.spells[pawnFrom.idPawn][action.spell]
      && this.spells[pawnFrom.idPawn][action.spell].application === 'EndOpponent'
    ) {
      console.log('EndOpponent');
      actions = actions.filter((a) => a.phase === action.phase);
    }

    if (actions.length) {
      setTimeout(
        () => {
          const skill = new SkillBase(
            this,
            pawnFrom,
            pawnTo,
            actions,
            this.spells[pawnFrom.idPawn][action.spell],
            action,
            action.type === 'Buff' || action.type === 'Casting',
          );
          (new Promise(
            (resolve) => {
              resolve(true);
            },
          )).then(() => skill.run())
            .then(() => {
              if (castResolve) {
                castResolve();
              }
            });
        },
        prepareScreenTransitionTime,
      );
    } else if (castResolve) {
      castResolve();
    }
  }

  beforeBattleScene(timeline, transitionDuration, holdTime, from, to) {
    const playerPawn = from.isCurrentPlayer ? from.idPawn : to.idPawn;
    const opponentPawn = !to.isCurrentPlayer ? to.idPawn : from.idPawn;

    console.log('beforeBattleScene', this.currentPlayerGroup.children);

    timeline.add({
      tween: {
        targets: this.bgImageFade,
        fillAlpha: {
          value: 0.5,
          duration: transitionDuration,
          ease: 'Power1',
        },
        yoyo: true,
        hold: holdTime,
        offset: 0,
      },
    });
    timeline.add({
      tween: {
        targets: this.bgImage,
        duration: transitionDuration,
        scale: 1.25,
        ease: 'Sine.easeInOut',
        yoyo: true,
        hold: holdTime,
        offset: 0,
      },
    });
    this.currentPlayerGroup.children.each(
      (p) => {
        console.log('beforeBattleScene 1', Object.entries(p.objects));
        if (p.idPawn !== playerPawn) {
          timeline.add({
            tween: {
              targets: Object.values(p.objects),
              props: {
                x: { value: '-=1000', duration: transitionDuration, ease: 'Sine.easeInOut' },
                y: { value: '440', duration: transitionDuration, ease: 'Sine.easeInOut' },
              },
              yoyo: true,
              hold: holdTime,
              offset: 0,
            },
          });
        } else {
          timeline.add({
            tween: {
              // targets: p.children.entries.filter((e) => e.texture.key !== 'heroShadow'),
              targets: Object.values(p.objects),
              props: {
                x: { value: '300', duration: transitionDuration, ease: 'Sine.easeInOut' },
                y: { value: '410', duration: transitionDuration, ease: 'Sine.easeInOut' },
              },
              yoyo: true,
              hold: holdTime,
              offset: 0,
            },
          });
        }
      },
    );
    this.opponentPlayerGroup.children.each(
      (p) => {
        if (p.idPawn !== opponentPawn) {
          timeline.add({
            tween: {
              targets: Object.values(p.objects),
              props: {
                x: { value: '+=1000', duration: transitionDuration, ease: 'Sine.easeInOut' },
                y: { value: '410', duration: transitionDuration, ease: 'Sine.easeInOut' },
              },
              yoyo: true,
              hold: holdTime,
              offset: 0,
            },
          });
        } else {
          timeline.add({
            tween: {
              targets: Object.values(p.objects),
              props: {
                x: { value: '1100', duration: transitionDuration, ease: 'Sine.easeInOut' },
                y: { value: '410', duration: transitionDuration, ease: 'Sine.easeInOut' },
              },
              yoyo: true,
              hold: holdTime,
              offset: 0,
            },
          });
        }
      },
    );
  }

  processState({
    fightActions,
    fightPawns,
  }, tempResult) {
    if (tempResult) {
      this.queue = this.queue.then(() => {
        setTimeout(() => {
          store.dispatch(setFightResult(tempResult));
        }, 5000);
      });
      return;
    }
    [...fightPawns[0], ...fightPawns[1]].forEach(
      (pawnState) => {
        this.queue = this.queue.then(() => this.findPawnById(pawnState.id).setNextInfo(pawnState));
      },
    );
    if (fightActions.length) {
      fightActions.forEach((action) => {
        const pawnFrom = this.findPawnById(action.from.id);
        const pawnTo = this.findPawnById(action.to.id);

        switch (true) {
          case action.type === 'Damage':
            this.queue = this.queue.then(
              () => new Promise((resolve) => {
                this.battleZoom(
                  pawnFrom,
                  pawnTo,
                  action,
                  resolve,
                );
              }),
            );
            break;
          case action.type === 'Casting':
          case action.type === 'Buff':
            this.queue = this.queue.then(
              () => new Promise((resolve) => {
                this.cast(action, 50, pawnFrom, pawnTo, resolve);
              }),
            );
            break;
          case action.type === 'Dodged':
            this.queue = this.queue.then(
              () => new Promise((resolve) => {
                const timeline = this.add.timeline();
                pawnTo.showFloatingText(
                  timeline,
                  0,
                  1200,
                  'Dodged',
                  pawnTo.isCurrentPlayer === this.isCurrentPlayer ? '#36a131' : '#F8EF58',
                );
                timeline.play();
                resolve();
              }),
            );
            break;
          case action.type === 'RegeneratedActionPoints':
            this.queue = this.queue.then(
              () => new Promise((resolve) => {
                const timeline = this.add.timeline();
                pawnTo.showFloatingText(
                  timeline,
                  0,
                  1200,
                  `Restored +${action.regeneratedActionPoints}`,
                  '#2e34e3',
                );
                timeline.play();
                resolve();
              }),
            );
            break;
          // case action.type === 'Heal':
          //   this.queue = this.queue.then(
          //     () => new Promise((resolve) => {
          //       pawnTo.showFloatingText(
          //         `${action?.heal?.toString() || ''}`,
          //         pawnTo.isCurrentPlayer === this.isCurrentPlayer ? '#36a131' : '#F8EF58',
          //         true,
          //         pawnTo.pawn,
          //       );
          //     }),
          //   );
          //   break;
          default:
            console.error('***can\'t process action, undefined type', action);
        }
        this.queue = this.queue.then(
          () => new Promise((resolve) => {
            store.dispatch(setSpellToLog(action));
            resolve();
          }),
        );
      });
    }

    [...fightPawns[0], ...fightPawns[1]].forEach(
      (pawnState) => {
        this.queue = this.findPawnById(pawnState.id).checkBuffEnd(pawnState.buffs, this.queue);
      },
    );

    [...fightPawns[0], ...fightPawns[1]].forEach(
      (pawnState) => {
        // console.log('--Create Switch info promise');
        this.queue = this.queue.then(() => this.findPawnById(pawnState.id).switchInfo());
      },
    );
  }

  findPawnById(id) {
    let pawn = null;
    pawn = this.currentPlayerGroup.children.entries.find((p) => p.idPawn === id);
    pawn = pawn || this.opponentPlayerGroup.children.entries.find((p) => p.idPawn === id);
    return pawn;
  }

  update() {
    // selected arrow state
    if (this.selectedPawn && !this.battleScene) {
      this.selectArrow.setVisible(true).setPosition(this.selectedPawn.objects.pawn.x, 250);
    } else {
      this.selectArrow.setVisible(false);
    }
    // if (this.selectedOpponentPawn && !this.battleScene) {
    //   this.selectArrowOpponent.setVisible(true).setPosition(this.selectedOpponentPawn.pawn.x, 250);
    // } else {
    //   this.selectArrowOpponent.setVisible(false);
    // }
  }
}

export default BattleScene;
