import { API_URL } from '../constants/constants';

const responseParse = async (response) => ({
  data: await response.json(),
  status: response.status,
});

export const postSprite = async (payload) => {
  const token = localStorage.getItem('token');
  const result = await fetch(`${API_URL}api/skills-constructor/sprites`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
  return responseParse(result);
};

export const deleteSprite = async (id) => {
  const token = localStorage.getItem('token');
  const result = await fetch(`${API_URL}api/skills-constructor/sprites/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
  return result.status === 200 ? { status: 200 } : responseParse(result);
};

export const getSprites = async () => {
  const token = localStorage.getItem('token');
  const result = await fetch(`${API_URL}api/skills-constructor/sprites?limit=300&offset=0`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
  return responseParse(result);
};

export const postSpell = async (payload) => {
  const token = localStorage.getItem('token');
  const result = await fetch(`${API_URL}api/skills-constructor/spells`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
  return result.status === 200 ? { status: 200 } : responseParse(result);
};

export const updateSpell = async (id, data) => {
  const token = localStorage.getItem('token');
  const result = await fetch(`${API_URL}api/skills-constructor/spells/${id}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return result.status === 200 ? { status: 200 } : responseParse(result);
};

export const getSpells = async () => {
  const token = localStorage.getItem('token');
  const result = await fetch(
    `${API_URL}api/skills-constructor/spells?limit=200&offset=0`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json',
      },
    },
  );
  return responseParse(result);
};

export const deleteSpell = async (id) => {
  const token = localStorage.getItem('token');
  const result = await fetch(`${API_URL}api/skills-constructor/spells/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
  return result.status === 200 ? { status: 200 } : responseParse(result);
};

export const getSpriteData = async (spriteName) => {
  const token = localStorage.getItem('token');
  const result = await fetch(
    `${API_URL}api/skills-constructor/sprites/${spriteName}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json',
      },
    },
  );
  return responseParse(result);
};

export const getSpellData = async (spellId) => {
  const token = localStorage.getItem('token');
  const result = await fetch(
    `${API_URL}api/skills-constructor/spells/${spellId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json',
      },
    },
  );
  return responseParse(result);
};

export const getSkins = async () => {
  const token = localStorage.getItem('token');
  const result = await fetch(
    `${API_URL}api/skins-constructor?limit=200&offset=0`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json',
      },
    },
  );
  return responseParse(result);
};

export const postSkin = async (payload) => {
  const token = localStorage.getItem('token');
  const result = await fetch(`${API_URL}api/skins-constructor`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
  return result.status === 200 ? { status: 200 } : responseParse(result);
};

export const updateSkin = async (id, data) => {
  const token = localStorage.getItem('token');
  const result = await fetch(`${API_URL}api/skins-constructor/${id}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return result.status === 200 ? { status: 200 } : responseParse(result);
};

export const deleteSkin = async (id) => {
  const token = localStorage.getItem('token');
  const result = await fetch(`${API_URL}api/skins-constructor/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
  return result.status === 200 ? { status: 200 } : responseParse(result);
};

export const getSets = async () => {
  const token = localStorage.getItem('token');
  const result = await fetch(
    `${API_URL}api/equipment-constructor/sets?limit=200&offset=0`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json',
      },
    },
  );
  return responseParse(result);
};

export const postSet = async (payload) => {
  const token = localStorage.getItem('token');
  const result = await fetch(`${API_URL}api/equipment-constructor/sets`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
  return result.status === 200 ? { status: 200 } : responseParse(result);
};

export const updateSet = async (id, data) => {
  const token = localStorage.getItem('token');
  const result = await fetch(`${API_URL}api/equipment-constructor/sets/${id}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return result.status === 200 ? { status: 200 } : responseParse(result);
};

export const getSetData = async (id) => {
  const token = localStorage.getItem('token');
  const result = await fetch(
    `${API_URL}api/equipment-constructor/sets/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json',
      },
    },
  );
  return responseParse(result);
};

export const deleteSet = async (id) => {
  const token = localStorage.getItem('token');
  const result = await fetch(`${API_URL}api/equipment-constructor/sets/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
  return result.status === 200 ? { status: 200 } : responseParse(result);
};
