import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './LayoutWindow.module.scss';
import Header from '../Marketplace/Components/Header';
import MobileLayout from '../MobileLayout';
import ArenaWrapper from './ArenaWrapper';
import EndOfBattle from '../EndOfBattle';
import { selectFightInfo } from '../../store/slices/arena';
import { selectEvm } from '../../store/slices/auth';

const LayoutWindow = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const address = useSelector(selectEvm);
  const result = useSelector(selectFightInfo);

  useEffect(() => {
    const onResize = (e) => {
      setWidth(e.target.innerWidth);
    };
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
  // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      { width > 880 ? (
        <>
          <Header address={address} />
          <div className={styles.mainWindow}>
            <ArenaWrapper address={address} />
          </div>
        </>
      ) : (
        <MobileLayout>
          <ArenaWrapper address={address} />
        </MobileLayout>
      )}
      {result ? <EndOfBattle /> : null}
    </>
  );
};

export default LayoutWindow;
