import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styles from './Mobile.module.scss';
import notification from '../../static/img/arena/Mobyle/Notification indicator.png';
import fightPressed from '../../static/img/header/balancebar/button_fight_pressed.png';
import fight from '../../static/img/header/balancebar/fight.png';
import FightNotification from '../Marketplace/Components/Header/BalanceBar/FightNotification';
import { selectInviteFrom } from '../../store/slices/arena';
import { selectEvm } from '../../store/slices/auth';

const MobileLayout = ({ children, flag }) => {
  const [isBurgerOpened, setIsBurgerOpened] = useState(false);
  const [isFight, setIsFight] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [IsNotificationIndicator, setIsNotificationIndicator] = useState(false);
  const { inviteFrom } = useSelector(selectInviteFrom);
  const address = useSelector(selectEvm);

  const navigate = useNavigate();

  const onBurgerClick = () => {
    setIsBurgerOpened(!isBurgerOpened);
  };
  useEffect(() => {
    if (inviteFrom) {
      setIsNotificationIndicator(true);
    } else {
      setIsNotificationIndicator(false);
    }
  }, [inviteFrom]);

  const redirect = (button) => {
    switch (button) {
      case 'Arena':
        navigate('/arena-hub');
        break;
      case 'Market':
        navigate('/marketplace');
        break;
      case 'Pawns':
        navigate('/marketplace');
        break;
      case 'Equipment':
        navigate('/equipment');
        break;
      case 'Quests':
        navigate('/quests');
        break;
      default:
        break;
    }
  };

  return (
    <div className={`${styles.content_wrapper_mobile} ${flag ? styles.full_height : ''}`}>
      <div className={styles.mobile__corner_top_left} />
      <div className={styles.mobile__corner_top_center}>
        <div className={styles.mobile__status_container}>
          <div className={styles.mobile__status_container__group}>
            <div className={styles.mobile__status_container__number}>
              {
                address
                  ? `${address.slice(0, 5)}...
                          ${address.slice(address.length - 4, address.length).toUpperCase()}`
                  : ''
              }
            </div>
            <div className={styles.mobile__status_container__connection__connected} />
          </div>
          <div className={styles.notification_wrapper}>
            <div className={styles.mobile__status_container__balance}>0.000001</div>
            <div
              role="presentation"
              onMouseDown={() => setIsFight(true)}
              onMouseUp={() => setIsFight(false)}
              onMouseLeave={() => setIsFight(false)}
              onClick={() => setIsNotification(!isNotification)}
              className={styles.button_fight}
            >
              {IsNotificationIndicator && <img src={notification} alt="notification" className={styles.notification} />}
              <img src={isFight ? fightPressed : fight} alt="fight" className={styles.button_balance} />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.wrapper_notification}>
        <FightNotification
          setIsNotification={setIsNotification}
          isNotification={isNotification}
          setIsNotificationIndicator={setIsNotificationIndicator}
          opponentAddress={inviteFrom}
        />
      </div>
      <div className={styles.wrapper_notification}>
        <FightNotification
          setIsNotification={setIsNotification}
          isNotification={isNotification}
          setIsNotificationIndicator={setIsNotificationIndicator}
          opponentAddress={inviteFrom}
        />
      </div>
      <div className={styles.mobile__corner_top_right}>
        <button
          type="button"
          className={styles.mobile__burger}
          onClick={onBurgerClick}
        >
          &nbsp;
        </button>
        <div
          role="presentation"
          className={[
            styles.mobile__burger_items__container,
            (isBurgerOpened ? styles.mobile__burger_items__container__active : ''),
          ].join(' ')}
          onClick={onBurgerClick}
          onKeyPress={onBurgerClick}
        >
          <div className={styles.mobile__burger_items}>
            <div className={styles.mobile__burger_items__item}>
              <button
                type="button"
                onClick={() => redirect('Quests')}
              >
                Quests
              </button>
            </div>
            <div className={styles.mobile__burger_items__item}>
              <button
                type="button"
                onClick={() => redirect('Arena')}
              >
                Arena
              </button>
            </div>
            <div className={styles.mobile__burger_items__item}>
              <button
                type="button"
                onClick={() => redirect('Equipment')}
              >
                Equipment
              </button>
            </div>
            <div className={styles.mobile__burger_items__item}>
              <button
                type="button"
                onClick={() => redirect('Pawns')}
              >
                Pawns
              </button>
            </div>
            <div className={styles.mobile__burger_items__item}>
              <button type="button">Alchemist</button>
            </div>
            <div className={styles.mobile__burger_items__item}>
              <button
                type="button"
                onClick={() => redirect('Market')}
              >
                Market
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.mobile__corner_left} />
      <div className={`${styles.mobile__content} ${flag ? styles.full : ''} `}>
        {/* <div className={styles.mobile_content_decor_left} /> */}
        {children}
        {/* <div className={styles.mobile_content_decor_left} /> */}
      </div>
      <div className={styles.mobile__corner_right} />
      <div className={styles.mobile__corner_bottom_left_outer} />
      <div className={styles.mobile__corner_bottom_left_outer_center} />
      <div className={styles.mobile__corner_bottom} />
      <div className={styles.mobile__corner_bottom_right_outer} />
      <div className={styles.mobile__corner_bottom_right_outer_center} />
    </div>
  );
};

export default MobileLayout;
