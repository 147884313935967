import React from 'react';
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import Tooltip from './Tooltip';
import { clearSpell, getSpellsDataById } from '../../../../store/slices/constructor';

const SpellPreview = ({
  id, name, spellId,
}) => {
  const dispatch = useDispatch();

  return (
    <ReactTooltip
      id={id}
      place="top"
      backgroundColor="none"
      arrowColor="transparent"
      delayShow={100}
      afterShow={() => dispatch(getSpellsDataById(spellId))}
      afterHide={() => dispatch(clearSpell())}
    >
      <Tooltip
        name={name}
      />
    </ReactTooltip>
  );
};

export default SpellPreview;
