import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFieldArray, useForm } from 'react-hook-form';
import styles from '../SkillUtils.module.scss';
import Actions from './Actions';
import FormulaField from '../FormulaField';
import Modifications from './Modifications';
import deleteImg from '../../../static/img/header/balancebar/dropdown/Decline button.png';
import { deleteSpriteImg, onSpritePost, selectSpriteLink } from '../../../store/slices/skillConstructor';

const ActionsForm = ({
  sprites, onSubmit, selectedPawn, setSelectedPawn, scene, data, onSpellDelete,
}) => {
  const [effectActionGroup, setEffectActionGroup] = useState('Initial');
  const [actionPhase, setActionPhase] = useState('Precast');
  const dispatch = useDispatch();
  const {
    register, control, watch, handleSubmit, setValue, reset,
  } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'actions',
  });
  const modifications = useFieldArray({
    control,
    name: 'effect.modifications',
  });
  const sideEffectsList = [
    { label: 'Remove casting spells', value: 'RemoveCastingSpells' },
  ];
  // const sideEffects = useFieldArray({
  //   control,
  //   name: 'sideEffects',
  // });
  const effectActionGroups = ['Initial', 'Persistent', 'End'];
  const actionPhases = ['Precast', 'Cast'];

  setSelectedPawn(watch('class'));

  const spriteLink = useSelector(selectSpriteLink);

  const onSpriteSelect = (file) => {
    const formData = new FormData();
    formData.append('image', file);
    dispatch(onSpritePost(formData));
  };

  const playScene = () => {
    scene.createSpell(watch());
  };

  // useEffect(() => {
  //   data = {
  //     spellName: 'shot',
  //     image: 'https://i.bimium.link/skills/ff826948ff854516a4d39bf12bbb1cdc.png',
  //     class: 'archer',
  //     cost: '7',
  //     quickness: 'Quick',
  //     application: 'Immediately',
  //     type: 'Primary',
  //     cooldown: '0',
  //     description: 'simple quick shot',
  //     hasEffect: false,
  //     actions: [
  //       {
  //         action_type: 'pawnAnimation',
  //         group: 'Persistent',
  //         delay: '0',
  //         duration: '500',
  //         animation: 'anim-archer-shoot',
  //       },
  //       {
  //         action_type: 'pawnAnimation',
  //         group: 'Persistent',
  //         delay: '1300',
  //         duration: '500',
  //         animation: 'anim-archer-idle',
  //       },
  //       {
  //         action_type: 'opponentAnimation',
  //         group: 'Persistent',
  //         delay: '1500',
  //         duration: '500',
  //         animation: 'hit',
  //       },
  //       {
  //         action_type: 'opponentAnimation',
  //         group: 'Persistent',
  //         delay: '2500',
  //         duration: '500',
  //         animation: 'idle',
  //       },
  //     ],
  //     effect_modifications: [
  //
  //     ],
  //     formula: '5',
  //   };
  //   if (data) {
  //     Object.keys(data).forEach((option) => setValue(option, data[option]));
  //   }
  // }, []);

  useEffect(() => {
    if (data === null || data === undefined) {
      reset();
      remove();
    }
    if (data) {
      Object.keys(data).forEach((option) => setValue(option, data[option]));
    }
  }, [data]);

  useEffect(() => {
    if (spriteLink) {
      setValue('image', `https://i.bimium.link/${spriteLink}`);
    }
  }, [spriteLink]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
    >
      <div
        className={styles.header}
      >
        <button type="button" onClick={() => playScene()}>Play Scene</button>
      </div>
      <div className={styles.form_row}>
        <div>
          Spell Name
        </div>
        <input
          type="text"
          {...register('spellName')}
        />
      </div>
      <div className={styles.form_row}>
        <div>Skill icon</div>
        <div>Add link to .png image, or upload .png image</div>
        <div style={{ display: 'flex', columnGap: '10px' }}>
          <input
            type="text"
            {...register('image')}
            disabled={spriteLink}
          />
          <img
            role="presentation"
            src={deleteImg}
            alt="del"
            width="20px"
            height="20px"
            onClick={() => {
              dispatch(deleteSpriteImg());
              setValue('image', '');
            }}
          />
        </div>
        {!spriteLink
          ? (
            <input
              style={{ marginTop: '10px' }}
              type="file"
              accept="image/png"
              onChange={(e) => onSpriteSelect(e.target.files[0])}
            />
          )
          : null}
      </div>
      <div className={styles.form_row}>
        <div>
          Pawn Class
        </div>
        <select
          style={{ margin: 0 }}
          defaultValue={selectedPawn}
          {...register('class')}
        >
          <option value="" hidden>Select</option>
          <option value="Archer">Archer</option>
          <option value="Mage">Mage</option>
          <option value="Warrior">Warrior</option>
        </select>
      </div>
      <div className={styles.form_row}>
        <div>
          Cost
        </div>
        <input
          type="text"
          {...register('cost')}
        />
      </div>
      <div className={styles.form_row}>
        <div>
          Application
        </div>
        <select
          style={{ margin: 0 }}
          defaultValue=""
          {...register('application')}
        >
          <option value="" hidden>Select</option>
          <option value="EndOpponent">End Opponent</option>
          <option value="Immediately">Immediately</option>
        </select>
      </div>
      <div className={styles.form_row}>
        <div>
          Type
        </div>
        <select
          style={{ margin: 0 }}
          defaultValue=""
          {...register('type')}
        >
          <option value="" hidden>Select</option>
          <option value="Primary">Primary</option>
          <option value="Secondary">Secondary</option>
        </select>
      </div>
      <div className={styles.form_row}>
        <div>
          Target
        </div>
        <select
          style={{ margin: 0 }}
          defaultValue=""
          {...register('target')}
        >
          <option value="" hidden>Select</option>
          <option value="Self">Self</option>
          <option value="Opponent">Opponent</option>
          <option value="Teammate">Teammate</option>
          <option value="Any">Any</option>
        </select>
      </div>
      <div className={styles.form_row} style={{ position: 'relative' }}>
        <div>
          Formula
        </div>
        <FormulaField
          value={watch('formula')}
          setValue={setValue}
          fieldName="formula"
        />
      </div>
      <div className={styles.form_row}>
        <div>
          Cooldown
        </div>
        <input
          type="number"
          min={0}
          max={9999}
          step={1}
          {...register('cooldown')}
        />
      </div>
      <div className={styles.form_row}>
        <div>
          Description
        </div>
        <textarea
          {...register('description')}
        />
      </div>
      <div className={styles.form_row}>
        <div>
          Side effect
        </div>
        {sideEffectsList.map((fieldDef, index) => (
          <label>
            <input
              type="checkbox"
              {...register(`sideEffects.${index}`)}
              value={fieldDef.value}
            />
            {fieldDef.label}
          </label>
        ))}
      </div>
      <div className={styles.form_row}>
        <div>
          <label>
            <input
              type="checkbox"
              {...register('hasEffect')}
            />
            Has effect
          </label>
        </div>
      </div>
      {watch('hasEffect')
        ? (
          <>
            <div className={styles.form_row}>
              <div>
                Name
              </div>
              <input
                type="text"
                {...register('effect.name')}
              />
            </div>
            <div className={styles.form_row}>
              <div>
                Duration (Turns)
              </div>
              <input
                type="text"
                {...register('effect.duration')}
              />
            </div>
            {watch('target') === 'Opponent'
              ? (
                <div className={styles.form_row}>
                  <div>
                    Target
                  </div>
                  <select
                    type="text"
                    {...register('effect.target')}
                  >
                    <option value="" hidden>Select</option>
                    <option value="Self">Self</option>
                    <option value="Opponent">Opponent</option>
                  </select>
                </div>
              )
              : null}
            {modifications.fields.map((field, index) => (
              <Modifications
                index={index}
                register={register}
                watch={watch}
                key={field.id}
                append={append}
                onDelete={() => modifications.remove(index)}
                setValue={setValue}
              />
            ))}
            <div className={styles.form_row}>
              <button
                type="button"
                onClick={() => modifications.append({
                  parameter: '',
                  formula: '',
                })}
              >
                Add Modification
              </button>
            </div>
          </>
        )
        : null}
      <div
        className={
            watch('hasEffect') ? styles.animation_block_container_has_tabs : styles.animation_block_container
          }
      >
        {watch('hasEffect')
          ? (
            <div className={styles.effectTabsContainer}>
              {
                  effectActionGroups.map((tab) => (
                    <div
                      className={`${styles.effectTabsContainer__tab}
                   ${effectActionGroup === tab ? styles.effectTabsContainer__tab__active : ''}`}
                      onClick={() => setEffectActionGroup(tab)}
                      role="presentation"
                      key={tab}
                    >
                      { tab }
                    </div>
                  ))
                }
            </div>
          )
          : null}
        {watch('application') === 'EndOpponent'
          ? (
            <div className={styles.effectTabsContainer}>
              {
                  actionPhases.map((tab) => (
                    <div
                      className={`${styles.effectTabsContainer__tab}
                   ${actionPhase === tab ? styles.effectTabsContainer__tab__active : ''}`}
                      onClick={() => setActionPhase(tab)}
                      role="presentation"
                      key={tab}
                    >
                      { tab }
                    </div>
                  ))
                }
            </div>
          )
          : null}
        {fields.map((field, index) => (
          <Actions
            index={index}
            register={register}
            watch={watch}
            key={field.id}
            sprites={sprites}
            group={watch('hasEffect') ? effectActionGroup : null}
            phase={watch('application') === 'EndOpponent' ? actionPhase : null}
            onDelete={() => remove(index)}
            setValue={setValue}
          />
        ))}
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <button
          type="button"
          onClick={() => append({
            type: '',
            group: effectActionGroup,
            phase: actionPhase,
          })}
        >
          Add Action
        </button>
        <button type="submit">Submit</button>
        {data && data.id
          ? (
            <button
              type="button"
              onClick={() => onSpellDelete(data.id)}
            >
              Delete Spell
            </button>
          )
          : null}
      </div>
    </form>
  );
};

export default ActionsForm;
