import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectedPawn, selectPickPawn } from '../../../../store/slices/quests';
import { resetInventoryForEquipment } from '../../../../store/slices/requests';
import styles from '../../../../components/Marketplace/Components/Pawns/Cards/Cards.module.scss';

const CardPawn = ({
  data, setShowPickPawnModal,
}) => {
  const dispatch = useDispatch();
  const pickPawn = useSelector(selectPickPawn);
  return (
    <div
      role="presentation"
      className={`${styles.card} ${styles[data.tier]} ${data?.gameStatus === 'Idle' ? '' : styles.card_filter}`}
      onClick={() => {
        if (data?.gameStatus === 'Idle') {
          dispatch(selectedPawn(data));
          dispatch(resetInventoryForEquipment());
          setShowPickPawnModal(false);
        }
      }}
    >
      <div className={styles.card_top_level}>{data.level}</div>
      <div className={styles.card_top} />
      <div className={styles.card_top_right} />
      <div className={styles.card_left} />
      <div className={styles.card_content}>
        <img src={`https://i.bimium.link/${data.image}`} alt="person" />
        {pickPawn?.nftId === data.nftId
          ? <div className={styles.select} />
          : null}
      </div>
      <div className={styles.card_right} />
      <div className={styles.card_bottom_left} />
      <div className={styles.card_bottom} />
      <div className={styles.card_bottom_right} />
    </div>
  );
};

export default CardPawn;
