import React from 'react';
import loupe from '../../../../../static/img/inventory/pagination/search-icon.png';
import styles from './Search.module.scss';

const Search = ({ search, setSearch }) => (
  <div className={styles.button_buy}>
    <div className={styles.button_left} />
    <div className={styles.button_content}>
      <input
        type="text"
        placeholder="Search..."
        className={styles.search}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <img src={loupe} alt="search" className={styles.search_icon} />
    </div>
    <div className={styles.button_right} />
  </div>
);

export default Search;
