import Phaser from 'phaser';
import { DEFAULT_FRAME_RATE } from './const';

class SpritePreviewScene extends Phaser.Scene {
  constructor(config) {
    super(config);

    this.queue = new Promise((resolve) => {
      resolve(true);
    });
  }

  setSetFrameFunc(setFrameFunc) {
    this.setFrameFunc = setFrameFunc;
  }

  preload() {
    // this.load.image('background', 'assets/Battle background.png');
    this.load.image('transparent', 'assets/ps-neutral.png');
  }

  createSprite(config, currentXY, opponentXY) {
    if (config.key && config.path.length && config.width && config.height) {
      config.width = parseInt(config.width.toString(), 10);
      config.height = parseInt(config.height.toString(), 10);
    } else {
      return;
    }

    if (!config.originCurrent) {
      config.originCurrent = '0,0';
    }
    if (!config.originOpponent) {
      config.originOpponent = '0,0';
    }
    if (this.ts) {
      this.ts.destroy(true);
    }
    if (this.tsOpponent) {
      this.tsOpponent.destroy(true);
    }
    if (this.sprite) {
      this.sprite.destroy(true);
    }
    if (this.spriteOpponent) {
      this.spriteOpponent.destroy(true);
    }

    this.ts = this.add.tileSprite(
      currentXY[0],
      currentXY[1],
      config.width,
      config.height,
      'transparent',
    )
      .setDisplayOrigin(...SpritePreviewScene.getXYOrigin(config.originCurrent))
      .setName('tiles');
    this.tsOpponent = this.add.tileSprite(
      opponentXY[0],
      opponentXY[1],
      config.width,
      config.height,
      'transparent',
    )
      .setDisplayOrigin(...SpritePreviewScene.getXYOrigin(config.originOpponent))
      .setName('tilesOpponent');

    this.add.existing(this.ts);
    this.add.existing(this.tsOpponent);
    this.load.spritesheet(config.key, config.path, { frameWidth: config.width, frameHeight: config.height });
    this.load.once(Phaser.Loader.Events.COMPLETE, () => {
      // texture loaded so use instead of the placeholder
      this.sprite = (new Phaser.GameObjects.Sprite(this, currentXY[0], currentXY[1], config.key))
        .setDisplayOrigin(...SpritePreviewScene.getXYOrigin(config.originCurrent))
        .setDepth(20);
      this.spriteOpponent = (new Phaser.GameObjects.Sprite(this, opponentXY[0], opponentXY[1], config.key))
        .setDisplayOrigin(...SpritePreviewScene.getXYOrigin(config.originOpponent))
        .setFlipX(true)
        .setDepth(20);
      this.add.existing(this.sprite);
      this.add.existing(this.spriteOpponent);

      this.createAnimations(config);
    });
    this.load.start();
  }

  setOrigin(isCurrentPlayer, x, y) {
    (isCurrentPlayer ? this.sprite : this.spriteOpponent)?.setDisplayOrigin(x, y);
  }

  static getXYOrigin(value) {
    if (value && value.length > 3) {
      const vA = value.split(',');
      if (vA.length === 2 && vA[0].length && vA[1].length) {
        return [parseInt(vA[0], 10), parseInt(vA[1], 10)];
      }
    }
    return [0, 0];
  }

  createAnimations(config) {
    config.animations.forEach(
      (a) => {
        if (a.key && a.start.toString().length && a.end.toString().length) {
          const gfnConf = {};
          if (Object.hasOwn(a, 'start')) {
            gfnConf.start = parseInt(a.start.toString(), 10);
          }
          if (Object.hasOwn(a, 'end')) {
            gfnConf.end = parseInt(a.end.toString(), 10);
          }
          if (this.anims.exists(a.key)) {
            this.anims.remove(a.key);
          }
          this.anims.create({
            key: a.key,
            frames: this.anims.generateFrameNumbers(config.key, gfnConf),
            frameRate: parseInt(a.frameRate.toString(), 10) || DEFAULT_FRAME_RATE,
            repeat: Object.hasOwn(a, 'repeat') ? parseInt(a.repeat.toString(), 10) : 0,
          });
        }
      },
    );
  }

  setSpriteFrame(frame) {
    this.sprite?.setFrame(parseInt(frame, 10));
  }

  playAnimation(key) {
    if (key) {
      this.sprite.anims.play(key);
      this.spriteOpponent.anims.play(key);
    }
  }
}

export default SpritePreviewScene;
