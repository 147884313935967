import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import CombatItem from '../components/CombatItem';
import { typeFunc } from '../../../utils/chooseData';
import { selectActivePreset, selectGuilds } from '../../../store/slices/preset';
import VsButton from '../components/VsButton';
import styles from '../Equipment.module.scss';
import { selectLoading } from '../../../store/slices/requests';

const CombatPreset = ({
  setSelectedPawn, setIsChanged,
}) => {
  const guild = useSelector(selectGuilds);
  const loading = useSelector(selectLoading);
  const activePreset = useSelector(selectActivePreset);

  const combatPreset = useMemo(() => {
    if (activePreset === 1) {
      return guild[typeFunc(1)]?.length ? [...guild[typeFunc(1)], null, null] : [null, null, null];
    }
    if (activePreset === 2) {
      return guild[typeFunc(2)]?.length
        ? guild[typeFunc(2)]?.length === 1
          ? [...guild[typeFunc(2)], null, null]
          : [...guild[typeFunc(2)], null]
        : [null, null, null];
    }
    return guild[typeFunc(3)]?.length
      ? guild[typeFunc(3)]?.length === 1
        ? [...guild[typeFunc(3)], null, null]
        : guild[typeFunc(3)]?.length === 2
          ? [...guild[typeFunc(3)], null]
          : [...guild[typeFunc(3)]]
      : [null, null, null];
  }, [activePreset, guild]);

  return (
    <>
      <div className={styles.pick_number}>
        <div className={styles.title}>Combat presets</div>
        <div className={styles.pick}>
          <VsButton
            vs="OneVsOne"
            btnNumber={1}
            loading={loading}
            setIsChanged={setIsChanged}
          />
          <VsButton
            vs="TwoVsTwo"
            btnNumber={2}
            loading={loading}
            setIsChanged={setIsChanged}
          />
          <VsButton
            vs="ThreeVsThree"
            btnNumber={3}
            loading={loading}
            setIsChanged={setIsChanged}
          />
        </div>
      </div>
      <div className={styles.set_player}>
        {combatPreset.map((elem, index) => (
          <div key={[index]}>
            <CombatItem
              data={elem}
              index={index + 1}
              setSelectedPawn={setSelectedPawn}
            />
          </div>
        ))}
        <div className={styles.line} />
      </div>
    </>
  );
};

export default CombatPreset;
