import { archerData } from '../constants/archer';
import { mageData } from '../constants/mage';
import { warriorData } from '../constants/warrior';
import OneHover from '../static/img/equipment/1v1 hover.png';
import OneActive from '../static/img/equipment/1v1 pressed.png';
import One from '../static/img/equipment/1v1.png';
import TwoHover from '../static/img/equipment/2v2 hover.png';
import TwoActive from '../static/img/equipment/2v2 pressed.png';
import Two from '../static/img/equipment/2v2.png';
import ThreeHover from '../static/img/equipment/3v3 hover.png';
import ThreeActive from '../static/img/equipment/3v3 pressed.png';
import Three from '../static/img/equipment/3v3.png';
import passHover from '../static/img/header/balancebar/dropdown/Decline button hover.png';
import passPressed from '../static/img/header/balancebar/dropdown/Decline button pressed.png';
import PawnsHover from '../static/img/equipment/sidebar-left/Pawns hover.png';
import PawnsActive from '../static/img/equipment/sidebar-left/Pawns active.png';
import GearHover from '../static/img/equipment/sidebar-left/Gear hover.png';
import GearActive from '../static/img/equipment/sidebar-left/Gear active.png';
import SpellsHover from '../static/img/equipment/sidebar-left/Spells hover.png';
import SpellsActive from '../static/img/equipment/sidebar-left/Spells active.png';
import ItemsHover from '../static/img/equipment/sidebar-left/Items hover.png';
import ItemsActive from '../static/img/equipment/sidebar-left/Items active.png';
import AgreeHover from '../static/img/header/balancebar/dropdown/Accept button hover.png';
import buttonAgreeActive from '../static/img/header/balancebar/dropdown/Accept button hover pressed.png';
import openButtonHover from '../static/img/header/balancebar/dropdown/button-dropdown-hover.png';
import openButtonPressed from '../static/img/header/balancebar/dropdown/button-dropdown-click.png';

export const chooseData = {
  Archer: archerData,
  Mage: mageData,
  Warrior: warriorData,
};

export const status = {
  OneVsOne: {
    hover: OneHover,
    pressed: OneActive,
    unActive: One,
  },
  TwoVsTwo: {
    hover: TwoHover,
    pressed: TwoActive,
    unActive: Two,
  },
  ThreeVsThree: {
    hover: ThreeHover,
    pressed: ThreeActive,
    unActive: Three,
  },
};

export const passStatus = {
  hover: passHover,
  pressed: passPressed,
};

export const btnStatus = (vs) => status[vs];

export const typeFunc = (value) => {
  if (value === 1) {
    return 'OneVsOne';
  }
  if (value === 2) {
    return 'TwoVsTwo';
  }
  if (value === 3) {
    return 'ThreeVsThree';
  }
  return null;
};

export const pawnsStatus = {
  hover: PawnsHover,
  pressed: PawnsActive,
};

export const gearStatus = {
  hover: GearHover,
  pressed: GearActive,
};

export const spellsStatus = {
  hover: SpellsHover,
  pressed: SpellsActive,
};

export const itemsStatus = {
  hover: ItemsHover,
  pressed: ItemsActive,
};

export const handleTab = {
  Pawns: '',
  Equipment: 'equipment',
  Spells: 'spells',
  Items: 'items',
};

export const agreeStatus = {
  hover: AgreeHover,
  pressed: buttonAgreeActive,
};

export const disagreeStatus = {
  hover: passHover,
  pressed: passPressed,
};

export const dropdownStatus = {
  hover: openButtonHover,
  pressed: openButtonPressed,
};

export const filterType = {
  Filter: '',
  Mage: 'Mage',
  Warrior: 'Warrior',
  Archer: 'Archer',
};

export const tierType = {
  Common: 'Common',
  Uncommon: 'Uncommon',
  Rare: 'Rare',
  Epic: 'Epic',
  Legendary: 'Legendary',
};

export const filterClass = {
  Class: '',
  Mage: 'Mage',
  Warrior: 'Warrior',
  Archer: 'Archer',
};

export const filterTier = {
  Tier: '',
  Common: 'Common',
  Uncommon: 'Uncommon',
  Rare: 'Rare',
  Epic: 'Epic',
  Legendary: 'Legendary',
};

export const filterSlot = {
  Slot: '',
  Helmet: 'Helmet',
  Chest: 'Chest',
  Boots: 'Boots',
  Weapon: 'Weapon',
  Shield: 'Shield',
  Ring: 'Ring',
};

export const filterApplication = {
  Application: '',
  Immediately: 'Immediately',
  'End opponent': 'EndOpponent',
};

export const filterTypeData = {
  Type: '',
  Primary: 'Primary',
  Secondary: 'Secondary',
};

export const mainNetId = '42161';
