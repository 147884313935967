import logo from '../static/img/mage/mage_icon.png';
import ignite from '../static/img/mage/ignite.png';
import counter from '../static/img/mage/counter.png';
import iceWall from '../static/img/mage/ice_wall.png';
import magicShield from '../static/img/mage/magic_shield.png';
import fireball from '../static/img/mage/fireball.png';
import sunderMind from '../static/img/mage/sunder_mind.png';
// import fireAttack from '../static/img/mage/mage-level1-attack_fire.gif';
// import buff from '../static/img/mage/mage-level1-buff-debuff.gif';
// import idle from '../static/img/mage/mage-level1-idle.gif';
// import hit from '../static/img/mage/mage-level1-hit.gif';
// import mageIdleBreak from '../static/img/mage/mage-level1-idle_break.gif';
// import magicalShieldInitialBack from '../static/img/mage/ME-MagicalShield-Initial-Back.gif';
// import magicalShieldInitialFront from '../static/img/mage/ME-MagicalShield-Initial-Front.gif';
// import magicalShieldPersistentBack from '../static/img/mage/ME-MagicalShield-Persistent-Back.gif';
// import magicalShieldPersistentFront from '../static/img/mage/ME-MagicalShield-Persistent-Front.gif';
// import igniteHitFront from '../static/img/mage/ME-Ignite-Hit-Front.gif';
// import igniteCastBack from '../static/img/mage/ME-Volatility-Initial-Back.gif';
// import igniteCastFront from '../static/img/mage/ME-Volatility-Initial-Front.gif';
// import ignitePersistentBack from '../static/img/mage/ME-Volatility-Persistent-Back.gif';
// import ignitePersistentFront from '../static/img/mage/ME-Volatility-Persistent-Front.gif';

export const mageData = {
  logo,
  spells: {
    // lethal_strike: {
    //   cost: {
    //     count: 3,
    //     type: 'magicka',
    //   },
    //   type: 'primary',
    //   quickness: 'normal',
    //   application: 'end_opponent',
    //   cooldown: 0,
    //   description: '(1 * MD) + add 1 stack of "Volatility" to the caster',
    //   img: ignite,
    // },
    ignite: {
      cost: {
        count: 3,
        type: 'magicka',
      },
      type: 'primary',
      quickness: 'normal',
      application: 'end_opponent',
      cooldown: 0,
      description: '(1 * MD) + add 1 stack of "Volatility" to the caster',
      img: ignite,
    },
    pyroclasm: {
      cost: {
        count: 10,
        type: 'magicka',
      },
      type: 'primary',
      quickness: 'normal',
      application: 'end_opponent',
      cooldown: 0,
      description: '(1 + (0.4 * "Volatility")) * MD, removes all stacks of "Volaility"',
      img: fireball,
    },
    counter: {
      cost: {
        count: 15,
        type: 'magicka',
      },
      type: 'secondary',
      quickness: 'quick',
      application: 'immediately',
      cooldown: 0,
      description: 'Negates entirely the currently casting abilities',
      img: counter,
    },
    magic_shield: {
      cost: {
        count: 6,
        type: 'magicka',
      },
      type: 'secondary',
      quickness: 'quick',
      application: 'immediately',
      cooldown: 0,
      description: '(1 * MD) in shield, for 3 turns - stacking',
      img: magicShield,
    },
    ice_wall: {
      cost: {
        count: 20,
        type: 'magicka',
      },
      type: 'primary',
      quickness: 'quick',
      application: 'immediately',
      cooldown: 4,
      description: 'Reduces speed by 25, (2*MD) if hit',
      img: iceWall,
    },
    sunder_mind: {
      cost: {
        count: 7,
        type: 'magicka',
      },
      type: 'secondary',
      quickness: 'quick',
      application: 'immediately',
      cooldown: 0,
      description: '(0.5 * MD) reduced Magic Resistance',
      img: sunderMind,
    },
  },
  // spellsAnimations: {
  //   ignite: fireAttack,
  //   pyroclasm: fireAttack,
  //   counter: buff,
  //   magic_shield: buff,
  //   ice_wall: buff,
  //   sunder_mind: buff,
  //   hit,
  //   idle,
  // },
  // typeAnimation: {
  //   casting: {
  //     img: mageIdleBreak,
  //     duration: 1600,
  //     backdrop: {
  //       ignite: {
  //         type: 'igniteCast',
  //         back: igniteCastBack,
  //         front: igniteCastFront,
  //         duration: 2600,
  //       },
  //       pyroclasm: {
  //         type: 'igniteCast',
  //         back: igniteCastBack,
  //         front: igniteCastFront,
  //         duration: 2600,
  //       },
  //     },
  //     backdropPersistent: {
  //       ignite: {
  //         type: 'ignitePersistent',
  //         back: ignitePersistentBack,
  //         front: ignitePersistentFront,
  //       },
  //       pyroclasm: {
  //         type: 'ignitePersistent',
  //         back: ignitePersistentBack,
  //         front: ignitePersistentFront,
  //       },
  //     },
  //   },
  //   hit: {
  //     img: hit,
  //     duration: 700,
  //   },
  //   attack: {
  //     img: fireAttack,
  //     duration: 2200,
  //     backdropHit: {
  //       type: 'igniteHitFront',
  //       img: igniteHitFront,
  //       duration: 1600,
  //     },
  //   },
  //   buff: {
  //     img: buff,
  //     duration: 2600,
  //     backdrop: {
  //       magic_shield: {
  //         type: 'magicShieldCast',
  //         back: magicalShieldInitialBack,
  //         front: magicalShieldInitialFront,
  //         duration: 2600,
  //       },
  //     },
  //     backdropPersistent: {
  //       magic_shield: {
  //         type: 'magicShieldPersistent',
  //         back: magicalShieldPersistentBack,
  //         front: magicalShieldPersistentFront,
  //         duration: 2600,
  //       },
  //     },
  //   },
  // },
};
