export class ActionDamage {
  constructor(address, action, pawnData, setActionSelf, setActionOpponent, backdrop, setBackdrop) {
    this.address = address;
    this.action = action;
    this.pawnData = pawnData;
    this.setActionSelf = setActionSelf;
    this.setActionOpponent = setActionOpponent;
    this.backdrop = backdrop;
    this.setBackdrop = setBackdrop;
  }

  toPromises() {
    return [
      () => new Promise((resolve) => {
        if (this.action.from === this.address) {
          this.setActionSelf(this.pawnData.typeAnimation.attack.img);
        } else {
          this.setActionOpponent(this.pawnData.typeAnimation.attack.img);
        }
        this.clear(resolve, this.pawnData.typeAnimation.attack.duration, false);
      }),
      () => Promise.all([
        new Promise((resolve) => {
          if (this.action.from === this.address) {
            this.setActionOpponent(this.pawnData.typeAnimation.hit.img);
          } else {
            this.setActionSelf(this.pawnData.typeAnimation.hit.img);
          }
          this.clear(resolve, this.pawnData.typeAnimation.hit.duration);
        }),
        this.pawnData.typeAnimation.attack.backdropHit
          ? new Promise((resolve) => {
            // hit front animation
            if (this.action.from === this.address) {
              this.setBackdrop({
                player: [...this.backdrop.player],
                opponent: [...this.backdrop.opponent, this.pawnData.typeAnimation.attack.backdropHit.type],
              });
            } else {
              this.setBackdrop({
                player: [...this.backdrop.player, this.pawnData.typeAnimation.attack.backdropHit.type],
                opponent: [...this.backdrop.opponent],
              });
            }
            setTimeout(() => {
              this.setBackdrop({
                player: [...this.backdrop.player].filter(
                  (i) => i !== this.pawnData.typeAnimation.attack.backdropHit.type,
                ),
                opponent: [...this.backdrop.opponent].filter(
                  (i) => i !== this.pawnData.typeAnimation.attack.backdropHit.type,
                ),
              });
              resolve(true);
            }, this.pawnData.typeAnimation.attack.backdropHit.duration);
          })
          : Promise.resolve(),
      ]),
    ];
  }

  clear(resolve, duration, idle = true) {
    setTimeout(() => {
      if (idle) {
        this.setActionSelf(this.pawnData.spellsAnimations.idle);
        this.setActionOpponent(this.pawnData.spellsAnimations.idle);
      }
      resolve(true);
    }, duration);
  }
}
