import React from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import styles from './Cards.module.scss';
import MintCard from '../MintCard';
import { convertFormat } from '../../../../../utils/format';

const Cards = ({ data, text, reset }) => {
  const onCardClick = () => {
    reset({
      price: '',
    });
  };

  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div
      role="presentation"
      className={`${styles.card} ${styles[data.tier]}`}
      onClick={() => {
        if (!pathname.startsWith('/marketplace/pawns/transfer')) {
          onCardClick();
        }
        if (pathname.startsWith('/marketplace/pawns/sell')) {
          navigate(`/marketplace/pawns/sell/${data.nftId}`);
        } else if (pathname.startsWith('/marketplace/pawns/transfer')) {
          navigate(`/marketplace/pawns/transfer/${data.nftId}`);
        } else {
          navigate(`/marketplace/pawns/buy/${data.nftId}`);
        }
      }}
    >
      <div className={styles.card_top_level}>{data.level}</div>
      <div className={styles.card_top} />
      <div className={styles.card_top_right} />

      <div className={styles.card_left} />
      <div className={styles.card_content}>
        {data.image === 'Mint'
          ? <MintCard />
          : (
            <img
              src={data.image.includes('characters')
                ? `https://i.bimium.link/${data.image}`
                : data.image}
              alt="pawn"
            />
          )}
        {data.nftStatus === 'Listed' && text !== 'Market' && <div className={styles.select} />}
      </div>
      <div className={styles.card_right} />
      <div className={styles.card_bottom_left} />
      <div className={styles.card_bottom} />
      <div className={styles.card_bottom_right} />
      {text === 'Market' && (
      <div className={styles.price_wrapper}>
        <div className={styles.price_left} />
        <div className={`${styles.price_centre} ${data.price === 'Mint' ? styles.free_mint : ''}`}>
          {data.price === 'Mint' ? 'Free Mint' : convertFormat(+data.price)}
        </div>
        <div className={styles.price_right} />
      </div>
      )}
    </div>
  );
};

export default Cards;
