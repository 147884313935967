import ActionPawnAnimation from './action-pawn-animation';
import ActionOpponentAnimation from './action-opponent-animation';
import ActionSpriteAnimation from './action-sprite-animation';
import ActionSound from './action-sound';

const createActionByConfig = (skill, config) => {
  switch (config.type.toLowerCase()) {
    case 'pawnanimation':
      return new ActionPawnAnimation(skill, config);
    case 'opponentanimation':
      return new ActionOpponentAnimation(skill, config);
    case 'spriteanimation':
      return new ActionSpriteAnimation(skill, config);
    case 'sound':
      return new ActionSound(skill, config);
    default:
      console.log('NOT IMPLEMENTED ACTION TYPE IN FACTORY', config);
  }
  return null;
};

export default createActionByConfig;
