import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import styles from './StatusBar.module.scss';
import separator from '../../../static/img/arena/Separator.png';
import { buttonsArray } from '../../../constants/constants';
import ResourceBar from '../../../components/ResourceBar/ResourceBar';
import Tooltip from '../../../components/Tooltip';
import { mageData } from '../../../constants/mage';
import { archerData } from '../../../constants/archer';
import { warriorData } from '../../../constants/warrior';
import { selectActiveSkill, setActiveSkill } from '../../../store/slices/arena';
import uniqueId from '../../../utils/uniqueId';
import { stats } from '../../../constants/stats';
import { selectOpenChat } from '../../../store/slices/chat';
// import { handleDoSpellAction } from '../../../utils/arenaSocket';

const StatusBar = ({
  who = 'enemy', data, isMyTurn,
}) => {
  const dispatch = useDispatch();
  const [width, setWidth] = useState(window.innerWidth);
  const chooseData = {
    Archer: archerData,
    Mage: mageData,
    Warrior: warriorData,
  };

  const userData = chooseData[data.class];
  const activeSkill = useSelector(selectActiveSkill);
  const openChat = useSelector(selectOpenChat);
  // eslint-disable-next-line consistent-return
  const handleKeyPress = (e) => {
    if (openChat) return null;
    const choseSpell = (number) => dispatch(setActiveSkill({
      name: Object.keys(userData.spells)[number],
      ...userData.spells[Object.keys(userData.spells)[number]],
    }));
    switch (e.key.toLowerCase()) {
      case 'q':
        choseSpell(0);
        break;
      case 'w':
        choseSpell(1);
        break;
      case 'e':
        choseSpell(2);
        break;
      case 'r':
        choseSpell(3);
        break;
      case 't':
        choseSpell(4);
        break;
      case 'y':
        choseSpell(5);
        break;
      default:
        return null;
    }
  };

  useEffect(() => {
    window.addEventListener('keypress', handleKeyPress);

    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, [openChat]);

  useEffect(() => {
    const onResize = (e) => {
      setWidth(e.target.innerWidth);
    };
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <div className={`
    ${styles.wrapper}
    ${who === 'user' ? styles.user : ''}`}
    >
      <div className={styles.info}>
        <div className={styles.effects}>
          {
            Object.keys(userData.spells).map((elem, index) => (
              <div
                className={`${styles.effects_block} ${activeSkill?.name === elem ? styles.selected_skill : ''}`}
                key={uniqueId('statusBarSpells')}
              >
                <div className={`
                ${styles.img_wrapper}
                 ${!isMyTurn ? styles.filter : ''}
                   ${data?.spells
                  .filter((item) => item.code === elem)[0]?.lockedTime
                  ? styles.active
                  : ''}
                 `}
                >
                  <img
                    role="presentation"
                    src={userData.spells[elem].img}
                    alt=""
                    data-tip
                    data-for={`${elem}Tip${who ? 'user' : ''}`}
                    onKeyPress={(e) => {
                      if (who === 'user') {
                        handleKeyPress(e);
                      }
                    }}
                    onClick={() => {
                      if (who === 'user') {
                        dispatch(setActiveSkill({
                          name: elem,
                          ...userData.spells[elem],
                        }));
                      }
                    }}
                  />
                  {data?.spells.filter((item) => item.code === elem)[0]?.lockedTime ? (
                    <span className={styles.turns}>
                      {`${data?.spells
                        .filter((item) => item.code === elem)[0]?.lockedTime} Turn${data?.spells
                        .filter((item) => item.code === elem)[0]?.lockedTime !== 1 ? 's' : ''}`}
                    </span>
                  ) : null}
                </div>
                {width > 880 && (
                <div className={styles.button_bg}>
                  {buttonsArray[index]}
                </div>
                )}
                <ReactTooltip
                  id={`${elem}Tip${who ? 'user' : ''}`}
                  place="top"
                  backgroundColor="none"
                  arrowColor="transparent"
                  delayShow={1000}
                >
                  <Tooltip
                    description={userData.spells[elem].description}
                    img={userData.spells[elem].img}
                    name={elem}
                    type={userData.spells[elem].type}
                  />
                </ReactTooltip>
              </div>
            ))
          }
        </div>
        <div style={who === 'user' ? { display: 'none' } : {}}>
          <img src={separator} alt="" className={styles.separator} />
        </div>
        <div className={styles.info__bottom}>
          <div className={styles.stats}>
            {/* eslint-disable-next-line array-callback-return,consistent-return */}
            {Object.keys(stats).map((elem) => {
              if (elem in data) {
                return (
                  <div key={uniqueId('stats')}>
                    <img
                      src={stats[elem]}
                      alt={elem}
                      data-tip
                      data-for={`${elem}Tip${who ? 'user' : ''}`}
                    />
                    <ReactTooltip
                      id={`${elem}Tip${who ? 'user' : ''}`}
                      place="top"
                      backgroundColor="none"
                      arrowColor="transparent"
                    >
                      <Tooltip
                        description={`${elem}: ${data[elem]}`}
                        img={stats[elem]}
                        name={elem}
                      />
                    </ReactTooltip>
                  </div>
                );
              }
            })}
          </div>
          <div className={styles.count}>
            <span>
              {`${data.health}/${data.maxHealth}`}
            </span>
            <span>
              {`${data.actionPoints}/${data.maxActionPoints}`}
            </span>
          </div>
          <div className={styles.bars}>
            <ResourceBar
              type="hp"
              count={data.health}
              maxCount={data.maxHealth}
            />
            <ResourceBar
              type={data.class.toLowerCase()}
              count={data.actionPoints}
              maxCount={data.maxActionPoints}
            />
          </div>
        </div>
      </div>
      {width > 880 && (
      <div className={`${styles.character_img} ${!isMyTurn ? styles.filter : ''}`}>
        <img src={userData.logo} alt="" />
      </div>
      )}
    </div>
  );
};

export default StatusBar;
