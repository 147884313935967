import React, { useState } from 'react';
import styles from './NavButton.module.scss';

const NavButton = ({
  text, isOpenAdventure, isOpenInventory, isOpenAlchemist,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      role="presentation"
      onMouseDown={() => setIsOpen(true)}
      onMouseUp={() => setIsOpen(false)}
      className={`${styles.button_wrapper} ${
        isOpenAdventure || isOpenInventory || isOpenAlchemist || isOpen ? styles.active : ''
      }`}
    >
      <div className={styles.button_left} />
      <div className={styles.button_content}>
        <span>{text}</span>
      </div>
      {(text === 'Adventure' || text === 'Inventory' || text === 'Alchemist') && (
        <div className={styles.down} />
      )}
      <div className={styles.button_right} />
    </div>
  );
};

export default NavButton;
