import { createSlice } from '@reduxjs/toolkit';
import {
  getEvmRequest, postAddressRequest, postDisconnect, postUsersVerify,
} from '../../../utils/requests';

export const auth = createSlice({
  name: 'auth',
  initialState: {
    evm: null,
    loading: false,
    evmLoaded: false,
    error: false,
    message: null,
    verify: false,
  },
  reducers: {
    getUsersEvmRequested: (state) => ({
      ...state,
      loading: true,
    }),
    getUsersEvmFailure: (state, { payload }) => ({
      ...state,
      evmLoaded: true,
      loading: false,
      error: payload,
    }),
    getUsersEvmSuccess: (state, { payload }) => ({
      ...state,
      evmLoaded: true,
      loading: false,
      evm: payload,
    }),
    postUserAddressRequested: (state) => ({
      ...state,
      loading: true,
    }),
    postUserAddressFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    postUserAddressSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      message: payload,
    }),
    postUserVerifyRequested: (state) => ({
      ...state,
      loading: true,
    }),
    postUserVerifyFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    postUserVerifySuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      verify: payload,
    }),
    setEvm: (state, { payload }) => ({
      ...state,
      evm: payload,
    }),
  },
});

// ---- actions ----
export const {
  getUsersEvmRequested, getUsersEvmFailure, getUsersEvmSuccess,
  postUserAddressRequested, postUserAddressFailure, postUserAddressSuccess,
  postUserVerifyRequested, postUserVerifyFailure, postUserVerifySuccess,
  setEvm,
} = auth.actions;

// ---- request actions ----
export const getEvm = (payload) => async (dispatch) => {
  try {
    dispatch(getUsersEvmRequested());
    const response = await getEvmRequest(payload);
    if (response.status >= 400) {
      dispatch(getUsersEvmFailure('error'));
    } else if (response.status === 200) {
      dispatch(getUsersEvmSuccess(response.data));
    }
  } catch (e) {
    dispatch(getUsersEvmFailure('error'));
  }
};

export const postAddress = (payload) => async (dispatch) => {
  try {
    dispatch(postUserAddressRequested());
    const response = await postAddressRequest(payload);
    if (response.status >= 400) {
      dispatch(postUserAddressFailure(response.data.message));
    } else if (response.status <= 204) {
      dispatch(postUserAddressSuccess(response.data));
    }
  } catch (e) {
    dispatch(postUserAddressFailure(e.message));
  }
};

export const verifyUser = (payload) => async (dispatch) => {
  try {
    dispatch(postUserVerifyRequested());
    const response = await postUsersVerify(payload);
    if (response.status >= 400) {
      dispatch(postUserVerifyFailure(response.data.message));
    } else if (response.status <= 204) {
      dispatch(postUserVerifySuccess(true));
      window.location.href = '/';
    }
  } catch (e) {
    dispatch(postUserVerifyFailure(e.message));
  }
};

export const disconnectEvm = () => async (dispatch) => {
  try {
    dispatch(postUserVerifyRequested());
    const response = await postDisconnect();
    if (response.status >= 400) {
      dispatch(postUserVerifyFailure(response.data.message));
    } else if (response.status <= 204) {
      dispatch(postUserVerifySuccess(true));
    }
  } catch (e) {
    dispatch(postUserVerifyFailure(e.message));
  }
};

// ---- selectors ----
export const selectEvm = (state) => (state.auth.evm);
export const selectLoading = (state) => ({ loading: state.auth.loading, evmLoaded: state.auth.evmLoaded });
export const selectMessage = (state) => (state.auth.message);
export const selectVerify = (state) => (state.auth.verify);
