import Phaser from 'phaser';
import Action from './action';

class ActionSound extends Action {
  constructor(skill, config) {
    super(config);
    this.skill = skill;
    this.timeline = config.timeline;

    // eslint-disable-next-line prefer-destructuring
    this.key = (/skill-sounds\/(.*).(?:mp3|wav)/gm).exec(config.soundPath)[1];

    this.skill.scene.load.audio(this.key, config.soundPath);
    skill.scene.load.once(Phaser.Loader.Events.COMPLETE, () => {
      this.skill.scene.sound.add(this.key);
      this.timeline.add({
        in: this.delay,
        sound: this.key,
        // run: () => {
        //   console.log('!!!!RUN!!!!SOUND!!!!', config);
        // },
      });
    });

    this.skill.scene.load.start();
  }
}

export default ActionSound;
