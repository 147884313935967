/*eslint-disable*/
import { createSlice } from '@reduxjs/toolkit';
import {
  getBatchInfo, mintByAddress, transferPawn,
  // getBatchPawnsRemaining,
  // getCurrentUserWhiteListedAllocationRemaining,
  // getTotalLeftInBatch,
  // onBlockListener,
  // presaleBuy, removeListeners,
  // removeListeners,
} from '../../../utils/requests';
import { notificationShowAction } from '../utils';
// import { notificationShowAction } from '../utils';

export const presale = createSlice({
  name: 'presale',
  initialState: {
    batchInfo: null,
    loading: false,
    presaleLoading: false,
    mintLoading: false,
    totalLeft: null,
    batchRemaining: null,
    allocationRemaining: null,
  },
  reducers: {
    onMintRequested: (state) => ({
      ...state,
      mintLoading: true,
    }),
    onMintFailure: (state, { payload }) => ({
      ...state,
      mintLoading: false,
      error: payload,
    }),
    onMintSuccess: (state) => ({
      ...state,
      mintLoading: false,
      error: false,
    }),
    getPresaleBatchInfoRequested: (state) => ({
      ...state,
      loading: true,
    }),
    getPresaleBatchInfoFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    getPresaleBatchInfoSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: false,
      batchInfo: payload,
    }),
    onPresaleBuyRequested: (state) => ({
      ...state,
      presaleLoading: true,
    }),
    onPresaleBuyFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    onPresaleBuySuccess: (state) => ({
      ...state,
      presaleLoading: false,
    }),
    getTotalLeftSuccess: (state, { payload }) => ({
      ...state,
      totalLeft: payload,
    }),
    getTotalLeftFailure: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
    getBatchRemainingSuccess: (state, { payload }) => ({
      ...state,
      batchRemaining: payload,
    }),
    getBatchRemainingFailure: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
    getAllocationRemainingSuccess: (state, { payload }) => ({
      ...state,
      allocationRemaining: payload,
    }),
    getAllocationRemainingFailure: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
  },
});

// ---- actions ----
export const {
  getPresaleBatchInfoSuccess, getPresaleBatchInfoRequested, getPresaleBatchInfoFailure,
  onPresaleBuySuccess, onPresaleBuyRequested, onPresaleBuyFailure,
  getTotalLeftSuccess, getTotalLeftFailure,
  onMintRequested, onMintFailure, onMintSuccess,
  getBatchRemainingSuccess, getBatchRemainingFailure,
  getAllocationRemainingSuccess, getAllocationRemainingFailure,
} = presale.actions;

// ---- request actions ----
export const getPresaleBatchInfo = (payload) => async (dispatch) => {
  try {
    dispatch(getPresaleBatchInfoRequested());
    const response = await getBatchInfo(payload);
    if (response.status >= 400) {
      dispatch(getPresaleBatchInfoFailure(response.data.message));
    } else if (response.status <= 204) {
      dispatch(getPresaleBatchInfoSuccess(response.data));
    }
  } catch (e) {
    dispatch(getPresaleBatchInfoFailure(e.message));
  }
};

export const onPresaleBuy = (batchId, amount, priceInWei) => async (dispatch) => {
  // try {
  //   dispatch(onPresaleBuyRequested());
  //   // eslint-disable-next-line no-unused-vars
  //   const response = await presaleBuy(batchId, amount, priceInWei);
  //   await onBlockListener('MintFinished', () => {
  //     removeListeners();
  //     dispatch(onPresaleBuySuccess());
  //     dispatch(notificationShowAction(true));
  //   });
  //   if (response.status >= 400) {
  //     dispatch(onPresaleBuyFailure(response.data.message));
  //   } else if (response.status <= 204) {
  //     dispatch(onPresaleBuySuccess(response.data));
  //   }
  // } catch (e) {
  //   dispatch(onPresaleBuyFailure(e.message));
  // }
};

export const onMint = () => async (dispatch) => {
  try {
    dispatch(onMintRequested());
    await mintByAddress()
    dispatch(notificationShowAction(true, "Minted successfully"));
    dispatch(onMintSuccess());
  } catch (e) {
    const err = e.message;
    dispatch(onMintFailure(err));
    if (err.includes('user rejected')) {
      dispatch(notificationShowAction(false, "User denied transaction"));
    } else {
      dispatch(notificationShowAction(false, "Minted failure"));
    }
  }
};

export const getTotalLeft = (batchId) => async (dispatch) => {
  // try {
  //   const response = await getTotalLeftInBatch(batchId);
  //   if (!response) {
  //     dispatch(getTotalLeftFailure('error'));
  //   } else {
  //     dispatch(getTotalLeftSuccess(response));
  //   }
  // } catch (e) {
  //   dispatch(getTotalLeftFailure(e.message));
  // }
};

export const getBatchRemaining = (batchId) => async (dispatch) => {
  // try {
  //   const response = await getBatchPawnsRemaining(batchId);
  //   if (!response) {
  //     dispatch(getBatchRemainingFailure('error'));
  //   } else {
  //     dispatch(getBatchRemainingSuccess(response));
  //   }
  // } catch (e) {
  //   dispatch(getBatchRemainingFailure(e.message));
  // }
};

export const getAllocationRemaining = (batchId) => async (dispatch) => {
  // try {
  //   const response = await getCurrentUserWhiteListedAllocationRemaining(batchId);
  //   if (!response) {
  //     dispatch(getAllocationRemainingFailure('error'));
  //   } else {
  //     dispatch(getAllocationRemainingSuccess(response));
  //   }
  // } catch (e) {
  //   dispatch(getAllocationRemainingFailure(e.message));
  // }
};

// ---- selectors ----
export const selectBatchInfo = (state) => (state.presale.batchInfo);
export const selectBatchInfoLoading = (state) => (state.presale.loading);
export const selectBatchAdditionalData = (state) => ({
  totalLeft: state.presale.totalLeft,
  batchRemaining: state.presale.batchRemaining,
  allocationRemaining: state.presale.allocationRemaining,
});
export const selectPresaleLoading = (state) => (state.presale.presaleLoading);
export const selectMintLoading = (state) => (state.presale.mintLoading);
export const selectTransferLoading = (state) => (state.presale.transferLoading);
