import React from 'react';
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import Tooltip from '../Tooltip';
import {
  clearStats,
  getPawnStats,
} from '../../../../store/slices/pawnSelect';

const StatsPreview = ({
  id, name, stats, pawnId,
}) => {
  const dispatch = useDispatch();

  return (
    <ReactTooltip
      id={id}
      place="top"
      backgroundColor="none"
      arrowColor="transparent"
      delayShow={100}
      afterShow={() => dispatch(getPawnStats(pawnId))}
      afterHide={() => dispatch(clearStats())}
    >
      <Tooltip
        name={name}
        stats={stats}
      />
    </ReactTooltip>
  );
};

export default StatsPreview;
