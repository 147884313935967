import React, {
  useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import ReactTooltip from 'react-tooltip';
import { selectEvm } from '../../store/slices/auth';
import {
  clearPawns,
  getQuestInfo,
  getReward, putQuest, removePawn,
  resetQuestInfo, resetStartQuest, selectPickPawn,
  selectQuestInfo,
  selectQuestInfoLoading, selectReward, selectStartQuest,
} from '../../store/slices/quests';
import Header from '../../components/Marketplace/Components/Header';
import loader from '../../static/img/loader.gif';
import Die from '../../static/img/quests/Difficulty.png';
import Separator from '../../components/LayoutWindow/Separator';
import InfoButton from '../../components/Arena/MatchmakingButton';
import PickPawnModal from './PickPawnModal';
import { notificationShowAction } from '../../store/slices/utils';
import MobileLayout from '../../components/MobileLayout';
import QuestRewards from '../../components/QuestRewards';
import uniqueId from '../../utils/uniqueId';
import { selectPawnAnimation } from '../../components/Experience';
import Tooltip from './PickPawnModal/Tooltip';
import { resetInventoryForEquipment } from '../../store/slices/requests';
import CountDown from './CountDown';
import PotentialRewards from './PotentialRewards';
import styles from './QuestPage.module.scss';
import StatsPreview from './PickPawnModal/StatsPreview';

const QuestPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { slot } = useParams();

  const [showPickPawnModal, setShowPickPawnModal] = useState(false);
  const [isClick, setIsClick] = useState(false);
  const [isClickOnBack, setIsClickOnBack] = useState(false);
  const [isTimeout, setIsTimeout] = useState(false);

  const address = useSelector(selectEvm);
  const isLoading = useSelector(selectQuestInfoLoading);
  const questData = useSelector(selectQuestInfo);
  const pickPawn = useSelector(selectPickPawn);
  const successStartQuest = useSelector(selectStartQuest);
  const successGetReward = useSelector(selectReward);

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const onResize = (e) => {
      setWidth(e.target.innerWidth);
    };
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    dispatch(getQuestInfo(slot));
  }, [slot]);

  useEffect(() => {
    if (successStartQuest) {
      navigate('/quests');
      dispatch(notificationShowAction(true, 'The quest has started successfully'));
      dispatch(resetStartQuest());
    }
  }, [successStartQuest]);

  useEffect(() => () => {
    dispatch(resetQuestInfo());
    dispatch(clearPawns());
    dispatch(resetInventoryForEquipment());
  }, []);

  const questContent = useMemo(() => {
    if (width < 880 && isLoading) {
      return (
        <MobileLayout>
          <div className={styles.loader}>
            <img src={loader} alt="loading" />
          </div>
        </MobileLayout>
      );
    }
    if (isLoading) {
      return (
        <div className={styles.wrapper}>
          <div className={styles.loader}>
            <img src={loader} alt="loading" />
          </div>
        </div>
      );
    }
    if (width < 880 && questData) {
      return (
        <MobileLayout>
          {successGetReward
            ? (
              <QuestRewards
                reward={successGetReward}
              />
            )
            : null}
          <div className={styles.header}>
            <div className={styles.separator} />
            <div className={`${styles.titleBox} ${styles[questData.rarity]}`}>
              <span>{questData.name.toUpperCase()}</span>
              <div className={`${styles.type_level} ${styles[questData.rarity]}`}>{questData.category}</div>
            </div>
            <div className={styles.separator_mobile} />
          </div>
          <div className={styles.wrapper_content}>
            <div className={styles.left_content}>
              <div className={styles.description}>
                {questData.description}
              </div>
              <div className={styles.dec} />
              <div className={styles.pawn_pick}>
                <div className={styles.pick_button}>
                  {pickPawn.map((pawn, index) => (
                    <>
                      <div
                        key={uniqueId('selectedPawn')}
                        role="presentation"
                        className={styles.pick_pawn_image}
                        onClick={() => {
                          dispatch(removePawn(pawn));
                        }}
                        data-tip
                        data-for={pawn?.nftId?.toString() || String(index)}
                      >
                        <img src={`https://i.bimium.link/${pawn?.image}`} alt="pawn" />
                      </div>
                      <ReactTooltip
                        id={pawn?.nftId?.toString() || String(index)}
                        place="top"
                        backgroundColor="none"
                        arrowColor="transparent"
                        delayShow={100}
                      >
                        <Tooltip
                          name={pawn.name}
                          stats={pawn.stats}
                        />
                      </ReactTooltip>
                    </>
                  ))}
                  {!questData?.pawns?.length
                    && Array.from(Array(questData.maxPawnCount - pickPawn.length).keys()).map(() => (
                      <div
                        key={uniqueId('pick')}
                        role="presentation"
                        className={styles.pick_pawn}
                        onClick={() => {
                          setShowPickPawnModal(true);
                        }}
                      />
                    ))}
                  {questData?.pawns?.length
                    ? questData.pawns.map((pawn) => (
                      <div
                        key={uniqueId('selectedPawn')}
                        className={styles.pick_pawn_image}
                      >
                        <img src={selectPawnAnimation[pawn.class]} alt="pawn" />
                      </div>
                    ))
                    : null}
                </div>
                <div className={styles.button_group}>
                  {questData.status === 'Running' && !isTimeout ? (
                    <CountDown
                      duration={questData.duration * 60 - Math.floor(
                        (new Date()).getTime() / 1000 - (new Date(questData?.startedTime)).getTime() / 1000,
                      )}
                      setIsTimeout={setIsTimeout}
                      isTimeout={isTimeout}
                    />
                  ) : (
                    <>
                      <div
                        className={styles.area_soon}
                        data-title="Coming soon"
                      >
                        <InfoButton
                          text=""
                        // isClick={isClick}
                          onClick={() => {}}
                        />
                      </div>
                      <div
                        role="presentation"
                        onMouseUp={() => setIsClick(false)}
                        onMouseDown={() => setIsClick(true)}
                        className={styles.info_button}
                      >
                        {questData.status === 'Finished' || (questData.status === 'Running' && isTimeout)
                          ? (
                            <InfoButton
                              text="Claim rewards"
                              isClick={isClick}
                              onClick={() => dispatch(getReward())}
                            />
                          )
                          : (
                            <InfoButton
                              text="Embark"
                              isClick={isClick}
                              onClick={() => {
                                if (pickPawn) {
                                  dispatch(putQuest({
                                    slot,
                                    pawn: pickPawn.nftId,
                                  }));
                                } else {
                                  dispatch(notificationShowAction(false, 'Pick the pawn'));
                                }
                              }}
                            />
                          )}
                      </div>
                    </>
                  )}
                  <div
                    role="presentation"
                    onMouseUp={() => setIsClickOnBack(false)}
                    onMouseDown={() => setIsClickOnBack(true)}
                    className={styles.info_button}
                  >
                    <InfoButton
                      text="Back to quests"
                      onClick={() => {
                        navigate('/quests');
                        dispatch(clearPawns());
                      }}
                      isClick={isClickOnBack}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.separator_v} />
            <div className={styles.quest_info}>
              <div className={styles.wrapper_image}>
                <div
                  className={styles.wrapper_img}
                  // data-title="Coming soon"
                >
                  <div className={`${styles.border_wrapper} ${styles[questData.rarity]}`} />
                  <img src={`https://i.bimium.link/${questData.image}`} alt="quest" />
                </div>
              </div>
              <div className={styles.dec} />
              <div className={styles.mobile_wrap_reward}>
                <div className={styles.chance}>
                  <img src={Die} alt="die" />
                  <span>Chance of success:</span>
                  <span>Coming soon...</span>
                </div>
                <div className={styles.rewards}>
                  <span>Potential rewards</span>
                  <PotentialRewards />
                </div>
              </div>
              <div className={styles.dec} />
            </div>
          </div>
          <div className={styles.separator_mobile} />
        </MobileLayout>
      );
    }
    if (questData) {
      return (
        <div className={styles.wrapper}>
          <Link to="/" className="closeWindow"><button type="button">&#10006;</button></Link>
          {successGetReward
            ? (
              <QuestRewards
                reward={successGetReward}
              />
            )
            : null}
          <div className={styles.header}>
            <div className={styles.separator} />
            <div className={`${styles.titleBox} ${styles[questData.rarity]}`}>
              <span>{questData.name.toUpperCase()}</span>
              <div className={`${styles.type_level} ${styles[questData.rarity]}`}>{questData.category}</div>
            </div>
            <div className={styles.separator} />
          </div>
          <div className={styles.wrapper_content}>
            <div className={styles.left_content}>
              <div className={styles.description}>
                {questData.description}
              </div>
              <div className={styles.separator_next} />
              <div className={styles.pawn_pick}>
                <div className={styles.pick_button}>
                  {pickPawn.map((pawn, index) => (
                    <>
                      <div
                        key={[index]}
                        role="presentation"
                        className={styles.pick_pawn_image}
                        onClick={() => {
                          dispatch(removePawn(pawn));
                        }}
                        data-tip
                        data-for={pawn?.nftId?.toString() || String(index)}
                      >
                        <img src={`https://i.bimium.link/${pawn?.image}`} alt="pawn" />
                      </div>
                      <ReactTooltip
                        id={pawn?.nftId?.toString() || String(index)}
                        place="top"
                        backgroundColor="none"
                        arrowColor="transparent"
                        delayShow={100}
                      >
                        <Tooltip
                          name={pawn.name}
                          stats={pawn.stats}
                        />
                      </ReactTooltip>
                    </>
                  ))}
                  {!questData?.pawns?.length
                    && Array.from(Array(questData.maxPawnCount - pickPawn.length).keys()).map(() => (
                      <div
                        key={uniqueId('pick_quest')}
                        role="presentation"
                        className={styles.pick_pawn}
                        onClick={() => {
                          setShowPickPawnModal(true);
                        }}
                      />
                    ))}
                  {questData?.pawns?.length
                    ? questData.pawns.map((pawn, index) => (
                      <div
                        className={styles.pick_pawn_image}
                        data-tip="show"
                        data-for={pawn?.nftId?.toString() || String(index)}
                        key={uniqueId('selectedPawn_questData')}
                      >
                        <img src={selectPawnAnimation[pawn.class]} alt="pawn" />
                        <StatsPreview
                          id={pawn?.nftId?.toString() || String(index)}
                          pawnId={pawn.nftId}
                          name={pawn.name}
                        />
                      </div>
                    ))
                    : null}
                </div>
                <div className={styles.button_group}>
                  {questData.status === 'Running' && !isTimeout ? (
                    <CountDown
                      duration={questData.duration * 60 - Math.floor(
                        (new Date()).getTime() / 1000 - (new Date(questData?.startedTime)).getTime() / 1000,
                      )}
                      setIsTimeout={setIsTimeout}
                      isTimeout={isTimeout}
                    />
                  ) : (
                    <>
                      <div
                        className={styles.area_soon}
                        data-title="Coming soon"
                      >
                        <InfoButton
                          text=""
                        // isClick={isClick}
                          onClick={() => {}}
                        />
                      </div>
                      <div
                        role="presentation"
                        onMouseUp={() => setIsClick(false)}
                        onMouseDown={() => setIsClick(true)}
                      >
                        {questData.status === 'Finished' || (questData.status === 'Running' && isTimeout)
                          ? (
                            <InfoButton
                              text="Claim rewards"
                              isClick={isClick}
                              onClick={() => dispatch(getReward(slot))}
                            />
                          )
                          : (
                            <InfoButton
                              text="Embark"
                              isClick={isClick}
                              onClick={() => {
                                if (pickPawn.length) {
                                  dispatch(putQuest({
                                    slot,
                                    pawns: pickPawn.map((pawn) => pawn.nftId),
                                  }));
                                } else {
                                  dispatch(notificationShowAction(false, 'Pick the pawn'));
                                }
                              }}
                            />
                          )}
                      </div>
                    </>
                  )}
                  <div
                    role="presentation"
                    onMouseUp={() => setIsClickOnBack(false)}
                    onMouseDown={() => setIsClickOnBack(true)}
                  >
                    <InfoButton
                      text="Back to quests"
                      onClick={() => {
                        navigate('/quests');
                        dispatch(clearPawns());
                      }}
                      isClick={isClickOnBack}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.separator_v} />
            <div className={styles.quest_info}>
              <div className={styles.wrapper_image}>
                <div
                  className={styles.wrapper_img}
                  // data-title="Coming soon"
                >
                  <div className={`${styles.border_wrapper} ${styles[questData.rarity]}`} />
                  <img src={`https://i.bimium.link/${questData.image}`} alt="quest" />
                </div>
              </div>
              <div className={styles.dec} />
              <div className={styles.chance}>
                <img src={Die} alt="die" />
                <span>Chance of success:</span>
                <span>Coming soon...</span>
              </div>
              <div className={styles.dec} />
              <div className={styles.rewards}>
                <span>Potential rewards</span>
                <PotentialRewards />
              </div>
            </div>
          </div>
          <div className={styles.wrap_sep}>
            <Separator reverse />
          </div>
        </div>
      );
    }
    return null;
  }, [isLoading, questData, pickPawn.length, isTimeout,
    isClick, isClickOnBack, questData?.status, width, successGetReward]);

  return (
    <>
      <Header address={address} />
      {showPickPawnModal
        ? <PickPawnModal setShowPickPawnModal={setShowPickPawnModal} pickedPawns={pickPawn} />
        : null}
      {questContent}
    </>
  );
};

export default QuestPage;
