import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './BattlePlace.module.scss';
import HeaderCasting from './HeaderCasting';
import StatusBarHero from './StatusBarHero';
import { mageData } from '../../../constants/mage';
import { selectFightActions } from '../../../store/slices/arena';
import { Manager } from '../../../animations/arena/manager';
import magicalShieldInitialBack from '../../../static/img/mage/ME-MagicalShield-Initial-Back.gif';
import magicalShieldInitialFront from '../../../static/img/mage/ME-MagicalShield-Initial-Front.gif';
import igniteCastBack from '../../../static/img/mage/ME-Volatility-Initial-Back.gif';
import igniteCastFront from '../../../static/img/mage/ME-Volatility-Initial-Front.gif';
import magicalShieldPersistentBack from '../../../static/img/mage/ME-MagicalShield-Persistent-Back.gif';
import magicalShieldPersistentFront from '../../../static/img/mage/ME-MagicalShield-Persistent-Front.gif';
import igniteHitFront from '../../../static/img/mage/ME-Ignite-Hit-Front.gif';
import ignitePersistentBack from '../../../static/img/mage/ME-Volatility-Persistent-Back.gif';
import ignitePersistentFront from '../../../static/img/mage/ME-Volatility-Persistent-Front.gif';

const BattlePlace = ({ address, player, opponent }) => {
  const actions = useSelector(selectFightActions);
  const pawnData = mageData;

  const [actionSelf, setActionSelf] = useState(pawnData.spellsAnimations.idle);
  const [actionOpponent, setActionOpponent] = useState(pawnData.spellsAnimations.idle);
  const [backdropAnimation, setBackdropAnimation] = useState({ player: [], opponent: [] });
  const [manager] = useState(
    new Manager(address, setActionSelf, setActionOpponent, pawnData, setBackdropAnimation),
  );

  manager.setBackdropState(backdropAnimation);

  // useEffect(() => {
  //   manager.setBackdropState(backdropAnimation);
  // }, [backdropAnimation]);

  useEffect(() => {
    manager.addActions(actions);
  }, [actions]);

  // useEffect(() => {
  //   if (manager) {
  //     manager.changeAnimations(animations);
  //   }
  // }, [animations]);

  return (
    <div className={styles.battle_arena_wrapper}>
      <HeaderCasting actions={actions} address={address} player={player} opponent={opponent} />
      <div className={styles.arena_container}>
        <div className={styles.wrapper_bar}>
          <StatusBarHero buff={[...player.buffs, ...player.debuffs]} playerClass={player.class} />
          <StatusBarHero buff={[...opponent.buffs, ...opponent.debuffs]} playerClass={opponent.class} reverse />
        </div>
        <div className={styles.wrapper_hero}>
          <div className={styles.player}>
            {/* <img */}
            {/*  src={magicalShieldInitialBack} */}
            {/*  alt="back" */}
            {/*  className={styles.backAnimation} */}
            {/* /> */}
            {
              backdropAnimation.player.some((i) => i === 'magicShieldCast')
                ? (
                  <img
                    src={magicalShieldInitialBack}
                    alt="magicalShieldInitialBack"
                    className={styles.backAnimation}
                  />
                )
                : null
            }
            {
              backdropAnimation.player.some((i) => i === 'magicShieldPersistent')
                ? (
                  <img
                    src={magicalShieldPersistentBack}
                    alt="magicalShieldPersistentBack"
                    className={styles.backAnimation}
                  />
                )
                : null
            }
            {
              backdropAnimation.player.some((i) => i === 'igniteCast')
                ? (
                  <img
                    src={igniteCastBack}
                    alt="igniteCastBack"
                    className={`${styles.backAnimation} ${styles.igniteCast}`}
                  />
                )
                : null
            }
            {
              backdropAnimation.player.some((i) => i === 'ignitePersistent')
                ? (
                  <img
                    src={ignitePersistentBack}
                    alt="ignitePersistentBack"
                    className={styles.backAnimation}
                  />
                )
                : null
            }
            <img
              src={actionSelf}
              className={styles.middleAnimation}
              alt="hero"
            />
            {
              backdropAnimation.player.some((i) => i === 'magicShieldCast')
                ? (
                  <img
                    src={magicalShieldInitialFront}
                    alt="magicalShieldInitialFront"
                    className={styles.frontAnimation}
                  />
                )
                : null
            }
            {
              backdropAnimation.player.some((i) => i === 'magicShieldPersistent')
                ? (
                  <img
                    src={magicalShieldPersistentFront}
                    alt="magicalShieldInitialFront"
                    className={styles.frontAnimation}
                  />
                )
                : null
            }
            {
              backdropAnimation.player.some((i) => i === 'igniteCast')
                ? (
                  <img
                    src={igniteCastFront}
                    alt="igniteCastFront"
                    className={`${styles.frontAnimation} ${styles.igniteCast}`}
                  />
                )
                : null
            }
            {
              backdropAnimation.player.some((i) => i === 'ignitePersistent')
                ? (
                  <img
                    src={ignitePersistentFront}
                    alt="ignitePersistentFront"
                    className={styles.frontAnimation}
                  />
                )
                : null
            }
            {
              backdropAnimation.player.some((i) => i === 'igniteHitFront')
                ? (
                  <img
                    src={igniteHitFront}
                    alt="igniteHitFront"
                    className={styles.frontAnimation}
                  />
                )
                : null
            }
          </div>
          <div className={styles.enemy}>
            {
              backdropAnimation.opponent.some((i) => i === 'magicShieldCast')
                ? (
                  <img
                    src={magicalShieldInitialBack}
                    alt="magicalShieldInitialBack"
                    className={styles.backAnimation}
                  />
                )
                : null
            }
            {
              backdropAnimation.opponent.some((i) => i === 'magicShieldPersistent')
                ? (
                  <img
                    src={magicalShieldPersistentBack}
                    alt="magicalShieldPersistentBack"
                    className={styles.backAnimation}
                  />
                )
                : null
            }
            {
              backdropAnimation.opponent.some((i) => i === 'igniteCast')
                ? (
                  <img
                    src={igniteCastBack}
                    alt="igniteCastBack"
                    className={`${styles.backAnimation} ${styles.igniteCast}`}
                  />
                )
                : null
            }
            {
              backdropAnimation.opponent.some((i) => i === 'ignitePersistent')
                ? (
                  <img
                    src={ignitePersistentBack}
                    alt="ignitePersistentBack"
                    className={styles.backAnimation}
                  />
                )
                : null
            }
            <img
              src={actionOpponent}
              alt="hero"
              className={styles.middleAnimationEnemy}
            />
            {
              backdropAnimation.opponent.some((i) => i === 'magicShieldCast')
                ? (
                  <img
                    src={magicalShieldInitialFront}
                    alt="magicalShieldInitialFront"
                    className={styles.frontAnimation}
                  />
                )
                : null
            }
            {
              backdropAnimation.opponent.some((i) => i === 'magicShieldPersistent')
                ? (
                  <img
                    src={magicalShieldPersistentFront}
                    alt="magicalShieldInitialFront"
                    className={styles.frontAnimation}
                  />
                )
                : null
            }
            {
              backdropAnimation.opponent.some((i) => i === 'igniteCast')
                ? (
                  <img
                    src={igniteCastFront}
                    alt="{igniteCastFront}"
                    className={`${styles.frontAnimation} ${styles.igniteCast}`}
                  />
                )
                : null
            }
            {
              backdropAnimation.opponent.some((i) => i === 'ignitePersistent')
                ? (
                  <img
                    src={ignitePersistentFront}
                    alt="ignitePersistentFront"
                    className={styles.frontAnimation}
                  />
                )
                : null
            }
            {
              backdropAnimation.opponent.some((i) => i === 'igniteHitFront')
                ? (
                  <img
                    src={igniteHitFront}
                    alt="igniteHitFront"
                    className={styles.frontAnimation}
                  />
                )
                : null
            }
          </div>
          {/* <img */}
          {/*   src={mageData.spellsAnimations[enemyStatus]} */}
          {/*   alt="hero" */}
          {/*   className={enemyStatus !== 'idle' ? `animation_${enemyStatus}` : ''} */}
          {/*   onAnimationEnd={() => { */}
          {/*     if (enemyStatus !== 'idle') { */}
          {/*       setEnemyStatus('idle'); */}
          {/*     } */}
          {/*   }} */}
          {/* /> */}
        </div>
      </div>
    </div>

  );
};

export default BattlePlace;
