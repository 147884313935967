import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import InfoButton from '../../../components/Arena/MatchmakingButton';
import { selectOpenChat } from '../../../store/slices/chat';
import { createConsumable } from '../../../store/slices/constructor';
import mage from '../../../static/img/mage/mage_icon.png';
import warrior from '../../../static/img/warrior/warrior_icon.png';
import archer from '../../../static/img/archer/archer_icon.png';
import styles from '../ItemsUtils/ItemsUtils.module.scss';

const itemType = {
  Mage: mage,
  Warrior: warrior,
  Archer: archer,
};

const arrayParams = ['Health', 'Magicka'];

const ConsumablesUtils = () => {
  const dispatch = useDispatch();
  const [isClick, setIsClick] = useState(false);
  const [file, setFile] = useState();

  const isOpen = useSelector(selectOpenChat);

  const {
    register, handleSubmit, setValue, watch, reset,
  } = useForm();

  const onSubmit = (submitData) => {
    const newObj = { ...submitData };
    const formData = new FormData();
    Object.keys(newObj).forEach((elem) => {
      if (typeof newObj[elem] !== 'object') {
        formData.append(elem, newObj[elem]);
      }
      if (elem === 'image' && file) {
        formData.append('image', file);
      }
      if (elem === 'additionalStats') {
        formData.append('additionalStats.health.value', newObj.additionalStats.health.value || 0);
        formData.append('additionalStats.health.type', newObj.additionalStats.health.type);
        formData.append('additionalStats.magicka.value', newObj.additionalStats.magicka.value || 0);
        formData.append('additionalStats.magicka.type', newObj.additionalStats.magicka.type);
      }
    });
    dispatch(createConsumable(formData, reset));
  };

  const preview = (event) => {
    const img = document.getElementById('frame');
    img.style.visibility = 'visible';
    img.src = URL.createObjectURL(event.target.files[0]);
    setFile(event.target.files[0]);
  };

  const downHandler = (event) => {
    if (event.code === 'Enter' && !isOpen) {
      setIsClick(true);
      handleSubmit(onSubmit)();
    }
  };

  const upHandler = (event) => {
    if (event.code === 'Enter' && !isOpen) {
      setIsClick(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', downHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [window, isOpen]);

  useEffect(() => {
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keyup', upHandler);
    };
  }, [window, isOpen]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper_cardinfo}>
      <div className={`${styles.cardinfo} ${styles.Common}`}>
        <div className={styles.corner_cardinfo_top_left} />
        <div className={styles.corner_cardinfo_top_left_part} />
        <div className={styles.corner_cardinfo_line_top_left} />
        <div className={styles.border_cardinfo_topline_left} />
        <div className={styles.border_cardinfo_top_left_centre} />
        <div className={styles.border_cardinfo_topline_left_centre} />
        <div className={styles.border_cardinfo_top_centre} />
        <div className={styles.border_cardinfo_topline_right_centre} />
        <div className={styles.border_cardinfo_top_right_centre} />
        <div className={styles.border_cardinfo_topline_right} />
        <div className={styles.border_cardinfo_leftline} />
        <div className={styles.corner_cardinfo_top_right} />
        <div className={styles.corner_cardinfo_line_bottom_left} />
        <div className={styles.corner_cardinfo_lineBottom_right} />
        <div className={styles.corner_part_left} />
        <div className={styles.corner_part_right} />
        <div className={styles.corner_part_centre} />
        <div className={styles.corner_part_centre_bottom} />
        <div className={styles.content_left}>
          <div className={styles.content_left_img}>
            <div className={styles.wrapper_pawn}>
              <div className={styles.pawn}>
                <div className={styles.pawn_img}>
                  {/* eslint-disable-next-line jsx-a11y/alt-text */}
                  <img id="frame" src="" alt="" style={{ visibility: 'hidden' }} />
                  <input
                    {...register('image', { required: true })}
                    id="upload-photo"
                    type="file"
                    name="img"
                    accept="image/*"
                    onChange={(e) => preview(e)}
                    className={styles.custom_file_input}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.content_left_pattern} />
        </div>
        <div className={styles.border_line_left} />
        <div className={styles.border_line_centre} />
        <div className={styles.border_line_right} />
        <div className={styles.border_lineIn_left} />
        <div className={styles.border_lineIn_right} />
        <div className={styles.content_right_cons}>
          <div className={styles.content_right_character}>
            <span>Rarity</span>
            <div className={styles.form}>
              <select
                {...register('tier', { required: true })}
              >
                <option value="" hidden>Select</option>
                <option value="Common">Common</option>
                <option value="Uncommon">Uncommon</option>
                <option value="Rare">Rare</option>
                <option value="Epic">Epic</option>
                <option value="Legendary">Legendary</option>
              </select>
            </div>
          </div>
          <div className={styles.content_right_character_img}>
            <div className={styles.content_name_img_cons}>
              <img src={itemType.Mage} alt="type" />
            </div>
          </div>
          <div className={styles.content_right_character}>
            <div className={styles.params_level}>
              <span className={styles.level}>Level</span>
              <input
                {...register('level', {
                  required: true,
                  valueAsNumber: true,
                  pattern: {
                    value: /^(0|[1-9]\d*)(\.\d+)?$/,
                  },
                })}
                type="number"
                min={1}
                max={100}
                placeholder=""
                value={watch('level')}
                onChange={(e) => setValue('level', e.target.value)}
                className={styles.params_input}
              />
            </div>
          </div>
          <div
            className={`${styles.content_right_pattern} ${styles.content_right_pattern_rotate}`}
          />
          <div className={styles.content_right_main_info}>
            <span className={styles.content_right_text}>str</span>
            <div className={styles.content_right_num}>
              <input
                type="number"
                placeholder=""
                // value={watch('str')}
                min={1}
                max={100}
                // onChange={(e) => setValue('str', e.target.value)}
                disabled
              />
            </div>
            <span className={styles.content_right_text}>dex</span>
            <div className={styles.content_right_num}>
              <input
                type="number"
                placeholder=""
                // value={watch('dex')}
                min={1}
                max={100}
                // onChange={(e) => setValue('dex', e.target.value)}
                disabled
              />
            </div>
            <span className={styles.content_right_text}>spr</span>
            <div className={styles.content_right_num}>
              <input
                type="number"
                placeholder=""
                // value={watch('spr')}
                min={1}
                max={100}
                // onChange={(e) => setValue('spr', e.target.value)}
                disabled
              />
            </div>
            <span className={styles.content_right_text}>con</span>
            <div className={styles.content_right_num}>
              <input
                type="number"
                placeholder=""
                // value={watch('con')}
                min={1}
                max={100}
                // onChange={(e) => setValue('con', e.target.value)}
                disabled
              />
            </div>

            <span className={styles.content_right_text}>int</span>
            <div className={styles.content_right_num}>
              <input
                type="number"
                placeholder=""
                // value={watch('int')}
                min={1}
                max={100}
                // onChange={(e) => setValue('int', e.target.value)}
                disabled
              />
            </div>

            <span className={styles.content_right_text}>cha</span>
            <div className={styles.content_right_num}>
              <input
                type="number"
                placeholder=""
                // value={watch('cha')}
                min={1}
                max={100}
                // onChange={(e) => setValue('cha', e.target.value)}
                disabled
              />
            </div>
          </div>

          <div className={styles.content_right_pattern} />

          <div className={styles.content_right_main}>
            {arrayParams.map((elem, i) => (
              <div className={styles.params} key={[i]}>
                <span>{elem}</span>
                <input
                  {...register(`additionalStats.${elem.toLowerCase()}.value`, {
                    valueAsNumber: true,
                  })}
                  type="number"
                  placeholder=""
                  value={watch(`additionalStats.${elem.toLowerCase()}.value`)}
                  step={0.01}
                  min={1}
                  max={1000}
                  onChange={(e) => setValue(`additionalStats.${elem.toLowerCase()}.value`, e.target.value)}
                />
                <select {...register(`additionalStats.${elem.toLowerCase()}.type`)}>
                  <option value="Value" hidden>Select</option>
                  <option value="Value">Value</option>
                  <option value="Percent">Percent</option>
                </select>
              </div>
            ))}
          </div>
          <div className={styles.content_right_pattern} />
        </div>
        <div className={styles.content_name}>
          <div className={styles.content_name_text}>
            <input
              {...register('Name', { required: true })}
              placeholder="enter name"
              type="text"
              value={watch('Name')}
              onChange={(e) => setValue('Name', e.target.value)}
            />
          </div>
        </div>
        <div className={styles.corner_cardinfo_bottom_left} />
        <div className={styles.border_cardinfo_bottomline_left} />
        <div className={styles.border_cardinfo_bottom_left_centre} />
        <div className={styles.border_cardinfo_bottomline_left_centre} />
        <div className={styles.border_cardinfo_bottom_centre} />
        <div className={styles.border_cardinfo_bottomline_right_centre} />
        <div className={styles.border_cardinfo_bottom_right_centre} />
        <div className={styles.border_cardinfo_bottomline_right} />
        <div className={styles.corner_cardinfo_bottom_right} />
      </div>
      <button
        className={styles.wrapper_button_match}
        type="submit"
        onMouseUp={() => setIsClick(false)}
        onMouseDown={() => setIsClick(true)}
        onKeyDown={() => downHandler()}
        onKeyUp={() => upHandler()}
      >
        <InfoButton
          text="Submit"
          isClick={isClick}
        />
      </button>
    </form>
  );
};

export default ConsumablesUtils;
