// eslint-disable-next-line max-classes-per-file
import Phaser from 'phaser';
import { ladderXY } from './const';

class Point {
  constructor(x, y, checkClimb) {
    this.x = x;
    this.y = y;
    this.checkClimb = checkClimb;
  }
}

class Ladder {
  constructor(position) {
    this.stairGap = 10;
    this.position = position;
    this.xMove = 3.5;
    this.yMove = 1.73;
    this.isFromLeftToRight = this.position[0].x < this.position[1].x;
  }

  playerMoving(player, cursors, walk, walkStop, key) {
    if ((cursors.right.isDown || cursors.left.isDown) && !(cursors.left.isDown && cursors.right.isDown)) {
      const xDif = cursors.right.isDown ? this.xMove : this.xMove * -1;
      let yDif;
      if (
        (this.isFromLeftToRight && cursors.right.isDown) || (!this.isFromLeftToRight && cursors.left.isDown)
      ) {
        yDif = this.yMove * -1;
      } else if (
        (this.isFromLeftToRight && cursors.left.isDown) || (!this.isFromLeftToRight && cursors.right.isDown)
      ) {
        yDif = this.yMove;
      }
      player.flipX = cursors.left.isDown;
      if (player.y + yDif < this.position[1].y) {
        player.setPosition(
          player.x + xDif,
          this.position[1].y,
        );
      } else if (player.y + yDif > this.position[0].y) {
        player.setPosition(
          player.x + xDif,
          this.position[0].y,
        );
      } else {
        player.setPosition(
          player.x + xDif,
          player.y + yDif,
        );
      }
      player.anims.play(`${key}anim-male-walk`, true);
      walk();
      if (player.y >= this.position[0].y || player.y <= this.position[1].y) {
        return false;
      }
    } else {
      player.anims.play(`${key}anim-male-idle`, true);
      walkStop();
    }
    return true;
  }

  // Check what cursor activate that stair
  getActiveCursorToClimb(y) {
    // return this.position[0].y === y ? 'up' : (this.position[1].y === y ? 'down' : false);
    if (this.position[0].y === y) {
      if (this.position[0].checkClimb) {
        return ['up', true];
      }
      return ['up', false];
    }
    if (this.position[1].y === y) {
      if (this.position[1].checkClimb) {
        return ['down', true];
      }
      return ['down', false];
    }
    return [false, false];
  }

  checkStairForCompetitor(x, y) {
    const point = new Phaser.Geom.Point(x, y);
    const line = new Phaser.Geom.Line(this.position[0].x, this.position[0].y, this.position[1].x, this.position[1].y);
    return Phaser.Geom.Intersects.PointToLine(point, line, 5);
  }

  checkStair(x, y, cursors, flying) {
    const nearStair = (this.position[0].y === y && Math.abs(this.position[0].x - x) < this.stairGap)
      || (this.position[1].y === y && Math.abs(this.position[1].x - x) < this.stairGap);
    if (flying) {
      const point = new Phaser.Geom.Point(x, y);
      const line = new Phaser.Geom.Line(this.position[0].x, this.position[0].y, this.position[1].x, this.position[1].y);
      return Phaser.Geom.Intersects.PointToLine(point, line, 5);
    }
    if (nearStair) {
      const [needleCursor, needCursorCheck] = this.getActiveCursorToClimb(y);
      if (needleCursor && ((needCursorCheck && cursors[needleCursor].isDown) || !needCursorCheck)) {
        // near stair and active cursor is down
        if (
          (needleCursor === 'up' && this.isFromLeftToRight && cursors.right.isDown)
          || (needleCursor === 'up' && !this.isFromLeftToRight && cursors.left.isDown)
          || (needleCursor === 'down' && this.isFromLeftToRight && cursors.left.isDown)
          || (needleCursor === 'down' && !this.isFromLeftToRight && cursors.right.isDown)
        ) {
          return true;
        }
      }
    }
    return false;
  }

  static createLadders() {
    return ladderXY.map((i) => (
      new Ladder([
        new Point(i[0], i[1], i[2]),
        new Point(i[3], i[4], i[5]),
      ])
    ));
  }

  static checkNearStairs(x, y, ladders, cursors, flying) {
    return ladders.find((l) => l.checkStair(x, y, cursors, flying));
  }

  static checkNearStairsForCompetitor(x, y, ladders) {
    return ladders.find((l) => l.checkStairForCompetitor(x, y));
  }
}

export default Ladder;
