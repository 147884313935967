import React, {
  useEffect, useLayoutEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Pawns from '../../../static/img/equipment/sidebar-left/Pawns.png';
import PawnsActive from '../../../static/img/equipment/sidebar-left/Pawns active.png';
import Gear from '../../../static/img/equipment/sidebar-left/Gear.png';
import GearActive from '../../../static/img/equipment/sidebar-left/Gear active.png';
import Spells from '../../../static/img/equipment/sidebar-left/Spells.png';
import SpellsActive from '../../../static/img/equipment/sidebar-left/Spells active.png';
import Items from '../../../static/img/equipment/sidebar-left/Items.png';
import ItemsActive from '../../../static/img/equipment/sidebar-left/Items active.png';
import {
  getPage,
  // getNewInventoryForEquipment,
  getPawnsSellForEquipment,
  selectPawnsForEquipment,
} from '../../../store/slices/requests';
import { selectPawn } from '../../../store/slices/pawnSelect';
import {
  pawnAddEquipmentToPawnPreset,
  savePawnsToGuilds, selectEquipmentPawnId, selectEquipmentType,
  selectGuilds,
  selectPawnEquipment,
  selectPawnItems,
  selectPawnSpells, setEquipmentType,
} from '../../../store/slices/preset';
import { clearSetup } from '../../../store/slices/crafting';
import PawnSpells from './PawnSpells';
import PawnItems from './PawnItems';
import PawnInventory from './PawnInventory';
import {
  filterType,
  gearStatus, handleTab, itemsStatus, pawnsStatus, spellsStatus, tierType, typeFunc,
} from '../../../utils/chooseData';
import PawnEquipments from './PawnEquipments';
import { selectEvm } from '../../../store/slices/auth';
import styles from './Inventory.module.scss';

const Inventory = ({
  data, selectedPawn, setSelectedPawn, setIsChanged, count = 1, filter, search, tier,
  filterByClass, filterByTier, filterBySlot, filterByApplication, filterByType, filterByClassForSpell,
  filterByTierForItems,
}) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [pawns, setPawns] = useState('');
  const [gear, setGear] = useState('');
  const [spells, setSpells] = useState('');
  const [items, setItems] = useState('');
  const [isActive, setIsActive] = useState(pathname.includes('crafting') ? 'Equipment' : 'Pawns');

  const [isClickLeft, setIsClickLeft] = useState(false);
  const [isClickRight, setIsClickRight] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);
  const [page, setPage] = useState(1);

  const address = useSelector(selectEvm);
  const pawn = useSelector(selectPawn);
  const pawnGuildItems = useSelector(selectPawnItems);
  const pawnGuildEquipment = useSelector(selectPawnEquipment);
  const pawnGuildSpells = useSelector(selectPawnSpells);
  const guild = useSelector(selectGuilds);
  const pawnInventory = useSelector(selectPawnsForEquipment);
  const pawnIdEquipment = useSelector(selectEquipmentPawnId);
  const equipmentType = useSelector(selectEquipmentType);

  const handlePawns = (info, vs) => {
    const filterArray = Array.isArray(guild[typeFunc(vs)])
      ? guild[typeFunc(vs)].map((item) => item.pawn.nftId).includes(info.nftId)
      : null;
    const arrayCount = Array.isArray(guild[typeFunc(vs)]) ? guild[typeFunc(vs)].length : 0;
    if (!filterArray && arrayCount < vs) {
      const [pawnDataObj] = pawnInventory.filter((item) => item.nftId === info.nftId).map((part) => ({
        nftId: part.nftId,
        name: part.name,
        class: part.class,
      }));
      dispatch(savePawnsToGuilds({
        vs: typeFunc(vs),
        pawn: pawnDataObj,
      }));
      if (selectedPawn === pawnIdEquipment) {
        dispatch(pawnAddEquipmentToPawnPreset({
          consumables: pawnGuildItems,
          spells: pawnGuildSpells,
          id: pawnIdEquipment,
          vs: typeFunc(vs),
        }));
      }
      if (vs === 1) {
        setIsChanged(true);
      }
      if ((guild[typeFunc(vs)] ? !guild[typeFunc(vs)].map((elem) => elem.pawn.nftId).includes(info.nftId) : false)
        && vs !== 1) {
        setIsChanged(true);
      }
    }
  };

  const onInventoryPageInc = () => {
    if (Math.ceil(count / 9) > page) {
      setPage(page + 1);
    }
  };

  const onInventoryPageDec = () => {
    if (page - 1 > 0) {
      setPage(page - 1);
    }
  };

  useLayoutEffect(() => {
    if (pathname.includes('crafting')) {
      dispatch(setEquipmentType('Equipment'));
    }
  }, []);

  useEffect(() => {
    if (pathname.includes('crafting')) return;
    dispatch(getPawnsSellForEquipment({
      limit: 12,
      address,
      sort_by: 'Default',
      offset: 0,
    }));
    dispatch(getPage(2));
  }, []);

  useEffect(() => {
    if (width <= 880) {
      dispatch(getPawnsSellForEquipment({
        limit: 9,
        address,
        sort_by: 'Default',
        offset: (page - 1) * 9,
        name: search,
        tier: tierType[tier] || '',
        class: filterType[filter] ? filterType[filter] : '',
      }));
    }
  }, [page, search, tier, filter]);

  useEffect(() => {
    const onResize = (e) => {
      setWidth(e.target.innerWidth);
    };
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const content = useMemo(() => {
    if (equipmentType === 'Pawns') {
      return (
        <PawnInventory
          setSelectedPawn={setSelectedPawn}
          selectedPawn={selectedPawn}
          handlePawns={handlePawns}
          filter={filter}
          search={search}
          tier={tier}
        />
      );
    }
    if (equipmentType === 'Spells') {
      return (
        <PawnSpells
          pawn={pawn}
          selectedPawn={selectedPawn}
          pawnGuildSpells={pawnGuildSpells}
          filterByApplication={filterByApplication}
          filterByType={filterByType}
          filterByClassForSpell={filterByClassForSpell}
        />
      );
    }
    if (equipmentType === 'Items') {
      return (
        <PawnItems
          pawn={pawn}
          selectedPawn={selectedPawn}
          pawnGuildItems={pawnGuildItems}
          filterByTierForItems={filterByTierForItems}
        />
      );
    }
    if (equipmentType === 'Equipment') {
      return (
        <PawnEquipments
          pawn={pawn}
          selectedPawn={selectedPawn}
          pawnGuildEquipment={pawnGuildEquipment}
          filterByClass={filterByClass}
          filterByTier={filterByTier}
          filterBySlot={filterBySlot}
        />
      );
    }
    return null;
  }, [
    filter, tier, search,
    data,
    handlePawns,
    pawnGuildItems,
    filterByApplication, filterByType, filterByClassForSpell,
    pawnGuildSpells, filterByTierForItems,
    equipmentType, filterByClass, filterByTier, filterBySlot,
  ]);

  return (
    <div className={`${styles.wrapper} ${pathname.includes('crafting') ? styles.wrapper_crafting : ''} `}>
      <div className={styles.buttons}>
        {width < 880 && (
        <div
          className={`${styles.left_arrow} ${isClickLeft && styles.active}`}
          role="presentation"
          onMouseDown={() => {
            setIsClickLeft(true);
            if (page > 1) {
              onInventoryPageDec();
            }
          }}
          onMouseUp={() => setIsClickLeft(false)}
          onMouseLeave={() => setIsClickLeft(false)}
        />
        )}
        <div className={styles.wrapper_buttons}>
          <button
            type="button"
            className={pathname.includes('crafting') ? styles.disabled : ''}
            disabled={pathname.includes('crafting')}
            onMouseEnter={() => (isActive !== 'Pawns' ? setPawns('hover') : setPawns('pressed'))}
            onMouseDown={() => setPawns('pressed')}
            onMouseLeave={() => setPawns('')}
            onClick={() => {
              setIsActive('Pawns');
              dispatch(setEquipmentType('Pawns'));
            }}
          >
            <img src={pawns ? pawnsStatus[pawns] : isActive === 'Pawns' ? PawnsActive : Pawns} alt="pawn" />
          </button>
          <button
            type="button"
            onMouseEnter={() => (isActive !== 'Equipment' ? setGear('hover') : setGear('pressed'))}
            onMouseDown={() => setGear('pressed')}
            onMouseLeave={() => setGear('')}
            onClick={() => {
              setIsActive('Equipment');
              dispatch(setEquipmentType('Equipment'));
              if (pathname.includes('crafting')) {
                dispatch(clearSetup());
              }
            }}
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            <img src={gear ? gearStatus[gear] : isActive === 'Equipment' ? GearActive : Gear} alt="gear" />
          </button>
          <button
            type="button"
            className={pathname.includes('crafting') ? styles.disabled : ''}
            disabled={pathname.includes('crafting')}
            onMouseEnter={() => (isActive !== 'Spells' ? setSpells('hover') : setSpells('pressed'))}
            onMouseDown={() => setSpells('pressed')}
            onMouseLeave={() => setSpells('')}
            onClick={() => {
              setIsActive('Spells');
              dispatch(setEquipmentType('Spells'));
            }}
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            <img src={spells ? spellsStatus[spells] : isActive === 'Spells' ? SpellsActive : Spells} alt="spell" />
          </button>
          <button
            type="button"
            onMouseEnter={() => (isActive !== 'Items' ? setItems('hover') : setItems('pressed'))}
            onMouseDown={() => setItems('pressed')}
            onMouseLeave={() => setItems('')}
            onClick={() => {
              setIsActive('Items');
              dispatch(setEquipmentType('Items'));
              if (pathname.includes('crafting')) {
                dispatch(clearSetup());
              }
            }}
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            <img src={items ? itemsStatus[items] : isActive === 'Items' ? ItemsActive : Items} alt="item" />
          </button>
        </div>
        {width < 880 && (
        <div
          className={`${styles.right_arrow} ${isClickRight && styles.active}`}
          role="presentation"
          onMouseDown={() => {
            setIsClickRight(true);
            onInventoryPageInc();
          }}
          onMouseUp={() => setIsClickRight(false)}
          onMouseLeave={() => setIsClickRight(false)}
        />
        )}
      </div>
      <div className={`
        ${styles.wrapper_inventory}
        ${pathname.includes('crafting') ? styles.crafting : ''}
        ${styles[handleTab[isActive]]}`}
      >
        {content}
      </div>
      {width < 880 && (
      <div className={styles.page}>
        {page}
        /
        {count ? Math.ceil(count / 9) : ''}
      </div>
      )}
    </div>
  );
};

export default Inventory;
