export const convertToReadableFormat = (number) => {
  if (!number && number !== 0) return '-';
  const numberLength = String(number.toFixed(0)).length;
  if (numberLength > 12) return `${(number / 1000000000000).toFixed(1)}T`;
  if (numberLength > 9) return `${(number / 1000000000).toFixed(1)}B`;
  if (numberLength > 6) return `${(number / 1000000).toFixed(1)}M`;
  if (numberLength > 3) return `${(number / 1000).toFixed(1)}K`;
  if (numberLength > 1) return +number.toFixed(2);
  return +number.toFixed(3);
};

export const convertFormat = (num) => {
  if (!num && num !== 0) return '-';
  if (num > 1000) return convertToReadableFormat(num);
  if (num >= 100) return +num.toFixed(2);
  if (num >= 99.99) return 99.99;
  if (num > 1) return +num.toFixed(2);
  if (num > 0.01) return +num.toFixed(3);
  if (num > 0.0001) return +num.toFixed(4);
  return '0...';
};

export const dividerCommaFormat = (number) => number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');

export const preventInvalidSymbols = (e) => {
  if (!(/^\d*\.?\d*$/.test(e.key))) {
    e.preventDefault();
  }
};
