import Phaser from 'phaser';

class MapScene extends Phaser.Scene {
  constructor(config) {
    super(config);
    this.navigate = config.navigate;
    this.setMapSceneCreated = config.setMapSceneCreated;
    this.mapItems = {
      'Water+Fireworks-{i}.gif.png': {
        iCount: 2,
        frames: 7,
        lastFrames: 6,
        frameWidth: 922,
        frameHeight: 556,
        instances: [],
        depth: 10,
      },
      'Ships-{i}.gif.png': {
        iCount: 20,
        frames: 12,
        lastFrames: 1,
        frameWidth: 922,
        frameHeight: 556,
        instances: [],
        depth: 20,
      },
      'Birds-{i}.gif.png': {
        iCount: 19,
        frames: 12,
        lastFrames: 7,
        frameWidth: 922,
        frameHeight: 556,
        instances: [],
        depth: 40,
      },
      'Clouds-{i}.gif.png': {
        iCount: 25,
        frames: 12,
        lastFrames: 7,
        frameWidth: 922,
        frameHeight: 556,
        instances: [],
        depth: 50,
      },
    };
  }

  showPreloader() {
    const progressBar = this.add.graphics();
    const progressBox = this.add.graphics();
    const { width } = this.cameras.main;
    const { height } = this.cameras.main;

    progressBox.fillStyle(0x222222, 0.8);
    // progressBox.fillRect(240, 270, 320, 50);
    progressBox.fillRect(width / 2 - 160, height / 2 - 170, 320, 50);

    const loadingText = this.make.text({
      x: width / 2,
      y: height / 2 - 50,
      text: 'Loading',
      style: {
        font: '20px monospace',
        fill: '#ffffff',
      },
    });
    loadingText.setOrigin(0.5, 0.5);

    const percentText = this.make.text({
      x: width / 2,
      y: height / 2 - 5,
      text: '0%',
      style: {
        font: '18px monospace',
        fill: '#ffffff',
      },
    });
    percentText.setOrigin(0.5, 0.5);

    const assetText = this.make.text({
      x: width / 2,
      y: height / 2 + 50,
      text: '',
      style: {
        font: '18px monospace',
        fill: '#ffffff',
      },
    });
    assetText.setOrigin(0.5, 0.5);

    this.load.on('progress', (value) => {
      percentText.setText(`${parseInt(value * 100, 10)}%`);
      progressBar.clear();
      progressBar.fillStyle(0xffffff, 1);
      // progressBar.fillRect(250, 280, 300 * value, 30);
      progressBar.fillRect(width / 2 - 150, height / 2 - 160, 300 * value, 30);
    });

    this.load.on('fileprogress', (file) => {
      assetText.setText(`Loading asset: ${file.key}`);
    });
    this.load.on('complete', () => {
      progressBar.destroy();
      progressBox.destroy();
      loadingText.destroy();
      percentText.destroy();
      assetText.destroy();
    });
  }

  preload() {
    this.showPreloader();

    this.load.image('background', '/assets/social/map/Map BG.png');
    Object.keys(this.mapItems).forEach(
      (itemKey) => {
        const n = this.mapItems[itemKey].iCount;
        [...Array(n).fill(0).map((value, index) => index + 1)].forEach(
          (value) => {
            const phaserKey = itemKey.replace('{i}', value.toString());
            this.load.spritesheet(
              phaserKey,
              `/assets/social/map/${phaserKey}`,
              { frameWidth: this.mapItems[itemKey].frameWidth, frameHeight: this.mapItems[itemKey].frameHeight },
            );
          },
        );
      },
    );

    this.load.image('titlebg', 'assets/social/map/map nn-export.png');
    this.load.image('titlebg-hover', 'assets/social/map/map nl-export.png');
    this.load.image('titlebg-inactive', 'assets/social/map/map nd-export.png');

    // tavern scene preload----------
    this.load.audio('steps', 'assets/social/steps2.mp3');
    this.load.audio('jumps', 'assets/social/jumps2.mp3');
    this.load.audio('touchdown', 'assets/social/touchdown.mp3');
    this.load.image('bgtavern', 'assets/social/taverna.png');
    this.load.image('ground', 'assets/social/ground.png');
    this.load.image('backgroundMessage', 'assets/social/background.png');

    this.load.multiatlas(
      'Tavern-Animations-1',
      '/assets/social/sprites/Tavern-Animations-1.json',
      '/assets/social/sprites/',
    );
    // tavern scene preload end-------
  }

  createMap() {
    this.bgImage = this.add.image(0, 0, 'background').setDisplayOrigin(0, 0).setScale(3, 3);

    Object.keys(this.mapItems).forEach(
      (itemKey) => {
        const n = this.mapItems[itemKey].iCount;
        [...Array(n).fill(0).map((value, index) => index + 1)].forEach(
          (value) => {
            const phaserKey = itemKey.replace('{i}', value.toString());
            const start = 0;
            const end = (value === n ? this.mapItems[itemKey].lastFrames : this.mapItems[itemKey].frames) - 1;
            this.mapItems[itemKey].instances.push(
              this.add.sprite(0, 0, phaserKey)
                .setOrigin(0, 0)
                .setScale(3)
                .setVisible(false)
                .setDepth(this.mapItems[itemKey].depth),
            );
            this.anims.create({
              key: `anim-${phaserKey}`,
              frames: this.anims.generateFrameNumbers(phaserKey, { start, end }),
              frameRate: 10,
              repeat: 0,
            });
          },
        );
      },
    );

    Object.keys(this.mapItems).forEach(
      (itemKey) => {
        // const n = this.mapItems[itemKey].iCount;
        this.mapItems[itemKey].instances.forEach(
          (el, index) => {
            const phaserKey = itemKey.replace('{i}', (index + 2).toString());
            this.mapItems[itemKey].instances[index].on('animationcomplete', () => {
              this.mapItems[itemKey].instances[index].setVisible(false);
              if (this.mapItems[itemKey].instances[index + 1]) {
                this.mapItems[itemKey].instances[index + 1].setVisible(true);
                this.mapItems[itemKey].instances[index + 1].play(`anim-${phaserKey}`);
              } else {
                this.mapItems[itemKey].instances[0].setVisible(true);
                this.mapItems[itemKey].instances[0].play(
                  `anim-${itemKey.replace('{i}', '1')}`,
                );
              }
            });
          },
        );
      },
    );

    Object.keys(this.mapItems).forEach(
      (itemKey) => {
        // const n = this.mapItems[itemKey].iCount;
        const phaserKey = itemKey.replace('{i}', (1).toString());
        const el = this.mapItems[itemKey].instances[0];
        el.setVisible(true);
        el.play(`anim-${phaserKey}`);
      },
    );
  }

  create() {
    this.createMap();

    const cursors = this.input.keyboard.createCursorKeys();

    this.controls = new Phaser.Cameras.Controls.SmoothedKeyControl({
      camera: this.cameras.main,
      left: cursors.left,
      right: cursors.right,
      up: cursors.up,
      down: cursors.down,
      zoomIn: this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.Q),
      zoomOut: this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.E),
      acceleration: 0.06,
      drag: 0.0005,
      maxSpeed: 1.0,
    });
    const cam = this.cameras.main;
    cam.setBounds(0, 0, 2766, 1668);
    cam.centerOn(1383, 834);
    // const gui = new dat.GUI();
    //
    // const help = {
    //   line1: 'Cursors to move',
    //   line2: 'Q & E to zoom',
    // };

    // const f1 = gui.addFolder('Camera');
    // f1.add(cam, 'x').listen();
    // f1.add(cam, 'y').listen();
    // f1.add(cam, 'scrollX').listen();
    // f1.add(cam, 'scrollY').listen();
    // f1.add(cam, 'rotation').min(0).step(0.01).listen();
    // f1.add(cam, 'zoom', 0.1, 2).step(0.1).listen();
    // f1.add(help, 'line1');
    // f1.add(help, 'line2');
    // f1.open();

    // create places
    this.labels = {};

    [
      {
        x: 1555, y: 1030, l: 'Tavern', scene: 'tavern-scene', // tavern
      },
      {
        x: 1420, y: 590, l: 'Marketplace', scene: null, // marketplace
      },
      {
        x: 730, y: 220, l: 'Temple', scene: null, // temple
      },
      {
        x: 2380, y: 490, l: 'Tournament', scene: null, // tournament
      },
    ].forEach(
      (c) => {
        this.labels[c.l] = { bg: null, bgHover: null, text: null };
        this.labels[c.l].bg = c.scene
          ? this.add.image(c.x, c.y - 30, 'titlebg').setOrigin(0.5, 0.5).setDepth(30).setAlpha(0.6)
          : this.add.image(c.x, c.y - 30, 'titlebg-inactive').setOrigin(0.5, 0.5).setDepth(30).setAlpha(0.6);
        this.labels[c.l].bgHover = this.add.image(c.x, c.y - 30, 'titlebg-hover')
          .setOrigin(0.5, 0.5).setDepth(30).setVisible(false);
        this.labels[c.l].text = this.add.text(
          c.x,
          c.y - 40,
          c.l,
          {
            font: '42px alagard',
            fill: '#573229',
            align: 'center',
          },
        ).setOrigin(0.5, 0.5).setDepth(31).setAlpha(0.6);
        if (c.scene) {
          this.labels[c.l].bg.setAlpha(1);
          this.labels[c.l].text.setAlpha(1);
        }
        this.add
          .circle(c.x, c.y, 140)
          // .setStrokeStyle(10, 0x1a65ac)
          .setInteractive({
            cursor: 'pointer',
            // hitArea: (new Phaser.Geom.Rectangle(config.x, config.y, 50, 200)),
            // hitAreaCallback: Pawn.hitAreaHandler,
          })
          .setDepth(100)
          .on('pointerdown', this.onClickPlace.bind({ scene: this, config: c }))
          .on('pointerover', () => {
            if (c.scene) {
              this.labels[c.l].bgHover.setVisible(true);
              this.labels[c.l].bg.setVisible(false);
            }
          })
          .on('pointerout', () => {
            if (c.scene) {
              this.labels[c.l].bgHover.setVisible(false);
              this.labels[c.l].bg.setVisible(true);
            }
          });
      },
    );

    this.input.on('pointermove', (p) => {
      if (!p.isDown) return;

      cam.scrollX -= (p.x - p.prevPosition.x) / cam.zoom;
      cam.scrollY -= (p.y - p.prevPosition.y) / cam.zoom;
    });

    this.input.on('wheel', (pointer, gameObjects, deltaX, deltaY) => {
      if (deltaY > 0) {
        const newZoom = cam.zoom - 0.01;
        if (window.innerWidth <= 2766 * newZoom && window.innerHeight <= newZoom * 1668) {
          cam.zoom = newZoom;
        } else {
          cam.zoom = Math.max(window.innerWidth / 2766, window.innerHeight / 1668);
        }
      }

      if (deltaY < 0) {
        const newZoom = cam.zoom + 0.01;
        if (newZoom < 1.3) {
          cam.zoom = newZoom;
        }
      }

      // this.camera.centerOn(pointer.worldX, pointer.worldY);
      // this.camera.pan(pointer.worldX, pointer.worldY, 2000, "Power2");
    });

    this.setMapSceneCreated(true);
  }

  // this from bind
  onClickPlace() {
    console.log('CLICK ON PLACE', this);
    // this.scene.game.scene.start(this.config.scene);
    // this.scene.game.scene.stop(this.scene.scene.key);
    if (this.config.scene) {
      this.scene.game.scene.sleep(this.scene.scene.key).run(this.config.scene);
    }
    // this.scene.selectPawn(this);
  }

  handleKey(isOpen) {
    if (isOpen) {
      this.input.keyboard.disableGlobalCapture();
      this.input.keyboard.enabled = false;
      this.input.mouse.stopListeners();
      this.input.mouse.enabled = false;
    } else {
      this.input.keyboard.enableGlobalCapture();
      this.input.keyboard.enabled = true;
      this.input.mouse.startListeners();
      this.input.mouse.enabled = true;
    }
  }

  // update(time: number, deltaTime: number)
  update(time, deltaTime) {
    this.controls?.update(deltaTime);
  }
}

export default MapScene;
