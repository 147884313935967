import { createSlice } from '@reduxjs/toolkit';
import { getItem, getStats, patchPawnStat } from '../../../utils/requests';
import { notificationShowAction } from '../utils';
// eslint-disable-next-line import/no-cycle
import { addPointsToStat } from '../preset';
import { updatePointsMyPawns } from '../requests';

export const pawnSelect = createSlice({
  name: 'pawnSelect',
  initialState: {
    statsLoading: false,
    itemLoading: false,
    pawn: {},
    defaultStats: {},
    allPawnStats: {},
  },
  reducers: {
    getPawnRequested: (state) => ({
      ...state,
      itemLoading: true,
    }),
    getStatsRequested: (state) => ({
      ...state,
      statsLoading: true,
    }),
    getPawnFailure: (state, { payload }) => ({
      ...state,
      itemLoading: false,
      error: payload,
    }),
    getStatsFailure: (state, { payload }) => ({
      ...state,
      statsLoading: false,
      error: payload,
    }),
    addStatFailure: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
    getPawnSuccess: (state, { payload }) => ({
      ...state,
      itemLoading: false,
      error: false,
      pawn: Object.keys(payload).length !== 0 ? payload : {},
      defaultStats: Object.keys(payload).length !== 0 ? payload.stats : {},
    }),
    getStatsSuccess: (state, { payload }) => ({
      ...state,
      statsLoading: false,
      error: false,
      allPawnStats: payload,
    }),
    addStatSuccess: (state, { payload }) => {
      const pawnStat = JSON.parse(JSON.stringify({ ...state.pawn.stats }));
      return ({
        ...state,
        error: false,
        pawn: {
          ...state.pawn,
          stats: {
            ...state.pawn.stats,
            [payload]: pawnStat[payload] + 1,
          },
        },
        defaultStats: {
          ...state.stats,
          [payload]: pawnStat[payload] + 1,
        },
      });
    },
    updateStats: (state, { payload }) => {
      const pawnStat = JSON.parse(JSON.stringify({ ...state.pawn.stats }));
      if (payload) {
        Object.keys(pawnStat).forEach((elem) => {
          Object.keys(payload).forEach((item) => {
            if (elem === item) {
              pawnStat[elem] += payload[item];
            }
          });
        });
      }
      return ({
        ...state,
        pawn: {
          ...state.pawn,
          stats: pawnStat,
        },
      });
    },
    removeSkills: (state, { payload }) => {
      if (!Object.keys({ ...state?.pawn }).length) {
        return ({
          ...state,
        });
      }
      const pawnStat = JSON.parse(JSON.stringify({ ...state.pawn.stats }));
      if (payload) {
        Object.keys(pawnStat).forEach((elem) => {
          Object.keys(payload).forEach((item) => {
            if (elem === item) {
              pawnStat[elem] -= payload[item];
            }
          });
        });
      }
      return ({
        ...state,
        pawn: {
          ...state.pawn,
          stats: pawnStat,
        },
      });
    },
    clearPawn: (state) => ({
      ...state,
      pawn: {},
      defaultStats: {},
    }),
    clearStats: (state) => ({
      ...state,
      allPawnStats: {},
    }),
  },
});

// ---- actions ----
export const {
  getPawnSuccess, getPawnRequested, getPawnFailure, clearPawn,
  addStatFailure, addStatSuccess, updateStats, removeSkills,
  getStatsRequested, getStatsFailure, getStatsSuccess, clearStats,
} = pawnSelect.actions;

// ---- request actions ----
export const getPawn = (payload) => async (dispatch) => {
  try {
    dispatch(getPawnRequested());
    const response = await getItem(payload);
    if (response.status >= 400) {
      dispatch(getPawnFailure(response.data.message));
    } else if (response.status <= 204) {
      dispatch(getPawnSuccess(response.data));
    }
  } catch (e) {
    dispatch(getPawnFailure(e.message));
  }
};

export const getPawnStats = (payload) => async (dispatch) => {
  try {
    dispatch(getStatsRequested());
    const response = await getStats(payload);
    if (response.status >= 400) {
      dispatch(getStatsFailure(response.data.message));
    } else if (response.status <= 204) {
      dispatch(getStatsSuccess(response.data));
    }
  } catch (e) {
    dispatch(getStatsFailure(e.message));
  }
};

export const updatePawnStats = (payload) => async (dispatch) => {
  try {
    const response = await patchPawnStat(payload);
    if (response.status >= 400) {
      dispatch(addStatFailure(response?.erros));
      dispatch(notificationShowAction(false, response?.detail || 'Something went wrong'));
    } else if (response.status <= 204) {
      dispatch(addStatSuccess(payload.stat));
      dispatch(addPointsToStat());
      dispatch(updatePointsMyPawns(payload.id));
    }
  } catch (e) {
    dispatch(addStatFailure(e.message));
  }
};

// ---- selectors ----
export const selectPawn = (state) => (state.pawnSelect.pawn);
export const selectPawnLoading = (state) => (state.pawnSelect.itemLoading);
export const selectDefaultStats = (state) => (state.pawnSelect.defaultStats);
export const selectStatsLoading = (state) => (state.pawnSelect.statsLoading);
export const selectAllPawnStats = (state) => (state.pawnSelect.allPawnStats);
