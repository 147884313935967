import React from 'react';
import NavButton from '../NavButton/index';
import styles from './DropDownItem.module.scss';

const DropDownItem = ({ text, redirect }) => (
  <div className={styles.dropdown_wrapper}>
    <div className={styles.dropdown_top} />
    {text.map((item, index) => (
      <div
        role="presentation"
        className={styles.dropdown_wrapper_item}
        key={[index]}
        onClick={() => redirect(item)}
      >
        <div className={styles.dropdown_left} />
        <div className={styles.dropdown_content}>
          <NavButton text={item} />
        </div>
        <div className={styles.dropdown_right} />
      </div>
    ))}
    <div className={styles.dropdown_bottom} />
  </div>
);

export default DropDownItem;
