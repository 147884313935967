import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectTurn } from '../../../../store/slices/arena';

const Timer = () => {
  const [timer, setTimer] = useState(179);

  const turn = useSelector(selectTurn);

  useEffect(() => {
    const timerOut = setInterval(() => {
      setTimer((prev) => (prev ? timer - 1 : 0));
    }, 1000);
    return () => clearInterval(timerOut);
  }, [timer]);

  useEffect(() => {
    setTimer(179);
  }, [turn]);

  return timer;
};

export default Timer;
