import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import InfoButton from '../../../components/Marketplace/Components/Presale/InfoButton';
// import DropdownFilter from '../../../components/Marketplace/Components/Pawns/Filters/DropdownFilter';
import Search from '../../../components/Marketplace/Components/Presale/Search';
import DropdownFilter from '../../../components/Marketplace/Components/Pawns/Filters/DropdownFilter';
import { selectEquipmentType } from '../../../store/slices/preset';
import styles from '../../../components/Marketplace/Components/Pawns/Filters/Filters.module.scss';

const Filters = ({
  start, filter, setFilter, setTier, tier,
  search, setSearch, filterByClass, filterByTier, filterBySlot,
  setFilterBySlot, setFilterByClass, setFilterByTier, setFilterByApplication, filterByApplication,
  filterByTierForItems, setFilterByTierForItems, setFilterByType, filterByType,
  filterByClassForSpell, setFilterByClassForSpell,
}) => {
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isOpenClass, setIsOpenClass] = useState(false);
  const [isOpenTier, setIsOpenTier] = useState(false);
  const [isOpenSlot, setIsOpenSlot] = useState(false);
  const [isOpenApplication, setIsOpenApplication] = useState(false);
  const [isOpenType, setIsOpenType] = useState(false);

  const equipmentType = useSelector(selectEquipmentType);

  const filterContent = useMemo(() => {
    if (equipmentType === 'Equipment') {
      return (
        <>
          <div className={styles.wrapper_filter} onMouseLeave={() => setIsOpenClass(false)}>
            <div
              role="presentation"
              onClick={() => setIsOpenClass(!isOpenClass)}
            >
              <InfoButton text={filterByClass} />
            </div>
            {isOpenClass && (
            <DropdownFilter
              text={['Class', 'Mage', 'Warrior', 'Archer']}
              filter={filterByClass}
              setFilter={setFilterByClass}
              setIsOpenFilter={setIsOpenClass}
            />
            )}
          </div>
          <div className={styles.wrapper_filter} onMouseLeave={() => setIsOpenTier(false)}>
            <div
              role="presentation"
              onClick={() => setIsOpenTier(!isOpenTier)}
            >
              <InfoButton text={filterByTier} />
            </div>
            {isOpenTier && (
            <DropdownFilter
              text={['Tier', 'Common', 'Uncommon', 'Rare', 'Epic', 'Legendary']}
              filter={filterByTier}
              setFilter={setFilterByTier}
              setIsOpenFilter={setIsOpenTier}
            />
            )}
          </div>
          <div className={styles.wrapper_filter} onMouseLeave={() => setIsOpenSlot(false)}>
            <div
              role="presentation"
              onClick={() => setIsOpenSlot(!isOpenSlot)}
            >
              <InfoButton text={filterBySlot} />
            </div>
            {isOpenSlot && (
            <DropdownFilter
              text={['Slot', 'Helmet', 'Chest', 'Boots', 'Weapon', 'Shield', 'Ring']}
              filter={filterBySlot}
              setFilter={setFilterBySlot}
              setIsOpenFilter={setIsOpenSlot}
            />
            )}
          </div>
        </>
      );
    }
    if (equipmentType === 'Pawns') {
      return (
        <>
          <div className={styles.wrapper_filter} onMouseLeave={() => setIsOpenFilter(false)}>
            <div
              role="presentation"
              onClick={() => setIsOpenFilter(!isOpenFilter)}
            >
              <InfoButton text={filter} />
            </div>
            {isOpenFilter && (
            <DropdownFilter
              text={['Filter', 'Mage', 'Warrior', 'Archer']}
              filter={filter}
              tier={tier}
              setFilter={setFilter}
              setTier={setTier}
              setIsOpenFilter={setIsOpenFilter}
            />
            )}
          </div>
          <Search search={search} setSearch={setSearch} />
        </>
      );
    }
    if (equipmentType === 'Spells') {
      return (
        <>
          <div className={styles.wrapper_filter} onMouseLeave={() => setIsOpenClass(false)}>
            <div
              role="presentation"
              onClick={() => setIsOpenClass(!isOpenClass)}
            >
              <InfoButton text={filterByClassForSpell} />
            </div>
            {isOpenClass && (
            <DropdownFilter
              text={['Class', 'Mage', 'Warrior', 'Archer']}
              filter={filterByClassForSpell}
              setFilter={setFilterByClassForSpell}
              setIsOpenFilter={setIsOpenClass}
            />
            )}
          </div>
          <div className={styles.wrapper_filter} onMouseLeave={() => setIsOpenType(false)}>
            <div
              role="presentation"
              onClick={() => setIsOpenType(!isOpenType)}
            >
              <InfoButton text={filterByType} />
            </div>
            {isOpenType && (
            <DropdownFilter
              text={['Type', 'Primary', 'Secondary']}
              filter={filterByType}
              setFilter={setFilterByType}
              setIsOpenFilter={setIsOpenType}
            />
            )}
          </div>
          <div className={styles.wrapper_filter} onMouseLeave={() => setIsOpenApplication(false)}>
            <div
              role="presentation"
              onClick={() => setIsOpenApplication(!isOpenApplication)}
            >
              <InfoButton text={filterByApplication} />
            </div>
            {isOpenApplication && (
            <DropdownFilter
              text={['Application', 'Immediately', 'End opponent']}
              filter={filterByApplication}
              setFilter={setFilterByApplication}
              setIsOpenFilter={setIsOpenApplication}
            />
            )}
          </div>
        </>
      );
    }
    return (
      <div className={styles.wrapper_filter} onMouseLeave={() => setIsOpenTier(false)}>
        <div
          role="presentation"
          onClick={() => setIsOpenTier(!isOpenTier)}
        >
          <InfoButton text={filterByTierForItems} />
        </div>
        {isOpenTier && (
        <DropdownFilter
          text={['Tier', 'Common', 'Uncommon', 'Rare', 'Epic', 'Legendary']}
          filter={filterByTierForItems}
          setFilter={setFilterByTierForItems}
          setIsOpenFilter={setIsOpenTier}
        />
        )}
      </div>
    );
  }, [
    equipmentType, isOpenFilter, isOpenSlot, isOpenClass, filter, filterByType, isOpenType,
    isOpenTier, search, filterByClass, filterByTier, filterBySlot, filterByApplication, isOpenApplication,
  ]);

  return (
    <div className={`${styles.header_wrapper_left} ${start ? styles.start : ''}`}>
      {filterContent}
    </div>
  );
};

export default Filters;
