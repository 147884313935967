import { createSlice } from '@reduxjs/toolkit';
import { postSound, postSpriteImg } from '../../../utils/requests';
import { notificationShowAction } from '../utils';
import {
  deleteSet, deleteSkin,
  deleteSpell,
  deleteSprite, getSetData, getSets, getSkins, getSpellData,
  getSpells, getSpriteData, getSprites, postSet, postSkin, postSpell, postSprite, updateSet, updateSkin, updateSpell,
} from '../../../utils/skillRequests';

export const skillConstructor = createSlice({
  name: 'skillConstructor',
  initialState: {
    loading: false,
    error: false,
    link: '',
    soundLink: '',
    soundIndex: null,
    sprites: null,
    spells: null,
    skins: [],
    sets: [],
    activeSprite: null,
    activeSpell: null,
    activeSkin: null,
    activeSet: null,
    newSprite: null,
    clearAction: false,
  },
  reducers: {
    onImagePostRequest: (state) => ({
      ...state,
      loading: true,
    }),
    onImagePostFailure: (state) => ({
      ...state,
      loading: false,
    }),
    onImagePostSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      link: payload,
    }),
    onSoundPostRequest: (state) => ({
      ...state,
      loading: true,
    }),
    onSoundPostFailure: (state) => ({
      ...state,
      loading: false,
    }),
    onSoundPostSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      soundLink: payload.data,
      soundIndex: payload.index,
    }),
    deleteSound: (state) => ({
      ...state,
      soundLink: '',
    }),
    onSpritePostRequest: (state) => ({
      ...state,
      loading: true,
    }),
    onSpritePostFailure: (state) => ({
      ...state,
      loading: false,
    }),
    onSpritePostSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      newSprite: payload,
    }),
    onSpritesGetRequest: (state) => ({
      ...state,
      loading: true,
    }),
    onSpritesGetSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      sprites: payload,
    }),
    onSpritesGetFailure: (state) => ({
      ...state,
      loading: false,
    }),
    deleteSpriteImg: (state) => ({
      ...state,
      link: '',
    }),
    onFailureRequest: (state) => ({
      ...state,
      loading: false,
    }),
    onSuccessRequest: (state) => ({
      ...state,
      loading: false,
    }),
    onRequest: (state) => ({
      ...state,
      loading: true,
    }),
    onSpellsGetRequest: (state) => ({
      ...state,
      loading: true,
    }),
    onSpellsGetSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      spells: payload,
    }),
    onSpellsGetFailure: (state) => ({
      ...state,
      loading: false,
    }),
    onGetSpriteDataRequest: (state) => ({
      ...state,
      loading: true,
    }),
    onGetSpriteDataSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      activeSprite: payload,
    }),
    onGetSpriteDataFailure: (state) => ({
      ...state,
      loading: false,
    }),
    onSpriteDeleteSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      sprites: (state.sprites?.results
        ? [...state.sprites.results]
        : [...state.sprites]).filter((elem) => elem.id !== payload),
    }),
    onSpellDeleteSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      spells: [...state.spells].filter((elem) => elem.id !== payload),
    }),
    onGetSpellDataRequest: (state) => ({
      ...state,
      loading: true,
    }),
    onGetSpellDataSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      activeSpell: payload,
    }),
    onSpellUpdateSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      activeSpell: payload,
    }),
    onGetSpellDataFailure: (state) => ({
      ...state,
      loading: false,
    }),
    onSkinsGetRequest: (state) => ({
      ...state,
      loading: true,
    }),
    onSkinsGetSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      skins: payload,
    }),
    onSkinsGetFailure: (state) => ({
      ...state,
      loading: false,
    }),
    onSkinUpdateSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      activeSkin: payload,
    }),
    onSkinDeleteSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      skins: [...state.skins].filter((elem) => elem.id !== payload),
    }),
    onSetsGetRequest: (state) => ({
      ...state,
      loading: true,
    }),
    onSetsGetSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      sets: payload,
    }),
    onSetsGetFailure: (state) => ({
      ...state,
      loading: false,
    }),
    onSetUpdateSuccess: (state) => ({
      ...state,
      loading: false,
      // activeSet: payload,
    }),
    onGetSetDataRequest: (state) => ({
      ...state,
      loading: true,
    }),
    onGetSetDataSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      activeSet: payload,
    }),
    onGetSetDataFailure: (state) => ({
      ...state,
      loading: false,
    }),
    onSetDeleteSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      activeSet: null,
      sets: [...state.sets].filter((elem) => elem.id !== payload),
    }),
    clearNewSpriteData: (state) => ({
      ...state,
      newSprite: null,
    }),
    clearSpritesFormAction: (state, { payload }) => ({
      ...state,
      clearAction: payload,
    }),
  },
});

// ---- actions ----
export const {
  onImagePostRequest, onImagePostFailure, onImagePostSuccess, deleteSpriteImg,
  onSoundPostRequest, onSoundPostFailure, onSoundPostSuccess, deleteSound,
  onSpritePostRequest, onSpritePostSuccess, onSpritePostFailure,
  onSpritesGetRequest, onSpritesGetSuccess, onSpritesGetFailure,
  onRequest, onSuccessRequest, onFailureRequest,
  onSpellsGetRequest, onSpellsGetSuccess, onSpellsGetFailure,
  onGetSpriteDataRequest, onGetSpriteDataSuccess, onGetSpriteDataFailure,
  onSpriteDeleteSuccess, onSpellDeleteSuccess,
  onGetSpellDataRequest, onGetSpellDataSuccess, onGetSpellDataFailure, onSpellUpdateSuccess,
  onSkinsGetRequest, onSkinsGetSuccess, onSkinsGetFailure, onSkinUpdateSuccess, onSkinDeleteSuccess,
  onSetsGetRequest, onSetsGetSuccess, onSetsGetFailure, onSetUpdateSuccess,
  onGetSetDataRequest, onGetSetDataSuccess, onGetSetDataFailure, onSetDeleteSuccess,
  clearNewSpriteData, clearSpritesFormAction,
} = skillConstructor.actions;

// ---- request actions ----
export const onSpritePost = (data) => async (dispatch) => {
  dispatch(onImagePostRequest());
  const response = await postSpriteImg(data);
  if (response) {
    dispatch(onImagePostSuccess(response.data));
  } else {
    dispatch(onImagePostFailure());
    dispatch(notificationShowAction(false, 'Something went wrong'));
  }
};

export const onSoundPost = (data, index) => async (dispatch) => {
  dispatch(onSoundPostRequest());
  const response = await postSound(data);
  if (response) {
    dispatch(onSoundPostSuccess({
      data: response.data,
      index,
    }));
  } else {
    dispatch(onSoundPostFailure());
    dispatch(notificationShowAction(false, 'Something went wrong'));
  }
};

export const spritePostAction = (data) => async (dispatch) => {
  dispatch(onSpritePostRequest());
  const response = await postSprite(data);
  if (response.status === 200) {
    dispatch(onSpritePostSuccess(response.data));
  } else {
    dispatch(onSpritePostFailure());
    dispatch(notificationShowAction(false, response?.data?.title || 'Something went wrong'));
  }
};

export const spritesGetAction = () => async (dispatch) => {
  dispatch(onSpritesGetRequest());
  const response = await getSprites();
  if (response.status === 200) {
    dispatch(onSpritesGetSuccess(response.data));
  } else {
    dispatch(onSpritesGetFailure());
  }
};

export const spriteDeleteAction = (id) => async (dispatch) => {
  dispatch(onRequest());
  const response = await deleteSprite(id);
  if (response.status === 200) {
    dispatch(onSpriteDeleteSuccess(id));
  } else {
    dispatch(onFailureRequest());
  }
};

export const spellDeleteAction = (id) => async (dispatch) => {
  dispatch(onRequest());
  const response = await deleteSpell(id);
  if (response.status === 200) {
    dispatch(onSpellDeleteSuccess(id));
  } else {
    dispatch(onFailureRequest());
  }
};

export const spellPostAction = (data) => async (dispatch) => {
  dispatch(onRequest());
  const response = await postSpell(data);
  if (response.status === 200) {
    dispatch(onSuccessRequest(true));
  } else {
    dispatch(onFailureRequest());
    dispatch(notificationShowAction(false, response?.data?.title || 'Something went wrong'));
  }
};

export const spellsGetAction = () => async (dispatch) => {
  dispatch(onSpellsGetRequest());
  const response = await getSpells();
  if (response.status === 200) {
    dispatch(onSpellsGetSuccess(response.data?.results));
  } else {
    dispatch(onSpellsGetFailure());
  }
};

export const spellUpdateAction = (id, data) => async (dispatch) => {
  dispatch(onRequest());
  const response = await updateSpell(id, data);
  if (response.status === 200) {
    dispatch(onSpellUpdateSuccess(data));
  } else {
    dispatch(onFailureRequest());
  }
};

export const getSpriteDataAction = (spriteName) => async (dispatch) => {
  dispatch(onGetSpriteDataRequest());
  const response = await getSpriteData(spriteName);
  if (response.status === 200) {
    dispatch(onGetSpriteDataSuccess(response.data));
  } else {
    dispatch(onGetSpriteDataFailure());
    dispatch(notificationShowAction(false, response?.data?.title || 'Something went wrong'));
  }
};

export const getSpellDataAction = (spellId) => async (dispatch) => {
  dispatch(onGetSpellDataRequest());
  const response = await getSpellData(spellId);
  if (response.status === 200) {
    dispatch(onGetSpellDataSuccess(response.data));
  } else {
    dispatch(onGetSpellDataFailure());
    dispatch(notificationShowAction(false, response?.data?.title || 'Something went wrong'));
  }
};

export const skinsGetAction = () => async (dispatch) => {
  dispatch(onSkinsGetRequest());
  const response = await getSkins();
  if (response.status === 200) {
    dispatch(onSkinsGetSuccess(response.data?.results));
  } else {
    dispatch(onSkinsGetFailure());
  }
};

export const skinPostAction = (data) => async (dispatch) => {
  dispatch(onRequest());
  const response = await postSkin(data);
  if (response.status === 200) {
    dispatch(onSuccessRequest(true));
  } else {
    dispatch(onFailureRequest());
    dispatch(notificationShowAction(false, response?.data?.title || 'Something went wrong'));
  }
};

export const skinUpdateAction = (id, data) => async (dispatch) => {
  dispatch(onRequest());
  const response = await updateSkin(id, data);
  if (response.status === 200) {
    dispatch(onSkinUpdateSuccess(data));
  } else {
    dispatch(onFailureRequest());
  }
};

export const skinDeleteAction = (id) => async (dispatch) => {
  dispatch(onRequest());
  const response = await deleteSkin(id);
  if (response.status === 200) {
    dispatch(onSkinDeleteSuccess(id));
  } else {
    dispatch(onFailureRequest());
  }
};

export const setsGetAction = () => async (dispatch) => {
  dispatch(onSetsGetRequest());
  const response = await getSets();
  if (response.status === 200) {
    dispatch(onSetsGetSuccess(response.data?.results));
  } else {
    dispatch(onSetsGetFailure());
  }
};

export const setPostAction = (data, reset) => async (dispatch) => {
  dispatch(onRequest());
  const response = await postSet(data);
  if (response.status === 200) {
    dispatch(onSuccessRequest(true));
    dispatch(setsGetAction());
    dispatch(notificationShowAction(true, response?.data?.title || 'Set was created successfully'));
    reset();
  } else {
    dispatch(onFailureRequest());
    dispatch(notificationShowAction(false, response?.data?.title || 'Something went wrong'));
  }
};

export const setUpdateAction = (id, data) => async (dispatch) => {
  dispatch(onRequest());
  const response = await updateSet(id, data);
  if (response.status === 200) {
    dispatch(onSetUpdateSuccess(data));
    dispatch(setsGetAction());
    dispatch(notificationShowAction(true, response?.data?.title || 'Set was updated successfully'));
  } else {
    dispatch(onFailureRequest());
    dispatch(notificationShowAction(false, response?.data?.title || 'Something went wrong'));
  }
};

export const getSetDataAction = (id) => async (dispatch) => {
  dispatch(onGetSetDataRequest());
  const response = await getSetData(id);
  if (response.status === 200) {
    dispatch(onGetSetDataSuccess(response.data));
  } else {
    dispatch(onGetSetDataFailure());
    dispatch(notificationShowAction(false, response?.data?.title || 'Something went wrong'));
  }
};

export const setDeleteAction = (id, reset) => async (dispatch) => {
  dispatch(onRequest());
  const response = await deleteSet(id);
  if (response.status === 200) {
    dispatch(onSetDeleteSuccess(id));
    reset();
  } else {
    dispatch(onFailureRequest());
  }
};

// ---- selectors ----
export const selectConstructorLoading = (state) => state.skillConstructor.loading;
export const selectSpriteLink = (state) => state.skillConstructor.link;
export const selectSoundLink = (state) => ({
  soundLink: state.skillConstructor.soundLink,
  soundIndex: state.skillConstructor.soundIndex,
});
export const selectSprites = (state) => state.skillConstructor.sprites;
export const selectSpells = (state) => state.skillConstructor.spells;
export const selectSkins = (state) => state.skillConstructor.skins;
export const selectSets = (state) => state.skillConstructor.sets;
export const selectActiveSprite = (state) => state.skillConstructor.activeSprite;
export const selectActiveSpell = (state) => state.skillConstructor.activeSpell;
export const selectActiveSkin = (state) => state.skillConstructor.activeSkin;
export const selectActiveSet = (state) => state.skillConstructor.activeSet;

export const selectNewSprite = (state) => state.skillConstructor.newSprite;

export const selectClearAction = (state) => state.skillConstructor.clearAction;
