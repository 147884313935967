import React from 'react';
import { useDispatch } from 'react-redux';
import styles from '../../../Pawns/Cards/Cards.module.scss';
import portraitStyles from './SinglePortrait.module.scss';
import { setSelectedPortrait } from '../../../../../../store/slices/portraits';

const SinglePortrait = ({ elem, text }) => {
  const dispatch = useDispatch();
  return (
    <div
      role="presentation"
      className={`${styles.card} ${styles[elem.tier]} ${portraitStyles.card}`}
      onClick={() => dispatch(setSelectedPortrait(elem))}
    >
      <div className={`${styles.card_content} ${portraitStyles.card_content}`}>
        <img src={`https://i.bimium.link/${elem.image}`} alt="portrait" />
        {elem.nftStatus === 'Listed' && text !== 'Market' && <div className={portraitStyles.select} />}
      </div>
    </div>
  );
};

export default SinglePortrait;
