import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import styles from '../SkillUtils.module.scss';
import {
  deleteSpriteImg, onSpritePost,
  selectConstructorLoading, selectSkins,
  selectSpriteLink, skinDeleteAction, skinPostAction,
  skinsGetAction, skinUpdateAction,
} from '../../../store/slices/skillConstructor';
import loader from '../../../static/img/loader.gif';
import deleteImg from '../../../static/img/header/balancebar/dropdown/Decline button.png';

const SkinsForm = () => {
  const dispatch = useDispatch();

  const skinsList = useSelector(selectSkins);
  const loading = useSelector(selectConstructorLoading);
  const spriteLink = useSelector(selectSpriteLink);

  const {
    register, setValue, handleSubmit, watch,
  } = useForm();

  const selectedNameList = watch('nameList');

  const onSubmit = (data) => {
    if (['Archer', 'Mage', 'Warrior'].includes(selectedNameList)) {
      dispatch(skinPostAction({ ...data, isPawnSkin: true }));
    } else {
      dispatch(skinUpdateAction(selectedNameList, { ...data, isPawnSkin: true }));
    }
  };

  const onSpriteSelect = (file) => {
    const formData = new FormData();
    formData.append('image', file);
    dispatch(onSpritePost(formData));
  };

  useEffect(() => {
    if (spriteLink) {
      setValue('path', `https://i.bimium.link/${spriteLink}`);
    }
  }, [spriteLink]);

  useEffect(() => {
    if (['Archer', 'Mage', 'Warrior'].includes(selectedNameList)) {
      setValue('class', selectedNameList);
      setValue('name', '');
      setValue('path', '');
    } else if (selectedNameList) {
      const selectedSkin = skinsList.filter((elem) => elem.id === selectedNameList)[0];
      setValue('class', selectedSkin.class);
      setValue('name', selectedSkin.name);
      setValue('path', selectedSkin.path);
    }
  }, [selectedNameList]);

  useEffect(() => {
    dispatch(skinsGetAction());
  }, []);

  return (
    <form
      className={styles.form}
      onSubmit={handleSubmit(onSubmit)}
    >
      {loading
        ? (
          <div className={styles.loader_wrap}>
            <img src={loader} alt="" />
          </div>
        )
        : null}
      <div className={styles.scroll}>
        <select
          {...register('nameList')}
        >
          <option value="" hidden>Select</option>
          <optgroup label="Archer">
            <option value="Archer">
              New Archer skin
            </option>
            {skinsList
              .filter((elem) => elem.class === 'Archer')
              .map((elem) => (
                <option
                  value={elem.id}
                  key={elem.id}
                >
                  {elem.name}
                </option>
              ))}
          </optgroup>
          <optgroup label="Mage">
            <option value="Mage">
              New Mage skin
            </option>
            {skinsList
              .filter((elem) => elem.class === 'Mage')
              .map((elem) => (
                <option
                  value={elem.id}
                  key={elem.id}
                >
                  {elem.name}
                </option>
              ))}
          </optgroup>
          <optgroup label="Warrior">
            <option value="Warrior">New Warrior skin</option>
            {skinsList
              .filter((elem) => elem.class === 'Warrior')
              .map((elem) => (
                <option
                  value={elem.id}
                  key={elem.id}
                >
                  {elem.name}
                </option>
              ))}
          </optgroup>
        </select>
        <div className={styles.form_row}>
          <div>Name</div>
          <input
            type="text"
            {...register('name', { required: true })}
          />
        </div>
        <div className={styles.form_row}>
          <div>Class</div>
          <select
            {...register('class')}
          >
            <option value="" hidden>Select</option>
            <option value="Archer">Archer</option>
            <option value="Mage">Mage</option>
            <option value="Warrior">Warrior</option>
          </select>
        </div>
        <div className={styles.form_row}>
          <div>Sprite URL</div>
          <div>Add link to .png image, or upload .png image</div>
          <div style={{ display: 'flex', columnGap: '10px' }}>
            <input
              type="text"
              {...register('path', { required: true })}
              disabled={spriteLink}
            />
            <img
              role="presentation"
              src={deleteImg}
              alt="del"
              width="20px"
              height="20px"
              onClick={() => {
                dispatch(deleteSpriteImg());
                setValue('path', '');
              }}
            />
          </div>
          {!spriteLink
            ? (
              <input
                style={{ marginTop: '10px' }}
                type="file"
                accept="image/png"
                onChange={(e) => onSpriteSelect(e.target.files[0])}
              />
            )
            : null}
        </div>
        <div className={styles.buttons_block}>
          <button type="submit">Save</button>
          {selectedNameList && !['Archer', 'Mage', 'Warrior'].includes(selectedNameList)
            ? (
              <button
                type="button"
                onClick={() => dispatch(skinDeleteAction(selectedNameList))}
              >
                Delete Skin
              </button>
            )
            : null}
        </div>
      </div>
    </form>
  );
};

export default SkinsForm;
